import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { EmployeesService } from '../../services/employees/employees.service';
import { AdminService } from '../../services/admin/admin.service';
import { IPermissioonModel } from '../../models/addOn/permission.model';
import { QuestionnairesService } from '../../services/questionnaires/questionnaires.service';
import { UserModel } from 'src/app/models/users/users.model';
import { UsersService } from '../../services/users/users.service';
import { CompaniesService } from '../../services/companies/companies.service';
import { suscriberDestroyMiddleware } from '../../middleware/suscribers-destroy.middleware';
import { ICompanyModel, ICompanyModelGet } from '../../models/companies/companies.model';
import { HomeLandingComponent } from 'src/app/modules/home/home-landing/home-landing.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { permissionsOff } from '../../constants/permissionBase';
import { ModalService, StatisticsService } from 'src/app/services';
import { ICreateChangelogModel } from 'src/app/models/changelogs/changelogs.model';
import { ChangelogsService } from 'src/app/services/changelogs/changelogs.service';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { AccessService } from '../../services/access/access.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChooseRoleComponent } from '../choose-role/choose-role.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@suscriberDestroyMiddleware()
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('openModalCropHeader') openModalCropHeader: ElementRef; // para crop logo header
  @ViewChild('dismisseModalHead') dismisseModalHead: ElementRef; // modal cambio de logo
  @ViewChild('file', { static: false }) fileInput: ElementRef;
  // Bandera que nos indica si es la primer vez que inicia sesión (Para no moatrar siempre el Onboarding)
  firstTime = false;
  // Almacena información del usuario logueado
  userInfoLogged;
  // Alamacena suscripciones activas
  subscribers: any = [];
  // id de la empresa seleccionada
  idCompanySelected: string;
  companyInfo;
  logo = "";
  /** Bandera para mostrar menu de usuario */
  showOptionsMenu = false;
  // showNotifications = false;
  // newNotificationsFlag = false;
  companies: ICompanyModelGet[] = [];
  companiSel: ICompanyModelGet = undefined;
  logoUser;

  folders = {
    user: 'profilePicture',
    company: 'logoCompany'
  };

  /** Imagen seleccionada a guardar */
  demoBackground: {
    base64: string,
    formData: FormData,
    name: string
  } = {
      base64: '',
      formData: null,
      name: ''
    };

  croppedImage: ImageCroppedEvent;
  croppedImagePreview: string;
  imageChangedEvent: any = '';
  savedLogoId: any;

  notificationsPool: any = [];
  newNotification = false;
  /** Almacena los permisos */
  userPermissions: IPermissioonModel = Object.assign({}, permissionsOff);

  notifications: {
    original: any[],
    toShow: any[],
    newNotification: boolean
  } = {
      original: [],
      toShow: [],
      newNotification: false
    };

  isMulti: boolean = false;

  modalRef?: BsModalRef;

  higherRole = '';

  constructor(
    public auth: AuthService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    public employeeService: EmployeesService,
    private user: UsersService,
    private questionary: QuestionnairesService,
    private companiesService: CompaniesService,
    private home: HomeLandingComponent,
    private modalS: ModalService,
    private historyS: ChangelogsService,
    private statistics: StatisticsService,
    private userService: UsersService,
    private accessSv: AccessService,
    private modalService: BsModalService,
    private companieService: CompaniesService,
    private authService: AuthService
  ) {

    this.subscribers.permissions = this.user.userPermissions.subscribe((res) => (res && !res.length && res.length !== 0) ? (this.userPermissions = res) : '');
    if (sessionStorage.getItem('idCompanySelected')) {
      this.companiesService.getCompany(sessionStorage.getItem('idCompanySelected')).subscribe((response) => {
        if (response) {
          //console.log(response)
          this.logo = response.logo;
        } else {
          this.auth.logoCompanySelected.next(this.logo);
        }
      });
    }
    this.auth.logoCompanySelected.subscribe(res => {
      this.logo = res;
    });

    this.authService.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
    });
  }

  /**
   * Inicialización de componente
   */
  ngOnInit() {
    this.auth.firstTimeLogged.subscribe((response) => {
      this.firstTime = response;
    });
    this.auth.isLogged.subscribe(value => {
      if (value) {
        const idCompany = sessionStorage.getItem('idCompanySelected');
        if (idCompany && this.userInfoLogged?._id) {
          this.userService.getNotificationsByCompany(this.userInfoLogged._id, 4, idCompany).subscribe();
        }
      }
    });

    this.user.notifications.subscribe((value: any) => {
      if (value) {
        this.notifications.original = [];
        this.notifications.toShow = [];
        this.notifications.original = value.slice();
        this.notifications.toShow = this.notifications.original.reverse().slice(0, 4);
        this.checkNewNotification();

      }
    });

    this.subscribers.user = this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
      this.logoUser = this.userInfoLogged.profilePicture;
    });

    this.auth.allCompanies.subscribe((response) => {
      if (response && response.length !== 0) {
        this.companies = response;
        //console.log(this.companies[0]._id);
        sessionStorage.setItem('idCompanySelected', this.companies[0]._id);
        this.companiSel = response.find((comp: any) => comp._id === sessionStorage.getItem('idCompanySelected'));
      }
    });
    // Obtención del id de la empresa seleccionada
    this.idCompanySelected = sessionStorage.getItem('idCompanySelected');

    this.subscribers.consultants = this.user.allUsers.subscribe((value: UserModel[]) => {

      if (value.length > 0) {
        setTimeout(() => {
          const user: UserModel = value.find((userAd: UserModel) => userAd._id === this.userInfoLogged._id);
          if (user) {
            this.userInfoLogged.profilePicture = user.profilePicture;
            this.userInfoLogged.role = user.role;
            this.userInfoLogged.email = user.email;
            this.userInfoLogged.name = user.name;
            this.userInfoLogged.lastName = user.lastName;
          }
        }, 1);
      }

    });

    this.higherRole = sessionStorage.getItem('higherRole') || 'employee';

    if (sessionStorage.getItem('higherRole')) {

      this.higherRole = sessionStorage.getItem('higherRole');

    } else {
      this.auth.higherRole.subscribe(hRole => {
        this.higherRole = hRole;
      });
    }
  }

  checkNewNotification() {
    for (let index = 0; index < this.notifications.toShow.length; index++) {
      let element = this.notifications.toShow[index];
      if (!element.view) {
        this.newNotification = true;
        index = this.notifications.toShow.length;
      }
    }
  }

  nextBoard(boardingNumber) {
    this.auth.onBoard(boardingNumber);
    if (this.auth.onBoarding.value === 6) {
      this.document.body.classList.remove('overflow-board');
    }
  }

  createEmployee() {
    this.employeeService.isEditing.next({ value: false, id: '' });
  }

  isEmployee() {
    this.questionary.isEmployee.next(false);
    this.companiesService.editingProject.next({
      isEditing: false,
      idProject: '',
      idEmplyoees: [],
      nameProject: ''
    });
  }

  signOut() {
    this.auth.signOut();
  }

  onClickedOutside(e: Event) {
    this.showOptionsMenu = false;
  }
  /**
   * Destructor
   * @memberof PrivateRouteGuard
   */
  ngOnDestroy() { }

  sendTags(section: string) {
    switch (section) {
      // case 'plans':
      //   window.dataLayer.push({
      //     event: 'gtmevent',
      //     gtmCategory: 'headerLinks',
      //     gtmAction: 'goToPlans',
      //     gtmLabel: '2bt_header_plans'
      //   });
      //   break;

      case 'contact':
        window.dataLayer.push({
          event: 'gtmevent',
          gtmCategory: 'headerLinks',
          gtmAction: 'goToContact',
          gtmLabel: '2bt_header_contact'
        });
        break;

      case 'registry':
        window.dataLayer.push({
          event: 'gtmevent',
          gtmCategory: 'headerLinks',
          gtmAction: 'goToRegistry',
          gtmLabel: '2bt_header_registry'
        });
        break;
    }
  }

  /*   showHideNotifications(event)
    {
      event.preventDefault();
      event.stopPropagation();
      this.showNotifications = !this.showNotifications;
    }
  
    showHideModalNotifications(flag)
    {
      this.showNotifications = flag;
    } */

  changeCompany(data) {

    if (data != this.idCompanySelected) {
      Swal.fire({
        title: 'Cargando', text: 'Obteniendo datos de usuarios', icon: 'info',
        allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false
      });
      Swal.showLoading();
    }

    const company = this.companies.find(element => element._id === data);

    this.auth.idCompanieSelected.next(data);
    sessionStorage.setItem('idCompanySelected', data);
    this.auth.nameCompanySelected.next(company.companyName);
    this.auth.logoCompanySelected.next(company.logo);

    // if(this.router.url.includes('/home/welcome')){
    //   this.home.ngOnInit();
    // }

    this.userService.getNotificationsByCompany(sessionStorage.getItem('idUser'), 4, sessionStorage.getItem('idCompanySelected')).subscribe();
    this.router.navigate(['/home/init-page']);
    this.companieService.themeSelected.set(company.theme)
    setTimeout(() => {
      Swal.close();
      window.location.reload()
    }, 200);
  }
  cancelLoad() {
  }

  /*
  * Muesta y cierra el modal
  */
  fileChangeEvent(event: any) {
    this.openModalCropHeader.nativeElement.click();
    this.imageChangedEvent = event;
    $('#modalCropCoverHeader').removeClass('fade')
    $(".modal-backdrop").remove();
  }
  closeModal() {
    if (!this.logo) {
      this.fileInput.nativeElement.value = "";
    }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.croppedImagePreview = event.base64;
  }

  changeImage() {
    if (this.croppedImagePreview) {
      let arr = this.croppedImagePreview.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const idImage = Math.random().toString(26).replace('0.', '');
      const f = new File([u8arr], `${idImage}.png`, { type: mime });
      this.demoBackground.formData = new FormData();
      const array = f.type.split('/');
      const imgExt = `.${array[1]}`;
      const imageIdName = `${idImage}${imgExt}`;
      const dataName = `${imageIdName},${this.folders.company}`;

      const reader = new FileReader();
      reader.readAsDataURL(f);

      reader.onload = (event) => {
        this.demoBackground.base64 = reader.result.toString();
        this.demoBackground.formData.append('file', f, dataName);
        this.demoBackground.name = imageIdName;
      };

      let objDataCompany: any = Object.assign({}, this.companiSel);;
      objDataCompany.logo = imageIdName;
      objDataCompany.id = objDataCompany._id;
      let keys = ['commercialAddress', 'fiscalAddress', 'sameAddress', 'logo', 'companySector', 'businessName', 'companyName', 'id']
      for (const iterator of Object.keys(objDataCompany)) {
        if (!keys.includes(iterator)) {
          delete objDataCompany[iterator];
        }
      }
      delete objDataCompany.commercialAddress;
      delete objDataCompany.fiscalAddress;
      delete objDataCompany.businessName;
      delete objDataCompany.sameAddress;
      delete objDataCompany.companySector;

      this.subscribers.update = this.companiesService.updateCompanyImg(objDataCompany).subscribe((resp: any) => {
        if (resp) {
          this.subscribers.logo = this.companiesService.addImage(this.demoBackground.formData).subscribe((res: any) => {
            if (res.clave === 'OK') {
              this.auth.logoCompanySelected.next(
                this.demoBackground.name
              );
              Swal.close();
              this.modalS.successful({
                title: '¡Operación exitosa!'
              });
              /** Objeto que guarda datos para historial */
              const changelog: ICreateChangelogModel = {
                idCompany: this.idCompanySelected,
                change: {
                  img: this.userInfoLogged.profilePicture
                    ? this.userInfoLogged.profilePicture
                    : '',
                  name: `${this.userInfoLogged.name} ${this.userInfoLogged.lastName ? this.userInfoLogged.lastName : ''
                    } ${this.userInfoLogged.secondSurname
                      ? this.userInfoLogged.secondSurname
                      : ''
                    }`,
                  date: new Date(),
                  action: `Editó empresa`// ${resp.content.companyName},
                },
              };
              /** Funcion que envia los datos de la historia */
              this.historyS.setChangelog(changelog);
            }
          })
        }
      });
    }
  }

  pressNotification(item) {
    if (item.id) {
      this.user.viewNotification(item._id).subscribe();
      switch (item.type) {
        case 'questionnaire':
          this.statistics.getStatistics(item.secondId, item.id).subscribe(stats => {
            Swal.close();
            if (stats.content.questionnairesSend.answer > 0) {
              sessionStorage.setItem('nameProject', item.id);
              this.router.navigate(['/NOM-projects/report'])
              this.statistics.statisticsSelected.next(stats);
            } else {
              this.router.navigate(['/NOM-projects/send']);
            }
          });
          break;
        case 'questionnaire-nom':
          const url = this.router.createUrlTree(['/guide-one'])
          window.open("#" + url.toString() + '?idEmployee=' + item.secondId + '&idCompany=' + '&idProject=' + item.id + '', '_blank');
          break;
        case 'invitation_guest':
          sessionStorage.setItem('invitationSelected', item.id);
          sessionStorage.setItem('guest', item.secondId);
          this.router.navigateByUrl('/invite/menu/info');
          break;
        case 'invitation_company':
          sessionStorage.setItem('invitationSelected', item.id);
          sessionStorage.setItem('idCompanySelected', item.secondId);
          this.router.navigateByUrl('/invite/menu/info');
          break;
        case 'invitation_manage':
          sessionStorage.setItem('invitationSelected', item.id);
          sessionStorage.setItem('idCompanySelected', item.secondId);
          this.router.navigateByUrl('/invite/menu/guests');
          break;
        case 'suggestions':
          this.router.navigateByUrl('/box/list');
          break;
        case 'comment':
          let img = item.commentary.img ? "https://2bcore-site.s3.us-east-2.amazonaws.com/intranet/profilePicture/" + item.commentary.img : "/assets/img/person.svg"
          Swal.fire({
            confirmButtonText: 'Aceptar',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-secondary fs-14 mx-auto d-block justify-content-center d-flex align-items-center'
            }, html: `
            <div class="row p-2">
              <div class="col-2 p-0 px-sm-3 py-2 text-center">
                <img alt="User" class="user-image rounded-circle" style="
                  width: 54px;
                  height: 54px;
                  border: 1px solid #D4D4D4;
                "
                  src="`+ img + `"
                >
              </div>
              <div class="card col-10">
                <div class="card-header p-2">
                  <p class="mb-0 mt-1 fw-bold fs-14 text-justify">`+ item.commentary.name + `</p>` +
              `</div>
                <div class="card-body text-justify p-2">
                  <p class="mb-0 mt-1">`+ item.commentary.comment + `</p>
                </div>
              </div>
            </div>
            `
          });

          break;
        case 'publication':
          sessionStorage.setItem('postSelected', item.id);
          this.router.navigateByUrl('/posts/info');
          if (this.router.url.includes('/posts/info')) {
            window.location.reload();
          }
          break;
        case 'comment-publication':
          sessionStorage.setItem('postSelected', item.id);
          sessionStorage.setItem('comment', item.secondId);
          this.router.navigateByUrl('/posts/info');
          if (this.router.url.includes('/posts/info')) {
            window.location.reload();
          }
          break;
        case 'comment-report':
          sessionStorage.setItem('postSelected', item.secondId);
          sessionStorage.setItem('comment', item.id);
          this.router.navigateByUrl('/posts/info');
          if (this.router.url.includes('/posts/info')) {
            window.location.reload();
          }
          break;
        case 'comment-response':
          sessionStorage.setItem('postSelected', item.id);
          sessionStorage.setItem('comment', item.secondId);
          this.router.navigateByUrl('/posts/info');
          if (this.router.url.includes('/posts/info')) {
            window.location.reload();
          }
          break;
        case 'comment-innapropiate-admin':
          sessionStorage.setItem('postSelected', item.secondId);
          sessionStorage.setItem('comment', item.id);
          this.router.navigateByUrl('/posts/info');
          if (this.router.url.includes('/posts/info')) {
            window.location.reload();
          }
          break;
        case 'quiz'://Todos han respondido el quiz
          sessionStorage.setItem('idPeriodSelect', item.id);
          sessionStorage.setItem('PeriodOfNotification', 'true');
          this.router.navigateByUrl('/work/work');
          if (this.router.url.includes('/work/work')) {
            window.location.reload();
          }
          break;
        case 'quiz-missing': //Cuestionario sin responder
          const urlQuiz = this.router.createUrlTree(['/quiz'])
          window.open("#" + urlQuiz.toString() + '?idProject=' + item.id + '&idEmployee=' + item.secondId + '', '_blank');
          break;
        default:
          break;
      }
    }
  }

  goToHome() {
    this.router.navigate(['/home/init-page']);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  //-TODO: validar acceso al elemento del menú con las validaciones completas
  viewItem(item: string, submenu?: string): boolean {

    return this.accessSv.hasAccess(item, submenu);

  }

  changeRole() {

    this.modalRef = this.modalService.show(ChooseRoleComponent, {
      animated: true,
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    });
  }

  goToProfile() {
    this.employeeService.isEditing.next({ value: true, id: this.userInfoLogged.idEmployee });
    this.questionary.isEmployee.next(true)

    this.questionary.getEmployeeQuests(this.auth.idCompanieSelected.getValue(), this.userInfoLogged._id).subscribe((res) => {
      if (res) {
        this.employeeService.projectName.next(res.content[0] ? res.content[0] : {});
      }
    })
    this.employeeService.projectSelected.next(undefined);
    this.router.navigate(['/employees/personal-profile']);
  }

  toggleMenu() {
    this.auth.sideBarToggled.next(!this.auth.sideBarToggled.getValue());
  }

}
