
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrivateRegisterRouteGuard } from './private-route/private-register-route.guard';
import { SimplePrivateRouteGuard } from './private-route/simple-private-route.guard';
/**Guards */
import { PrivateRouteGuard } from './private-route/private-route.guard';
import { PublicRouteGuard } from './public-route/public-route.guard';
// tslint:disable-next-line:comment-type
/**
 * Modulo general de los guards
 * @export
 * @class GuardsModule
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [PrivateRouteGuard, PublicRouteGuard, PrivateRegisterRouteGuard,SimplePrivateRouteGuard]
})
export class GuardsModule { }
