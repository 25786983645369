import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {

  public override monthViewDayNumber({ date, locale }: DateFormatterParams): string {
    if (!locale) locale = 'en';
    return formatDate(date, 'dd', locale);
  }

}