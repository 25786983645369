import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { quizzes } from './quiz/questions';
import { ModalService } from 'src/app/services';
import { PeriodsComponent } from './periods/periods.component';
import { v4 as uuidv4 } from 'uuid';
import { IPermissioonModel } from 'src/app/models/addOn/permission.model';
import { permissionsOff } from 'src/app/constants/permissionBase';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-working',
  templateUrl: './working.component.html',
  styleUrls: ['./working.component.scss']
})
export class WorkingComponent implements OnInit, OnDestroy{
  @ViewChild('sendQuizModal') sendQuizModal: ElementRef;
  @ViewChild('closebutton') closebutton: ElementRef;

  loading = "d-none"
  public chartOptions: Partial<any>;
  /***alamcena suscripciones activas */
  subscribers: any = [];
  /** Id de la empresa seleccionada */
  idCompanie = '';
  // Almacena la lista de periodos de la empresa
  periods = [];

  //Almacena el periodo activo
  activePeriod;

    /** Almacena los permisos */
  userPermissions: IPermissioonModel = Object.assign({} , permissionsOff);

  // Almacena información del usuario logueado
  userInfoLogged;
  totalLenght: any;
  page: number = 1;
  quiz = quizzes.slice(0, quizzes.length);
  infoPeriod;
  promedios = [];
  generatePDF = "d-none";
  actualPeriod = "null";
  reportType = "1";
  selectedPeriod;
  selectedWorkCenters = [];
  selectedAreas = [];
  areas = [];
  filter;

  hours:Array<string> = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'];
  today = new Date();
  changeDate: any = {
    date: '',
    hour: '00:00'
  }
  dateInputInvalid:boolean = false;
  hourInputInvalid: boolean = false;

  limitDateCurretPeriod = {
    day: '',
    hour: ''
  };

  constructor(
    private quizService: QuizService,
    public router: Router,
    private modal: ModalService,
    private periodsComponent: PeriodsComponent,
    private user: UsersService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.subscribers.user = this.authService.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
    });
    this.subscribers.permissions = this.user.userPermissions.subscribe((res) => (res && !res.length && res.length !== 0) ? (this.userPermissions = res) : '');
    if(sessionStorage.getItem("idPeriodSelect"))
      this.actualPeriod = sessionStorage.getItem("idPeriodSelect");
    this.activePeriod = sessionStorage.getItem("idPeriodSelect");
    //sessionStorage.removeItem("idPeriodSelect");
    this.idCompanie = sessionStorage.getItem('idCompanySelected');
    this.subscribers.periods =  this.quizService.getPeriods(this.idCompanie).subscribe( response => {
       this.periods = response;
       if(this.actualPeriod == sessionStorage.getItem('lastPeriodFilter')){
        this.selectedWorkCenters = JSON.parse(sessionStorage.getItem('selectedWorkcenter'));
        this.selectedAreas = JSON.parse(sessionStorage.getItem('selectedAreas'))
        this.reportType = sessionStorage.getItem('reportType');
        let index = this.periods.findIndex(period => period._id == this.actualPeriod);
        this.selectedPeriod = this.periods[index];
        this.selectedPeriod.workCenter.forEach(workcenter => {
          workcenter.area.forEach(element => {
            let item = {
              workCenter: workcenter._id,
              area: element,
              unique: true
            }
            if(this.areas.findIndex(el => el.area == element) >= 0){
              item.unique = false;
            }
            if(this.selectedWorkCenters.findIndex(work => work._id == workcenter._id) >= 0){
              this.areas.push(item);
            }
          })
        });
      }
    });
    this.quizService.idPeriodSelect.subscribe((idPeriodSelect:string) =>{
      if(sessionStorage.getItem("idPeriodSelect")){
        if(sessionStorage.getItem('filterQuiz')){
          let item = JSON.parse(sessionStorage.getItem('filterQuiz'));
          this.filter = item;
          this.filter.centers = [];
          this.filter.areas = [];
          this.filter.works.forEach(element => {
            this.filter.centers.push(element.workcenter);
            element?.areas.forEach(area => {
              this.filter.areas.push(area);
            });
          });
          this.quizService.getFilterPeriod(item.id, item.works).subscribe(response => {
            if(response){
              this.infoPeriod = response;
              let labels = [];
              this.promedios = [];
              this.infoPeriod.promedio_por_indicador.forEach(element => {
                this.promedios.push(element.promedio_indicador);
                labels.push(element.indicador);
              });
              if (this.infoPeriod.promedio_general<= 20) {
                this.infoPeriod.colorGeneral = 'one'
                this.infoPeriod.imageGeneral = "assets/img/stats/one.png"
              } else if(this.infoPeriod.promedio_general<= 40 && this.infoPeriod.promedio_general> 20) {
                this.infoPeriod.colorGeneral = 'two'
                this.infoPeriod.imageGeneral = "assets/img/stats/two.png"
              } else if(this.infoPeriod.promedio_general<= 60 && this.infoPeriod.promedio_general> 40) {
                this.infoPeriod.colorGeneral = 'three'
                this.infoPeriod.imageGeneral = "assets/img/stats/three.png"
              } else if(this.infoPeriod.promedio_general<= 80 && this.infoPeriod.promedio_general> 60) {
                this.infoPeriod.colorGeneral = 'four'
                this.infoPeriod.imageGeneral = "assets/img/stats/four.png"
              } else if(this.infoPeriod.promedio_general<= 100 && this.infoPeriod.promedio_general> 80) {
                this.infoPeriod.colorGeneral = 'five'
                this.infoPeriod.imageGeneral = "assets/img/stats/five.png"
              }
              sessionStorage.setItem('infoPeriod', JSON.stringify(this.infoPeriod))
              this.chartOptions = {
                series: [
                  {
                    name: "Result",
                    data: this.promedios
                  }
                ],
                colors: [
                  function ({ value }) {
                    if (value <= 20) {
                      return "#FEAD18";
                    } else if(value <= 40 && value > 20) {
                      return "#FE746A";
                    }
                    else if(value <= 60 && value > 40) {
                      return "#CE496D";
                    }
                    else if(value <= 80 && value > 60) {
                      return "#966ACC";
                    }
                    else if(value <= 100 && value > 80) {
                      return "#33529C";
                    }
                  }
                ],
                chart: {
                  type: "bar",
                  height: 500,
                  width: 800,
                  toolbar: {
                    show: false,
                  },
                  events: {
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels:{ position: 'top'},
                    xaxis: {position: 'top'},
                  }
                },
                dataLabels: {
                  enabled: false,
                  distributed: true,
                  offsetX: 1100,
                  offsetY: 0,
                  style:{
                   fontSize: '18px',
                   fontWeight: 'bold',
                   colors: ['#33529C', '#966ACC', '#FEAD18'
                   ]
                 },
                },

                xaxis: {
                  categories: labels,
                  tickPlacement: 'between',
                  max: 100,
                  labels: {
                    show: true,
                    align: 'left',
                    position: "left",

                  },
                  axisTicks: {
                    show: false,
                },
                },

                yaxis:{
                  show: false,
                  position: 'top',
                  offsetY: 30,
                  labels: {
                    show: true,
                    align: 'left',
                  },
                },
                legend: {
                   position: 'left',
                },
                tooltip:{
                  enabled: false,
                }
              };

            }
          });
        }else{
        this.quizService.getPeriod(sessionStorage.getItem("idPeriodSelect")).subscribe(response =>{
          if(response.promedio_por_indicador){
            this.infoPeriod = response;
            let labels = [];
            this.promedios = [];
            this.infoPeriod.promedio_por_indicador.forEach(element => {
              this.promedios.push(element.promedio_indicador);
              labels.push(element.indicador);
            });
            if (this.infoPeriod.promedio_general<= 20) {
              this.infoPeriod.colorGeneral = 'one'
              this.infoPeriod.imageGeneral = "assets/img/stats/one.png"
            } else if(this.infoPeriod.promedio_general<= 40 && this.infoPeriod.promedio_general> 20) {
              this.infoPeriod.colorGeneral = 'two'
              this.infoPeriod.imageGeneral = "assets/img/stats/two.png"
            } else if(this.infoPeriod.promedio_general<= 60 && this.infoPeriod.promedio_general> 40) {
              this.infoPeriod.colorGeneral = 'three'
              this.infoPeriod.imageGeneral = "assets/img/stats/three.png"
            } else if(this.infoPeriod.promedio_general<= 80 && this.infoPeriod.promedio_general> 60) {
              this.infoPeriod.colorGeneral = 'four'
              this.infoPeriod.imageGeneral = "assets/img/stats/four.png"
            } else if(this.infoPeriod.promedio_general<= 100 && this.infoPeriod.promedio_general> 80) {
              this.infoPeriod.colorGeneral = 'five'
              this.infoPeriod.imageGeneral = "assets/img/stats/five.png"
            }
            sessionStorage.setItem('infoPeriod', JSON.stringify(this.infoPeriod))
            this.chartOptions = {
              series: [
                {
                  name: "Result",
                  data: this.promedios
                }
              ],
              colors: [
                function ({ value }) {
                  if (value <= 20) {
                    return "#FEAD18";
                  } else if(value <= 40 && value > 20) {
                    return "#FE746A";
                  }
                  else if(value <= 60 && value > 40) {
                    return "#CE496D";
                  }
                  else if(value <= 80 && value > 60) {
                    return "#966ACC";
                  }
                  else if(value <= 100 && value > 80) {
                    return "#33529C";
                  }
                }
              ],
              chart: {
                type: "bar",
                height: 500,
                width: 800,
                toolbar: {
                  show: false,
                },
                events: {
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels:{ position: 'top'},
                  xaxis: {position: 'top'},
                }
              },
              dataLabels: {
                enabled: false,
                distributed: true,
                offsetX: 1100,
                offsetY: 0,
                style:{
                 fontSize: '18px',
                 fontWeight: 'bold',
                 colors: ['#33529C', '#966ACC', '#FEAD18'
                 ]
               },
              },

              xaxis: {
                categories: labels,
                tickPlacement: 'between',
                max: 100,
                labels: {
                  show: true,
                  align: 'left',
                  position: "left",

                },
                axisTicks: {
                  show: false,
              },
              },

              yaxis:{
                show: false,
                position: 'top',
                offsetY: 30,
                labels: {
                  show: true,
                  align: 'left',
                },
              },
              legend: {
                 position: 'left',
              },
              tooltip:{
                enabled: false,
              }
            };

          }else{
            this.router.navigateByUrl('/work/send')
          }
        });
        }
      }
    });
    if(sessionStorage.getItem('PeriodOfNotification') == 'true'){
      this.changeActivePeriod();
    }
  }

  getSpecificReport(){
    sessionStorage.setItem('idPeriodSelect', this.actualPeriod)
    this.selectedAreas = [];
    this.areas = [];
    this.selectedWorkCenters = [];
    let index = this.periods.findIndex(period => period._id == this.actualPeriod);
    this.selectedPeriod = this.periods[index];
  }

  updateSelectedWorkCenter(workcenter:any){
    if(this.selectedWorkCenters.findIndex(item => item._id == workcenter._id)>=0){
      let index = this.selectedWorkCenters.findIndex(workCenter => workCenter._id == workcenter._id);
      this.selectedWorkCenters[index].area.forEach(element => {
        let i = this.selectedAreas.findIndex(item => item.area == element && item.workCenter == this.selectedWorkCenters[index]._id);
        if(i>=0){
          this.selectedAreas.splice(i,1);
          //this.areas.splice(i,1);
        }
        let j = this.areas.findIndex(el => el.area == element && el.workCenter == this.selectedWorkCenters[index]._id);
        if(j >= 0){
          this.areas.splice(j,1);
        }
      });
      this.selectedWorkCenters.splice(index,1);
    }else{
      this.selectedWorkCenters.push(workcenter);
      let index = this.selectedWorkCenters.findIndex(workCenter => workCenter._id == workcenter._id);
      this.selectedWorkCenters[index].area.forEach(element => {
        let item = {
          workCenter: workcenter._id,
          area: element,
          unique: true
        }
        if(this.areas.findIndex(el => el.area == element) >= 0){
          item.unique = false;
        }
        this.areas.push(item);
      })
    }
    //estabilizar areas
    this.areas.forEach(element => {
      let k = 0;
      this.areas.forEach(element2 => {
        if(element2.area == element.area){
          k++;
        }
      });
      if(k==1){
        element.unique = true;
      }
    });
  }

  checkStatusWorkCenter(workCenter){
    let index = this.selectedWorkCenters.findIndex(item => item._id == workCenter._id);
    return index >= 0 ? true : false;
  }

  updateSelectedArea(area:any, workCenterId){
    let item = {
      workCenter: workCenterId,
      area: area
    }
    if(this.selectedAreas.findIndex(item => item.area == area) >= 0){
      this.areas.forEach(el => {
        if(el.area == area){
          let index = this.selectedAreas.findIndex(item => item.area == area);
          this.selectedAreas.splice(index,1);
        }
      })
    }else{
      this.areas.forEach(el => {
        if(el.area == area){
          this.selectedAreas.push(el);
        }
      });
    }
  }

  checkStatusArea(area){
    let index = this.selectedAreas.findIndex(item => item.area == area);
    return index >= 0 ? true : false;
  }

  changeActivePeriod(){
    Swal.fire({
      title: 'Periodos',
      text: 'Obteniendo datos',
      icon: 'info',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.activePeriod = this.actualPeriod;
    sessionStorage.setItem("idPeriodSelect", this.actualPeriod);
    sessionStorage.removeItem('filterQuiz');
    sessionStorage.setItem('selectedWorkcenter', JSON.stringify(this.selectedWorkCenters));
    sessionStorage.setItem('selectedAreas', JSON.stringify(this.selectedAreas));
    sessionStorage.setItem('reportType', this.reportType);
    sessionStorage.setItem('lastPeriodFilter', this.actualPeriod);
    if(this.reportType == '1'){
      this.quizService.idPeriodSelect.next(this.actualPeriod);
      this.quizService.getPeriod(sessionStorage.getItem("idPeriodSelect")).subscribe(response =>{
        if(response){
          if(response.promedio_general){
            this.router.navigate(['/work/result']);
          }else{
            this.router.navigate(['/work/send']);
          }
        }
        Swal.close();
      })
    }else if(this.reportType == '2'){
      if(this.selectedWorkCenters.length == 0){
        this.quizService.idPeriodSelect.next(this.actualPeriod);
        this.quizService.getPeriod(sessionStorage.getItem("idPeriodSelect")).subscribe(response =>{
          if(response){
            if(response.promedio_general){
              this.router.navigate(['/work/result']);
            }else{
              this.router.navigate(['/work/send']);
            }
          }
          Swal.close();
        })
      }else{
        let item = {
          id: this.actualPeriod,
          works:[]
        }
        this.selectedWorkCenters.forEach(elementWorkCenter => {
          let temp = {
            workcenter: elementWorkCenter.name,
            areas: []
          }
          this.selectedAreas.forEach(element => {
            if(elementWorkCenter._id == element.workCenter && element.unique){
              temp.areas.push(element.area);
            }
          });
          item.works.push(temp);
        });
        sessionStorage.setItem('filterQuiz', JSON.stringify(item))
        this.quizService.getFilterPeriod(item.id, item.works).subscribe(response=>{
          if(response){
            if(response.promedio_general > 0){
              this.quizService.idPeriodSelect.next(this.actualPeriod);
              if(!this.router.url.includes('/work/result')){
                this.router.navigate(['/work/result']);
              }else{
                this.periodsComponent.ngOnInit();
              }

            }else{
              if(!this.router.url.includes('/work/send')){
                this.router.navigate(['/work/send']);
              }else{
                location.reload()
              }
            }
          }
          Swal.close();
        })
      }
    }
    let item = JSON.parse(sessionStorage.getItem('filterQuiz'));
    this.filter = item;
  }

  sendQuiz(){
    this.sendQuizModal.nativeElement.click();
  }

  sureModal(){
    if(this.reportType == '2'){
      let item = {
        id: this.actualPeriod,
        works:[]
      }
      this.selectedWorkCenters.forEach(elementWorkCenter => {
        let temp = {
          workcenter: elementWorkCenter.name,
          areas: []
        }
        this.selectedAreas.forEach(element => {
          if(elementWorkCenter._id == element.workCenter){
            temp.areas.push(element.area);
          }
        });
        item.works.push(temp);
      });
      this.quizService.sendAllQuiz(sessionStorage.getItem("idPeriodSelect"), item.works).subscribe(res =>{
        if(res.clave == 'OK'){
          this.modal.successful({
            title: '¡Operación exitosa!',
            message: 'Se han enviado las encuestas',
          });
        }
      })
    }else{
      this.quizService.sendAllQuiz(sessionStorage.getItem("idPeriodSelect")).subscribe(res =>{
        if(res.clave == 'OK'){
          this.modal.successful({
            title: '¡Operación exitosa!',
            message: 'Se han enviado las encuestas',
          });
        }
      })
    }
  }

  disableScrolling(){
    var x=window.scrollX;
    var y=window.scrollY;
    window.onscroll=function(){window.scrollTo(x, y);};
  }

  enableScrolling(){
      window.onscroll=function(){};
  }
  downloadPDF(){
    window.scrollTo(0, 0);
    this.generatePDF = "";
    this.disableScrolling();
    this.loading = "";

    setTimeout(async () => {
    await domtoimage.toPng(document.getElementById('containerChartReport'))
    .then(async (blob) => {
      sessionStorage.setItem('image-bar-graph', blob);
    });
    let blob = sessionStorage.getItem('image-bar-graph');
    var doc = new jsPDF('p', 'px');
    let infoPeriod = JSON.parse(sessionStorage.getItem('infoPeriod'));
    const addFooters = doc => {
      doc.setTextColor(0, 0, 0);
      const pageCount = doc.internal.getNumberOfPages()
      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 30, doc.internal.pageSize.height-20, {
          align: 'center'
        })
        var img = new Image()
        img.src = 'assets/img/logos/indicepdf.jpg'
        doc.addImage(img, 'png', 15, doc.internal.pageSize.height-35, 61, 25)
      }
    }
    const addHeaders = doc => {
      doc.setTextColor(0, 0, 0);
      const pageCount = doc.internal.getNumberOfPages()
      let date = new Date();
      doc.setFont('helvetica')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(7);
        doc.setFont('helvetica', 'normal')
        doc.text('Expedido: '+date.getDate()+"/"+date.getMonth()+'/'+date.getFullYear()+ ' ' + date.getHours()+ ':' +date.getMinutes() + ' hrs', doc.internal.pageSize.width -50, 15, {
          align: 'center'
        });
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold')
        doc.text('EVALUACIÓN CLIMA LABORAL - ' + infoPeriod.proyect_name , doc.internal.pageSize.width / 2, 40, {
          align: 'center', fontSize: '25px'
        })

        doc.setFontSize(8)
        var img = new Image()
        img.src = sessionStorage.getItem('logo')
        doc.addImage(img, 'png', 10, 10, 45, 45);

      }
    }

    //pagina 1
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    let namePDF = ""
    if(this.filter){
      namePDF= "CLIMA_LABORAL-CENTRO";
      let centerString = "";
      let areaString = "";
      let i = 0;
      this.filter.centers.forEach(element => {
        centerString += element;
        if(this.filter.centers.length > i+1){
          centerString += ", ";
        }
        i++;
      });
      i = 0;
      this.filter.areas.forEach(element => {
        areaString += element;
        if(this.filter.areas.length > i+1){
          areaString += ", ";
        }
        i++
      });
      doc.setFontType("bold");
      doc.text("Centro:", 25 , 70, {
        align: 'left'
      })
      doc.setFontType("normal");
      doc.text(centerString, 48 , 70, {
        align: 'left'
      })
      if(areaString != ""){
        namePDF= "CLIMA_LABORAL-ÁREA";
        doc.setFontType("bold");
        doc.text("Área:", 25 , 79, {
          align: 'left'
        })
        doc.setFontType("normal");
        doc.text(areaString, 43 , 79, {
          align: 'left'
        })
      }

      doc.setLineWidth(0.3);
      doc.line(22, 83, doc.internal.pageSize.width - 22, 83);
    }else{
      namePDF= "CLIMA_LABORAL-GENERAL";
      doc.text('Resultados generales', 25 , 70, {
        align: 'left'
      })
      doc.setLineWidth(0.3);
      doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    }

    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.setTextColor(77, 77, 77);
    doc.text('La calificación general del clima laboral es:', 25 , 95, {
      align: 'left'
    });
    let imageGeneral = "";
    let colorGeneral = []
    if (infoPeriod.promedio_general<= 20) {
      colorGeneral = [254, 173, 24]
      imageGeneral = "assets/img/stats/one.png"
    } else if(infoPeriod.promedio_general<= 40 && infoPeriod.promedio_general> 20) {
      colorGeneral = [254, 116, 106]
      imageGeneral = "assets/img/stats/two.png"
    }
    else if(infoPeriod.promedio_general<= 60 && infoPeriod.promedio_general> 40) {
      colorGeneral = [206, 73, 109]
      imageGeneral = "assets/img/stats/three.png"
    }
    else if(infoPeriod.promedio_general<= 80 && infoPeriod.promedio_general> 60) {
      colorGeneral = [150, 106, 204]
      imageGeneral = "assets/img/stats/four.png"
    }
    else if(infoPeriod.promedio_general<= 100 && infoPeriod.promedio_general> 80) {
      colorGeneral = [51, 82, 156]
      imageGeneral = "assets/img/stats/five.png"
    }

    doc.setTextColor(colorGeneral[0], colorGeneral[1], colorGeneral[2]);
    doc.setFontType('bold')
    if(infoPeriod.promedio_general == 100){
      doc.setFontSize(85);
      doc.text(infoPeriod.promedio_general+'', 30, 185, {
        align: 'left'
      })
    }else{
      doc.setFontSize(115);
      doc.text(infoPeriod.promedio_general+'', 40, 185, {
        align: 'left'
    })
    }
    var img = new Image()
    img.src = imageGeneral;
    doc.addImage(img, 'png', 145, 105, 50, 100) //X, Y, W, H
    doc.line(213, 90, 213, 230);
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.setTextColor(77, 77, 77);
    doc.text('Información general:', 218 , 95, {
      align: 'left'
    });

    let columnsGeneral = [{
      title: '',
      dataKey: 'one'
    },
    {
      title: '',
      dataKey: 'two'
    },
    {
      title: '',
      dataKey: 'three'
    },
    {
      title: '',
      dataKey: 'four'
    },
    {
      title: '',
      dataKey: 'five'
    },
    {
      title: '',
      dataKey: 'six'
    }];
    let rowsGeneral = [
    ];

    rowsGeneral.push({
      one:'Sexo:',
      two: '',
      three:'Edades:',
      four:'',
      five:'Estado Civil',
      six:''
    })
    rowsGeneral.push({
      one:'Femenino',
      two: infoPeriod?.general_info?.gender?.F+'%',
      three:'18 a 29',
      four: infoPeriod?.general_info?.age['1']+'%',
      five:'Soltero/a',
      six:infoPeriod?.general_info?.marital_status['1']+'%'
    })
    rowsGeneral.push({
      one:'Masculino',
      two: infoPeriod?.general_info?.gender?.M+'%',
      three:'30 a 39',
      four: infoPeriod?.general_info?.age['2']+'%',
      five:'Casado/a',
      six: infoPeriod?.general_info?.marital_status['2']+'%'
    })
    rowsGeneral.push({
      one:' ',
      two: ' ',
      three:'40 a 49',
      four: infoPeriod?.general_info?.age['3']+'%',
      five:'Union libre',
      six: infoPeriod?.general_info?.marital_status['3']+'%'
    })
    rowsGeneral.push({
      one:' ',
      two: ' ',
      three:'50 a 59',
      four: infoPeriod?.general_info?.age['4']+'%',
      five:'Divorciado/a',
      six: infoPeriod?.general_info?.marital_status['4']+'%'
    })
    rowsGeneral.push({
      one:' ',
      two: ' ',
      three:'60 y más',
      four:infoPeriod?.general_info?.age['5']+'%',
      five:'Viudo/a',
      six: infoPeriod?.general_info?.marital_status['5']+'%'
    })
    rowsGeneral.push({
      one:' ',
      two: ' ',
      three:' ',
      four:' ',
      five:' ',
      six:' '
    })
    rowsGeneral.push({
      one:'Años de antiguedad en la empresa:',
      three:'Escolaridad:'
    })
    rowsGeneral.push({
      one:'Menos de 1',
      two: infoPeriod?.general_info?.antiquity['1']+'%',
      three:'Sin escolaridad',
      four: infoPeriod?.general_info?.scolarship['1']+'%',
      five:'Licenciatura',
      six: infoPeriod?.general_info?.scolarship['6']+'%'
    })
    rowsGeneral.push({
      one:'De 1 a 3',
      two: infoPeriod?.general_info?.antiquity['2']+'%',
      three:'Primaria',
      four: infoPeriod?.general_info?.scolarship['2']+'%',
      five:'Maestría',
      six: infoPeriod?.general_info?.scolarship['7']+'%'
    })
    rowsGeneral.push({
      one:'De 4 a 9',
      two: infoPeriod?.general_info?.antiquity['3']+'%',
      three:'Secundaria',
      four: infoPeriod?.general_info?.scolarship['3']+'%',
      five:'Doctorado',
      six: infoPeriod?.general_info?.scolarship['8']+'%'
    })
    rowsGeneral.push({
      one:'Más de 10',
      two: infoPeriod?.general_info?.antiquity['4']+'%',
      three:'Bachillerato o preparatoria',
      four: infoPeriod?.general_info?.scolarship['4']+'%',
    })
    rowsGeneral.push({
      three:'Carrera técnica',
      four: infoPeriod?.general_info?.scolarship['5']+'%',
    })

    doc.autoTable(columnsGeneral, rowsGeneral, {
      startY: 100,
      margin:{
        left: 215
      },
      theme: 'plain',
      cellWidth: 'wrap',
      showHead: 'everyPage',
      cellPadding: 0,
      columnStyles: {
          0: {cellWidth: 45},
          1: {cellWidth: 25},
          2: {cellWidth: 45} ,
          3: {cellWidth: 25},
          4: {cellWidth: 45},
          5: {cellWidth: 25}
      },
      headStyles: {theme: 'grid'},
      styles: {overflow: 'linebreak', cellWidth: 'wrap', font: 'helvetica', fontSize: 7, overflowColumns: 'linebreak'},
      willDrawCell: function (data) {
        if (data.row.index == 0 || data.row.index == 7) {
          doc.setTextColor(0, 63, 155);
        }
        if(data.column.dataKey == 'two' || data.column.dataKey == 'four' || data.column.dataKey == 'six'){
          data.cell.styles.halign = 'right'
        }else{
          doc.setFont('helvetica', 'bold')
        }

        if ( data.row.index == 7) {
          data.row.height = 21;
        }else if ( data.row.index == 11) {
          data.row.height = 13
        }else{
         data.row.height = 8
        }
      },
    },
)
    /* let blob2 = sessionStorage.getItem('image-info');
    doc.addImage(blob2, 'JPG', 214, 87, doc.internal.pageSize.width / 2 - 10, doc.internal.pageSize.width / 2 - 90);       */

    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.setTextColor(77, 77, 77);
    doc.text('Calificación por indicador', 25 , 280, {
      align: 'left'
    });
    doc.addImage(blob, 'JPG', 20, 220, doc.internal.pageSize.width - 60, doc.internal.pageSize.width - 80);
    doc.addPage();
    doc.setPage(2)
    //Page 2
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    doc.text('Resultados por indicador', 25 , 70, {
      align: 'left'
    })
    doc.setLineWidth(0.3);
    doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    for (let index = 0; index < 3; index++) {
      let image = "";
      let color = []
      if (infoPeriod.promedio_por_indicador[index].promedio_indicador<= 20) {
        color = [254, 173, 24]
        image = "assets/img/stats/one.png"
      } else if(infoPeriod.promedio_por_indicador[index].promedio_indicador<= 40 && infoPeriod.promedio_por_indicador[index].promedio_indicador> 20) {
        color = [254, 116, 106]
        image = "assets/img/stats/two.png"
      }
      else if(infoPeriod.promedio_por_indicador[index].promedio_indicador<= 60 && infoPeriod.promedio_por_indicador[index].promedio_indicador> 40) {
        color = [206, 73, 109]
        image = "assets/img/stats/three.png"
      }
      else if(infoPeriod.promedio_por_indicador[index].promedio_indicador<= 80 && infoPeriod.promedio_por_indicador[index].promedio_indicador> 60) {
        color = [150, 106, 204]
        image = "assets/img/stats/four.png"
      }
      else if(infoPeriod.promedio_por_indicador[index].promedio_indicador<= 100 && infoPeriod.promedio_por_indicador[index].promedio_indicador> 80) {
        color = [51, 82, 156]
        image = "assets/img/stats/five.png"
      }
      doc.setTextColor(color[0], color[1], color[2])
      doc.setFontType('bold')
      if(infoPeriod.promedio_por_indicador[index].promedio_indicador < 100){
        doc.setFontSize(75);
      }else{
        doc.setFontSize(55);
      }
      doc.text(infoPeriod.promedio_por_indicador[index].promedio_indicador+'', 40, ((165*(index+1))+(index*20)), {
        align: 'left'
      })
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(12)
      doc.setTextColor(77, 77, 77);
      doc.text(infoPeriod.promedio_por_indicador[index].indicador+"", 35 , ((100*(index+1))+(index*65) +(index*20)), {
        align: 'left'
      });
      var img = new Image()
      img.src = image;
      doc.addImage(img, 'png', 115, ((90*(index+1))+(index*100)), 50, 100) //X, Y, W, H
      let i = 0;
      infoPeriod.promedio_por_indicador[index].question_answer.forEach(element => {
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8);
        doc.setTextColor(77, 77, 77);
        let question = doc.splitTextToSize(this.quiz[index].quizz_answer[element.question_number-1].title, doc.internal.pageSize.width / 2 - 10)
        doc.text( question, doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        })
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(10);
        doc.setTextColor(color[0], color[1], color[2])
        doc.text( element.promedio_question+"", doc.internal.pageSize.width - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        });
        //doc.line(doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*21) + (index*100) - (index*27) ), doc.internal.pageSize.width - 30, (110*(index+1) + (i*21) + (index*100) - (index*27) ));
        i++;
      });
    }
    // Page 3
    doc.addPage();
    doc.setPage(3)
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    doc.setTextColor(77, 77, 77);
    doc.text('Resultados por indicador', 25 , 70, {
      align: 'left'
    })
    doc.setLineWidth(0.3);
    doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    for (let index = 0; index < 3; index++) {
      let image = "";
      let color = []
      if (infoPeriod.promedio_por_indicador[index+3].promedio_indicador<= 20) {
        color = [254, 173, 24]
        image = "assets/img/stats/one.png"
      } else if(infoPeriod.promedio_por_indicador[index+3].promedio_indicador<= 40 && infoPeriod.promedio_por_indicador[index+3].promedio_indicador> 20) {
        color = [254, 116, 106]
        image = "assets/img/stats/two.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+3].promedio_indicador<= 60 && infoPeriod.promedio_por_indicador[index+3].promedio_indicador> 40) {
        color = [206, 73, 109]
        image = "assets/img/stats/three.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+3].promedio_indicador<= 80 && infoPeriod.promedio_por_indicador[index+3].promedio_indicador> 60) {
        color = [150, 106, 204]
        image = "assets/img/stats/four.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+3].promedio_indicador<= 100 && infoPeriod.promedio_por_indicador[index+3].promedio_indicador> 80) {
        color = [51, 82, 156]
        image = "assets/img/stats/five.png"
      }
      doc.setTextColor(color[0], color[1], color[2])
      doc.setFontType('bold')
      if(infoPeriod.promedio_por_indicador[index+3].promedio_indicador < 100){
        doc.setFontSize(75);
      }else{
        doc.setFontSize(55);
      }
      doc.text(infoPeriod.promedio_por_indicador[index+3].promedio_indicador+'', 40, ((165*(index+1))+(index*20)), {
        align: 'left'
      })
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(12)
      doc.setTextColor(77, 77, 77);
      doc.text(infoPeriod.promedio_por_indicador[index+3].indicador+"", 35 , ((100*(index+1))+(index*65) +(index*20)), {
        align: 'left'
      });
      var img = new Image()
      img.src = image;
      doc.addImage(img, 'png', 115, ((90*(index+1))+(index*100)), 50, 100) //X, Y, W, H
      let i = 0;
      infoPeriod.promedio_por_indicador[index+3].question_answer.forEach(element => {
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8);
        doc.setTextColor(77, 77, 77);
        let question = doc.splitTextToSize(this.quiz[index+3].quizz_answer[element.question_number-1].title, doc.internal.pageSize.width / 2 - 10)
        doc.text( question, doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        })
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(10);
        doc.setTextColor(color[0], color[1], color[2])
        doc.text( element.promedio_question+"", doc.internal.pageSize.width - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        });
        //doc.line(doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*21) + (index*100) - (index*27) ), doc.internal.pageSize.width - 30, (110*(index+1) + (i*21) + (index*100) - (index*27) ));
        i++;
      });
    }
    // Page 4
    doc.addPage();
    doc.setPage(4)
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    doc.setTextColor(77, 77, 77);
    doc.text('Resultados por indicador', 25 , 70, {
      align: 'left'
    })
    doc.setLineWidth(0.3);
    doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    for (let index = 0; index < 3; index++) {
      let image = "";
      let color = []
      if (infoPeriod.promedio_por_indicador[index+6].promedio_indicador<= 20) {
        color = [254, 173, 24]
        image = "assets/img/stats/one.png"
      } else if(infoPeriod.promedio_por_indicador[index+6].promedio_indicador<= 40 && infoPeriod.promedio_por_indicador[index+6].promedio_indicador> 20) {
        color = [254, 116, 106]
        image = "assets/img/stats/two.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+6].promedio_indicador<= 60 && infoPeriod.promedio_por_indicador[index+6].promedio_indicador> 40) {
        color = [206, 73, 109]
        image = "assets/img/stats/three.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+6].promedio_indicador<= 80 && infoPeriod.promedio_por_indicador[index+6].promedio_indicador> 60) {
        color = [150, 106, 204]
        image = "assets/img/stats/four.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+6].promedio_indicador<= 100 && infoPeriod.promedio_por_indicador[index+6].promedio_indicador> 80) {
        color = [51, 82, 156]
        image = "assets/img/stats/five.png"
      }
      doc.setTextColor(color[0], color[1], color[2])
      doc.setFontType('bold');
      if(infoPeriod.promedio_por_indicador[index+6].promedio_indicador < 100){
        doc.setFontSize(75);
      }else{
        doc.setFontSize(55);
      }

      doc.text(infoPeriod.promedio_por_indicador[index+6].promedio_indicador+'', 40, ((165*(index+1))+(index*20)), {
        align: 'left'
      })
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(12)
      doc.setTextColor(77, 77, 77);
      doc.text(infoPeriod.promedio_por_indicador[index+6].indicador+"", 35 , ((100*(index+1))+(index*65) +(index*20)), {
        align: 'left'
      });
      var img = new Image()
      img.src = image;
      doc.addImage(img, 'png', 115, ((90*(index+1))+(index*100)), 50, 100) //X, Y, W, H
      let i = 0;
      infoPeriod.promedio_por_indicador[index+6].question_answer.forEach(element => {
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8);
        doc.setTextColor(77, 77, 77);
        let question = doc.splitTextToSize(this.quiz[index+6].quizz_answer[element.question_number-1].title, doc.internal.pageSize.width / 2 - 10)
        doc.text( question, doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        })
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(10);
        doc.setTextColor(color[0], color[1], color[2])
        doc.text( element.promedio_question+"", doc.internal.pageSize.width - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        });
        //doc.line(doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*21) + (index*100) - (index*27) ), doc.internal.pageSize.width - 30, (110*(index+1) + (i*21) + (index*100) - (index*27) ));
        i++;
      });
    }
    // Page 5
    doc.addPage();
    doc.setPage(5)
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    doc.setTextColor(77, 77, 77);
    doc.text('Resultados por indicador', 25 , 70, {
      align: 'left'
    })
    doc.setLineWidth(0.3);
    doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    for (let index = 0; index < 3; index++) {
      let image = "";
      let color = []
      if (infoPeriod.promedio_por_indicador[index+9].promedio_indicador<= 20) {
        color = [254, 173, 24]
        image = "assets/img/stats/one.png"
      } else if(infoPeriod.promedio_por_indicador[index+9].promedio_indicador<= 40 && infoPeriod.promedio_por_indicador[index+9].promedio_indicador> 20) {
        color = [254, 116, 106]
        image = "assets/img/stats/two.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+9].promedio_indicador<= 60 && infoPeriod.promedio_por_indicador[index+9].promedio_indicador> 40) {
        color = [206, 73, 109]
        image = "assets/img/stats/three.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+9].promedio_indicador<= 80 && infoPeriod.promedio_por_indicador[index+9].promedio_indicador> 60) {
        color = [150, 106, 204]
        image = "assets/img/stats/four.png"
      }
      else if(infoPeriod.promedio_por_indicador[index+9].promedio_indicador<= 100 && infoPeriod.promedio_por_indicador[index+9].promedio_indicador> 80) {
        color = [51, 82, 156]
        image = "assets/img/stats/five.png"
      }
      doc.setTextColor(color[0], color[1], color[2])
      doc.setFontType('bold')
      if(infoPeriod.promedio_por_indicador[index+9].promedio_indicador < 100){
        doc.setFontSize(75);
      }else{
        doc.setFontSize(55);
      }
      doc.text(infoPeriod.promedio_por_indicador[index+9].promedio_indicador+'', 40, ((165*(index+1))+(index*25)), {
        align: 'left'
      })
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(12)
      doc.setTextColor(77, 77, 77);
      doc.text(infoPeriod.promedio_por_indicador[index+9].indicador+"", 35 , ((100*(index+1))+(index*65) +(index*15)), {
        align: 'left'
      });
      var img = new Image()
      img.src = image;
      doc.addImage(img, 'png', 115, ((90*(index+1))+(index*110) - (index*5)), 50, 100) //X, Y, W, H
      let i = 0;
      infoPeriod.promedio_por_indicador[index+9].question_answer.forEach(element => {
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8);
        doc.setTextColor(77, 77, 77);
        let question = doc.splitTextToSize(this.quiz[index+9].quizz_answer[element.question_number-1].title, doc.internal.pageSize.width / 2 - 10)
        doc.text( question, doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        })
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(10);
        doc.setTextColor(color[0], color[1], color[2])
        doc.text( element.promedio_question+"", doc.internal.pageSize.width - 40, (110*(index+1) + (i*18) + (index*100) - (index*25) ),  {
          align: 'left'
        });
        //doc.line(doc.internal.pageSize.width / 2 - 40, (110*(index+1) + (i*21) + (index*100) - (index*27) ), doc.internal.pageSize.width - 30, (110*(index+1) + (i*21) + (index*100) - (index*27) ));
        i++;
      });
    }
    // Page 5
    doc.addPage();
    doc.setPage(6)
    doc.setDrawColor(128, 128, 128);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(20, 60, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 110, 2, 2, 'FD');
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(8)
    doc.setTextColor(77, 77, 77);
    doc.text('Preguntas mejor y peor evaluadas de cada indicador', 25 , 70, {
      align: 'left'
    })
    doc.setLineWidth(0.3);
    doc.line(22, 75, doc.internal.pageSize.width - 22, 75);
    let columns = [{
      title: '',
      dataKey: 'indicator'
    },
    {
      title: '',
      dataKey: 'question'
    },
    {
      title: '',
      dataKey: 'number'
    }];
    let rows = [];
    let rowsWorst= []
    let indexA = 0;
    infoPeriod.promedio_por_indicador.forEach(indicador => {
      indicador.best_qualify_question.question = this.quiz[indexA].quizz_answer[(indicador.best_qualify_question.question_number-1)].title
      indicador.worst_qualify_question.question = this.quiz[indexA].quizz_answer[(indicador.worst_qualify_question.question_number-1)].title
      indexA++;
    });
    infoPeriod.promedio_por_indicador.forEach(element => {
      rows.push({
        indicator: element.indicador,
        question: element.best_qualify_question.question,
        number: element.best_qualify_question.promedio_question
      })
      rowsWorst.push({
        indicator: element.indicador,
        question: element.worst_qualify_question.question,
        number: element.worst_qualify_question.promedio_question
      })
    });
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(11)
    doc.setTextColor(77, 77, 77);
    doc.text("Pregunta mejor evaluada", 30 , 93, {
      align: 'left'
    });
    doc.autoTable(columns, rows, {
      startY: 103,
      theme: 'striped',
      cellWidth: 'wrap',
      showHead: 'everyPage',
      columnStyles: {
          0: {cellWidth: 80},
          1: {cellWidth: 290},
          2: {cellWidth: 20, textColor: [51, 82, 156], fontStyle: 'bold', fontSize:10},
      },
      headStyles: {theme: 'grid'},
      styles: {overflow: 'linebreak', cellWidth: 'wrap', font: 'helvetica', fontSize: 8, overflowColumns: 'linebreak'},
    })
    doc.text("Pregunta peor evaluada", 30 , 325, {
      align: 'left'
    });
    doc.autoTable(columns, rowsWorst, {
      startY: 335,
      theme: 'striped',
      cellWidth: 'wrap',
      showHead: 'everyPage',
      columnStyles: {
          0: {cellWidth: 80},
          1: {cellWidth: 290},
          2: {cellWidth: 20, textColor: [254, 116, 106], fontStyle: 'bold', fontSize:10},
      },
      headStyles: {theme: 'grid'},
      styles: {overflow: 'linebreak', cellWidth: 'wrap', font: 'helvetica', fontSize: 8, overflowColumns: 'linebreak'},
    })
    addHeaders(doc);
    addFooters(doc);
    doc.save(namePDF+`.pdf`);
    this.generatePDF = "d-none";
    this.loading = "d-none";
    this.enableScrolling();
    }, 600);

  }

  ngOnDestroy(){
    sessionStorage.removeItem("idPeriodSelect");
  }

  updateDate(form: NgForm){

    const { date, hour } = form.value;

    if(date == ''){ 
      this.dateInputInvalid = true;
      return; 
    }

    const _date = date?.split('-');
    const _hour = hour?.split(':');

    const selectedDay = new Date(_date[0], _date[1]-1, _date[2], _hour[0]);
    const currentDay  = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), this.today.getHours());
    
    let validFields = true;

    if(date == '' || selectedDay < currentDay){
      this.dateInputInvalid = true;
      validFields = false;
    }

    const currentTime = this.today.getHours();

    if(hour == '' || selectedDay <= currentDay){
      if(parseInt(_hour[0]) <= currentTime){
        this.hourInputInvalid = true;
        validFields = false;

      }
    }

    if(form.valid && validFields){
      this.quizService.updateDate(sessionStorage.getItem("idPeriodSelect"), selectedDay, hour).subscribe(response=>{
        if(response.clave == 'OK'){
          Swal.close();
          this.modal.successful({
            title: '¡Operación exitosa!',
            message: 'La fecha límite fue actualizada.',
          });
        }
      });

      form.resetForm({date: '', hour: '00:00'});
      this.closebutton.nativeElement.click();
      this.limitDateCurretPeriod = {day: selectedDay.toString(), hour: hour };
    }

  }

  cleanErrors(type:string){
    if(type == 'date'){
      this.dateInputInvalid = false
    }else{
      this.dateInputInvalid = false
      this.hourInputInvalid = false
    }
  }
}
