import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './root/root.component';
import { EmployeeComponent } from './employee/employee.component';
import { ComercialComponent } from './comercial/comercial.component';
import { EditProyectsComponent } from '@pmo/edit-proyects/edit-proyects.component';


const routes: Routes = [
  {
    path: 'root',
    component: RootComponent
  },
  {
    path: 'employee',
    component: EmployeeComponent
  },
  {
    path: 'comercial',
    component: ComercialComponent
  },
  {
    path: 'edit-proyect',
    component: EditProyectsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimeManagementRoutingModule { }
