<footer class="footer" [class.light-footer]="isFooterDark()">
  <div class="container footer-container">
    <div class="row align-items-center py-5 py-md-0">
      <div class="col-12 col-lg-auto mt-2 mt-md-0 text-center">
        <span class="time-icon-bcore-vertical-logo">
          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span
            class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>
        </span>
      </div>

      <div
        class="col-12 col-md d-flex flex-column flex-md-row justify-content-end align-items-center mt-5 mt-md-0 labels">
        <p>©2BCORE {{ currentYear }}</p>
        <p class="mt-4 mt-md-0 cr-pointer" (click)="openModalAvisoPrivacidad()">Aviso de privacidad</p>
        <p class="mt-4 mt-md-0 cr-pointer" (click)="openModalPoliticaSeguridad()">Política de seguridad de la
          información</p>
      </div>

      <div
        class="col-12 col-md-auto d-flex align-items-center justify-content-center justify-content-md-end mt-5 mt-md-0 mb-2 mb-md-0">
        <a class="icon-social-media mr-4 mr-md-3" href="https://www.linkedin.com/company/2btalents/" target="_blank">
          <img src="assets/icons/footer/LinkedIn.svg" class="icon" alt="linkedin" />
        </a>
        <a class="icon-social-media" href="https://www.facebook.com/2btalents" target="_blank">
          <img src="assets/icons/footer/Facebook.svg" class="icon" alt="facebook" />
        </a>
      </div>
    </div>
  </div>
</footer>