<button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    <span class="icon time-icon-status_rejected">
        <span class="path1"></span>
        <span class="path2"></span>
    </span>
    <h1 class="title">Rechazar actividad</h1>
</div>

<div mat-dialog-content>

    <p class="mb-4">Para rechazar esta actividad, indícale a {{ data.employeeName }} qué es lo que debe corregir.</p>
    <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Ingresa tu comentario</mat-label>
        <textarea matInput name="description" id="textarea" rows="3" maxlength="112" required [formControl]="comment"></textarea>
    </mat-form-field>

</div>
<div mat-dialog-actions class="deep-button">
    <button mat-flat-button color="primary" [disabled]="comment.invalid" (click)="onSubmit()">Enviar comentario</button>
</div>