import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http-service/http.service';
import { ICompanyModel, IProgramDataModel, ICompanyModelGet } from '../../models/companies/companies.model';
import { map } from 'rxjs/operators';
import { ResponseContent } from 'src/app/models';

// SERVICES
import { ErrorsService } from 'src/app/services/errors.service';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  /**Observable que almacena exactamente cuales partes del porcentaje de empresa ya fueron cumplidos */
  percentageModules = new BehaviorSubject<any>({});
  /**Observable que solo almacena el porcentaje bruto sin orden */
  lastPercentage = new BehaviorSubject<number>(0);
    /**observable que almacena el logo del usuario logeado */
    currentCompanyPhoto  = new BehaviorSubject<string>("");

  emptyProjects = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpService,
    private errorsService: ErrorsService,
    private authService: AuthService
  ) {

   }

  editedProject = new BehaviorSubject<boolean>(false);

  allCompanies = new BehaviorSubject<ICompanyModel[]>([]);

  companySelected = new BehaviorSubject<ICompanyModel>({});

  editingProject = new BehaviorSubject<{
    isEditing: boolean,
    idProject: string,
    idEmplyoees: string[],
    nameProject: string
  }> ({
    isEditing: false,
    idProject: '',
    idEmplyoees: [],
    nameProject: ''
  });


  /**
   * Enviar solicitud para agregar nueva empresa. Se agrega como NO ACTIVADA
   */
  createCompany(company): Observable <ResponseContent>{
    return this.http.post('/company/add', {company}, true).pipe(map((res: ResponseContent) => {
      return res;
    }));
  }

  /**
   * Retorna las empresas asiganas al administrador logeado
   */
  getAllCompanies(idUser: string): void {
    this.http.post('/company/getCompanies', {idUser}, true).subscribe((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        this.authService.allCompanies.next(response.content);
        return response;
      }else {
        return false;
      }
    });
  }

  getAllCompaniesByAccount(idUser: string){
    return this.http.post('/account/getAllCompaniesByAccount', {params:{id: idUser}}, true).pipe(map((res: any) => {
      if (res && res.clave === 'OK'){
        return res;
      }else{
        return false;
      }
    }));
  }

  getCompany(id: string ){
    return this.http.post('/company/getCompany', {id}, true).pipe(
      map((response: ResponseContent) => {
      if (response && response.clave === 'OK'){
        return response.content == null ? false : response.content;
      }else{
        return false;
      }
    }));
  }

  updateCompany(company: ICompanyModel, idUser: string): Observable <ResponseContent>{
    return this.http.post('/company/update', {company, idUser}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  updateCompanyImg(company: ICompanyModel): Observable <ResponseContent>{
    return this.http.post('/company/updateImg', {company}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  /**
   * Actualiza responsable
   */
  setResponsable(responsable: {
    name: string;
    lastName: string;
    secondSurname: string;
    professionalLicense: string;
    image: {
      nameId: string, name: string, size: string, type: string
    }[];
  },             idCompany: string): Observable <ResponseContent>{
    return this.http.post('/company/setResponsable', {data: responsable, idCompany}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  /**
   * Elimina una empresa
   */
  //  deleteCompany(idCompany: string): Observable <ResponseContent>{
  //   return this.http.post('/company/remove', {idCompany}, true).pipe(map((res: ResponseContent) => {
  //       return res;
  //   }));
  // }

  setProgramData(programData: IProgramDataModel, id: string): Observable <ResponseContent>{
    return this.http.post('/company/setProgramData', {id, programData}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  updateImplementationData( implData: any): Observable <ResponseContent>{
    return this.http.post('/company/updateImplData', {implData}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  getImplementationData(idUser: any,idCompany: any): Observable <ResponseContent>{
    return this.http.post('/company/getImplData', {idUser,idCompany}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  /**
   * Agrega logo de empresa
   */
  addImage(image: FormData): Observable <ResponseContent> {
    return this.http.post('/file/uploadFile', image, true).pipe(map((response: ResponseContent) => {
      return response;
    }));
  }

  /**
   * Realiza subida de múltiples imagenes
   */
  addImages(images: FormData): Observable <ResponseContent> {
    return this.http.post('/file/uploadFiles', images, true).pipe(map((response: ResponseContent) => {

      return response;
    }));
  }

  /**
   * Realiza borrado de imagen almacenada en S3
   * @param image Id aleatorio formado para nombre de la imagen.
   * @param folder
   */
  deleteImage(image: string, folder: string): Observable <ResponseContent> {
    return this.http.post('/file/deleteFile', {image, folder}, true).pipe(map((response: ResponseContent) => {

      return response;
    }));
  }

  /**
   *
   * @param idCompany
   */
  getProgress(id: string) {
    this.http.post('/company/getProgress', {id}, true).subscribe((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        this.lastPercentage.next(response.content.lastPercentage);
        this.percentageModules.next(response.content.progress);
      }
    },
    (error) => {
      this.errorsService.handleError(error);
    });
  }

  changeStatusReportQuery(idCompany: string) {
    this.http.post('/company/changeStatusReportQuery', {idCompany}, true).subscribe((response: ResponseContent) => {},
    (error) => {
      this.errorsService.handleError(error);
    });
  }

  createProject(idCompany: string, nameProject: string, idEmployeeList: string[]){
    return this.http.post('/company/createProject', {idCompany, nameProject, idEmployeeList}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return true;
      }else {
        false;
      }
    }));
  }

  deleteProject(id: string) {
    return this.http.post('/company/deleteProject', {id}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return true;
      }else {
        return false;
      }
    }));
  }

  getProjects(idCompany: string) {
    return this.http.post('/company/getProjects', {idCompany}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res.content;
      }
    }));
  }

  updateProject (id: string, newName: string, checkedList:string[]) {
    return this.http.post('/company/updateProject', {id, newName, checkedList}, true).pipe(map((res: ResponseContent) => {
      if(res && res.clave === 'OK'){
        return res.content;
      }
    }));
  }

  removeProgramData(programData: IProgramDataModel,id: string): Observable <ResponseContent>{
    return this.http.post('/company/removeProgramData', {programData,id}, true).pipe(map((res: ResponseContent) => {
      if(res && res.clave === 'OK'){
        return res;
      }
    }));
  }

  updateProgramData(programData: IProgramDataModel,id: string): Observable <ResponseContent>{
    return this.http.post('/company/updateProgramData', {programData, id}, true).pipe(map((res: ResponseContent) => {
      if(res && res.clave === 'OK'){
        return res;
      }
    }));
  }


  getImage(img: any): any {
        if (img) {
          const json = {
            folder: 'logoCompany',
            image: `${img}`
          };
          this.http.post('/file/getFile', json, true).subscribe((resp: any) => {
            if (resp && resp.clave == 'OK') {

              this.currentCompanyPhoto.next(`data:image/${img.replace('.', '')};base64,${resp.content}`);


            }

          });
        }


  }

  /*
  * Obtiene los colores disponibles para la paleta
  */

  getColorsPallete() {
    return this.http.post('/company/getColorPalette', {}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res.content;
      }
    }));
  }

  uploadLogoAndTheme(formData: FormData) {
    return this.http.post('/company/uploadLogoAndTheme', formData, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK'){
        return res.content;
      }
    }));
  }

  /**
   * Theme methods group
   */
  themeSelected = {
    /**
    * Get select theme from company selected
    */
    get(): ICompanyModelGet["theme"]{
      return JSON.parse(sessionStorage.getItem('theme')) as any;
    },
    /**
    * set select theme from company selected
    */
    set(theme: ICompanyModelGet["theme"]){
      sessionStorage.setItem('theme', JSON.stringify(theme));
      // set style
      document.documentElement.style.setProperty('--mainDemo', theme.color.main);
      document.documentElement.style.setProperty('--darkDemo', theme.color.dark);
      document.documentElement.style.setProperty('--lightDemo', theme.color.light);
      document.documentElement.style.setProperty('--main', theme.color.main);
      document.documentElement.style.setProperty('--dark', theme.color.dark);
      document.documentElement.style.setProperty('--light', theme.color.light);
    },
    reload(){
      if(sessionStorage.getItem('isLogged') === 'true'){
        this.set(this.get());
      }
    }

  }

}
