export let quizzes = [
  {
    indicator: 'Comunicación',
    class: 'comunication',
    quizz_answer: [
      {
        title: 'Existe una buena comunicación entre mis compañeros de trabajo.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Cuando hay cambios que afecten mi puesto de trabajo se me comunica de forma oportuna.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'La información de interés para todos llega de manera oportuna a mi persona.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'En mi equipo de trabajo, puedo expresar mi punto de vista, aun cuando contradiga a los demás miembros.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Los canales de comunicación en la empresa son los adecuados.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Existe una buena comunicación entre las áreas de la empresa.',
        question_number: 6,
        answer: 0,
      },
      {
        title: 'Mi jefe realiza reuniones periódicas para informar y escuchar a su equipo de trabajo. ',
        question_number: 7,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Cooperación',
    class: 'cooperation',
    quizz_answer: [
      {
        title: 'Existe sana competencia entre mis compañeros.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Mantengo buenas relaciones con los miembros de mi equipo de trabajo.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Puedo confiar en mis compañeros.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'En mi área de trabajo se buscan soluciones y no culpables.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Me siento parte del equipo.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Cuando tengo una tarea difícil, puedo contar con la ayuda de mis compañeros.',
        question_number: 6,
        answer: 0,
      },
      {
        title: 'Existe buena coordinación y apoyo entre las distintas áreas de la empresa.',
        question_number: 7,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Conflicto',
    class: 'conflict',
    quizz_answer: [
      {
        title: 'Considero que el trabajo que realiza mi jefe inmediato para manejar conflictos es bueno.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Mi empresa se encuentra organizada para preveer los problemas que se presentan.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'En esta empresa las personas son tolerantes con las opiniones ajenas. ',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Cuando existe algún conflicto entre compañeros de trabajo, existe disposición y formas adecuadas para resolverlo.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Los conflictos generados se resuelven de manera rápida y adecuada.',
        question_number: 5,
        answer: 0,
      }
    ]
  },
  {
    indicator: 'Ambiente',
    class: 'ambient',
    quizz_answer: [
      {
        title: 'La limpieza de los ambientes es adecuada.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Considero que la distribución física de mi área me permite trabajar cómoda y eficientemente.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'La iluminación es correcta para realizar mi trabajo.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Tengo el espacio necesario para desempeñar adecuadamente mi trabajo.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Cuento con las herramientas necesarias para realizar mi trabajo.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Puedo hacer mi trabajo de manera cómoda y segura.',
        question_number: 6,
        answer: 0,
      },
      {
        title: 'Me satisface la calidad de los servicios básicos.',
        question_number: 7,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Estructura',
    class: 'structure',
    quizz_answer: [
      {
        title: 'Los trámites que se utilizan en mi empresa son simples y facilitan la atención.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Las normas y reglas de mi empresa son claras y facilitan mi trabajo.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Conozco las tareas o funciones específicas que debo realizar en mi empresa.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Existe un ambiente organizado en mi empresa.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Las tareas que desempeño corresponden a mi función.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Los roles y funciones de mi área están bien definidos.',
        question_number: 6,
        answer: 0,
      },
      {
        title: 'El horario de trabajo definido es el correcto y no afecta mi vida privada.',
        question_number: 7,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Identidad',
    class: 'identity',
    quizz_answer: [
      {
        title: 'Con frecuencia nuestros compañeros hablan mal de la empresa.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Estoy comprometido con mi empresa.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Me siento a gusto de formar parte de la empresa.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Los objetivos de mi empresa reflejan mis intereses.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Cumplo un rol importante dentro de la empresa.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Me identifico con los valores de la empresa.',
        question_number: 6,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Liderazgo',
    class: 'leadership',
    quizz_answer: [
      {
        title: 'Mi jefe inmediato está disponible cuando se le necesita.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato se preocupa por crear un ambiente laboral agradable.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Los esfuerzos de los jefes se encaminan al logro de objetivos de la empresa.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato se reúne regularmente con los trabajadores para coordinar aspectos de trabajo.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato trata de obtener información antes de tomar una decisión.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato apoya mis esfuerzos.',
        question_number: 6,
        answer: 0,
      },
      {
        title: 'El jefe inmediato supervisa constantemente al personal.',
        question_number: 7,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato es justo en sus decisiones.',
        question_number: 8,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Motivación',
    class: 'motivation',
    quizz_answer: [
      {
        title: 'El trabajo que realizo es valorado por mi jefe inmediato.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Mi puesto de trabajo es interesante y desafiante.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato reconoce, valora y motiva mi trabajo.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'La empresa se preocupa por mantener elevado mi nivel de motivación.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Mi trabajo es valorado positivamente por la empresa.',
        question_number: 5,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Remuneración',
    class: 'remuneration',
    quizz_answer: [
      {
        title: 'Los beneficios que me ofrecen en mi trabajo son los adecuados.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Recibo mi pago a tiempo.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'Mi remuneración es adecuada en relación con el trabajo que realizo.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Mi esfuerzo ha tenido retribuciones justas.',
        question_number: 4,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Innovación',
    class: 'innovation',
    quizz_answer: [
      {
        title: 'La empresa se encuentra al día.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Tengo libertad de realizar mi trabajo de forma distinta alcanzando los mismos objetivos.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'La empresa te motiva a aportar nuevas ideas.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'Existen medios para dar nuevas ideas.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'La empresa escucha nuevas ideas y si son pertinentes las lleva acabo. ',
        question_number: 5,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Formación y desarrollo',
    class: 'formation-development',
    quizz_answer: [
      {
        title: 'La formación que recibo me ayuda a mantener actualizados mis conocimientos y a realizar mi trabajo cada día mejor.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'La empresa apuesta por capacitar y formar a las personas para favorecer su desarrollo profesional.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'La empresa ofrece oportunidades para satisfacer las expectativas de desarrollo profesional a aquellas personas que muestran interés.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'La entrevista de evaluación de desempeño me da una información clara y útil de cómo puedo mejorar mi trabajo.',
        question_number: 4,
        answer: 0,
      },
    ]
  },
  {
    indicator: 'Equidad',
    class: 'equity',
    quizz_answer: [
      {
        title: 'Cada persona realiza su trabajo de acuerdo a su puesto.',
        question_number: 1,
        answer: 0,
      },
      {
        title: 'Los objetivos que se fijan para mi trabajo son alcanzables.',
        question_number: 2,
        answer: 0,
      },
      {
        title: 'En la empresa se da un trato justo y equitativo a los empleados.',
        question_number: 3,
        answer: 0,
      },
      {
        title: 'En la empresa los ascensos son por desempeño y no por relaciones personales.',
        question_number: 4,
        answer: 0,
      },
      {
        title: 'Mi jefe inmediato tiene trato justo y equitativo.',
        question_number: 5,
        answer: 0,
      },
      {
        title: 'Existe equidad en las remuneraciones.',
        question_number: 6,
        answer: 0,
      },
    ]
  },
]