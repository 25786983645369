import { Component, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-signin',
  templateUrl: './single-signin.component.html',
  styleUrls: ['./single-signin.component.scss']
})
export class SingleSigninComponent implements OnInit{

  ngOnInit(): void {

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('x-session');
    sessionStorage.removeItem('isLogged');

  }

  navigateToLogin(): void {
    window.location.assign(environment.loginURL);
  }

 }
