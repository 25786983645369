export interface Team {
    collaborators: []
    createdAt: string
    leader: {
        id: string
        image: string
        name: string
    },
    name: string
    _id: string
}
export interface Employee {
    id: string
    name: string
    avatar: string
}

export const employees: Employee[] = [
    { id: '1', name: 'Oscar Leonardo Alarcón Rico', avatar: 'assets/users/pepe.png' },
    { id: '2', name: 'Andrea Tirado Miranda', avatar: 'assets/users/pepe.png' },
    { id: '3', name: 'Monica Andrea Mendoza Reza', avatar: 'assets/users/pepe.png' },
    { id: '4', name: 'Marcos Noel Alvarado Ledesma', avatar: 'assets/users/pepe.png' },
]

export const leaders: Employee[] = [
    { id: '5', name: 'Jorge Ávila', avatar: 'assets/users/jorge.png' },
    { id: '6', name: 'Sandra Romo', avatar: 'http://placekitten.com/100/100' },
    { id: '7', name: 'Jonathan Galindo', avatar: 'assets/users/jona.png' },
    { id: '8', name: 'Perla Segura', avatar: 'http://placekitten.com/100/100' },
    { id: '9', name: 'Antonio Carillo', avatar: 'http://placekitten.com/100/100' },
    { id: '10', name: 'Azucena Miranda', avatar: 'http://placekitten.com/100/100' },
    { id: '11', name: 'Roberto Villegas', avatar: 'assets/users/ux.png' },
    { id: '12', name: 'Priscila López', avatar: 'http://placekitten.com/100/100' },
]

export const teams = [
    { 
        id: '1',
        name: 'Desarrollo de soluciones',
        leaderId: leaders[2].id,
        team: [employees[0].id, employees[1].id, employees[2].id, employees[3].id],
        created: new Date('07/22/2024')
    },
    { 
        id: '2',
        name: 'Administración y finanzas',
        leaderId: leaders[1].id,
        team: [employees[3].id, employees[2].id],
        created: new Date('07/23/2024')
    },
]
