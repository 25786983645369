import { Injectable } from '@angular/core';
import { access } from './access';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AccessService {
  opportunityActiveIndex = new BehaviorSubject<number>(null);
  private access = access;

  userPermission = {}
  isLogged = false;

  constructor(){

    if(sessionStorage.getItem('isLogged') && sessionStorage.getItem('permission')){

      this.isLogged = JSON.parse(sessionStorage.getItem('isLogged'));

      if(this.isLogged){
        this.userPermission = JSON.parse(sessionStorage.getItem('permission'));
      }

    }

  }

  hasAccess(item:string, submenu?: string):boolean{

    let access = false;

    //access = this.userPermission[item]?.view || false; //-TODO: ver si se puede hacer desde back

    access = this.access['root'][item]?.view || false;
    //console.log(item, access);

    if(submenu){
      access = this.access['root'][submenu].submenu[item] || false;
      // console.log('submenu');
      // console.log(item, access);
    }
    //return true;
    return access;

  }
}
