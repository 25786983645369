import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    
    // bandera de texto rojo del login (ERROR-login-notFoundAccount)
    flagNotFoundAcountLogin:Boolean = false;
    
    // bandera de texto rojo folio invalido (ERROR-invalid-folio) 
    flagBoxInvalidFolio:Boolean = false;
    
    // bandera de texto rojo folio invalido (ERROR-invalid-folio) 
    flagBoxMailNotFound:Boolean = false;
    
    package:string = '';

    //bandera de alerta para empleado eliminado
    flagEliminatedEmployee = false;

    // Bandera que nos indica si acaba de cambiar su oasword
    flagJustResetYourPassword = false;

    // Bandera que confirmando su compra abierta
    flagConfirmingYourPurchase = false;

    // Nos indica si se va abrir y que mensaje se mostrara
    postModal = {
        open: false,
        title: '',
        sms: ''
    };

    //Bandera que indica que la ventana de los cuestionarios esta abierta y tiene error al acceder a la encuesta
    inComponentQuiz = false;
}