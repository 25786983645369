import { Component, OnInit } from '@angular/core';
import { ModalService } from './services/modal/modal.service';
import { AuthService } from './services/auth/auth.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UsersService } from './services/users/users.service';
import { IPermissioonModel } from './models/addOn/permission.model';
import { permissionsOff } from './constants/permissionBase';
import { CompaniesService } from './services/companies/companies.service';
import { AwsCognitoService } from './services/aws/aws-cognito.service';

export let browserRefresh = false;

declare global {
  interface Window {
    dataLayer: any;
  }
}

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'INTRANET 2BCORE';
  existAlert = false;
  rows: string;
  subscription: Subscription;
  dontShow=true;
  showArrowTwo=true;
  // Desabilitar pestaña Agregar expediente
  disableAddFiles = false;
  nomArea=false;
  showArrow = true;
  subscribers: any = [];
  logo:string;
  userInfoLogged;
  /** Almacena los permisos */
  userPermissions: IPermissioonModel = Object.assign({} , permissionsOff);


  constructor(
    private modal: ModalService,
    public router: Router,
    private auth: AuthService,
    private companieService: CompaniesService,
    private users: UsersService,
    private awsCognitoService: AwsCognitoService
    ){

      companieService.themeSelected.reload();

    this.subscribers.permissions = this.users.userPermissions.subscribe((res) => (res && !res.length && res.length !== 0) ? (this.userPermissions = res) : '' );

    this.modal.alertModal.subscribe(response => {
      // el numCase 0 indica que no hay alerta
      if (response.numCase !== 0){
        this.existAlert = true;
        if (this.router.url.includes('/login') || this.router.url.includes('/guides-summary')){
          this.rows = 'auto 1fr auto';
        }
        else if (this.router.url.includes('/administration-companies')){
          this.rows = 'auto auto 1fr';
        }else{
          this.rows = 'auto auto auto 1fr';
        }
      }else{
        this.existAlert = false;
        this.rows = '';
        if (this.router.url.includes('/administration-companies'))
        {
          this.rows = 'auto 1fr auto';
        }
      }

    });

    this.subscription = router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if(sessionStorage.getItem('isLogged') === 'true'){
          if(browserRefresh){
            this.auth.reloadSession().subscribe();
          }
        }
      }
  });
    console.log("%c2BCORE-INTRANET v1.1",
      "color:white;font-size: 16px;background:#559cff;font-weight: bold;"
    );
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    this.subscription = navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-177997979-1', {
        'page_path': event.urlAfterRedirects
      });
    });
  }
  ngOnInit(): void{

    this.subscribers.companyLogo = this.auth.logoCompanySelected.subscribe(res => {
      this.logo  = res;
    });
    this.subscribers.user = this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
    });

    setInterval(()=>{
      if(sessionStorage.getItem('isLogged') === 'true'){
        const time = new Date().getTime();
        const timeToRefresh = parseInt(sessionStorage.getItem('timeToRefresh'));
        if(time>= timeToRefresh && time <= timeToRefresh+60000){
          this.awsCognitoService.getRefreshTokenFromCognito().subscribe(res=>{
            sessionStorage.setItem('token', res.access_token);
          })
        }
      }
    }, 60000)
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onClickedOutside(e: Event) {
    this.dontShow=true;
  }
  sendComplain(){
    this.router.navigateByUrl(
      "/comment?idCompany=" +
      sessionStorage.getItem(
        'idCompanySelected'
      )
    );
  }
  gotoQuiz(url){
    sessionStorage.setItem("idPeriodSelect", "");
    this.router.navigateByUrl(url);
  }

  public enabledNotFound(): boolean {
    const areInNotFound = this.router.url.includes('/not-found');
    if (areInNotFound == false) return true;

    return this.auth.isLogged.value == true
  }
}
