import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { permissionsOff } from 'src/app/constants/permissionBase';
import { IPermissioonModel } from 'src/app/models/addOn/permission.model';
import { AccessService } from 'src/app/services/access/access.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UsersService } from 'src/app/services/users/users.service';
import { MENU_ITEMS } from './menu-items.const';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  userInfoLogged;
  tmRole:any;
  subscribers: any = {};
  userPermissions: IPermissioonModel = Object.assign({}, permissionsOff);

  fullAcceessRoles: Array<string> = ['root', 'admin'];

  nom35Route: string = '/home/welcome';

  comunicationContext = {$implicit: {...MENU_ITEMS.comunicacion}, level: 'menu'};
  inviteContext = {$implicit: {...MENU_ITEMS.invite}, level: 'menu'};
  registerHoursContext = {$implicit: {...MENU_ITEMS.registerHours}, level: 'menu'};
  teamsContext = {$implicit: {...MENU_ITEMS.teams}};
  projectsContext = {$implicit: {...MENU_ITEMS.projects}};
  commercialContext = {$implicit: {...MENU_ITEMS.commercial}};
  saludLaboralContext = {$implicit: {...MENU_ITEMS.saludLaboral}};
  gestionTalentoContext = {$implicit: {...MENU_ITEMS.gestionTalento}};
  registerContext = {$implicit: {...MENU_ITEMS.register}};

  constructor(
    private router: Router,
    protected auth: AuthService,
    private users: UsersService,
    private accessSv: AccessService
  ) { 
   
   
  }

  ngOnInit(): void {

    this.subscribers.permissions = this.users.userPermissions.subscribe(
      (res) => (res && !res.length && res.length !== 0) ? (this.userPermissions = res) : ''
    );
    this.subscribers.user = this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.tmRole = sessionStorage.getItem('timeManagementRole')
      this.nom35Route = this.userInfoLogged.role === 'employee' ? 'NOM-projects/projects' : '/home/welcome';
      this.evaluateManuItems();
    });
  }

  private evaluateManuItems() {
    const isPmo = this.tmRole === 'pmo';

    this.projectsContext.$implicit.label = isPmo ? 'Proyectos y asignaciones' : 'Proyectos'
    this.projectsContext.$implicit.icon = isPmo ? 'time-icon-Report' : 'time-icon-menu_plan';

    const isProyectLeader = this.tmRole === 'proyectLeader';
    this.registerContext.$implicit.label = isProyectLeader ? 'Registro de horas' : 'Gestión del tiempo'

    const indexNom35 = this.saludLaboralContext.$implicit.submenu.findIndex((sub) => sub.key == 'nom35');
    if (indexNom35 > -1) this.saludLaboralContext.$implicit.submenu[indexNom35].route = this.nom35Route;

    const indexBuzon = this.saludLaboralContext.$implicit.submenu.findIndex((sub) => sub.key == 'buzon');
    if (indexBuzon > -1) {
      const isEmployee = this.userInfoLogged.role === 'employee';
      const route = isEmployee ? '/comment' : '/box/list';
      this.saludLaboralContext.$implicit.submenu[indexBuzon].route = route;
      if (isEmployee) {
        this.saludLaboralContext.$implicit.submenu[indexBuzon].queryParams = {idCompany : sessionStorage.getItem('idCompanySelected')}
      }
    }
  }

  openSubMenu(submenu: string) {
    switch (submenu) {
      case 'saludLaboral': // 'occupational_healt':
        this.saludLaboralContext.$implicit.isOpen = !this.saludLaboralContext.$implicit.isOpen
        break;
      case 'gestionTalento': // 'talent-menu':
        this.gestionTalentoContext.$implicit.isOpen = !this.gestionTalentoContext.$implicit.isOpen
        break;
      case 'register':
        this.registerContext.$implicit.isOpen = !this.registerContext.$implicit.isOpen
        break;
    }
  }

  closeSidebarMobile() {
    if (this.auth.sideBarToggled.getValue() == true) {
      this.auth.sideBarToggled.next(false);
    }
  }

  //-TODO: validar acceso al elemento del menú con las validaciones completas
  viewItem(item: string, submenu?: string): boolean {
    return this.accessSv.hasAccess(item, submenu);
  }

  activeLink(level: 'menu' | 'submenu', item: string) {
    let isActive = false

    if (level == 'menu') {
      switch (item) {
        case 'comunicacion':
          const comunication = ['/posts/menu/list', '/posts/menu/schedule', '/posts/menu/history', '/posts/add', '/posts/info'];
          isActive = comunication.includes(this.router.url);
          break;
        case 'saludLaboral': // 'occupational_healt':
          const occupational_healt = ['/work/work', '/work/add', '/work/send', '/home/welcome', '/home/portfolio', '/home/implementation', '/home/programs', '/NOM-projects/projects', '/NOM-projects/add', 'NOM-projects/send', '/box/list'];
          isActive = occupational_healt.includes(this.router.url);
          break;
        case 'gestionTalento':// 'talent-menu':
          const talentMenu = ['/employees/list', '/employees/file', '/employees/file/profile', '/employees/file/bulk', '/employees/file/profile/nom-35/personal'];
          isActive = talentMenu.includes(this.router.url);
          break;
        case 'invitaciones':
          const invitaciones = ['/invite/events', '/invite/new-invite', '/invite/menu/info', '/invite/menu/guests', '/invite/menu/evidence'];
          isActive = invitaciones.includes(this.router.url);
          break;
        case 'register':
          const register = ['/hours-employee', '/hours-teams'];
          isActive = register.includes(this.router.url);
          break;
        case 'registerHours':
            const registerHours = ['/hours-employee', '/update', '/register', '/record', '/collaborator/details'];
            isActive = registerHours.find((route) => this.router.url.startsWith(route)) !== undefined;
            break;
      }

      return isActive;

    } else if (level == 'submenu') {

      switch (item) {
        case 'climaLaboral':
          const climaLaboral = ['/work/work', '/work/add', '/work/send'];
          isActive = climaLaboral.includes(this.router.url);
          break;
        case 'nom35':
          const nom35 = ['/home/welcome', '/home/portfolio', '/home/implementation', '/home/programs', '/NOM-projects/projects', 'NOM-projects/send', '/NOM-projects/add'];
          isActive = nom35.includes(this.router.url);
          break;
        case 'buzon':
          const buzon = ['/box/list',];
          isActive = buzon.includes(this.router.url);
          break;
        case 'gestionTalento':
          const gestion = ['/employees/list', '/employees/file', '/employees/file/profile', '/employees/file/bulk', '/employees/file/profile/nom-35/personal'];
          isActive = gestion.includes(this.router.url);
          break;
        case 'my-register':
          const myRegister = ['/hours-employee'];
          isActive = myRegister.includes(this.router.url);
          break;
        case 'team-register':
          const teamRegister = ['/hours-teams'];
          isActive = teamRegister.includes(this.router.url);
          break;
      }

      return isActive;

    }
  }

  ngOnDestroy(): void {
    if (this.subscribers.permissions) {
      this.subscribers.permissions.unsubscribe();
    }
    if (this.subscribers.user) {
      this.subscribers.user.unsubscribe();
    }
  }
}
