
<div class="container">
    <div class="row">
        <div class="col-11 mx-auto">
            <!-- <div class="container mt-2 mb-4 mx-auto">
                <div class="row">
                    <div class="col-lg d-lg-flex justify-content-lg-end">
                        <div class="col-lg-4 pr-0">
                            <div class="row d-flex justify-content-end px-lg-4">
                                <span class="mr-2 px-0 text-right my-auto fw-bold txt-blue fs-13">Empresa: </span>
                                <p class="mr-2 px-0 text-right my-auto fw-bold  fs-12">{{nameCompany}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="card mt-4">
                <div class="row mx-auto col-12 pb-3 pt-3">
                    <div class="col-lg-1 pt">
                        <img class="clockImage mt-n5 mt-lg-0" src="../../../../assets/img/test/timer.svg">
                    </div>
                    <div class="col-lg-8">
                        <div class="fw-bold txt-blue fs-16">
                            {{infoEmployee?.name}} {{infoEmployee?.surnames ? infoEmployee?.surnames : infoEmployee?.surnames}} {{infoEmployee?.secondSurname}}
                        </div>
                        <div class="txt-lightgray fs-12 pt-2">
                            {{infoEmployee?.marketstall}}
                        </div>
                        <div class="row txt-darkgrey justify-content-lg-center">
                            <div class="col-lg-5 fs-12">
                                Empresa: {{nameCompany}}
                            </div>
                            <div class="col-lg-3 fs-12">
                                Centro: {{infoEmployee?.workplace}}
                            </div>
                            <div class="col-lg-4 fs-12">
                                Área: {{infoEmployee?.area}}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mx-auto d-block text-right order-first order-lg-last">
                        <div class="fw-bold ">
                            No. de empleado <span class="txt-light-blue">{{infoEmployee?.employeeNumber}}</span>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row">
            <div class="col-12" >
                <div class="card my-3 mb-lg-0 event-card">
                    <div class="card-header p-0">
                        <div class="darkblue-tag fs-18 fw-bold">
                            <p>{{invitationInfo?.typeEvent}}</p>
                        </div>
                        <img [src]="background | imagePath: 'invitationBackground'" class="card-img-top">
                    </div>
                    <div class="card-body events pt-4">
                        <div class="row">
                            <label class="col-3 col-md-2 text-center"><span
                                    class="fw-bold text-cyan fs-21">{{invitationInfo?.initialDate.date | date :'MMM' |titlecase |slice:0:3  }}<br></span><span
                                    class="fw-bold fs-28">{{invitationInfo?.initialDate.date | date : 'd'}}<br></span><span class="fs-12">{{invitationInfo?.initialDate.date | date : 'y'}}</span></label>
                            <p class="col blue-title fw-bold fs-21 mt-md-3">{{invitationInfo?.tittle}}</p>
                        </div>
                        <div class="invitation-info invitation-info-event-card">
                            <angular-editor  [ngModelOptions]="{standalone: true}"  [(ngModel)]="htmlContent" [config]="editorConfig" style="border-color: #FAFAFA !important;"></angular-editor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card event-card my-3">
                    <div class="card-header events pb-0">
                        <p class="fw-bold fs-19 txt-blue text-center">Detalles sobre esta invitación</p>
                    </div>
                    <div class="card-body events pt-0">
                        <p class="fs-12"><img src="../../../../assets/icons/clock.svg" width="15px" height="15px"
                                class="mr-2">
                                {{ (invitationInfo?.initialDate.date | date:'dd MMMM')| titlecase}}, {{invitationInfo?.initialDate.hour}}:00 - {{ (invitationInfo?.endDate.date | date:'dd MMMM')| titlecase}} {{invitationInfo?.endDate.hour}}:00</p>
                        <p class="fs-12"><img src="../../../../assets/icons/clock.svg" width="15px" height="15px"
                                class="mr-2">
                            Fecha límite: {{ (invitationInfo?.limitDate.date | date:'dd MMMM')| titlecase}}, {{invitationInfo?.limitDate.hour}}:00</p>
                        <p class="fs-12" *ngIf="invitationInfo?.url !== ' '"><img src="../../../../assets/icons/link.svg" width="15px" height="15px"
                                class="mr-2">
                            {{invitationInfo?.url}}</p>
                        <p class="fs-12"><img src="../../../../assets/icons/placeholder.svg" width="15px" height="15px"
                                class="mr-2" *ngIf="invitationInfo?.location !== ' '">
                            {{invitationInfo?.location}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card event-card my-3">
                    <div class="card-header events pb-0">
                        <p class="fw-bold fs-19 txt-blue text-center">¿Tienes dudas sobre esta invitación?</p>
                    </div>
                    <div class="card-body events pt-0">
                        <p class="fs-12"><img src="../../../../assets/icons/conversation.svg" width="15px" height="15px"
                                class="mr-2">
                               {{invitationInfo?.contact}}</p>
                        <p class="fs-12"><img src="../../../../assets/icons/inbox.svg" width="15px" height="15px"
                                class="mr-2">
                            {{invitationInfo?.email}}</p>
                        <p class="fs-12" *ngIf="invitationInfo?.phone !== ''"><img src="../../../../assets/icons/mobile.svg" width="15px" height="15px"
                                class="mr-2">
                            {{invitationInfo?.phone}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
              <div *ngIf="!this.invitationDeadlineExpired" class="col-12 d-sm-flex justify-content-center mb-3 mt-2">
                <button class="col-12 col-sm-5 col-lg-4 col-xl-3 btn btn-secondary fs-14 mr-auto ml-auto mr-sm-3 d-block"  (click)="replyInvitation(true)" *ngIf="guestAssistance===undefined">Aceptar invitación</button>
                <button class="col-12 col-sm-5 col-lg-4 col-xl-3 btn btn-secondary fs-14 mr-auto ml-auto ml-sm-3 d-block mt-2 mt-sm-0"  (click)="replyInvitation(false)"  *ngIf="guestAssistance===undefined">Declinar invitación</button>
                <button class="btn btn-secondary fs-14 mx-auto d-block"  (click)="replyInvitation(true)" *ngIf="guestAssistance===false">Aceptar invitación</button>
                <button class="btn btn-secondary fs-14 mx-auto d-block"  (click)="replyInvitation(false)" *ngIf="guestAssistance===true">Declinar invitación</button>
              </div>
            </div>
            
            </div>
        </div>
    </div>
</div>
<div #openModal class="modal fade px-2" data-toggle="modal" data-target="#openModalLoading" id="openModalLoading" tabindex="-1" aria-labelledby="modalSuccess" aria-hidden="true">
    <div class="mx-auto modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div *ngIf="accepted">
                <p class="text-center fs-14 fw-bold text-darkgrey">¡Excelente!</p>
                <p class="txt-blue text-justify fs-14 fw-bold">
                    Hemos enviado un correo a {{infoEmployee?.businessEmail}} con todos los detalles de esta invitación. 
                </p>
                </div>
                <div *ngIf="!accepted">
                <p class="text-center fs-14 fw-bold text-darkgrey">¡Oh!</p>
                <p class="txt-blue text-justify fs-14 fw-bold">
                    Lamentamos que no puedas aceptar esta invitación
                </p>
                </div>
                <p class="txt-blue fs-14">¿Dudas?<br>Comunícate con:</p>
                <p class="fs-12"><img src="../../../../assets/icons/conversation.svg" width="15px" height="15px"
                    class="mr-2">
                  {{invitationInfo?.contact}}</p>
            <p class="fs-12"><img src="../../../../assets/icons/inbox.svg" width="15px" height="15px"
                    class="mr-2">
                {{invitationInfo?.email}}</p>
            <p class="fs-12"><img src="../../../../assets/icons/mobile.svg" width="15px" height="15px"
                    class="mr-2" *ngIf="invitationInfo?.phone !== ''">
                {{invitationInfo?.phone}}</p>
            </div>
            <div class="modal-footer border-0 pt-0 justify-content-center">
              <div class="row">
                <button type="button" class="btn-secondary fs-14 mr-auto ml-auto col-7 mb-3" data-dismiss="modal" (click)="reload();">Aceptar</button>
              </div>
            </div>
        </div>
    </div>
</div>