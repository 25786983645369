import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { pairwise, take } from 'rxjs/operators';;
// import 'rxjs/add/operator/pairwise';

@Component({
  selector: 'app-working-navigation',
  templateUrl: './working-navigation.component.html',
  styleUrls: ['./working-navigation.component.scss']
})
export class WorkingNavigationComponent implements OnInit {
  p: number;
  routes = [];
  preRoute = '';
  constructor(
    private router: Router,
  ) {
    this.routes = ['result', 'indicators','indicatorssecond','indicatorsthird','indicatorsfourth', 'questions']
    let url = this.router.url.split("/")
    url.pop()
    this.preRoute = url.join("/") + "/"
   }
  

  ngOnInit(): void {
    this.getRoutes();
    this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))  
        .subscribe((event: NavigationEnd) => {
        
        });
 
  }

  pageChanged(event) {
    this.p = event;
    this.router.navigate([this.preRoute + this.routes[event - 1]] );
    
  }   
  
  getRoutes(){
    switch (this.router.url) {
      case '/work/result':
        this.p = 1;
        break;
      case '/work/indicators':
        this.p = 2;
        break;
      case '/work/indicatorssecond':
        this.p = 3;
        break;
      case '/work/indicatorsthird':
        this.p = 4;
        break;
      case '/work/indicatorsfourth':
          this.p = 5;
          break;
      case '/work/questions':
            this.p = 6;
            break;
    }
  }
  

}
