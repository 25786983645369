/** RXJS */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
/** INTERFACES */
/**
 * Guard |
 * @export
 * @class PrivateRouteGuard
 * @implements {CanActivate}
 * @implements {OnDestroy}
 */
@Injectable()
export class  SimplePrivateRouteGuard implements CanActivate, CanActivateChild, OnDestroy {
  /** lista de suscripciones */
  subscribers: any = {};

  /**
   *Creates an instance of PrivateRouteGuard.
   * @param {AuthenticationService} authService
   * @memberof PrivateRouteGuard
   */
  constructor() {}

  /**
   * funciones necesarias para activacion de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PrivateRouteGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Fn | Revisa credenciales si está definido cliente y se retorna a `inicio`
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PublicRouteGuard
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Función para validación de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof PrivateRouteGuard
   */
  checkRoute(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return this.expiredLicense;
    return false;
  }

  /**
   * Destructor
   * @memberof PrivateRouteGuard
   */
  ngOnDestroy() {
  }
}
