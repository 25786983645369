import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as mqqt from 'mqtt';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { GlobalIntermediaryServices } from 'src/app/injections/GlobalIntermediaryServices';
import { AuthService } from '../auth/auth.service';
import { InvitationService } from '../invitation/invitation.service';
import { PublicationService } from '../publication-service/publication.service';
import { UsersService } from '../users/users.service';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class MqService {

    options = {
    username: '2bcoreAdmin',
    password: 'Esternocleidomastoideo_56',
    clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8)
    };
// variable de amazon MQ
    // client = mqqt.connect( 'mqtt+ssl://b-11c6cd56-e4b7-4fb7-82ae-44844cc80546-1.mq.us-east-1.amazonaws.com:8883', this.options);

  constructor(
    private globalIntSer: GlobalIntermediaryServices,
    private router: Router,
    private auth: AuthService,
    private invitation: InvitationService,
    private publicationService: PublicationService,
    private userService: UsersService,
    private modalService: BsModalService,
    private mqttService: MqttService
  ) { 
  }

  startMQ(){

    this.mqttService.onConnect.subscribe(()=>{
    })

    this.mqttService.onError.subscribe( () => {
      this.globalIntSer.desconectado();
    });

    this.mqttService.onOffline.subscribe(() => {
      this.globalIntSer.desconectado();
    });

    this.mqttService.onReconnect.subscribe(() => {
      this.globalIntSer.conectado();
    });

    this.mqttService.observe('employeeEmailWasUpdated').subscribe((message: IMqttMessage) => {
      const data = JSON.parse(message.payload.toString());
      if(data.idEm === sessionStorage.getItem('idEmp')){
        this.auth.signOut();
        Swal.fire({text:'Tu información ha sido actualizada por el administrador', icon: 'warning', confirmButtonText: 'Aceptar', 
          allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false});
      }
    });

    // this.client.on('login', (data: any) => {
    //   if (data.id === this.auth.userInfoLogged.getValue()._id && sessionStorage.getItem('isLogged') === 'true') {
    //     this.auth.multiSession.next(true);
    //     this.auth.signOut();
    //     Swal.fire({
    //       title: 'Alerta', text: 'Alguien ha iniciado sesión en tu cuenta desde otro navegador.',
    //       confirmButtonText:'Aceptar' ,
    //       icon: 'error', allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false
    //     });
    //   }
    // });

    this.mqttService.observe('updateGuest').subscribe((message: IMqttMessage) => {  
      const data = JSON.parse(message.payload.toString())
      this.invitation.socketAnswer(data);
    });

    this.mqttService.observe('deletedUsers').subscribe((message: IMqttMessage) => {
      const data = JSON.parse(message.payload.toString())
      if(data.idUsers.includes(sessionStorage.getItem('idUser'))){
        this.modalService.hide();
        this.auth.signOut();
      }
    });
    
    this.mqttService.observe('communications').subscribe((message: IMqttMessage) => {
      const data = JSON.parse(message.payload.toString())
      switch (data.change) {
        case 'publications':
          switch (data.type) {
            case 'company':
              this.publicationService.findAll().subscribe();
              break;
            case 'talents':
              this.publicationService.findTalents().subscribe();
              break;
            case 'commentsAndResponses':
              if(data.idPost === sessionStorage.getItem('postSelected') && this.router.url.includes('/posts/info') ){
                this.publicationService.findComments(sessionStorage.getItem('postSelected')).subscribe();
              }
              break;
            case 'delete':
              if(data.idPost === sessionStorage.getItem('postSelected')){
                if(this.router.url.includes('/posts/info')){
                  this.router.navigate(['/posts/menu/list'])
                }
              }
              if(this.router.url.includes('/posts/menu/list') || this.router.url.includes('/posts/menu/schedule') || this.router.url.includes('/posts/menu/history')){ 
                this.publicationService.findAll().subscribe();
                this.publicationService.findTalents().subscribe();
                this.publicationService.getScheduled().subscribe();
              }
              break;
            case 'update':
              if(data.idPost === sessionStorage.getItem('postSelected')){
                if(this.router.url.includes('/posts/info')){
                  this.publicationService.findOne(sessionStorage.getItem('postSelected')).subscribe();
                  this.publicationService.findComments(sessionStorage.getItem('postSelected')).subscribe();
                }
              }
              if(this.router.url.includes('/posts/menu/list') || this.router.url.includes('/posts/menu/schedule') || this.router.url.includes('/posts/menu/history')){ 
                this.publicationService.findAll().subscribe();
                this.publicationService.findTalents().subscribe();
                this.publicationService.getScheduled().subscribe();
              }
              break;
            default:
              break;
          }
          break;
        case 'notifications':
          // console.log("NOTIFICACION NUEVA");
          if(data.idUser === sessionStorage.getItem('idUser')){
            this.userService.getNotificationsByCompany(sessionStorage.getItem('idUser'), 4,sessionStorage.getItem('idCompanySelected')).subscribe();
          }
          break;
      
        default:
          break;
      }
    });
  };
}
// function Swal(arg0: { text: string; type: string; confirmButtonText: string; allowOutsideClick: boolean; allowEnterKey: boolean; allowEscapeKey: boolean; }) {
//   throw new Error('Function not implemented.');
// }

