import { Component, Input } from '@angular/core';
import { Breadcrumb } from './breadcrumb.conts';

@Component({
  selector: 'app-breadcrumb[crumbs]',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() homeRoute: string = '/home/init-page';
  @Input() crumbs!: Breadcrumb[];
}
