import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import Swal from 'sweetalert2';
import { ResponseContent } from '../../models';
import { map } from 'rxjs/operators';
import { Swal2Loader } from '@consts/swal2-loader';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(
    private http: HttpService
  ) { }


  /**
 * funcion para obtener tiempos
 *
 * @returns
 * @memberof TimeService
 */
  getTimes() {

    Swal.fire(Swal2Loader)

    return this.http.post('/time/get', {}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res.content;
      }
    }));
  }


  /**
* funcion para crear time
*
* @param {*} email
* @returns
* @memberof TimeService
*/
  createTime(time) {

    Swal.fire(Swal2Loader)

    return this.http.post('/time/create', time, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        return res;
      }
    }));
  }


  /**
* funcion para actualizar time
*
* @param {*} email
* @returns
* @memberof TimeService
*/
  updateTime(time) {

    Swal.fire(Swal2Loader)

    return this.http.post('/time/update', time, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        return res;
      }
    }));
  }

  
  /**
* funcion para eliminar time
*
* @param {*} email
* @returns
* @memberof TimeService
*/
deleteTime(id) {

  Swal.fire(Swal2Loader)

  return this.http.post('/time/update', id, true).pipe(map((res: ResponseContent) => {
    if (res && res.clave === 'OK') {
      return res;
    }
  }));
}


}
