import { Injectable } from '@angular/core';
import { ResponseContent } from '../../models';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/internal/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { EmployeesService } from '../employees/employees.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})


/**
 * Servicio para obtencion de estadisticas
 * @export
 */
export class StatisticsService {

  statisticsSelected = new BehaviorSubject <any>({});
  finishRequestStats = new BehaviorSubject <Boolean>(false);
  pdfs= false;

   /**
   * Creates an instance of AuthService.
   * @param {HttpService} http
   * @param {errorsService} errorsService
   */
  constructor(
    private http: HttpService,
    private route: Router,
    private auth: AuthService,
    private employee: EmployeesService
  ) {
  }

  /**
   * Carga masiva
   */
  getStatistics(idCompany, nameProject,percentageReports=false) {
    return this.http.post('/statistics/get', {idCompany, nameProject, percentageReports}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }
  setpdfs(value) {
    this.pdfs = value;
  }
  
  getpdfs() {
    return this.pdfs;
  }

  selectProject(project) {
    Swal.fire({
      title: 'Proyectos',
      text: 'Obteniendo datos',
      icon: 'info',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false
    });
    let isEmployee = false;
    if(!this.route.url.includes('/NOM-projects') ){
      isEmployee = true;
    }else{
      isEmployee = false;
    }
    Swal.showLoading();
    if (isEmployee) {
      this.employee.projectName.next(project);
      this.employee.projectSelected.next(project.questionnaire);
      if(project.questionnaire.finalized  && project.questionnaire.finalized != false){
        this.employee.notSelected.next(false);
      }else{
        this.employee.notSelected.next(true);
      }
      Swal.close();
    } else {
        this.employee.project.next(project);
        this.getStatistics(this.auth.idCompanieSelected.getValue(), project.nameProject).subscribe(stats => {
          Swal.close();
          if (stats.content.questionnairesSend.answer > 0) {
            this.statisticsSelected.next(stats);
            this.route.navigate(['/NOM-projects/report']);
          } else {
            this.route.navigate(['/NOM-projects/send']);
          }
        });
    }
  }
}
