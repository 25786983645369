import { Injectable } from '@angular/core';
/**RXJS */
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para generacion de token
 */
export class TokenService {

  /** Arreglo con servicios que ocupan token*/
  private servicesWithToken = [];

  /** Arreglo con servicios que ocupan interceptores */
  public servicesInterceptor = [];

  /**
   * cretes an instance of TokenService
   */
  constructor() { }

  /**
   * Fn | Declarar STC
   * @param {string} key
   * @param {string} value
   * @memberof AuthenticationTokenService
   */
  setToken(key: string): void {

    sessionStorage.setItem('token', key);

  }

  set_xSession(key: string): void {
    sessionStorage.setItem('x-session', key)
  }

  /**
   * Fn | Obtener valores de CSRF Token
   * @returns {*}
   * @memberof AuthenticationTokenService
   */
  getToken(): string {
    return sessionStorage.getItem('token');
  }

  /**
   * Fn | Limpiar CSRF Token
   * @memberof AuthenticationTokenService
   */
  clearToken(): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('x-session');
  }

  /**
   *Inserta en el arreglo el servicio valido para token
  * @param {string} service
  * @memberof TokenService
  */
  saveService(service: string) {
    if (!this.servicesWithToken.includes(service)) {
      this.servicesWithToken.push(service);
    }
  }
  
  /**
   *Inserta en el arreglo el servicio valido para token
  * @param {string} service
  * @memberof TokenService
  */
  saveServiceInterceptor(service: string) {
    if (!this.servicesInterceptor.includes(service)) {
      this.servicesInterceptor.push(service);
    }
  }
  
  /**
	 * Buscar si servicio requiere token
	 * @param {string} service
	 * @returns
	 * @memberof AuthenticationTokenService
	 */
  searchServiceToken(service: string) {
    for (const serv of this.servicesWithToken) {
      if (service === serv) {
        return sessionStorage.getItem('token');
      }
    }
    return undefined;
  }

  searchApiServiceToken(){

    return sessionStorage.getItem('x-session');

  }

}
