<button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    <span class="icon time-icon-menu_individual">
        <span class="path1"></span>
        <span class="path2"></span>
    </span>
    <h1 class="title">Carga de incidencia</h1>
</div>

<div mat-dialog-content>

    <p class="modal-text mb-4">Comienza seleccionando el tipo de incidencia:</p>

    <form class="text-center w-100" [formGroup]="incidenceForm">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
            <mat-label>Selecciona una incidencia</mat-label>
            <mat-select formControlName="incidence" class="deep-select-overlay">
                <mat-option *ngFor="let item of incidence" [value]="item.value" class="deep-mat-option">
                    {{ item.viewValue }}
                </mat-option>
            </mat-select>
            <span matSuffix class="time-icon-arrow_expand fs-16 p-3"></span>
        </mat-form-field>

        <div *ngIf="selectedIncidence && selectedIncidence !== 'pcgds' && selectedIncidence !== 'psgds'; else dayTime">
            <p class="modal-text my-4">Ingresa la fecha de inicio y fecha fin:</p>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-4 w-100 deep-mat-field">
                <mat-label>Fecha Inicio</mat-label>
                <input matInput readonly [matDatepicker]="picker1" formControlName="startDate" [min]="today"
                    [max]="incidenceForm.get('endDate')?.value" (click)="picker1.open()">
                <mat-datepicker-toggle matIconSuffix [for]="picker1">
                    <span class="time-icon-calendar_default fs-24" matDatepickerToggleIcon></span>
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                <mat-label>Fecha Fin</mat-label>
                <input matInput readonly [matDatepicker]="picker2" formControlName="endDate"
                    [min]="incidenceForm.get('startDate')?.value ? incidenceForm.get('startDate')?.value : today"
                    (click)="picker2.open()">
                <mat-datepicker-toggle matIconSuffix [for]="picker2">
                    <span class="time-icon-calendar_default fs-24" matDatepickerToggleIcon></span>
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <ng-template #dayTime>
            <div *ngIf="selectedIncidence === 'pcgds' || selectedIncidence === 'psgds'">
                <p class="modal-text my-4">Ingresa las horas y fecha de la incidencia:</p>
                <div class="d-flex justify-content-between mb-4">
                    <div class="px-0 mr-2 input-hours">
                        <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                            <mat-label>Horas</mat-label>
                            <input matInput formControlName="hours" id="hours" required type="number" autocomplete="off"
                                [appOnlyNumbersMaxLength]="2" (blur)="managementHours()">
                        </mat-form-field>
                    </div>
                    <div class="px-0 d-flex flex-column">
                        <span class="time-icon-time_plus mb-2" (click)="managementHours(1)"></span>
                        <span class="time-icon-time_minus" (click)="managementHours(-1)"></span>
                    </div>
                </div>

                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                    <mat-label>Fecha</mat-label>
                    <input matInput readonly [matDatepicker]="picker2" formControlName="startDate" required
                        [min]="today" (click)="picker2.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker2">
                        <span class="time-icon-calendar_default fs-24" matDatepickerToggleIcon></span>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-template>
    </form>

</div>
<div mat-dialog-actions class="deep-button">
    <button mat-flat-button color="primary" [disabled]="!incidenceForm.valid" (click)="onSubmit()">
        {{
        selectedIncidence === 'pcgds' || selectedIncidence === 'psgds'
        ? 'Aprobar'
        : 'Agregar incidencia'
        }}
    </button>
</div>