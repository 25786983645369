export interface Activity {
    activityId: string;
    date: Date;
    activity: string;
    type: string;
    code: string;
    hours: string;
    minutes: string;
    description: string;
    status: string;
    comments?: string;
}

export interface ActivitiesPerMonth {
    employeeId: string;
    employee: string;
    month: Date;
    activities: Activity[];
}

export const activities: ActivitiesPerMonth[] = [
    {
        employeeId: '001',
        employee: 'Lucero Jiménez',
        month: new Date(),
        activities: [
            {
                "activityId": "001001",
                "date": new Date('2024-12-19'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "2",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "rejected",
                "comments": "Debes incluir a detalle esta actividad para poder aprobarla."
            },
            {
                "activityId": "001002",
                "date": new Date('2024-12-19'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "4",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "rejected",
                "comments": "Debes incluir a detalle esta actividad para poder aprobarla."
            },
            {
                "activityId": "001003",
                "date": new Date('2024-12-19'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "2",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "rejected",
                "comments": "Debes incluir a detalle esta actividad para poder aprobarla."
            },
            {
                "activityId": "001004",
                "date": new Date('2024-12-14'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "6",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "approved"
            },
            {
                "activityId": "001005",
                "date": new Date('2024-12-14'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "2",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "pending"
            },
            {
                "activityId": "001006",
                "date": new Date('2024-12-13'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "2",
                "minutes": "0",
                "description": "Sistema de diseño y elementos.",
                "status": "pending"
            },
            {
                "activityId": "001007",
                "date": new Date('2024-12-12'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "0",
                "minutes": "30",
                "description": "Daily con el equipo de Santander.",
                "status": "rejected",
                "comments": "Debes incluir a detalle esta actividad para poder aprobarla."
            },
            {
                "activityId": "001008",
                "date": new Date('2024-12-12'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "7",
                "minutes": "30",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001009",
                "date": new Date('2024-12-11'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "8",
                "minutes": "0",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001010",
                "date": new Date('2024-12-10'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "3",
                "minutes": "40",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001011",
                "date": new Date('2024-12-09'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "1",
                "minutes": "30",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001012",
                "date": new Date('2024-12-04'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "8",
                "minutes": "0",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001013",
                "date": new Date('2024-11-30'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "8",
                "minutes": "0",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            },
            {
                "activityId": "001014",
                "date": new Date('2024-11-31'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "8",
                "minutes": "0",
                "description": "Diseño de prototipo R1.",
                "status": "approved"
            }
        ]
    },
    {
        employeeId: '002',
        employee: 'Jesús Acha',
        month: new Date(),
        activities: [
            {
                "activityId": "002001",
                "date": new Date('2024-07-15'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "6",
                "minutes": "0",
                "description": "Diseño de prototipo MVP para presentación con cliente.",
                "status": "pending"
            },
            {
                "activityId": "002002",
                "date": new Date('2024-07-24'),
                "activity": "Chatbot AI Assistant",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "2",
                "minutes": "0",
                "description": "Sistema de diseño y elementos.",
                "status": "pending"
            },
            {
                "activityId": "002003",
                "date": new Date('2024-07-23'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "0",
                "minutes": "30",
                "description": "Daily con el equipo de Santander.",
                "status": "pending"
            },
            {
                "activityId": "002004",
                "date": new Date('2024-07-23'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "7",
                "minutes": "30",
                "description": "Diseño de prototipo R1.",
                "status": "pending"
            },
            {
                "activityId": "002005",
                "date": new Date('2024-07-19'),
                "activity": "Santander Unique",
                "type": "Project",
                "code": "E402BCCIA232711N",
                "hours": "8",
                "minutes": "0",
                "description": "Diseño de prototipo R1.",
                "status": "pending"
            }
        ]
    }
]
