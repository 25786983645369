<div class="container mb-3">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 mb-4">
          <div class="row">
            <div class="col-12 col-lg-9 mb-4">
              <div class="input-search-wrapper  position-relative">
                <input [disabled]="!this.lastPost" 
                  type="text" 
                  class="form-control search-input"
                  [(ngModel)]="search" 
                  (keyup)="searchPosts($event)" 
                  placeholder="Buscar publicación..." 
                  maxlength="80"
                />
                <span class="search">
                  <span class="icon-search fs-16 align-self-center"></span>
                </span>
              </div>  
            </div>
            <div class="col-lg-3 col-12 d-flex justify-content-center txt-dark px-0" *ngIf="( userInfoLogged.role === 'admin' || userInfoLogged.role === 'root' ) ? true : (this.userPermissions.communication?.toPost)">
              <a href="#" 
                [routerLink]="['/posts/add']"
                class="btn btn-add-post d-flex px-2 justify-content-center txt-light"
              >
                <div class="fs-14 mr-lg-2 cross-icon mr-1 txt-light">+</div>
                <div class="fs-12">Agregar publicación</div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 reset-search" *ngIf="search.length">
          <a href="#" class="txt-dark" (click)="resetSearch($event)">< Regresar</a>
        </div>
        <div class="col-12 search-title" *ngIf="search.length">
          <h2 class="color-textS wrap-txt">Resultados para: "{{search}}"</h2>
        </div>

        <div class="col-12 no-posts-wrapper text-center ml-3"
          *ngIf="postList && !postList.length &&  !this.lastPost && !search.length">
          <img src="/assets/img/publications.jpg" width="30%" alt="">
          <p *ngIf="userInfoLogged?.role == 'employee'">Aún no tienes publicaciones.</p>
          <p *ngIf="userInfoLogged?.role != 'employee'">Aún no tienes publicaciones creadas.</p>
        </div>
        <div class="col-12 text-center ml-3" *ngIf="(!this.search.length === false) && this.search.length !== 0 && postListFiltered.length === 0 ">
          <img src="/assets/img/communication/busqueda.svg" alt="">
          <p class="fs-28 color-text">No coincide ninguna publicación con la búsqueda.</p>
        </div>

        <!-- Primera publicación -->
        <div class="col-12" *ngIf="this.search.length === 0 && this.lastPost !== undefined">
          <div class="card post-card first-post">
            <div class="card-head p-0">
              <img [src]="lastPost && lastPost?.img ? (lastPost?.img | imagePath: 'postBackground') : '/assets/img/communication/cover-generic.jpg'"
                width="100%" class="first-post" alt="">
              <div class="blue-tag fw-bold text-center px-3">
                <label class="fs-20 fsm-10 fw-bold">
                  <span>{{lastPost.date | date:'dd'}}</span> de
                  <span>{{ (lastPost.date | date:'MMMM')| titlecase}}
                            de {{ lastPost.date | date:'yyyy'}}</span>
                </label>
              </div>
              <div class="darkblue-tag fs-12 fw-bold d-flex justify-content-center" *ngIf="userInfoLogged.role !== 'employee' && (this.userPermissions.communication.editPost || this.userPermissions.communication.deletePost)">
                <div *ngIf="(this.userPermissions.communication.editPost && (this.lastPost.area.length !== 0 || this.lastPost.center.length !== 0))" class="mr-2">
                  <span class="icon-action" (click)="getPostPrivacy(this.lastPost)" data-toggle="modal" data-target="#privacyPostModal">
                    <img src="assets/icons/communication/privacy.svg" width="16px" height="16px" alt="">
                  </span>
                </div>
                <div>
                  <span [ngClass]="(this.userPermissions.communication.deletePost) ? 'd-block' : 'd-none'" class="icon-delete fs-24 cr-pointer" (click)="this.postToDelete = lastPost._id;this.namePostToDelete=lastPost.title" data-toggle="modal" data-target="#modalDel"></span>
                </div>
                <div>
                  <a [ngClass]="(this.userPermissions.communication.editPost) ? 'd-block ml-2' : 'd-none'" (click)="goToDetail(lastPost._id)">
                    <span class="icon-action">
                      <img src="assets/icons/communication/edit.svg" width="16px" height="16px" alt="">
                    </span>
                  </a>
                </div>

              </div>
              <div class="info-card w-100 px-lg-4 px-3">
                <div class="row px-2">
                  <div class="brd mb-2 pl-2 col-lg-10 col-8 ">
                    <p class="fs-18 fsm-14 fw-bold mb-0">{{lastPost.title | slice: 0:69}}{{lastPost.title.length > 69 ? "..." : "" }}</p>
                    <div class="invitation-info-event-card descipt">
                      <angular-editor [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="lastPost.publication" [config]="editorConfig"
                        style="border-color: #FAFAFA !important;"
                      ></angular-editor>
                    </div>
                  </div>
                  <div class="py-5 px-3 col-lg-2 col-4 fs-12 text-lg-right text-md-right text-center ml-lg-auto">
                    <button (click)="seePublication(lastPost._id)" class="btn btn-secondary fs-12 fw-bold">Ver más</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cards publicaciones -->
        <div class="col-lg-4 col-12 mt-4 mb-4"
          *ngFor="let item of (this.search.length === 0 ? this.postList : this.postListFiltered) | paginate: {itemsPerPage: 6, currentPage: pageIndex}; let tempInd = index;">
          <div class="card post-card content-card" >
            <div class="card-head p-0">
              <img [src]="item.img ? (item.img | imagePath: 'postBackground') : '/assets/img/communication/cover-generic.jpg'" 
                width="100%" height="149px" alt="">
              
              <div class="darkblue-tag card-tag fs-12 fw-bold d-flex justify-content-center" *ngIf="userInfoLogged.role !== 'employee' && (this.userPermissions.communication.editPost || this.userPermissions.communication.deletePost)">
                <div *ngIf="(this.userPermissions.communication.editPost && (item.area.length !== 0 || item.center.length !== 0))" class="mr-2">
                  <span class="icon-action" (click)="getPostPrivacy(item)" data-toggle="modal" data-target="#privacyPostModal">
                    <img src="assets/icons/communication/privacy.svg" width="16px" height="16px" alt="">
                  </span>
                </div>
                <div>
                  <span [ngClass]="(this.userPermissions.communication.deletePost) ? 'd-block' : 'd-none'" class="icon-delete fs-24 cr-pointer" (click)="this.postToDelete = item._id;" data-toggle="modal" data-target="#modalDel"></span>
                </div>
                <div [ngClass]="(this.userPermissions.communication.editPost) ? 'd-block ml-2' : 'd-none'" (click)="goToDetail(item._id)">
                  <a>
                    <span class="icon-action">
                      <img src="assets/icons/communication/edit.svg" width="16px" height="16px" alt="">
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body events">
              <p class="overflow-hidden fs-18 txt-dark fw-bold mb-lg-3 mb-2 title-post">{{item.title | slice: 0:65}}{{item.title.length > 65 ? "..." : "" }}</p>
              <p class="fs-12 txt-lightgray2 mb-2 mb-lg-3">
                <span>{{item.date | date:'dd'}}</span> de
                <span>{{ (item.date | date:'MMMM')| titlecase}} de {{ item.date | date:'yyyy'}}</span>
              </p>
              <div class="invitation-info-event-card d-none d-lg-block">
                <angular-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="item.publication"
                  [config]="editorConfig" style="border-color: #FAFAFA !important;"></angular-editor>
              </div>
              <div class=" fs-12 text-right pt-lg-3 mt-lg-2">
                <button (click)="seePublication(item._id)" class="btn btn-secondary p-0 fw-bold">Ver más</button>
              </div>
            </div>
          </div>
        </div>
          
        <div *ngIf="postList.length > 6" class="paginator text-center pb-0 mt-3 col-12">
          <pagination-controls previousLabel="" 
            nextLabel="" 
            (pageChange)="pageChanged($event)"
            class="pagination-Control fs-12 "
          ></pagination-controls>
        </div>
        <p class="fs-20 fw-bold txt-blue my-3 col-12" *ngIf="this.slides.length && ( this.userInfoLogged.role === 'admin' || this.userInfoLogged.role === 'root')">Publicaciones 2BTalents</p>
        <div class="container" *ngIf="this.slides.length && ( this.userInfoLogged.role === 'admin' || this.userInfoLogged.role === 'root')">
          <div class="row">
            <div class="mb-4 col-12">
              <ngx-slick-carousel class="carousel-slick public-carousel-experience properties-carousel-experience publications-carousel"
                #slickModal="slick-carousel" [config]="slideConfigExperience">
                <div ngxSlickItem *ngFor="let slide of slides; index as i" class="slide">
                  <div class="card event-card mx-2">
                    <div class="card-head p-0">
                      <img [src]="slide.img ? (slide.img | imagePath: 'postBackground') : '/assets/img/communication/cover-generic.jpg'" width="100%" height="149px" alt="">
                    </div>
                    <div class="card-body events">
                      <p class="overflow-hidden fs-18 txt-blue fw-bold mb-1 fixed-txt"> {{slide.title | slice: 0:69}}{{slide.title.length > 69 ? "..." : "" }} </p>
                      <p class="fs-12 txt-lightgray2">
                        <span>{{slide.date | date:'dd'}}</span> de
                        <span>{{ (slide.date | date:'MMMM')| titlecase}} de {{ slide.date | date:'yyyy'}}</span>
                      </p>
                      <div class="fs-12 text-center mt-4">
                        <button (click)="seePublication(slide._id)" class="btn btn-secondary p-0">Ver más</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="privacyPostModal" tabindex="-1" role="dialog" aria-labelledby="privacyPostModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 px-2 py-0">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <img class="close-modal-image" src="/assets/icons/crossoutlightblue.svg" alt="">
              </button>
            </div>
            <div class="modal-body pt-0">
              <div class="row fs-12">
                <div class="col-12">
                  <p class="privacy-modal-label fs-14 txt-blue">Esta publicación no es visible para los siguientes centros o áreas:</p>
                  <div class="form-inline">
                    <div class="selected-game" *ngFor="let w of this.selects.center">
                      <span class="badge badge-light fs-12 txt-grey fw-regular p-1 mr-2" *ngIf="w.isSelected">{{w.item}}
                        <img src="assets/icons/checkmarkfillgrey.svg" class="ml-1" width="10px" height="10px" (click)="w.isSelected =  !w.isSelected">
                      </span>
                    </div>
                    <div class="selected-game" *ngFor="let a of this.selects.area">
                      <span class="badge badge-light fs-12 txt-grey fw-regular p-1 mr-2" *ngIf="a.isSelected">{{a.item}}
                        <img src="assets/icons/checkmarkfillgrey.svg" class="ml-1" width="10px" height="10px" (click)="a.isSelected =  !a.isSelected">
                      </span>
                    </div>
                  </div>
                  <p class="mb-2 mt-3 fs-14 txt-blue">Puedes cambiar la privacidad de la publicación: </p>
                  <p class="mb-2 fs-12 txt-blue">Filtros</p>
                  <div class="dropdown col col-lg-7 col-xl-6 px-0">
                    <button class="btn dropdown-toggle drop-filter w-100 fs-12 text-left"
                      type="button" id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Centros/Áreas
                    </button>
                    <div class="dropdown-menu w-100 fs-14 pt-1" id="dropdownContent">
                      <p class="mb-0 fw-bold ml-2">Centros</p>
                      <div class="scrolling">
                        <div class="dropdown-item py-0 mb-0"
                          *ngFor="let w of this.selects.center">
                          <label class="row position-relative d-flex align-items-center">
                            <input type="checkbox" class="col" [(ngModel)]="w.isSelected" name="list" value="{{w.item}}" >
                            <span class="checkmark-square"></span>
                            <p class="col mb-0 ml-3">{{w.item}}</p>
                          </label>
                        </div>
                      </div>
                      <div class="dropdown-divider my-0"></div>

                      <p class="mb-0 fw-bold ml-2 mt-1">Áreas</p>
                      <div class="scrolling">
                        <div class="dropdown-item py-0 mb-0" *ngFor="let a of this.selects.area">
                          <label class="row position-relative d-flex align-items-center">
                            <input type="checkbox" class="col" [(ngModel)]="a.isSelected" name="listarea" value="{{a.item}}" >
                            <span class="checkmark-square"></span>
                            <p class="col mb-0 ml-3">{{a.item}}</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 pt-0 justify-content-center pt-3">
              <button type="button" class="btn-secondary fs-14" data-dismiss="modal" (click)="this.savePost();">Aceptar</button>
            </div>
          </div>
        </div>
        <!-- para evitar residuos cuando lo saquen de sesión -->
        <span #dismisseModal class="hide" data-dismiss="modal"></span>
      </div>

      <!-- modal-->
      <div class="modal fade px-0" id="modalDel" tabindex="-1" aria-labelledby="modalDel" aria-hidden="true">
        <div class="mx-auto modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 justify-content-center">
              <label class="modal-title fs-14 txt-dark pb-4 fw-bold"> ¿Estás seguro de querer eliminar esta publicación? </label>
            </div>
            <div class="modal-footer border-0 pt-0 justify-content-center">
              <button type="button" (click)="this.postToDelete = null;" class="btn-secondary fs-14" data-dismiss="modal">Cancelar</button>
              <button type="button" (click)="onDeletePost()" class="btn-secondary fs-14 mr-sm-3" data-dismiss="modal">Aceptar</button>
            </div>
          </div>
        </div>
        <!-- para evitar residuos cuando lo saquen de sesión -->
        <span #dismisseModal class="hide" data-dismiss="modal"></span>
      </div>
    </div>
  </div>
</div>
