import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Overview } from '../project.const';
import { Employee } from '@pmo/teams/team/team.const';

@Component({
  selector: 'app-overview[overview][allEmployees]',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  @Input() overview!: Overview;
  @Input() allEmployees: Employee[] = [];
  @Output() downloadReportEvent = new EventEmitter<boolean>();

  public downloadReport() {
    this.downloadReportEvent.emit(true);
  }
}
