import { Component, OnInit } from '@angular/core';
import { EmployeesService } from '../../../services/employees/employees.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service'
import { QuestionaryModel } from 'src/app/models/questionnaires/questionary.model'
import { questionsTest3Text } from '../../../constants/text/questionsTest3Text';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ModalService } from 'src/app/services';

@Component({
  selector: 'app-guide-three',
  templateUrl: './guide-three.component.html',
  styleUrls: ['./guide-three.component.scss']
})

export class GuideThreeComponent implements OnInit {
  name: string = "Nombre";
  firstLastname: string = "PrimerApellido";
  secondLastname: string = "SegundoApellido";
  employeeNumber: string = "924";
  job: string = "Desarrollador Front End";
  business: string = "2BCore S.A. DE C.V.";
  campus: string = "CDMX";
  area: string = "Operaciones y Servicios";

  //ALmacena las guias
  guide = [];
  //Almacena las respuestas del usuario de la primera seccion
  sectionOne = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionTwo = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionThree = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionFour = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionFive = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionSix = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionSeven = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionEight = []
  //Almacena las respuestas del usuario de la primera seccion
  sectionNine = []




  //Questions
  questionsSectionOne = questionsTest3Text.SECTION_ONE;
  questionsSectionTwo = questionsTest3Text.SECTION_TWO;
  questionsSectionThree = questionsTest3Text.SECTION_THREE;
  questionsSectionFour = questionsTest3Text.SECTION_FOUR;
  questionsSectionFive = questionsTest3Text.SECTION_FIVE;
  questionsSectionSix = questionsTest3Text.SECTION_SIX;
  questionsSectionSeven = questionsTest3Text.SECTION_SEVEN;
  questionsSectionEight = questionsTest3Text.SECTION_EIGHT;
  questionsSectionNine = questionsTest3Text.SECTION_NINE;
  questionsSectionTen = questionsTest3Text.SECTION_TEN;
  questionsSectionEleven = questionsTest3Text.SECTION_ELEVEN;
  questionsSectionTwelve = questionsTest3Text.SECTION_TWELVE;
  questionsSectionFourteen = questionsTest3Text.SECTION_FOURTEEN;
  questionsSectionFifteen = questionsTest3Text.SECTION_FIFTEEN;


  //DataSession
  private idEmployee: string;
  private idCompany: string;
  private idProject: string;
  private nameProject: string;

  //Data
  II41: string = '9';
  II42: string = '9';
  II43: string = '9';
  II44: string = '9';
  II45: string = '9';
  II46: string = '9';

  //Status
  preSubmit: boolean = true;
  error: boolean = false;
  questionsB: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,];
  optionalSeccion1: boolean = false;
  optionalSeccion2: boolean = false;

  //Style
  progressbarCountProgress: number = 0;
  pb: number = 0;
  shipP: number = -65;
  endColorLabel: string = "#D4D4D4";
  blockedText1Title: string = "#D4D4D4";
  blockedText1: string = "#D4D4D4";
  blockedText2Title: string = "#D4D4D4";
  blockedText2: string = "#D4D4D4";
  displayInfo: string = 'none';
  withSidebar = false;

  //Models
  infoEmployee: any;
  nameCompany: string;
  answer: QuestionaryModel;

  //Misc
  test3: FormGroup;
  params;
  currentRoute: string;
  visible = false;
  visibletwo = false;

  answerQueue: QuestionaryModel[] = [];
  dispatching = false;
  serviceToCustomersOrUsersForm: FormGroup;
  bossForm: FormGroup;
  changeSection = 0;
  mostrar = 'container';
  mostrard = 'container d-none d-block d-lg-none d-xl-none'
  constructor(private route: ActivatedRoute,
    private employee: EmployeesService,
    private questionnares: QuestionnairesService,
    public router: Router,
    private fb: FormBuilder,
    private modal: ModalService 
  ) {}

  ngOnInit(): void {
    window.scroll({ top: 0 });
    this.initForm();
    this.preSubmit = true;
    this.idEmployee = this.route.snapshot.queryParamMap.get('idEmployee');
    this.idCompany = this.route.snapshot.queryParamMap.get('idCompany');
    this.idProject = this.route.snapshot.queryParamMap.get('idProject');

    if (this.idEmployee) {
      this.questionnares.getEmployee(this.idEmployee).subscribe(res => {
        Swal.fire({
          title: 'Guía III',
          text: 'Obteniendo guía',
          icon: 'info',
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false
        });
        Swal.showLoading();
        if (res) {
          this.infoEmployee = res.content;
          if (this.infoEmployee.idCompany == this.idCompany) {
            this.questionnares.getCompanyName(this.infoEmployee.idCompany).subscribe((response) => {
              if (response) {
                this.nameCompany = response.content.companyName
                if (this.idProject) {
                  this.questionnares.getProjectName(this.idProject).subscribe(res => {
                    this.nameProject = res.content;
                    this.questionnares.getEmployeeQuests(this.idCompany, this.idEmployee).subscribe(response => {
                      Swal.close();
                      response.content.forEach(element => {
                        if (element.nameProject == this.nameProject) {
                          if (element.questionnaire.guideThree.finalized == true) {
                            this.router.navigate(['/guides-summary'], {
                              queryParams: {
                                idEmployee: this.idEmployee,
                                idCompany: this.idCompany, idProject: this.idProject, new: false
                              }
                            });
                          }
                          let quest = element.questionnaire;
                          if (element.questionnaire.guideThree.section.length > 0) {
                            this.changeSection = (element.questionnaire.guideThree.section.length - 1);
                          } else {
                            this.changeSection = 0;
                          }
                          if (quest) {
                            this.loadProgress(quest);
                          }
                          // * The date is validated only if it is not a forwarded link
                          if(!element.questionnaire.chanceOutsideLimitDate){
                            if(element.expired){
                              this.modal.warning({
                                title: "Cuestionario expirado.",
                                message: "Ya no está disponible este cuestionario.",
                              });
                            }
                          }
                        }
                      });
                    })
                  });
                }
              }
            });
          } else {
            throw new Error("Empleado no perteneciente a esta compañia");
          }
        }
      });

    }
    window.scroll({ top: 0 });

    this.initForm();
    this.initRadiosThatDisableSections()
    if (this.router.url == '/employees/file/profile/nom-35/one') {
      this.visible = false;
      this.visibletwo = true;
    } else if (this.router.url == '/employees/file/profile/nom-35/personal') {
      this.visible = false;
      this.visibletwo = false
    } else if (this.router.url == '/employees/file/profile/nom-35/three') {
      this.visible = false;
      this.visibletwo = true;
    } else {
      this.visible = true;
      this.visibletwo = true
    }
    if (this.router.url.includes('/employees/file/profile/nom-35')) {
      this.visible = false;
    } else if (this.router.url === '/employees/file/profile/nom-35/personal') {
      this.visible = false;
    } else {
      this.visible = true;
    }
    this.preSubmit = true;
    this.currentRoute = this.router.url;
    this.getAnswer();
    if (this.router.url.includes('/three')) {
      this.serviceToCustomersOrUsersForm.disable()
      this.bossForm.disable()
      this.test3.disable();
    }
  }
  loadProgress(quest: any) {
    if (quest.guideThree.disable.length > 1) {
      if (quest.guideThree.disable[0].value == 'si') {
        this.blockedText1Title = "#003f9b";
        this.blockedText1 = "#212529";
        this.optionalSeccion1 = true;
        this.serviceToCustomersOrUsersForm.controls.option.setValue('Si');

        
        if (quest.guideThree.section[12]) {
          try {
            if (quest.guideThree.section[12].idSection == 13) {
              this.test3.controls.III65.setValue(quest.guideThree.section[12].questions[0].value);
              this.test3.controls.III66.setValue(quest.guideThree.section[12].questions[1].value);
              this.test3.controls.III67.setValue(quest.guideThree.section[12].questions[2].value);
              this.test3.controls.III68.setValue(quest.guideThree.section[12].questions[3].value);
            } else if (quest.guideThree.section[12].idSection == 14) {
              this.test3.controls.III65.setValue(quest.guideThree.section[13].questions[0].value);
              this.test3.controls.III66.setValue(quest.guideThree.section[13].questions[1].value);
              this.test3.controls.III67.setValue(quest.guideThree.section[13].questions[2].value);
              this.test3.controls.III68.setValue(quest.guideThree.section[13].questions[3].value);
            }
            this.test3.controls.III65.setValue(quest.guideThree.section[12].questions[0].value);
            this.test3.controls.III66.setValue(quest.guideThree.section[12].questions[1].value);
            this.test3.controls.III67.setValue(quest.guideThree.section[12].questions[2].value);
            this.test3.controls.III68.setValue(quest.guideThree.section[12].questions[3].value);
          } catch (error) { }
        }
      } else {
        this.blockedText1Title = "#D4D4D4"
        this.blockedText1 = "#D4D4D4";
        this.optionalSeccion1 = false;
        this.serviceToCustomersOrUsersForm.controls.option.setValue('No')
      }

      if (quest.guideThree.disable[1].value == 'si') {
        this.bossForm.controls.option.setValue('Si');
        this.blockedText2Title = "#003f9b";
        this.blockedText2 = "#212529"
        this.optionalSeccion2 = true;
        if (quest.guideThree.section[12].idSection == 14) {
          try {
            this.test3.controls.III69.setValue(quest.guideThree.section[12].questions[0].value);
            this.test3.controls.III70.setValue(quest.guideThree.section[12].questions[1].value);
            this.test3.controls.III71.setValue(quest.guideThree.section[12].questions[2].value);
            this.test3.controls.III72.setValue(quest.guideThree.section[12].questions[3].value);
          } catch (e) { }
        } else if (quest.guideThree.section[13]) {
          try {
            this.test3.controls.III69.setValue(quest.guideThree.section[13].questions[0].value);
            this.test3.controls.III70.setValue(quest.guideThree.section[13].questions[1].value);
            this.test3.controls.III71.setValue(quest.guideThree.section[13].questions[2].value);
            this.test3.controls.III72.setValue(quest.guideThree.section[13].questions[3].value);
          } catch (e) { }
        }
      } else {
        this.blockedText2Title = "#D4D4D4"
        this.blockedText2 = "#D4D4D4"
        this.optionalSeccion2 = false;
        this.bossForm.controls.option.setValue('No');
      }
    }
    quest.guideThree.section.forEach(section => {
      if (section.idSection == 1) {
        try {
          //seccion 1
          this.test3.controls.III1.setValue(quest.guideThree.section[0].questions[0].value);
          this.test3.controls.III2.setValue(quest.guideThree.section[0].questions[1].value);
          this.test3.controls.III3.setValue(quest.guideThree.section[0].questions[2].value);
          this.test3.controls.III4.setValue(quest.guideThree.section[0].questions[3].value);
          this.test3.controls.III5.setValue(quest.guideThree.section[0].questions[4].value);
        } catch (error) {
        }
      } else if (section.idSection == 2) {
        try {
          //seccion 2
          this.test3.controls.III6.setValue(quest.guideThree.section[1].questions[0].value);
          this.test3.controls.III7.setValue(quest.guideThree.section[1].questions[1].value);
          this.test3.controls.III8.setValue(quest.guideThree.section[1].questions[2].value);
        } catch (error) {
        }
      } else if (section.idSection == 3) {
        try {
          //seccion 3
          this.test3.controls.III9.setValue(quest.guideThree.section[2].questions[0].value);
          this.test3.controls.III10.setValue(quest.guideThree.section[2].questions[1].value);
          this.test3.controls.III11.setValue(quest.guideThree.section[2].questions[2].value);
          this.test3.controls.III12.setValue(quest.guideThree.section[2].questions[3].value);
        } catch (error) {
        }
      } else if (section.idSection == 4) {
        try {
          //seccion 4
          this.test3.controls.III13.setValue(quest.guideThree.section[3].questions[0].value);
          this.test3.controls.III14.setValue(quest.guideThree.section[3].questions[1].value);
          this.test3.controls.III15.setValue(quest.guideThree.section[3].questions[2].value);
          this.test3.controls.III16.setValue(quest.guideThree.section[3].questions[3].value);
        } catch (error) {
        }
      } else if (section.idSection == 5) {
        try {
          //seccion 5
          this.test3.controls.III17.setValue(quest.guideThree.section[4].questions[0].value);
          this.test3.controls.III18.setValue(quest.guideThree.section[4].questions[1].value);
          this.test3.controls.III19.setValue(quest.guideThree.section[4].questions[2].value);
          this.test3.controls.III20.setValue(quest.guideThree.section[4].questions[3].value);
          this.test3.controls.III21.setValue(quest.guideThree.section[4].questions[4].value);
          this.test3.controls.III22.setValue(quest.guideThree.section[4].questions[5].value);
        } catch (error) {
        }
      } else if (section.idSection == 6) {
        try {
          //seccion 6
          this.test3.controls.III23.setValue(quest.guideThree.section[5].questions[0].value);
          this.test3.controls.III24.setValue(quest.guideThree.section[5].questions[1].value);
          this.test3.controls.III25.setValue(quest.guideThree.section[5].questions[2].value);
          this.test3.controls.III26.setValue(quest.guideThree.section[5].questions[3].value);
          this.test3.controls.III27.setValue(quest.guideThree.section[5].questions[4].value);
          this.test3.controls.III28.setValue(quest.guideThree.section[5].questions[5].value);
        } catch (error) {
        }
      } else if (section.idSection == 7) {
        try {
          //seccion 7
          this.test3.controls.III29.setValue(quest.guideThree.section[6].questions[0].value);
          this.test3.controls.III30.setValue(quest.guideThree.section[6].questions[1].value);
        } catch (error) {
        }
      } else if (section.idSection == 8) {
        try {
          //seccion 8
          this.test3.controls.III31.setValue(quest.guideThree.section[7].questions[0].value);
          this.test3.controls.III32.setValue(quest.guideThree.section[7].questions[1].value);
          this.test3.controls.III33.setValue(quest.guideThree.section[7].questions[2].value);
          this.test3.controls.III34.setValue(quest.guideThree.section[7].questions[3].value);
          this.test3.controls.III35.setValue(quest.guideThree.section[7].questions[4].value);
          this.test3.controls.III36.setValue(quest.guideThree.section[7].questions[5].value);
        } catch (error) {
        }
      } else if (section.idSection == 9) {
        try {
          //seccion 9
          this.test3.controls.III37.setValue(quest.guideThree.section[8].questions[0].value);
          this.test3.controls.III38.setValue(quest.guideThree.section[8].questions[1].value);
          this.test3.controls.III39.setValue(quest.guideThree.section[8].questions[2].value);
          this.test3.controls.III40.setValue(quest.guideThree.section[8].questions[3].value);
          this.test3.controls.III41.setValue(quest.guideThree.section[8].questions[4].value);
        } catch (error) {
        }
      } else if (section.idSection == 10) {
        try {
          //seccion 10
          this.test3.controls.III42.setValue(quest.guideThree.section[9].questions[0].value);
          this.test3.controls.III43.setValue(quest.guideThree.section[9].questions[1].value);
          this.test3.controls.III44.setValue(quest.guideThree.section[9].questions[2].value);
          this.test3.controls.III45.setValue(quest.guideThree.section[9].questions[3].value);
          this.test3.controls.III46.setValue(quest.guideThree.section[9].questions[4].value);
        } catch (error) {
        }
      } else if (section.idSection == 11) {
        try {
          //seccion 11
          this.test3.controls.III47.setValue(quest.guideThree.section[10].questions[0].value);
          this.test3.controls.III48.setValue(quest.guideThree.section[10].questions[1].value);
          this.test3.controls.III49.setValue(quest.guideThree.section[10].questions[2].value);
          this.test3.controls.III50.setValue(quest.guideThree.section[10].questions[3].value);
          this.test3.controls.III51.setValue(quest.guideThree.section[10].questions[4].value);
          this.test3.controls.III52.setValue(quest.guideThree.section[10].questions[5].value);
          this.test3.controls.III53.setValue(quest.guideThree.section[10].questions[6].value);
          this.test3.controls.III54.setValue(quest.guideThree.section[10].questions[7].value);
          this.test3.controls.III55.setValue(quest.guideThree.section[10].questions[8].value);
          this.test3.controls.III56.setValue(quest.guideThree.section[10].questions[9].value);
        } catch (error) {
        }
      } else if (section.idSection == 12) {
        try {
          //seccion 12
          this.test3.controls.III57.setValue(quest.guideThree.section[11].questions[0].value);
          this.test3.controls.III58.setValue(quest.guideThree.section[11].questions[1].value);
          this.test3.controls.III59.setValue(quest.guideThree.section[11].questions[2].value);
          this.test3.controls.III60.setValue(quest.guideThree.section[11].questions[3].value);
          this.test3.controls.III61.setValue(quest.guideThree.section[11].questions[4].value);
          this.test3.controls.III62.setValue(quest.guideThree.section[11].questions[5].value);
          this.test3.controls.III63.setValue(quest.guideThree.section[11].questions[6].value);
          this.test3.controls.III64.setValue(quest.guideThree.section[11].questions[7].value);
        } catch (error) {
        }
      } else if (section.idSection == 13) {
        try {
          //seccion 13
          this.test3.controls.III65.setValue(quest.guideThree.section[12].questions[0].value);
          this.test3.controls.III66.setValue(quest.guideThree.section[12].questions[1].value);
          this.test3.controls.III67.setValue(quest.guideThree.section[12].questions[2].value);
          this.test3.controls.III68.setValue(quest.guideThree.section[12].questions[3].value);
        } catch (error) {
        }
      } else if (section.idSection == 14) {
        try {
          //seccion 14
          this.test3.controls.III69.setValue(quest.guideThree.section[13].questions[0].value);
          this.test3.controls.III70.setValue(quest.guideThree.section[13].questions[1].value);
          this.test3.controls.III71.setValue(quest.guideThree.section[13].questions[2].value);
          this.test3.controls.III72.setValue(quest.guideThree.section[13].questions[3].value);
        } catch (error) {
        }
      }
    });
  }

  /**
   * start radios that disable sections (customer service and boss).
   * @memberof GuideTwoComponent
   */
  initRadiosThatDisableSections() {
    this.serviceToCustomersOrUsersForm = this.fb.group({
      option: ['n/s', Validators.required],
    });
    this.bossForm = this.fb.group({
      option: ['n/s', Validators.required],
    });
  }

  initForm() {
    this.test3 = this.fb.group({
      III1: ['', Validators.required],
      III2: ['', Validators.required],
      III3: ['', Validators.required],
      III4: ['', Validators.required],
      III5: ['', Validators.required],
      III6: ['', Validators.required],
      III7: ['', Validators.required],
      III8: ['', Validators.required],
      III9: ['', Validators.required],
      III10: ['', Validators.required],
      III11: ['', Validators.required],
      III12: ['', Validators.required],
      III13: ['', Validators.required],
      III14: ['', Validators.required],
      III15: ['', Validators.required],
      III16: ['', Validators.required],
      III17: ['', Validators.required],
      III18: ['', Validators.required],
      III19: ['', Validators.required],
      III20: ['', Validators.required],
      III21: ['', Validators.required],
      III22: ['', Validators.required],
      III23: ['', Validators.required],
      III24: ['', Validators.required],
      III25: ['', Validators.required],
      III26: ['', Validators.required],
      III27: ['', Validators.required],
      III28: ['', Validators.required],
      III29: ['', Validators.required],
      III30: ['', Validators.required],
      III31: ['', Validators.required],
      III32: ['', Validators.required],
      III33: ['', Validators.required],
      III34: ['', Validators.required],
      III35: ['', Validators.required],
      III36: ['', Validators.required],
      III37: ['', Validators.required],
      III38: ['', Validators.required],
      III39: ['', Validators.required],
      III40: ['', Validators.required],
      III41: ['', Validators.required],
      III42: ['', Validators.required],
      III43: ['', Validators.required],
      III44: ['', Validators.required],
      III45: ['', Validators.required],
      III46: ['', Validators.required],
      III47: ['', Validators.required],
      III48: ['', Validators.required],
      III49: ['', Validators.required],
      III50: ['', Validators.required],
      III51: ['', Validators.required],
      III52: ['', Validators.required],
      III53: ['', Validators.required],
      III54: ['', Validators.required],
      III55: ['', Validators.required],
      III56: ['', Validators.required],
      III57: ['', Validators.required],
      III58: ['', Validators.required],
      III59: ['', Validators.required],
      III60: ['', Validators.required],
      III61: ['', Validators.required],
      III62: ['', Validators.required],
      III63: ['', Validators.required],
      III64: ['', Validators.required],
      III65: ['9', Validators.required],
      III66: ['9', Validators.required],
      III67: ['9', Validators.required],
      III68: ['9', Validators.required],
      III69: ['9', Validators.required],
      III70: ['9', Validators.required],
      III71: ['9', Validators.required],
      III72: ['9', Validators.required]

    });


    this.getRouterStyles();
  }

  /**
   * Valida ruta para agregar estilos de cuestionario con o sin sidebar
   */
  getRouterStyles(): void {
    this.withSidebar = this.router.url !== '/guide-three';
  }

  async servicioaClientes(value) {
    if (value) {
      this.blockedText1Title = "#003f9b";
      this.blockedText1 = "#212529";
      this.optionalSeccion1 = true;
      this.test3.controls.III65.setValue('');
      this.test3.controls.III66.setValue('');
      this.test3.controls.III67.setValue('');
      this.test3.controls.III68.setValue('');
    } else {
      this.blockedText1Title = "#D4D4D4";
      this.blockedText1 = "#D4D4D4";
      this.optionalSeccion1 = false;
      this.test3.controls.III65.setValue('9');
      this.test3.controls.III66.setValue('9');
      this.test3.controls.III67.setValue('9');
      this.test3.controls.III68.setValue('9');
      this.sendAnswer(13, 1, 'Nunca');
      this.sendAnswer(13, 2, 'Nunca');
      this.sendAnswer(13, 3, 'Nunca');
      this.sendAnswer(13, 4, 'Nunca');
    }
  }

  async soyJefe(value) {
    if (value) {
      this.blockedText2Title = "#003f9b";
      this.blockedText2 = "#212529";
      this.optionalSeccion2 = true;
      this.test3.controls.III69.setValue('');
      this.test3.controls.III70.setValue('');
      this.test3.controls.III71.setValue('');
      this.test3.controls.III72.setValue('');

    } else {
      this.blockedText2Title = "#D4D4D4";
      this.blockedText2 = "#D4D4D4";
      this.optionalSeccion2 = false;
      this.test3.controls.III69.setValue('9');
      this.test3.controls.III70.setValue('9');
      this.test3.controls.III71.setValue('9');
      this.test3.controls.III72.setValue('9');
      this.sendAnswer(14, 1, 'Nunca');
      this.sendAnswer(14, 2, 'Nunca');
      this.sendAnswer(14, 3, 'Nunca');
      this.sendAnswer(14, 4, 'Nunca');

    }
  }

  checkAnswers() {
    const test: {
      III1: string;
      III2: string;
      III3: string;
      III4: string;
      III5: string;
      III6: string;
      III7: string;
      III8: string;
      III9: string;
      III10: string;
      III11: string;
      III12: string;
      III13: string;
      III14: string;
      III15: string;
      III16: string;
      III17: string;
      III18: string;
      III19: string;
      III20: string;
      III21: string;
      III22: string;
      III23: string;
      III24: string;
      III25: string;
      III26: string;
      III27: string;
      III28: string;
      III29: string;
      III30: string;
      III31: string;
      III32: string;
      III33: string;
      III34: string;
      III35: string;
      III36: string;
      III37: string;
      III38: string;
      III39: string;
      III40: string;
      III41: string;
      III42: string;
      III43: string;
      III44: string;
      III45: string;
      III46: string;
      III47: string;
      III48: string;
      III49: string;
      III50: string;
      III51: string;
      III52: string;
      III53: string;
      III54: string;
      III55: string;
      III56: string;
      III57: string;
      III58: string;
      III59: string;
      III60: string;
      III61: string;
      III62: string;
      III63: string;
      III64: string;
      III65: string;
      III66: string;
      III67: string;
      III68: string;
      III69: string;
      III70: string;
      III71: string;
      III72: string;
    } = this.test3.value;
    this.preSubmit = false;

  }

  upgradeProgressBar() {

    this.pb = this.progressbarCountProgress * 5;
    this.shipP = this.shipP + 25;
    if (this.pb == 100) {
      this.endColorLabel = "#99C4FF";
    }
  }

  sleep(ms) {
    return new Promise<void>(prom => {
      setTimeout(() => {
        prom();
      }, ms);
    });
  }

  sendAnswer(section: number, question: number, value: string) {
    let answer = {
      "section": Number(section),
      "question": Number(question),
      "value": value
    }
    if (this.answerQueue.length == 0) {
      this.answerQueue.push(answer);
    } else {
      if (this.answerQueue[this.answerQueue.length - 1].section == answer.section && this.answerQueue[this.answerQueue.length - 1].question == answer.question) {
        this.answerQueue.pop()
        this.answerQueue.push(answer)
      } else {
        this.answerQueue.push(answer)
      }
    }
  }

  sendAnswerDisable(name: string, value: string) {
    let answer = {
      "idCompany": this.idCompany,
      "idProject": this.idProject,
      "idEmployee": this.idEmployee,
      "guide": "guideThree",
      "name": name,
      "value": value
    };
    this.questionnares.sendAnswerDisable(answer).subscribe(res => {
      if (res.clave != "OK") {
        throw new Error("Error al mandar respuesta");

      }
    });
  }

  answerTrigger(value, question) {
    var splitted = question.split(".");
    this.sendAnswer(splitted[0], splitted[1], value);
  }

  nextQuest() {
    let answers = {
      "idCompany": this.idCompany,
      "idProject": this.idProject,
      "idEmployee": this.idEmployee,
      "guide": "guideThree",
      "answers": this.answerQueue
    }
    Swal.fire({
      title: 'Guía III',
      text: 'Terminando envio de respuestas',
      icon: 'info',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.questionnares.sendAnswer(answers).subscribe(async res => {
      if (res.clave != "OK") {
        throw new Error("Error al mandar respuestas");
      } else {
        this.checkSend()
      }
    });
  }
  checkSend() {
    this.questionnares.evalGuide({
      idProject: this.idProject, 
      idEmployee: this.idEmployee
    }).subscribe(res => {
      if(res && res.clave==="OK"){

        this.questionnares.gratitudeEmail({
            idProject: this.idProject,
            idEmployee: this.idEmployee 
          }).subscribe(email => {});

        Swal.close();
        this.router.navigate(['/guides-summary'], {
          queryParams: {
            idEmployee: this.idEmployee,
            idCompany: this.idCompany, idProject: this.idProject, new: true, img: 3
          }
        });
      }
    })
  }

  async saveProgress() {
    if (this.answerQueue.length > 0) {
      let answers = {
        "idCompany": this.idCompany,
        "idProject": this.idProject,
        "idEmployee": this.idEmployee,
        "guide": "guideThree",
        "answers": this.answerQueue
      }
      Swal.fire({
        title: 'Guía III',
        text: 'Guardando respuestas',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.questionnares.sendAnswer(answers).subscribe(async res => {
        if (res.clave != "OK") {
          throw new Error("Error al mandar respuestas");
        } else {
          Swal.close();
        }
      });
    } else {
      Swal.fire({
        title: 'Guía III',
        text: 'Sin progreso que salvar',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      await this.sleep(1000);
      Swal.close();
    }
  }

  getAnswer() {
    this.employee.projectSelected.subscribe((res) => {
      if (res) {
        let quest = res;
        if (Object.keys(quest).length != 0) {
          // this.guide.push(quest);
          if (quest.finalized == true) {
            try {
              //Seccion 1
              this.test3.controls.III1.setValue(quest.guideThree.section[0].questions[0].value);
              this.test3.controls.III2.setValue(quest.guideThree.section[0].questions[1].value);
              this.test3.controls.III3.setValue(quest.guideThree.section[0].questions[2].value);
              this.test3.controls.III4.setValue(quest.guideThree.section[0].questions[3].value);
              this.test3.controls.III5.setValue(quest.guideThree.section[0].questions[4].value);

              this.test3.controls.III6.setValue(quest.guideThree.section[1].questions[0].value);
              this.test3.controls.III7.setValue(quest.guideThree.section[1].questions[1].value);
              this.test3.controls.III8.setValue(quest.guideThree.section[1].questions[2].value);

              this.test3.controls.III9.setValue(quest.guideThree.section[2].questions[0].value);
              this.test3.controls.III10.setValue(quest.guideThree.section[2].questions[1].value);
              this.test3.controls.III11.setValue(quest.guideThree.section[2].questions[2].value);
              this.test3.controls.III12.setValue(quest.guideThree.section[2].questions[3].value);

              this.test3.controls.III13.setValue(quest.guideThree.section[3].questions[0].value);
              this.test3.controls.III14.setValue(quest.guideThree.section[3].questions[1].value);
              this.test3.controls.III15.setValue(quest.guideThree.section[3].questions[2].value);
              this.test3.controls.III16.setValue(quest.guideThree.section[3].questions[3].value);

              this.test3.controls.III17.setValue(quest.guideThree.section[4].questions[0].value);
              this.test3.controls.III18.setValue(quest.guideThree.section[4].questions[1].value);
              this.test3.controls.III19.setValue(quest.guideThree.section[4].questions[2].value);
              this.test3.controls.III20.setValue(quest.guideThree.section[4].questions[3].value);
              this.test3.controls.III21.setValue(quest.guideThree.section[4].questions[4].value);
              this.test3.controls.III22.setValue(quest.guideThree.section[4].questions[5].value);

              this.test3.controls.III23.setValue(quest.guideThree.section[5].questions[0].value);
              this.test3.controls.III24.setValue(quest.guideThree.section[5].questions[1].value);
              this.test3.controls.III25.setValue(quest.guideThree.section[5].questions[2].value);
              this.test3.controls.III26.setValue(quest.guideThree.section[5].questions[3].value);
              this.test3.controls.III27.setValue(quest.guideThree.section[5].questions[4].value);
              this.test3.controls.III28.setValue(quest.guideThree.section[5].questions[5].value);

              this.test3.controls.III29.setValue(quest.guideThree.section[6].questions[0].value);
              this.test3.controls.III30.setValue(quest.guideThree.section[6].questions[1].value);

              this.test3.controls.III31.setValue(quest.guideThree.section[7].questions[0].value);
              this.test3.controls.III32.setValue(quest.guideThree.section[7].questions[1].value);
              this.test3.controls.III33.setValue(quest.guideThree.section[7].questions[2].value);
              this.test3.controls.III34.setValue(quest.guideThree.section[7].questions[3].value);
              this.test3.controls.III35.setValue(quest.guideThree.section[7].questions[4].value);
              this.test3.controls.III36.setValue(quest.guideThree.section[7].questions[5].value);

              this.test3.controls.III37.setValue(quest.guideThree.section[8].questions[0].value);

              this.test3.controls.III38.setValue(quest.guideThree.section[8].questions[1].value);
              this.test3.controls.III39.setValue(quest.guideThree.section[8].questions[2].value);
              this.test3.controls.III40.setValue(quest.guideThree.section[8].questions[3].value);
              this.test3.controls.III41.setValue(quest.guideThree.section[8].questions[4].value);

              this.test3.controls.III42.setValue(quest.guideThree.section[9].questions[0].value);
              this.test3.controls.III43.setValue(quest.guideThree.section[9].questions[1].value);
              this.test3.controls.III44.setValue(quest.guideThree.section[9].questions[2].value);
              this.test3.controls.III45.setValue(quest.guideThree.section[9].questions[3].value);
              this.test3.controls.III46.setValue(quest.guideThree.section[9].questions[4].value);

              this.test3.controls.III47.setValue(quest.guideThree.section[10].questions[0].value);
              this.test3.controls.III48.setValue(quest.guideThree.section[10].questions[1].value);
              this.test3.controls.III49.setValue(quest.guideThree.section[10].questions[2].value);
              this.test3.controls.III50.setValue(quest.guideThree.section[10].questions[3].value);
              this.test3.controls.III51.setValue(quest.guideThree.section[10].questions[4].value);
              this.test3.controls.III52.setValue(quest.guideThree.section[10].questions[5].value);
              this.test3.controls.III53.setValue(quest.guideThree.section[10].questions[6].value);
              this.test3.controls.III54.setValue(quest.guideThree.section[10].questions[7].value);
              this.test3.controls.III55.setValue(quest.guideThree.section[10].questions[8].value);
              this.test3.controls.III56.setValue(quest.guideThree.section[10].questions[9].value);

              this.test3.controls.III57.setValue(quest.guideThree.section[11].questions[0].value);
              this.test3.controls.III58.setValue(quest.guideThree.section[11].questions[1].value);
              this.test3.controls.III59.setValue(quest.guideThree.section[11].questions[2].value);
              this.test3.controls.III60.setValue(quest.guideThree.section[11].questions[3].value);
              this.test3.controls.III61.setValue(quest.guideThree.section[11].questions[4].value);
              this.test3.controls.III62.setValue(quest.guideThree.section[11].questions[5].value);
              this.test3.controls.III63.setValue(quest.guideThree.section[11].questions[6].value);
              this.test3.controls.III64.setValue(quest.guideThree.section[11].questions[7].value);
              if (quest.guideThree.disable[0].value == 'si') {
                this.blockedText1Title = "#003f9b";
                this.blockedText1 = "#212529";
                this.optionalSeccion1 = true;
                this.serviceToCustomersOrUsersForm.controls.option.setValue('Si');
                if (quest.guideThree.section[12].idSection == 13) {
                  this.test3.controls.III65.setValue(quest.guideThree.section[12].questions[0].value);
                  this.test3.controls.III66.setValue(quest.guideThree.section[12].questions[1].value);
                  this.test3.controls.III67.setValue(quest.guideThree.section[12].questions[2].value);
                  this.test3.controls.III68.setValue(quest.guideThree.section[12].questions[3].value);
                } else if (quest.guideThree.section[12].idSection == 14) {
                  this.test3.controls.III65.setValue(quest.guideThree.section[13].questions[0].value);
                  this.test3.controls.III66.setValue(quest.guideThree.section[13].questions[1].value);
                  this.test3.controls.III67.setValue(quest.guideThree.section[13].questions[2].value);
                  this.test3.controls.III68.setValue(quest.guideThree.section[13].questions[3].value);
                }
                this.test3.controls.III65.setValue(quest.guideThree.section[12].questions[0].value);
                this.test3.controls.III66.setValue(quest.guideThree.section[12].questions[1].value);
                this.test3.controls.III67.setValue(quest.guideThree.section[12].questions[2].value);
                this.test3.controls.III68.setValue(quest.guideThree.section[12].questions[3].value);
              } else {
                this.blockedText1Title = "#D4D4D4"
                this.blockedText1 = "#D4D4D4";
                this.optionalSeccion1 = false;
                this.serviceToCustomersOrUsersForm.controls.option.setValue('No')
              }

              if (quest.guideThree.disable[1].value == 'si') {
                this.bossForm.controls.option.setValue('Si');
                this.blockedText2Title = "#003f9b";
                this.blockedText2 = "#212529"
                this.optionalSeccion2 = true;
                if (quest.guideThree.section[12].idSection == 14) {
                  this.test3.controls.III69.setValue(quest.guideThree.section[12].questions[0].value);
                  this.test3.controls.III70.setValue(quest.guideThree.section[12].questions[1].value);
                  this.test3.controls.III71.setValue(quest.guideThree.section[12].questions[2].value);
                  this.test3.controls.III72.setValue(quest.guideThree.section[12].questions[3].value);
                }
                this.test3.controls.III69.setValue(quest.guideThree.section[13].questions[0].value);
                this.test3.controls.III70.setValue(quest.guideThree.section[13].questions[1].value);
                this.test3.controls.III71.setValue(quest.guideThree.section[13].questions[2].value);
                this.test3.controls.III72.setValue(quest.guideThree.section[13].questions[3].value);
              } else {
                this.blockedText2Title = "#D4D4D4"
                this.blockedText2 = "#D4D4D4"
                this.optionalSeccion2 = false;
                this.bossForm.controls.option.setValue('No');
              }


              this.test3.disable()
            } catch (error) {
            }
          } else {
            this.initForm();
          }
        }
      }
    });
  }
  nextSection() {
    this.changeSection++;
    window.scrollTo(0, 0);
  }

  goBack() {
    this.changeSection--;
    window.scrollTo(0, 0);
  }
}
