import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress[projection][realProgress]',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
  @Input() projection!: number;
  @Input() realProgress!: number;
}
