import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import Swal from 'sweetalert2';
import { ResponseContent } from '../../models';
import { map } from 'rxjs/operators';
import { Swal2Loader } from '@consts/swal2-loader';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OportunityService {
  activeProject = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpService
  ) { }


  /**
 * funcion para obtener Oportunities
 *
 * @returns
 * @memberof OportunityService
 */
  getOportunities() {

    Swal.fire(Swal2Loader)

    return this.http.post('/oportunity/get', {}, true).pipe(map((res: ResponseContent) => {
      if (res) {
        Swal.close();
        return res.content;
      }
    }));
  }

    /**
 * funcion para obtener Oportunities del empleado
 *
 * @returns
 * @memberof OportunityService
 */
    getOportunitiesByEmployee(id: string) {

      Swal.fire(Swal2Loader)
  
      return this.http.post('/oportunity/getByEmployee', {id}, true).pipe(map((res: ResponseContent) => {
        if (res && res.clave === 'OK') {
          Swal.close();
          return res.content;
        }
      }));
    }

       /**
 * funcion para obtener Oportunities del empleado
 *
 * @returns
 * @memberof OportunityService
 */
       getOportunityById(id: string) {

        Swal.fire(Swal2Loader)
    
        return this.http.post('/oportunity/getOportunity', {id}, true).pipe(map((res: ResponseContent) => {
          if (res && res.clave === 'OK') {
            Swal.close();
            return res.content;
          }
        }));
      }

    getOportunitiesByCategory() {

      Swal.fire(Swal2Loader)
  
      return this.http.post('/oportunity/getbyCategory', {}, true).pipe(map((res: ResponseContent) => {
        if (res && res.clave === 'OK') {
          Swal.close();
          return res.content;
        }
      }));
    }


  /**
* funcion para crear Oportunity
*
* @param {*} email
* @returns
* @memberof OportunityService
*/
  createOportunity(oportunity) {

    Swal.fire(Swal2Loader)

    return this.http.post('/oportunity/create', oportunity, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
       Swal.close();
        return res;
      }
    }));
  }


  /**
* funcion para actualizar Oportunity
*
* @param {*} email
* @returns
* @memberof OportunityService
*/
  updateOportunity(oportunity) {

    Swal.fire(Swal2Loader)

    return this.http.post('/oportunity/update', oportunity, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
       Swal.close();
        return res;
      }
    }));
  }

  
  /**
* funcion para eliminar Oportunity
*
* @param {*} email
* @returns
* @memberof OportunityService
*/
deleteOportunity(id) {

  Swal.fire(Swal2Loader)

  return this.http.post('/oportunity/delete', id, true).pipe(map((res: ResponseContent) => {
    if (res && res.clave === 'OK') {
     Swal.close();
      return res;
    }
  }));
}


}
