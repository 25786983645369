import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from 'src/app/services/employees/employees.service';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service'
import { IQuestionnairesEmployee, QuestionaryModel } from 'src/app/models/questionnaires/questionary.model'
import { questionsTest2Text } from '../../../constants/text/questionsTest2Text';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ModalService } from 'src/app/services';

@Component({
  selector: 'app-guide-two',
  templateUrl: './guide-two.component.html',
  styleUrls: ['./guide-two.component.scss']
})

export class GuideTwoComponent implements OnInit {
  //Almacena las guias
  guide = [];
  //Almacena las respuestas del usuario de la primera seccion
  sectionOne = []
  //Almacena las respuestas del usuario de la segunda seccion
  sectionTwo = []
  //Almacena las respuestas del usuario de la tercera seccion
  sectionThree = []
  //Almacena las respuestas del usuario de la cuarta seccion
  sectionFour = []
  //Almacena las respuestas del usuario de la quinta seccion
  sectionFive = []
  //Almacena las respuestas del usuario de la sexta seccion
  sectionSix = []
  //Almacena las respuestas del usuario de la septima seccion
  sectionSeven = []
  //Almacena las respuestas del usuario de la octava seccion
  sectionEight = []
  testAnswer;
  changeSection = 0;
  //DataSession
  private idEmployee: string;
  private idCompany: string;
  private idProject: string;
  private nameProject: string;

  //Questions
  questionsSectionOne = questionsTest2Text.SECTION_ONE;
  questionsSectionTwo = questionsTest2Text.SECTION_TWO;
  questionsSectionThree = questionsTest2Text.SECTION_THREE;
  questionsSectionFour = questionsTest2Text.SECTION_FOUR;
  questionsSectionFive = questionsTest2Text.SECTION_FIVE;
  questionsSectionSix = questionsTest2Text.SECTION_SIX;

  //Data
  II41: string = '9';
  II42: string = '9';
  II43: string = '9';
  II44: string = '9';
  II45: string = '9';
  II46: string = '9';

  //Status
  preSubmit: boolean = true;
  error: boolean = false;
  questionsB: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,];
  optionalSeccion1: boolean = false;
  optionalSeccion2: boolean = false;

  //Style
  progressbarCountProgress: number = 0;
  pb: number = 0;
  shipP: number = -65;
  endColorLabel: string = "#D4D4D4";
  blockedText1Title: string = "#D4D4D4";
  blockedText1: string = "#D4D4D4";
  blockedText2Title: string = "#D4D4D4";
  blockedText2: string = "#D4D4D4";
  displayInfo: string = 'none';
  withSidebar = false;

  //Models
  infoEmployee: any;
  nameCompany: string;
  answer: QuestionaryModel;

  //Misc
  test2: FormGroup;
  params;
  currentRoute: string;
  visible = false;
  visibletwo = false;
  serviceToCustomersOrUsersForm: FormGroup
  bossForm: any

  answerQueue: QuestionaryModel[] = [];
  dispatching = false;

  mostrar = 'container d-none d-lg-block';
  mostrard = 'container d-none d-block d-lg-none d-xl-none'
  constructor(private route: ActivatedRoute,
    private employee: EmployeesService,
    private questionnares: QuestionnairesService,
    public router: Router,
    private fb: FormBuilder,
    private modal: ModalService
  ) { }

  ngOnInit(): void {
    this.idEmployee = this.route.snapshot.queryParamMap.get('idEmployee');
    this.idCompany = this.route.snapshot.queryParamMap.get('idCompany');
    this.idProject = this.route.snapshot.queryParamMap.get('idProject');
    if (this.idEmployee) {
      this.questionnares.getEmployee(this.idEmployee).subscribe(res => {
        Swal.fire({
          title: 'Guía II',
          text: 'Obteniendo guía',
          icon: 'info',
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false
        });
        Swal.showLoading();
        if (res) {
          this.infoEmployee = res.content;
          if (this.infoEmployee.idCompany == this.idCompany) {
            this.questionnares.getCompanyName(this.infoEmployee.idCompany).subscribe((response) => {
              if (response) {
                this.nameCompany = response.content.companyName
                if (this.idProject) {
                  this.questionnares.getProjectName(this.idProject).subscribe(res => {
                    this.nameProject = res.content;
                    this.questionnares.getEmployeeQuests(this.idCompany, this.idEmployee).subscribe(response => {
                      Swal.close();
                      response.content.forEach(element => {
                        if (element.nameProject == this.nameProject) {
                          if (element.questionnaire.guideTwo.finalized == true) {
                            this.router.navigate(['/guides-summary'], {
                              queryParams: {
                                idEmployee: this.idEmployee,
                                idCompany: this.idCompany, idProject: this.idProject, new: false
                              }
                            });
                          }
                          let quest = element.questionnaire;
                          if (element.questionnaire.guideTwo.section.length > 0) {
                            this.changeSection = (element.questionnaire.guideTwo.section.length - 1);
                          } else {
                            this.changeSection = 0;
                          }
                          if (quest) {
                            this.loadProgress(quest);
                          }
                          // * The date is validated only if it is not a forwarded link
                          if(!element.questionnaire.chanceOutsideLimitDate){
                            if(element.expired){
                              this.modal.warning({
                                title: "Cuestionario expirado.",
                                message: "Ya no está disponible este cuestionario.",
                              });
                            }
                          }
                        }
                      });
                    })
                  });
                }
              }
            });
          } else {
            throw new Error("Empleado no perteneciente a esta compañia");
          }
        }
      });
    }
    window.scroll({ top: 0 });
    this.initForm();
    this.initRadiosThatDisableSections();

    if (this.router.url.includes('/employees/file/profile/nom-35')) {
      this.visible = false;
    } else if (this.router.url === '/employees/file/profile/nom-35/personal') {
      this.visible = false;
    } else {
      this.visible = true;
    }
    this.preSubmit = true;
    this.currentRoute = this.router.url;
    this.getAnswer();
    if (this.router.url.includes('/two')) {

      this.serviceToCustomersOrUsersForm.disable()
      this.bossForm.disable()
      this.test2.disable();
    }
  }
  /**
   * start radios that disable sections (customer service and boss).
   * @memberof GuideTwoComponent
   */
  initRadiosThatDisableSections() {
    this.serviceToCustomersOrUsersForm = this.fb.group({
      option: ['n/s', Validators.required],
    });
    this.bossForm = this.fb.group({
      option: ['n/s', Validators.required],
    });
  }
  
  loadProgress(quest: any) {
     if (quest.guideTwo.disable.length > 1){
      // * Position zero is always customer service
      if (quest.guideTwo.disable[0].value == 'si') {
        this.blockedText1Title = "#003f9b";
        this.blockedText1 = "#212529"
        this.optionalSeccion1 = true
        this.serviceToCustomersOrUsersForm.controls.option.setValue('Si')
        if (quest.guideTwo.section[6]) {
          try {
            if (quest.guideTwo.section[6].idSection == 7) {
              this.test2.controls.II41.setValue(quest.guideTwo.section[6].questions[0].value);
              this.test2.controls.II42.setValue(quest.guideTwo.section[6].questions[1].value);
              this.test2.controls.II43.setValue(quest.guideTwo.section[6].questions[2].value);
            } else if (quest.guideTwo.section[6].idSection == 8) {
              this.test2.controls.II41.setValue(quest.guideTwo.section[7].questions[0].value);
              this.test2.controls.II42.setValue(quest.guideTwo.section[7].questions[1].value);
              this.test2.controls.II43.setValue(quest.guideTwo.section[7].questions[2].value);
            } else {
              this.test2.controls.II41.setValue(quest.guideTwo.section[6].questions[0].value);
              this.test2.controls.II42.setValue(quest.guideTwo.section[6].questions[1].value);
              this.test2.controls.II43.setValue(quest.guideTwo.section[6].questions[2].value);
            }
          } catch (e) { }
        }

      } else {
        this.blockedText1Title = "#D4D4D4"
        this.blockedText1 = "#D4D4D4";
        this.optionalSeccion1 = false;
        this.serviceToCustomersOrUsersForm.controls.option.setValue('No')

        this.optionalSeccion1 = false
      }
      if (quest.guideTwo.disable[1].value == 'si') {
        //Seccion 8
        this.bossForm.controls.option.setValue('Si');
        this.blockedText2Title = "#003f9b";
        this.blockedText2 = "#212529"
        this.optionalSeccion2 = true;
        if (quest.guideTwo.section[6].idSection == 8) {
          try {
            this.test2.controls.II44.setValue(quest.guideTwo.section[6].questions[0].value);
            this.test2.controls.II45.setValue(quest.guideTwo.section[6].questions[1].value);
            this.test2.controls.II46.setValue(quest.guideTwo.section[6].questions[2].value);
          } catch (e) { }
        } else if (quest.guideTwo.section[7]) {
          try {
            this.test2.controls.II44.setValue(quest.guideTwo.section[7].questions[0].value);
            this.test2.controls.II45.setValue(quest.guideTwo.section[7].questions[1].value);
            this.test2.controls.II46.setValue(quest.guideTwo.section[7].questions[2].value);
          } catch (e) { }
        }

      } else {
        this.blockedText2Title = "#D4D4D4"
        this.blockedText2 = "#D4D4D4"
        this.optionalSeccion2 = false;
        this.bossForm.controls.option.setValue('No');


      }
    }
    quest.guideTwo.section.forEach(section => {
      if (section.idSection == 1) {
        try {
          //seccion 1
          this.test2.controls.II1.setValue(quest.guideTwo.section[0].questions[0].value);
          this.test2.controls.II2.setValue(quest.guideTwo.section[0].questions[1].value);
          this.test2.controls.II3.setValue(quest.guideTwo.section[0].questions[2].value);
          this.test2.controls.II4.setValue(quest.guideTwo.section[0].questions[3].value);
          this.test2.controls.II5.setValue(quest.guideTwo.section[0].questions[4].value);
          this.test2.controls.II6.setValue(quest.guideTwo.section[0].questions[5].value);
          this.test2.controls.II7.setValue(quest.guideTwo.section[0].questions[6].value);
          this.test2.controls.II8.setValue(quest.guideTwo.section[0].questions[7].value);
          this.test2.controls.II9.setValue(quest.guideTwo.section[0].questions[8].value);
        } catch (error) {
        }
      } else if (section.idSection == 2) {
        try {
          //seccion 2
          this.test2.controls.II10.setValue(quest.guideTwo.section[1].questions[0].value);
          this.test2.controls.II11.setValue(quest.guideTwo.section[1].questions[1].value);
          this.test2.controls.II12.setValue(quest.guideTwo.section[1].questions[2].value);
          this.test2.controls.II13.setValue(quest.guideTwo.section[1].questions[3].value);

        } catch (error) {
        }
      } else if (section.idSection == 3) {
        try {
          //seccion 3
          this.test2.controls.II14.setValue(quest.guideTwo.section[2].questions[0].value);
          this.test2.controls.II15.setValue(quest.guideTwo.section[2].questions[1].value);
          this.test2.controls.II16.setValue(quest.guideTwo.section[2].questions[2].value);
          this.test2.controls.II17.setValue(quest.guideTwo.section[2].questions[3].value);
        } catch (error) {
        }
      } else if (section.idSection == 4) {
        try {
          //Seccio n 4
          this.test2.controls.II18.setValue(quest.guideTwo.section[3].questions[0].value);
          this.test2.controls.II19.setValue(quest.guideTwo.section[3].questions[1].value);
          this.test2.controls.II20.setValue(quest.guideTwo.section[3].questions[2].value);
          this.test2.controls.II21.setValue(quest.guideTwo.section[3].questions[3].value);
          this.test2.controls.II22.setValue(quest.guideTwo.section[3].questions[4].value);
        } catch (error) {
        }
      } else if (section.idSection == 5) {
        try {
          //seccion 5
          this.test2.controls.II23.setValue(quest.guideTwo.section[4].questions[0].value);
          this.test2.controls.II24.setValue(quest.guideTwo.section[4].questions[1].value);
          this.test2.controls.II25.setValue(quest.guideTwo.section[4].questions[2].value);
          this.test2.controls.II26.setValue(quest.guideTwo.section[4].questions[3].value);
          this.test2.controls.II27.setValue(quest.guideTwo.section[4].questions[4].value);
        } catch (error) {
        }
      } else if (section.idSection == 6) {
        try {
          //seccion 6
          this.test2.controls.II28.setValue(quest.guideTwo.section[5].questions[0].value);
          this.test2.controls.II29.setValue(quest.guideTwo.section[5].questions[1].value);
          this.test2.controls.II30.setValue(quest.guideTwo.section[5].questions[2].value);
          this.test2.controls.II31.setValue(quest.guideTwo.section[5].questions[3].value);
          this.test2.controls.II32.setValue(quest.guideTwo.section[5].questions[4].value);
          this.test2.controls.II33.setValue(quest.guideTwo.section[5].questions[5].value);
          this.test2.controls.II34.setValue(quest.guideTwo.section[5].questions[6].value);
          this.test2.controls.II35.setValue(quest.guideTwo.section[5].questions[7].value);
          this.test2.controls.II36.setValue(quest.guideTwo.section[5].questions[8].value);
          this.test2.controls.II37.setValue(quest.guideTwo.section[5].questions[9].value);
          this.test2.controls.II38.setValue(quest.guideTwo.section[5].questions[10].value);
          this.test2.controls.II39.setValue(quest.guideTwo.section[5].questions[11].value);
          this.test2.controls.II40.setValue(quest.guideTwo.section[5].questions[12].value);
        } catch (error) {
        }
      } else if (section.idSection == 7) {
        try {
          //seccion 7
          this.test2.controls.II41.setValue(quest.guideTwo.section[6].questions[0].value);
          this.test2.controls.II42.setValue(quest.guideTwo.section[6].questions[1].value);
          this.test2.controls.II43.setValue(quest.guideTwo.section[6].questions[2].value);
        } catch (error) {
        }
      } else if (section.idSection == 8) {
        try {
          //Seccion 8
          this.test2.controls.II44.setValue(quest.guideTwo.section[7].questions[0].value);
          this.test2.controls.II45.setValue(quest.guideTwo.section[7].questions[1].value);
          this.test2.controls.II46.setValue(quest.guideTwo.section[7].questions[2].value);
        } catch (error) {
        }
      }
    })
  }

  initForm() {
    this.test2 = this.fb.group({
      II1: ['', Validators.required],
      II2: ['', Validators.required],
      II3: ['', Validators.required],
      II4: ['', Validators.required],
      II5: ['', Validators.required],
      II6: ['', Validators.required],
      II7: ['', Validators.required],
      II8: ['', Validators.required],
      II9: ['', Validators.required],
      II10: ['', Validators.required],
      II11: ['', Validators.required],
      II12: ['', Validators.required],
      II13: ['', Validators.required],
      II14: ['', Validators.required],
      II15: ['', Validators.required],
      II16: ['', Validators.required],
      II17: ['', Validators.required],
      II18: ['', Validators.required],
      II19: ['', Validators.required],
      II20: ['', Validators.required],
      II21: ['', Validators.required],
      II22: ['', Validators.required],
      II23: ['', Validators.required],
      II24: ['', Validators.required],
      II25: ['', Validators.required],
      II26: ['', Validators.required],
      II27: ['', Validators.required],
      II28: ['', Validators.required],
      II29: ['', Validators.required],
      II30: ['', Validators.required],
      II31: ['', Validators.required],
      II32: ['', Validators.required],
      II33: ['', Validators.required],
      II34: ['', Validators.required],
      II35: ['', Validators.required],
      II36: ['', Validators.required],
      II37: ['', Validators.required],
      II38: ['', Validators.required],
      II39: ['', Validators.required],
      II40: ['', Validators.required],
      II41: ['9', Validators.required],
      II42: ['9', Validators.required],
      II43: ['9', Validators.required],
      II44: ['9', Validators.required],
      II45: ['9', Validators.required],
      II46: ['9', Validators.required]
    });
    this.getRouterStyles();
  }

  /**
   * Valida ruta para agregar estilos de cuestionario con o sin sidebar
   */
  getRouterStyles(): void {
    this.withSidebar = this.router.url !== '/guide-two';
  }

  async servicioaClientes(value) {
    if (value) {
      this.blockedText1Title = "#003f9b";
      this.blockedText1 = "#212529"
      this.optionalSeccion1 = true;
      this.test2.controls.II41.setValue('');
      this.test2.controls.II42.setValue('');
      this.test2.controls.II43.setValue('');
    } else {
      this.blockedText1Title = "#D4D4D4"
      this.blockedText1 = "#D4D4D4";
      this.optionalSeccion1 = false;
      this.test2.controls.II41.setValue('9');
      this.test2.controls.II42.setValue('9');
      this.test2.controls.II43.setValue('9');
      this.sendAnswer(7, 1, 'Nunca');
      this.sendAnswer(7, 2, 'Nunca');
      this.sendAnswer(7, 3, 'Nunca');
    }
  }

  async soyJefe(value) {
    if (value) {
      this.blockedText2Title = "#003f9b";
      this.blockedText2 = "#212529"
      this.optionalSeccion2 = true;
      this.test2.controls.II44.setValue('');
      this.test2.controls.II45.setValue('');
      this.test2.controls.II46.setValue('');
    } else {
      this.blockedText2Title = "#D4D4D4"
      this.blockedText2 = "#D4D4D4"
      this.optionalSeccion2 = false;
      this.test2.controls.II44.setValue('9');
      this.test2.controls.II45.setValue('9');
      this.test2.controls.II46.setValue('9');
      this.sendAnswer(8, 1, 'Nunca');
      this.sendAnswer(8, 2, 'Nunca');
      this.sendAnswer(8, 3, 'Nunca');
    }
  }

  checkAnswers() {
    const test: {
      II1: string;
      II2: string;
      II3: string;
      II4: string;
      II5: string;
      II6: string;
      II7: string;
      II8: string;
      II9: string;
      II10: string;
      II11: string;
      II12: string;
      II13: string;
      II14: string;
      II15: string;
      II16: string;
      II17: string;
      II18: string;
      II19: string;
      II20: string;
      II21: string;
      II22: string;
      II23: string;
      II24: string;
      II25: string;
      II26: string;
      II27: string;
      II28: string;
      II29: string;
      II30: string;
      II31: string;
      II32: string;
      II33: string;
      II34: string;
      II35: string;
      II36: string;
      II37: string;
      II38: string;
      II39: string;
      II40: string;
      II41: string;
      II42: string;
      II43: string;
      II44: string;
      II45: string;
      II46: string;
    } = this.test2.value;
    this.preSubmit = false;
  }

  upgradeProgressBar() {

    this.pb = this.progressbarCountProgress * 5;
    this.shipP = this.shipP + 25;
    if (this.pb == 100) {
      this.endColorLabel = "#99C4FF";
    }
  }

  sleep(ms) {
    return new Promise(prom => {
      setTimeout(() => {
        prom;
      }, ms);
    });
  }

  sendAnswer(section: number, question: number, value: string) {
    let answer = {
      "section": Number(section),
      "question": Number(question),
      "value": value
    }


    if (this.answerQueue.length == 0) {
      this.answerQueue.push(answer);
    } else {
      if (this.answerQueue[this.answerQueue.length - 1].section == answer.section && this.answerQueue[this.answerQueue.length - 1].question == answer.question) {
        this.answerQueue.pop()
        this.answerQueue.push(answer)
      } else {
        this.answerQueue.push(answer)
      }
    }

  }

  sendAnswerDisable(name: string, value: string) {
    let answer = {
      "idCompany": this.idCompany,
      "idProject": this.idProject,
      "idEmployee": this.idEmployee,
      "guide": "guideTwo",
      "name": name,
      "value": value
    };
    this.questionnares.sendAnswerDisable(answer).subscribe(res => {
      if (res.clave != "OK") {
        throw new Error("Error al mandar respuesta");

      }
    });
  }


  answerTrigger(value, question) {
    var splitted = question.split(".");
    this.sendAnswer(splitted[0], splitted[1], value);
  }

  nextQuest() {
    let answers = {
      "idCompany": this.idCompany,
      "idProject": this.idProject,
      "idEmployee": this.idEmployee,
      "guide": "guideTwo",
      "answers": this.answerQueue
    }
    Swal.fire({
      title: 'Guía II',
      text: 'Terminando envio de respuestas',
      icon: 'info',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false
    });
    Swal.showLoading();


    this.questionnares.sendAnswer(answers).subscribe(async res => {
      if (res.clave != "OK") {
        throw new Error("Error al mandar respuestas");
      } else {
        this.checkSend()
      }
    });
  }

  checkSend() {
    this.questionnares.evalGuide({
      idProject: this.idProject, 
      idEmployee: this.idEmployee
    }).subscribe(res => {
      if(res && res.clave==="OK"){

        this.questionnares.gratitudeEmail({
            idProject: this.idProject,
            idEmployee: this.idEmployee 
          }).subscribe(email => {});

        Swal.close();
        this.router.navigate(['/guides-summary'], {
          queryParams: {
            idEmployee: this.idEmployee,
            idCompany: this.idCompany, idProject: this.idProject, new: true, img: 2
          }
        });
      }
    });
  }

  async saveProgress() {
    if (this.answerQueue.length > 0) {
      let answers = {
        "idCompany": this.idCompany,
        "idProject": this.idProject,
        "idEmployee": this.idEmployee,
        "guide": "guideTwo",
        "answers": this.answerQueue
      }
      Swal.fire({
        title: 'Guía II',
        text: 'Guardando respuestas',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.questionnares.sendAnswer(answers).subscribe(async res => {
        if (res.clave != "OK") {
          throw new Error("Error al mandar respuestas");
        } else {
          Swal.close();
        }
      });
    } else {
      Swal.fire({
        title: 'Guía II',
        text: 'Sin progreso que salvar',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      await this.sleep(1000);
      Swal.close();
    }
  }

  getAnswer() {
    this.employee.projectSelected.subscribe((res) => {
      if (res) {
        let quest: IQuestionnairesEmployee = res
        if (Object.keys(quest).length != 0) {
          if (quest.finalized == true) {
            try {
              //seccion 1
              this.test2.controls.II1.setValue(quest.guideTwo.section[0].questions[0].value);
              this.test2.controls.II2.setValue(quest.guideTwo.section[0].questions[1].value);
              this.test2.controls.II3.setValue(quest.guideTwo.section[0].questions[2].value);
              this.test2.controls.II4.setValue(quest.guideTwo.section[0].questions[3].value);
              this.test2.controls.II5.setValue(quest.guideTwo.section[0].questions[4].value);
              this.test2.controls.II6.setValue(quest.guideTwo.section[0].questions[5].value);
              this.test2.controls.II7.setValue(quest.guideTwo.section[0].questions[6].value);
              this.test2.controls.II8.setValue(quest.guideTwo.section[0].questions[7].value);
              this.test2.controls.II9.setValue(quest.guideTwo.section[0].questions[8].value);

              //seccion 2
              this.test2.controls.II10.setValue(quest.guideTwo.section[1].questions[0].value);
              this.test2.controls.II11.setValue(quest.guideTwo.section[1].questions[1].value);
              this.test2.controls.II12.setValue(quest.guideTwo.section[1].questions[2].value);
              this.test2.controls.II13.setValue(quest.guideTwo.section[1].questions[3].value);

              //seccion 3
              this.test2.controls.II14.setValue(quest.guideTwo.section[2].questions[0].value);
              this.test2.controls.II15.setValue(quest.guideTwo.section[2].questions[1].value);
              this.test2.controls.II16.setValue(quest.guideTwo.section[2].questions[2].value);
              this.test2.controls.II17.setValue(quest.guideTwo.section[2].questions[3].value);
              //Seccio n 4
              this.test2.controls.II18.setValue(quest.guideTwo.section[3].questions[0].value);
              this.test2.controls.II19.setValue(quest.guideTwo.section[3].questions[1].value);
              this.test2.controls.II20.setValue(quest.guideTwo.section[3].questions[2].value);
              this.test2.controls.II21.setValue(quest.guideTwo.section[3].questions[3].value);
              this.test2.controls.II22.setValue(quest.guideTwo.section[3].questions[4].value);
              //seccion 5
              this.test2.controls.II23.setValue(quest.guideTwo.section[4].questions[0].value);
              this.test2.controls.II24.setValue(quest.guideTwo.section[4].questions[1].value);
              this.test2.controls.II25.setValue(quest.guideTwo.section[4].questions[2].value);
              this.test2.controls.II26.setValue(quest.guideTwo.section[4].questions[3].value);
              this.test2.controls.II27.setValue(quest.guideTwo.section[4].questions[4].value);
              //seccion 6
              this.test2.controls.II28.setValue(quest.guideTwo.section[5].questions[0].value);
              this.test2.controls.II29.setValue(quest.guideTwo.section[5].questions[1].value);
              this.test2.controls.II30.setValue(quest.guideTwo.section[5].questions[2].value);
              this.test2.controls.II31.setValue(quest.guideTwo.section[5].questions[3].value);
              this.test2.controls.II32.setValue(quest.guideTwo.section[5].questions[4].value);
              this.test2.controls.II33.setValue(quest.guideTwo.section[5].questions[5].value);
              this.test2.controls.II34.setValue(quest.guideTwo.section[5].questions[6].value);
              this.test2.controls.II35.setValue(quest.guideTwo.section[5].questions[7].value);
              this.test2.controls.II36.setValue(quest.guideTwo.section[5].questions[8].value);
              this.test2.controls.II37.setValue(quest.guideTwo.section[5].questions[9].value);
              this.test2.controls.II38.setValue(quest.guideTwo.section[5].questions[10].value);
              this.test2.controls.II39.setValue(quest.guideTwo.section[5].questions[11].value);
              this.test2.controls.II40.setValue(quest.guideTwo.section[5].questions[12].value);

              //seccion 7
              if (quest.guideTwo.disable[0].value == 'si') {
                this.blockedText1Title = "#003f9b";
                this.blockedText1 = "#212529"
                this.optionalSeccion1 = true
                this.serviceToCustomersOrUsersForm.controls.option.setValue('Si')
                if (quest.guideTwo.section[6].idSection == 7) {
                  this.test2.controls.II41.setValue(quest.guideTwo.section[6].questions[0].value);
                  this.test2.controls.II42.setValue(quest.guideTwo.section[6].questions[1].value);
                  this.test2.controls.II43.setValue(quest.guideTwo.section[6].questions[2].value);
                } else if (quest.guideTwo.section[6].idSection == 8) {
                  this.test2.controls.II41.setValue(quest.guideTwo.section[7].questions[0].value);
                  this.test2.controls.II42.setValue(quest.guideTwo.section[7].questions[1].value);
                  this.test2.controls.II43.setValue(quest.guideTwo.section[7].questions[2].value);
                } else {
                  this.test2.controls.II41.setValue(quest.guideTwo.section[6].questions[0].value);
                  this.test2.controls.II42.setValue(quest.guideTwo.section[6].questions[1].value);
                  this.test2.controls.II43.setValue(quest.guideTwo.section[6].questions[2].value);
                }

              } else {
                this.blockedText1Title = "#D4D4D4"
                this.blockedText1 = "#D4D4D4";
                this.optionalSeccion1 = false;
                this.serviceToCustomersOrUsersForm.controls.option.setValue('No')

                this.optionalSeccion1 = false
              }
              if (quest.guideTwo.disable[1].value == 'si') {
                //Seccion 8
                this.bossForm.controls.option.setValue('Si');
                this.blockedText2Title = "#003f9b";
                this.blockedText2 = "#212529"
                this.optionalSeccion2 = true;
                if (quest.guideTwo.section[6].idSection == 8) {
                  this.test2.controls.II44.setValue(quest.guideTwo.section[6].questions[0].value);
                  this.test2.controls.II45.setValue(quest.guideTwo.section[6].questions[1].value);
                  this.test2.controls.II46.setValue(quest.guideTwo.section[6].questions[2].value);
                }
                this.test2.controls.II44.setValue(quest.guideTwo.section[7].questions[0].value);
                this.test2.controls.II45.setValue(quest.guideTwo.section[7].questions[1].value);
                this.test2.controls.II46.setValue(quest.guideTwo.section[7].questions[2].value);
              } else {
                this.blockedText2Title = "#D4D4D4"
                this.blockedText2 = "#D4D4D4"
                this.optionalSeccion2 = false;
                this.bossForm.controls.option.setValue('No');


              }


              this.test2.disable()
            } catch (error) {
            }
          } else {
            this.initForm();
          }
        }
      }
    })

  }

  nextSection() {
    this.changeSection++;
    window.scrollTo(0, 0);
  }

  goBack() {
    this.changeSection--;
    window.scrollTo(0, 0);
  }
};
