<!-- <div class="{{mostrard}}">
    <div class="container mt-5 align-middle">
        <div class="row ">
            <div class="col-12 text-center">
                Esta sección debe ser realizada en una computadora.
            </div>
        </div>
        <div class="row mt-3 pt-4">
            <div class="d-flex mx-auto">
                <button class="btn-secondary">Salir</button>
            </div>
        </div>
    </div>
</div> -->
<div class="container pr-lg-0">
    <div class="container mt-2 mb-4 mx-auto" *ngIf="visible">
        <div class="row">
            <a href="#" [routerLink]="['/home/welcome']">
                <img src="assets/img/logos/01.svg" class="logo mr-2" width="124px" height="40px">
            </a>
            <div class="col-lg d-lg-flex justify-content-lg-end">
                <div class="col-lg-4 pr-0">
                    <div class="row d-flex justify-content-end px-lg-4">
                        <span class="mr-2 px-0 text-right my-auto fw-bold txt-blue fs-13">Empresa: </span>
                        <p class="mr-2 px-0 text-right my-auto fw-bold  fs-12">{{nameCompany}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="visible" class="card mt-4">
        <div class="row mx-auto col-12 pb-3 pt-3">
            <div class="col-lg-1 pt-2">
                <img class="clockImage mt-n5 mt-lg-0" src="../../../../assets/img/test/timer.svg">
            </div>
            <div class="col-lg-8">
                <div class="fw-bold txt-blue fs-16">
                    {{infoEmployee?.name}} {{infoEmployee?.surnames}}
                </div>
                <div class="txt-lightgray fs-12 pt-2">
                    {{infoEmployee?.marketstall}}
                </div>
                <div class="row txt-darkgrey justify-content-lg-center">
                    <div class="col-lg-5 fs-12">
                        Empresa: {{nameCompany}}
                    </div>
                    <div class="col-lg-3 fs-12">
                        Centro: {{infoEmployee?.workplace}}
                    </div>
                    <div class="col-lg-4 fs-12">
                        Área: {{infoEmployee?.area}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mx-auto d-block text-right order-first order-lg-last">
                <div class="fw-bold ">
                    No. de empleado <span class="txt-light-blue">{{infoEmployee?.employeeNumber}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 fs-21 txt-grey fw-black">
        <div class="row ">
            <div class="col-12 text-center">
                Guía de Referencia III
            </div>
        </div>
        <div class="row mt-3">
            <div class="text-center">
                CUESTIONARIO PARA IDENTIFICAR LOS FACTORES DE RIESGO PSICOSOCIAL Y EVALUAR EL ENTORNO ORGANIZACIONAL EN
                LOS CENTROS DE TRABAJO
            </div>
        </div>
    </div>
    <div class="col-12 form-inline ml-1 pt-4 fs-20 txt-blue px-0" *ngIf="this.router.url == '/guide-three'">
        Instrucciones
        <div class="cssToolTip infoTool pl-2" (mouseenter)="displayInfo = 'inline'" (mouseleave)="displayInfo = 'none'">
            <img src="assets/icons/questionary/info_fill.svg" alt="info">
            <div class="content-tool fs-12 text-left" [style.display]="displayInfo">
                <div class="row">
                    <div class="col-12 px-3 border-bottom mb-2">
                        <div class="row">
                            <div class="ml-2">
                                <img src="assets/icons/info.svg" width="25px" alt="info-tooltip">
                            </div>
                            <div class="col pl-3 py-2">
                                <p class="mb-0 pl-3">Información importante e Instrucciones</p>
                            </div>
                            <div class="col-2 py-1 closebtn text-right" (click)="displayInfo = 'none'">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="#B3B3B3"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="#B3B3B3"
                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-3">
                        <p>Con la finalidad de establecer los elementos para identificar, analizar y prevenir los
                            factores de riesgo psicosocial, así como para promover un entorno organizacional favorable
                            en los centros de trabajo, te invitamos a resolver
                            el siguiente cuestionario; antes de contestarlo es importante recordar lo siguiente:
                        </p>
                        <ul>
                            <li>1. Verificar que las condiciones de las instalaciones y mobiliario en dónde te
                                encuentres sean idóneas</li>
                            <li>2. Entender claramente que la finalidad del cuestionario es identificar, analizar y
                                prevenir los factores de riesgo psicosocial para promover un entorno organizacional
                                favorable en los centros de trabajo.</li>
                            <li>3. Es muy importante que sepas que tus respuestas se manejaran de acuerdo con la
                                PRIVACIDAD Y CONFIABILIDAD DEL MANEJO DE LOS DATOS DE TU EMPRESA, el uso de la
                                información proporcionada y sus resultaros serán EXCLUSIVAMENTE
                                para fines de mejora del ambiente de trabajo.</li>
                            <li>4. En el siguiente cuestionario no existen respuestas correctas o incorrectas.</li>
                            <li>5. Considera EL ÚLTIMO MES para dar tu respuesta a cada pregunta.</li>
                        </ul>
                        <p>
                            Selecciona SI o No cada pregunta.
                        </p>
                        <p>
                            ¡Gracias por tu tiempo!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 px-0 ml-1 pt-3 pb-2 fs-14 txt-grey">
        Contesta las siguientes preguntas seleccionando una de las opciones disponibles. Todos los campos son
        obligatorios.
    </div>
    <button (click)="saveProgress()" class="btn-secondary fs-12" *ngIf="visible">Guardar Progreso</button>
    <form [formGroup]="test3" (ngSubmit)="checkAnswers()">
        <div [ngSwitch]="changeSection" class="fs-14">
            <!-- Section 1 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="0">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Para responder las preguntas siguientes considere las condiciones ambientales de su centro de
                    trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionOne; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question"></div>
                            <div class="row col-lg-8 d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+1}}" id="Siempre{{i+1}}"
                                                formControlName="III{{i+1}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','1.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+1}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+1}}" id="Casi siempre{{i+1}}"
                                                    formControlName="III{{i+1}}" value="Casi siempre"
                                                    formControlName="III{{i+1}}"
                                                    (change)="answerTrigger('Casi siempre','1.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+1}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+1}}" id="Algunas veces{{i+1}}"
                                                    formControlName="III{{i+1}}" value="Algunas veces"
                                                    formControlName="III{{i+1}}"
                                                    (change)="answerTrigger('Algunas veces','1.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+1}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+1}}" id="Casi nunca{{i+1}}"
                                                    formControlName="III{{i+1}}" value="Casi nunca"
                                                    formControlName="III{{i+1}}"
                                                    (change)="answerTrigger('Casi nunca','1.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+1}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+1}}" id="Nunca{{i+1}}"
                                                    formControlName="III{{i+1}}" value="Nunca"
                                                    formControlName="III{{i+1}}"
                                                    (change)="answerTrigger('Nunca','1.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+1}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionOne.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 2 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="1">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Para responder a las preguntas siguientes piense en la cantidad y ritmo de trabajo que tiene.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionTwo; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+6}}" id="Siempre{{i+6}}"
                                                formControlName="III{{i+6}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','2.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+6}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+6}}" id="Casi siempre{{i+6}}"
                                                    formControlName="III{{i+6}}" value="Casi siempre"
                                                    formControlName="III{{i+6}}"
                                                    (change)="answerTrigger('Casi siempre','2.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+6}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+6}}" id="Algunas veces{{i+6}}"
                                                    formControlName="III{{i+6}}" value="Algunas veces"
                                                    formControlName="III{{i+6}}"
                                                    (change)="answerTrigger('Algunas veces','2.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+6}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+6}}" id="Casi nunca{{i+6}}"
                                                    formControlName="III{{i+6}}" value="Casi nunca"
                                                    formControlName="III{{i+6}}"
                                                    (change)="answerTrigger('Casi nunca','2.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+6}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+6}}" id="Nunca{{i+6}}"
                                                    formControlName="III{{i+6}}" value="Nunca"
                                                    formControlName="III{{i+6}}"
                                                    (change)="answerTrigger('Nunca','2.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+6}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionTwo.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 3 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="2">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con el esfuerzo mental que le exige su trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionThree; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+9}}" id="Siempre{{i+9}}"
                                                formControlName="III{{i+9}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','3.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+9}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+9}}" id="Casi siempre{{i+9}}"
                                                    formControlName="III{{i+9}}" value="Casi siempre"
                                                    formControlName="III{{i+9}}"
                                                    (change)="answerTrigger('Casi siempre','3.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+9}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+9}}" id="Algunas veces{{i+9}}"
                                                    formControlName="III{{i+9}}" value="Algunas veces"
                                                    formControlName="III{{i+9}}"
                                                    (change)="answerTrigger('Algunas veces','3.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+9}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+9}}" id="Casi nunca{{i+9}}"
                                                    formControlName="III{{i+9}}" value="Casi nunca"
                                                    formControlName="III{{i+9}}"
                                                    (change)="answerTrigger('Casi nunca','3.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+9}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+9}}" id="Nunca{{i+9}}"
                                                    formControlName="III{{i+9}}" value="Nunca"
                                                    formControlName="III{{i+9}}"
                                                    (change)="answerTrigger('Nunca','3.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+9}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionThree.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 4 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="3">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las
                    responsabilidades que tiene.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionFour; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+13}}" id="Siempre{{i+13}}"
                                                formControlName="III{{i+13}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','4.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+13}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+13}}" id="Casi siempre{{i+13}}"
                                                    formControlName="III{{i+13}}" value="Casi siempre"
                                                    formControlName="III{{i+13}}"
                                                    (change)="answerTrigger('Casi siempre','4.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+13}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+13}}" id="Algunas veces{{i+13}}"
                                                    formControlName="III{{i+13}}" value="Algunas veces"
                                                    formControlName="III{{i+13}}"
                                                    (change)="answerTrigger('Algunas veces','4.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+13}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+13}}" id="Casi nunca{{i+13}}"
                                                    formControlName="III{{i+13}}" value="Casi nunca"
                                                    formControlName="III{{i+13}}"
                                                    (change)="answerTrigger('Casi nunca','4.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+13}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+13}}" id="Nunca{{i+13}}"
                                                    formControlName="III{{i+13}}" value="Nunca"
                                                    formControlName="III{{i+13}}"
                                                    (change)="answerTrigger('Nunca','4.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+13}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionFour.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 5 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="4">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con su jornada de trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionFive; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+17}}" id="Siempre{{i+17}}"
                                                formControlName="III{{i+17}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','5.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+17}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+17}}" id="Casi siempre{{i+17}}"
                                                    formControlName="III{{i+17}}" value="Casi siempre"
                                                    formControlName="III{{i+17}}"
                                                    (change)="answerTrigger('Casi siempre','5.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+17}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+17}}" id="Algunas veces{{i+17}}"
                                                    formControlName="III{{i+17}}" value="Algunas veces"
                                                    formControlName="III{{i+17}}"
                                                    (change)="answerTrigger('Algunas veces','5.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+17}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+17}}" id="Casi nunca{{i+17}}"
                                                    formControlName="III{{i+17}}" value="Casi nunca"
                                                    formControlName="III{{i+17}}"
                                                    (change)="answerTrigger('Casi nunca','5.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+17}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+17}}" id="Nunca{{i+17}}"
                                                    formControlName="III{{i+17}}" value="Nunca"
                                                    formControlName="III{{i+17}}"
                                                    (change)="answerTrigger('Nunca','5.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+17}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionFive.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 6 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="5">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionSix; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+23}}" id="Siempre{{i+23}}"
                                                formControlName="III{{i+23}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','6.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+23}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+23}}" id="Casi siempre{{i+23}}"
                                                    formControlName="III{{i+23}}" value="Casi siempre"
                                                    formControlName="III{{i+23}}"
                                                    (change)="answerTrigger('Casi siempre','6.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+23}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+23}}" id="Algunas veces{{i+23}}"
                                                    formControlName="III{{i+23}}" value="Algunas veces"
                                                    formControlName="III{{i+23}}"
                                                    (change)="answerTrigger('Algunas veces','6.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+23}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+23}}" id="Casi nunca{{i+23}}"
                                                    formControlName="III{{i+23}}" value="Casi nunca"
                                                    formControlName="III{{i+23}}"
                                                    (change)="answerTrigger('Casi nunca','6.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+23}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+23}}" id="Nunca{{i+23}}"
                                                    formControlName="III{{i+23}}" value="Nunca"
                                                    formControlName="III{{i+23}}"
                                                    (change)="answerTrigger('Nunca','6.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+23}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionSix.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 7 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="6">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con cualquier tipo de cambio que ocurra en su trabajo
                    (considere los últimos cambios realizados).
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionSeven; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+29}}" id="Siempre{{i+29}}"
                                                formControlName="III{{i+29}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','7.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+29}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+29}}" id="Casi siempre{{i+29}}"
                                                    formControlName="III{{i+29}}" value="Casi siempre"
                                                    formControlName="III{{i+29}}"
                                                    (change)="answerTrigger('Casi siempre','7.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+29}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+29}}" id="Algunas veces{{i+29}}"
                                                    formControlName="III{{i+29}}" value="Algunas veces"
                                                    formControlName="III{{i+29}}"
                                                    (change)="answerTrigger('Algunas veces','7.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+29}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+29}}" id="Casi nunca{{i+29}}"
                                                    formControlName="III{{i+29}}" value="Casi nunca"
                                                    formControlName="III{{i+29}}"
                                                    (change)="answerTrigger('Casi nunca','7.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+29}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+29}}" id="Nunca{{i+29}}"
                                                    formControlName="III{{i+29}}" value="Nunca"
                                                    formControlName="III{{i+29}}"
                                                    (change)="answerTrigger('Nunca','7.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+29}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionSeven.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 8 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="7">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con la capacitación e información que se le proporciona
                    sobre su trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionEight; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+31}}" id="Siempre{{i+31}}"
                                                formControlName="III{{i+31}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','8.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+31}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+31}}" id="Casi siempre{{i+31}}"
                                                    formControlName="III{{i+31}}" value="Casi siempre"
                                                    formControlName="III{{i+31}}"
                                                    (change)="answerTrigger('Casi siempre','8.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+31}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+31}}" id="Algunas veces{{i+31}}"
                                                    formControlName="III{{i+31}}" value="Algunas veces"
                                                    formControlName="III{{i+31}}"
                                                    (change)="answerTrigger('Algunas veces','8.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+31}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+31}}" id="Casi nunca{{i+31}}"
                                                    formControlName="III{{i+31}}" value="Casi nunca"
                                                    formControlName="III{{i+31}}"
                                                    (change)="answerTrigger('Casi nunca','8.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+31}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+31}}" id="Nunca{{i+31}}"
                                                    formControlName="III{{i+31}}" value="Nunca"
                                                    formControlName="III{{i+31}}"
                                                    (change)="answerTrigger('Nunca','8.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+31}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionEight.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 9 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="8">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con el o los jefes con quien tiene contacto.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionNine; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+37}}" id="Siempre{{i+37}}"
                                                formControlName="III{{i+37}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','9.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+37}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+37}}" id="Casi siempre{{i+37}}"
                                                    formControlName="III{{i+37}}" value="Casi siempre"
                                                    formControlName="III{{i+37}}"
                                                    (change)="answerTrigger('Casi siempre','9.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+37}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+37}}" id="Algunas veces{{i+37}}"
                                                    formControlName="III{{i+37}}" value="Algunas veces"
                                                    formControlName="III{{i+37}}"
                                                    (change)="answerTrigger('Algunas veces','9.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+37}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+37}}" id="Casi nunca{{i+37}}"
                                                    formControlName="III{{i+37}}" value="Casi nunca"
                                                    formControlName="III{{i+37}}"
                                                    (change)="answerTrigger('Casi nunca','9.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+37}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+37}}" id="Nunca{{i+37}}"
                                                    formControlName="III{{i+37}}" value="Nunca"
                                                    formControlName="III{{i+37}}"
                                                    (change)="answerTrigger('Nunca','9.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+37}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionNine.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 10 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="9">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes se refieren a las relaciones con sus compañeros.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionTen; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+42}}" id="Siempre{{i+42}}"
                                                formControlName="III{{i+42}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','10.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+42}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+42}}" id="Casi siempre{{i+42}}"
                                                    formControlName="III{{i+42}}" value="Casi siempre"
                                                    formControlName="III{{i+42}}"
                                                    (change)="answerTrigger('Casi siempre','10.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+42}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+42}}" id="Algunas veces{{i+42}}"
                                                    formControlName="III{{i+42}}" value="Algunas veces"
                                                    formControlName="III{{i+42}}"
                                                    (change)="answerTrigger('Algunas veces','10.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+42}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+42}}" id="Casi nunca{{i+42}}"
                                                    formControlName="III{{i+42}}" value="Casi nunca"
                                                    formControlName="III{{i+42}}"
                                                    (change)="answerTrigger('Casi nunca','10.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+42}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+42}}" id="Nunca{{i+42}}"
                                                    formControlName="III{{i+42}}" value="Nunca"
                                                    formControlName="III{{i+42}}"
                                                    (change)="answerTrigger('Nunca','10.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+42}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionTen.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 11 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="10">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con la información que recibe sobre su rendimiento en el
                    trabajo, el reconocimiento, el sentido de pertenencia y la estabilidad que le ofrece su trabajo.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionEleven; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+47}}" id="Siempre{{i+47}}"
                                                formControlName="III{{i+47}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','11.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+47}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+47}}" id="Casi siempre{{i+47}}"
                                                    formControlName="III{{i+47}}" value="Casi siempre"
                                                    formControlName="III{{i+47}}"
                                                    (change)="answerTrigger('Casi siempre','11.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+47}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+47}}" id="Algunas veces{{i+47}}"
                                                    formControlName="III{{i+47}}" value="Algunas veces"
                                                    formControlName="III{{i+47}}"
                                                    (change)="answerTrigger('Algunas veces','11.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+47}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+47}}" id="Casi nunca{{i+47}}"
                                                    formControlName="III{{i+47}}" value="Casi nunca"
                                                    formControlName="III{{i+47}}"
                                                    (change)="answerTrigger('Casi nunca','11.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+47}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+47}}" id="Nunca{{i+47}}"
                                                    formControlName="III{{i+47}}" value="Nunca"
                                                    formControlName="III{{i+47}}"
                                                    (change)="answerTrigger('Nunca','11.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+47}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionEleven.length-1" class="hr-m-2">
                </div>
            </div>

            <!-- Section 12 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" *ngSwitchCase="11">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con actos de violencia laboral (malos tratos, acoso,
                    hostigamiento, acoso psicológico).
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionTwelve; index as i">
                    <div class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+57}}" id="Siempre{{i+57}}"
                                                formControlName="III{{i+57}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','12.'+(i+1))" required>
                                            <span class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+57}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+57}}" id="Casi siempre{{i+57}}"
                                                    formControlName="III{{i+57}}" value="Casi siempre"
                                                    formControlName="III{{i+57}}"
                                                    (change)="answerTrigger('Casi siempre','12.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+57}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+57}}" id="Algunas veces{{i+57}}"
                                                    formControlName="III{{i+57}}" value="Algunas veces"
                                                    formControlName="III{{i+57}}"
                                                    (change)="answerTrigger('Algunas veces','12.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+57}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+57}}" id="Casi nunca{{i+57}}"
                                                    formControlName="III{{i+57}}" value="Casi nunca"
                                                    formControlName="III{{i+57}}"
                                                    (change)="answerTrigger('Casi nunca','12.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+57}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+57}}" id="Nunca{{i+57}}"
                                                    formControlName="III{{i+57}}" value="Nunca"
                                                    formControlName="III{{i+57}}"
                                                    (change)="answerTrigger('Nunca','12.'+(i+1))" required>
                                                <span class="checkmark-radio"></span>

                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+57}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionTwelve.length-1" class="hr-m-2">
                </div>
            </div>


            <!-- Special Questions -->

            <div *ngSwitchCase="12">
                <div class="txt-grey mt-5">
                    Las preguntas siguientes están relacionadas con la atención a clientes y usuarios
                </div>
                <div class="card mt-2 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
                    <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                        <div class="col-lg-6 text-center text-cyan">
                            <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                            Sección/Pregunta
                        </div>
                        <div class="col-6 text-right text-cyan d-none d-lg-block">
                            <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                            Respuesta
                        </div>
                    </div>
                    <hr class="hr-5 mt-0 mb-2">
                    <form [formGroup]="serviceToCustomersOrUsersForm">
                        <div class="container ">
                            <div class="row ">
                                <div class="row col-lg">
                                    <div class="col-lg">En mi trabajo debo brindar servicio a clientes o usuarios:</div>
                                </div>
                                <div class="row col-lg-4 d-flex flex-row-reverse ">
                                    <div class="col-auto row mx-auto mx-lg-2">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="option" id="optionTrabajoNo" value="No"
                                                    (change)="servicioaClientes(0)" formControlName="option"
                                                    (click)="sendAnswerDisable('serviciocliente','no')" checked>
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="optionTrabajoNo">No</label>
                                        </div>
                                    </div>
                                    <div class="col-auto row mx-auto mx-lg-2">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="option" id="optionTrabajoSi" value="Si"
                                                    formControlName="option" (change)="servicioaClientes(true)"
                                                    (click)="sendAnswerDisable('serviciocliente','si')">
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="optionTrabajoSi">Sí</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            <!-- Special 1 -->

            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las
                    responsabilidades que tiene.
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                    <div class="col-lg-6 text-center">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-center d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div *ngFor="let question of questionsSectionFourteen; index as i">

                    <div [style.color]="blockedText1" class="container">
                        <div class="row ">
                            <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                            </div>
                            <div class="row d-flex flex-row-reverse align-items-center"
                                [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="III{{i+65}}" id="Siempre{{i+65}}"
                                                formControlName="III{{i+65}}" value="Siempre"
                                                (change)="answerTrigger('Siempre','13.'+(i+1))" required>
                                            <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Siempre{{i+65}}">Siempre</label>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+65}}" id="Casi siempre{{i+65}}"
                                                    formControlName="III{{i+65}}" value="Casi siempre"
                                                    formControlName="III{{i+65}}"
                                                    (change)="answerTrigger('Casi siempre','13.'+(i+1))" required>
                                                <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                                    class="checkmark-radio-blocked"></span>
                                                <ng-template #elseBlockNoBloacked>
                                                    <span class="checkmark-radio"></span>

                                                </ng-template>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi siempre{{i+65}}">Casi siempre</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+65}}" id="Algunas veces{{i+65}}"
                                                    formControlName="III{{i+65}}" value="Algunas veces"
                                                    formControlName="III{{i+65}}"
                                                    (change)="answerTrigger('Algunas veces','13.'+(i+1))" required>
                                                <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                                    class="checkmark-radio-blocked"></span>
                                                <ng-template #elseBlockNoBloacked>
                                                    <span class="checkmark-radio"></span>

                                                </ng-template>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Algunas veces{{i+65}}">Algunas veces</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+65}}" id="Casi nunca{{i+65}}"
                                                    formControlName="III{{i+65}}" value="Casi nunca"
                                                    formControlName="III{{i+65}}"
                                                    (change)="answerTrigger('Casi nunca','13.'+(i+1))" required>
                                                <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                                    class="checkmark-radio-blocked"></span>
                                                <ng-template #elseBlockNoBloacked>
                                                    <span class="checkmark-radio"></span>

                                                </ng-template>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Casi nunca{{i+65}}">Casi nunca</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+65}}" id="Nunca{{i+65}}"
                                                    formControlName="III{{i+65}}" value="Nunca"
                                                    formControlName="III{{i+65}}"
                                                    (change)="answerTrigger('Nunca','13.'+(i+1))" required>
                                                <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                                    class="checkmark-radio-blocked"></span>
                                                <ng-template #elseBlockNoBloacked>
                                                    <span class="checkmark-radio"></span>

                                                </ng-template>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Nunca{{i+65}}">Nunca</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="i<questionsSectionFourteen.length-1" class="hr-m-2">
                </div>
            </div>
            </div>

            <div *ngSwitchCase="13">
                <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
                    <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                        <div class="col-lg-6 text-center text-cyan">
                            <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                            Sección/Pregunta
                        </div>
                        <div class="col-6 text-right text-cyan d-none d-lg-block">
                            <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                            Respuesta
                        </div>
                    </div>
                    <hr class="hr-5 mt-0 mb-2">
                    <form [formGroup]="bossForm">
                        <div class="container ">
                            <div class="row ">
                                <div class="row col-lg pr-0">
                                    <div class="col-lg" [ngClass]="{'pr-0': withSidebar}">Soy jefe de otros
                                        trabajadores:
                                    </div>
                                </div>
                                <div class="row col-lg-4 d-flex flex-row-reverse ">
                                    <div class="col-auto row mx-auto mx-lg-2">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="option" id="optionJefeNo" value="No"
                                                    formControlName="option" (change)="soyJefe(0)"
                                                    (click)="sendAnswerDisable('jefe','no')">
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="optionJefeNo">No</label>
                                        </div>
                                    </div>
                                    <div class="col-auto row mx-auto mx-lg-2">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="option" id="optionJefeSi" value="Si"
                                                    formControlName="option" (click)="sendAnswerDisable('jefe','si')"
                                                    (change)="soyJefe(true)">
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="optionJefeSi">Sí</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                <!-- Special 2 -->

                <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
                    <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                        Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las
                        responsabilidades que tiene.
                    </div>
                    <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                        <div class="col-lg-6 text-center">
                            <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                            Sección/Pregunta
                        </div>
                        <div class="col-6 text-center d-none d-lg-block">
                            <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                            Respuesta
                        </div>
                    </div>
                    <hr class="hr-5 mt-0 mb-2">
                    <div *ngFor="let question of questionsSectionFifteen; index as i">

                        <div [style.color]="blockedText2" class="container">
                            <div class="row ">
                                <div class="row col-lg pr-0" [ngClass]="{'pr-0': withSidebar}" [innerHTML]="question">
                                </div>
                                <div class="row d-flex flex-row-reverse align-items-center"
                                    [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                                    <div class="col-lg-auto row">
                                        <div class="col-auto pl-1">
                                            <label class="position-relative">
                                                <input type="radio" name="III{{i+69}}" id="Siempre{{i+69}}"
                                                    formControlName="III{{i+69}}" value="Siempre"
                                                    (change)="answerTrigger('Siempre','14.'+(i+1))" required>
                                                <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                    class="checkmark-radio-blocked"></span>
                                                <ng-template #elseBlockNoBloacked>
                                                    <span class="checkmark-radio"></span>

                                                </ng-template>
                                            </label>
                                        </div>
                                        <div class="col-auto pl-1 pt-1">
                                            <label for="Siempre{{i+69}}">Siempre</label>
                                        </div>
                                        <div class="col-lg-auto row">
                                            <div class="col-auto pl-1">
                                                <label class="position-relative">
                                                    <input type="radio" name="III{{i+69}}" id="Casi siempre{{i+69}}"
                                                        formControlName="III{{i+69}}" value="Casi siempre"
                                                        formControlName="III{{i+69}}"
                                                        (change)="answerTrigger('Casi siempre','14.'+(i+1))" required>
                                                    <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                        class="checkmark-radio-blocked"></span>
                                                    <ng-template #elseBlockNoBloacked>
                                                        <span class="checkmark-radio"></span>

                                                    </ng-template>
                                                </label>
                                            </div>
                                            <div class="col-auto pl-1 pt-1">
                                                <label for="Casi siempre{{i+69}}">Casi siempre</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-auto row">
                                            <div class="col-auto pl-1">
                                                <label class="position-relative">
                                                    <input type="radio" name="III{{i+69}}" id="Algunas veces{{i+69}}"
                                                        formControlName="III{{i+69}}" value="Algunas veces"
                                                        formControlName="III{{i+69}}"
                                                        (change)="answerTrigger('Algunas veces','14.'+(i+1))" required>
                                                    <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                        class="checkmark-radio-blocked"></span>
                                                    <ng-template #elseBlockNoBloacked>
                                                        <span class="checkmark-radio"></span>

                                                    </ng-template>
                                                </label>
                                            </div>
                                            <div class="col-auto pl-1 pt-1">
                                                <label for="Algunas veces{{i+69}}">Algunas veces</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-auto row">
                                            <div class="col-auto pl-1">
                                                <label class="position-relative">
                                                    <input type="radio" name="III{{i+69}}" id="Casi nunca{{i+69}}"
                                                        formControlName="III{{i+69}}" value="Casi nunca"
                                                        formControlName="III{{i+69}}"
                                                        (change)="answerTrigger('Casi nunca','14.'+(i+1))" required>
                                                    <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                        class="checkmark-radio-blocked"></span>
                                                    <ng-template #elseBlockNoBloacked>
                                                        <span class="checkmark-radio"></span>

                                                    </ng-template>
                                                </label>
                                            </div>
                                            <div class="col-auto pl-1 pt-1">
                                                <label for="Casi nunca{{i+69}}">Casi nunca</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-auto row">
                                            <div class="col-auto pl-1">
                                                <label class="position-relative">
                                                    <input type="radio" name="III{{i+69}}" id="Nunca{{i+69}}"
                                                        formControlName="III{{i+69}}" value="Nunca"
                                                        formControlName="III{{i+69}}"
                                                        (change)="answerTrigger('Nunca','14.'+(i+1))" required>
                                                    <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                        class="checkmark-radio-blocked"></span>
                                                    <ng-template #elseBlockNoBloacked>
                                                        <span class="checkmark-radio"></span>

                                                    </ng-template>
                                                </label>
                                            </div>
                                            <div class="col-auto pl-1 pt-1">
                                                <label for="Nunca{{i+69}}">Nunca</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="i<questionsSectionFifteen.length-1" class="hr-m-2">
                    </div>
                </div>
            </div>
            <div class="col-12 form-inline px-0 mb-4">
                <div class="col-sm px-0">
                <button class="fs-12 mx-auto mx-sm-0 mr-sm-auto d-block btn-secondary" (click)="goBack()" *ngIf="changeSection>0">Sección anterior</button>
                </div>
                <div class="col-sm px-0 mt-3 mt-sm-0">
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===0" [disabled]="test3.controls['III'+1].invalid || test3.controls['III'+2].invalid || test3.controls['III'+3].invalid || test3.controls['III'+4].invalid || test3.controls['III'+5].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===1" [disabled]="test3.controls['III'+6].invalid || test3.controls['III'+7].invalid || test3.controls['III'+8].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===2" [disabled]="test3.controls['III'+9].invalid || test3.controls['III'+10].invalid || test3.controls['III'+11].invalid || test3.controls['III'+12].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===3" [disabled]="test3.controls['III'+13].invalid || test3.controls['III'+14].invalid || test3.controls['III'+15].invalid || test3.controls['III'+16].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===4" [disabled]="test3.controls['III'+17].invalid || test3.controls['III'+18].invalid || test3.controls['III'+19].invalid || test3.controls['III'+20].invalid || test3.controls['III'+21].invalid || test3.controls['III'+22].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===5" [disabled]="test3.controls['III'+23].invalid || test3.controls['III'+24].invalid || test3.controls['III'+25].invalid || test3.controls['III'+26].invalid || test3.controls['III'+27].invalid || test3.controls['III'+28].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===6" [disabled]="test3.controls['III'+29].invalid || test3.controls['III'+30].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===7" [disabled]="test3.controls['III'+31].invalid || test3.controls['III'+32].invalid || test3.controls['III'+33].invalid || test3.controls['III'+34].invalid || test3.controls['III'+35].invalid || test3.controls['III'+36].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===8" [disabled]="test3.controls['III'+37].invalid || test3.controls['III'+38].invalid || test3.controls['III'+39].invalid || test3.controls['III'+40].invalid || test3.controls['III'+41].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===9" [disabled]="test3.controls['III'+42].invalid || test3.controls['III'+43].invalid || test3.controls['III'+44].invalid || test3.controls['III'+45].invalid || test3.controls['III'+46].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===10" [disabled]="test3.controls['III'+47].invalid || test3.controls['III'+48].invalid || test3.controls['III'+49].invalid || test3.controls['III'+50].invalid || test3.controls['III'+51].invalid || test3.controls['III'+52].invalid || test3.controls['III'+53].invalid || test3.controls['III'+54].invalid || test3.controls['III'+55].invalid || test3.controls['III'+56].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===11" [disabled]="test3.controls['III'+57].invalid || test3.controls['III'+58].invalid || test3.controls['III'+59].invalid || test3.controls['III'+60].invalid || test3.controls['III'+61].invalid || test3.controls['III'+62].invalid || test3.controls['III'+63].invalid || test3.controls['III'+64].invalid">Siguiente sección</button>
                <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===12" [disabled]="serviceToCustomersOrUsersForm.controls['option'].invalid || serviceToCustomersOrUsersForm.controls['option'].value ==='n/s'">Siguiente sección</button>
                <button [disabled]="test3.invalid || serviceToCustomersOrUsersForm.controls['option'].value ==='n/s' || bossForm.controls['option'].value ==='n/s'" (click)="nextQuest()" class="fs-12 btn-secondary mx-auto mx-sm-0 ml-sm-auto d-block" *ngIf="visible && changeSection===13">Continuar</button>
            </div>
        </div>
        </div>
    </form>
</div>
