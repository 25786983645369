import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/internal/operators';
import { ResponseContent } from '../../models';
import { IPublicationModel, ICommentsModel } from '../../models/publications/publication.model';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  constructor(
    private http: HttpService
  ) { }

  companyPublications = new BehaviorSubject<IPublicationModel[]>([]);
  
  comments = new BehaviorSubject<any>(undefined);

  post = new BehaviorSubject<any>(undefined);

  nomPublications = new BehaviorSubject<IPublicationModel[]>([]);

  scheduled = new BehaviorSubject<IPublicationModel[]>([]);

  history = new BehaviorSubject<{posts: IPublicationModel[], yearsOfPublications : number[]} >({posts: [],yearsOfPublications:[]});


  create(publication: IPublicationModel){
    return this.http.post('/publications/create', publication, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  findOne(id: string){
    return this.http.post('/publications/findOne', {id}, true).pipe(
      map((response: ResponseContent) => {
        response.content ? (this.post.next(response.content)) : '';
        return response.content;
        }
      )
    );
  }

  findAll(){
    const idCompany = sessionStorage.getItem('idCompanySelected');
    return this.http.post('/publications/findAll', { idCompany}, true).pipe(
      map((response: ResponseContent) => {
        this.companyPublications.next(response.content);
        }
      )
    );
  }

  findTalents(){
    return this.http.post('/publications/findTalents', {}, true).pipe(
      map((response: ResponseContent) => {
        this.nomPublications.next(response.content);
        }
      )
    );
  }

  update(publication: IPublicationModel, id : string){
    return this.http.post('/publications/update', {publication, id: id}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  delete(id: string){
    return this.http.post('/publications/delete', {id}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  createComment(id: string, comment: ICommentsModel){
    return this.http.post('/publications/createComment', {id, comment }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  like(idPublication: string){
    return this.http.post('/publications/setLike', {idPublication }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  dislike(idPublication: string){
    return this.http.post('/publications/quitLike', {idPublication }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  likeComment(idPublication: string, idComment: string){
    return this.http.post('/publications/setLikeComment', {idPublication, idComment }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  dislikeComment(idPublication: string, idComment: string){
    return this.http.post('/publications/quitLikeComment', {idPublication, idComment }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  answerComment (id: string, comment: ICommentsModel, idComment){
    return this.http.post('/publications/answerComment', {id, comment, idComment }, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  deleteComment(id: string, idComment: string){
    return this.http.post('/publications/deleteComment', {id, idComment}, true).pipe(
      map((response: ResponseContent) => {
          return (response && response.clave === 'OK');
        }
      )
    );
  }

  deleteAnswer(id: string, idAnswer: string){
    return this.http.post('/publications/deleteAnswer', {id, idAnswer}, true).pipe(
      map((response: ResponseContent) => {
        return (response && response.clave === 'OK');
      }
    )
    );
  }

  updateAnswer(id: string, idAnswer: string, answer:string, idUser: string, idComment:string){
    return this.http.post('/publications/updateAnswer', {id, idAnswer, idUser, answer, idComment}, true).pipe(
      map((response: ResponseContent) => {
        return response;
      }
    )
    );
  }

  updateComment(id: string, idComment: string, comment:string, idUser: string){
    return this.http.post('/publications/updateComment', {id, idUser, idComment, comment}, true).pipe(
      map((response: ResponseContent) => {
        return response;
      }
    )
    );
  }

  findComments(id: string){
    return this.http.post('/publications/findComments', {id}, true).pipe(
      map((response: ResponseContent) => {
        response.content ? (this.comments.next(response.content)) : '';
        return response.content;
        }
      )
    );
  }

  setLike(idUser: string, idPublication: string){
    return this.http.post('/publications/setLike', {idPublication, idUser}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  reportComment( idPublication: string, idUser: string, idComment:string, comment:string){
    return this.http.post('/publications/reportComment', {idPublication, idUser, idComment, comment}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  reportCommentOrAnswer(idPublication:string, idComment: string, idAnswer?:string){

    const params = {
      idPublication,
      idComment,
      idAnswer
    }

    return this.http.post('/publications/reportCommentOrAnswer', {params}, true).pipe(

      map((response: ResponseContent)=>{

        console.log(response);

        return response;

      })
    )
  }

  getScheduled(){
    const idCompany = sessionStorage.getItem('idCompanySelected');
    return this.http.post('/publications/scheduledPosts', {idCompany}, true).pipe(map((response: ResponseContent)=>{
      this.scheduled.next(response.content.scheduled);
      this.history.next(response.content.history);
    }));
  }

  /**
   * Generacion id para videos
   *
   * @returns
   * @memberof UserService
   */
   uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Obtiene el URL certificado del video en AWS S3.
   */
   getURLVideo(file){
    return this.http.post('/users/getURL', file, true).pipe(
      map((responsc) => {
          return responsc
        }
      )
    );
  }

  getFailWorks(){
    return this.http.post('/failWorks/findAll', {}, true).pipe(
      map((response: ResponseContent)=>{
        return response
      })
    );
  }
}
