import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-task',
  templateUrl: './modal-task.component.html',
  styleUrls: ['./modal-task.component.scss']
})
export class ModalTaskComponent {
  activityForm: FormGroup;
  colors: string[] = ['#2685f0', '#8cce17', '#febd2b', '#ff8b2c', '#6343c9', '#db359c','#e4254b','#03c3a8'];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ModalTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Recibe datos iniciales para editar
  ) {
    this.activityForm = this.fb.group({
      activity: [data?.activity || '', Validators.required],
      startDate: [data?.startDate || '', Validators.required],
      endDate: [data?.endDate || '', Validators.required],
      color: [data?.color || this.colors[0], Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAdd(): void {
    if (this.activityForm.valid) {
      this.dialogRef.close(this.activityForm.value); // Retorna los valores al componente padre
    }
  }

}
