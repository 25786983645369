import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { activities, Activity } from './record.conts';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { catalogActivities } from '../register-hours/activities.const';
import { proposals } from '@pmo/projects/projects.const';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { LogService } from 'src/app/services/log/log.service';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public activities: Activity[] = activities;
  public displayedColumns: string[] = ['date', 'activity', 'type', 'duration', 'description', 'status', 'options'];
  public activitiesDataSource!: MatTableDataSource<Activity, MatTableDataSourcePaginator>;
  public currentFilter: 'approved' | 'pending' | 'rejected' | '' = '';
  public breadcrumb: Breadcrumb[] = [
    CRUMBS.colaboratorRegisterActivities,
    CRUMBS.colaboratorHistory,
  ];
  idUser = '';
  constructor(private changeDetectorRef: ChangeDetectorRef,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.idUser = sessionStorage.getItem('idUser')
    this.logService.getLogsByCollaborator(this.idUser).subscribe(activitiesLog=>{
      if(activitiesLog){
        const activitiesTemplate = activitiesLog.slice().map((activity) => {
          return {
            date: activity.date,
            activity: activity.time,
            type: activity.oportunity,
            duration:{
              hours: activity.hours,
              minutes: activity.minutes,
            },
            status: activity.status,
            description: activity.activities
          };
        });
        this.activitiesDataSource = new MatTableDataSource(activitiesTemplate);
        this.activitiesDataSource.paginator = this.paginator;
        this.activitiesDataSource.sort = this.sort;
        console.log(this.activitiesDataSource)
      }
    })
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (this.sort.direction !== order) {
      this.sort.sort(<MatSortable>{ id: 'type', start: order, disableClear: true })
    }
  }

  applyFilter(status: 'approved' | 'pending' | 'rejected') {
    const nextFilter = this.currentFilter === status ? '' : status;
    this.currentFilter = nextFilter;
    this.activitiesDataSource.filter = nextFilter;

    if (this.activitiesDataSource.paginator) {
      this.activitiesDataSource.paginator.firstPage();
    }
  }
  
  public selectPage(pageIndex: number) {
    this.paginator.pageIndex = pageIndex;
    this.paginator.page.emit()
  }

}
