import { employees, leaders } from '@pmo/teams/team/team.const'


export interface ProjectInfo {
    overview: Overview
    progress: {
        projection: number
        realProgress: number
    }
    about: About
    dependencies: Dependency[]
    workPlan: WorkPlan[]
    risks: Risk[]
    additionalTasks: Task[]
    comments: Comment[]
}

export interface Overview {
    title: string
    leaderName: string
    leaderAvatar: string
    updatedAt: Date
    team: string[]
    sprint: number
    status: string
}
export interface About {
    description: string
    objectives: string[]
    client: string
    code: string
    methodology: string
}
export interface Dependency {
    description: string
    createdAt: Date
    inChargeAvatar: string
    inChargeName: string
    status: string
    commitmentAt: Date
}
/*
export interface WorkPlan {
    month: string,
    nextMonth: string,
    plan: Plan[]
}
*/
export interface WorkPlan {
    order: number
    activity: string
    startDate: Date,
    endDate: Date,
    color: string,
}

export interface Plan {
    title: string
    startWeek: number,
    endWeek: number,
    color: string
}

export interface Risk {
    title: string
    status: string
}
export interface Task {
    title: string
    status: string
}
export interface Comment {
    userAvatar: string
    userName: string
    createdAt: Date
    comment: string
    responses: [
        {
            userAvatar: string
            userName: string
            createdAt: Date
            comment: string
        }
    ]
}

const dependencies: Dependency[] = [
    {
        description: 'Herramientas de ticketing (e.g., JIRA, Zendesk) para la gestión de incidencias.',
        createdAt: new Date(),
        inChargeAvatar: employees[0].avatar,
        inChargeName: employees[0].name,
        status: 'To Do',
        commitmentAt: new Date(),
    },
    {
        description: 'Participación activa del equipo de desarrollo para solucionar problemas.',
        createdAt: new Date(),
        inChargeAvatar: employees[1].avatar,
        inChargeName: employees[1].name,
        status: 'WIP',
        commitmentAt: new Date(),
    },
    {
        description: 'Infraestructura de TI robusta y accesible para implementar soluciones.',
        createdAt: new Date(),
        inChargeAvatar: employees[1].avatar,
        inChargeName: employees[1].name,
        status: 'WIP',
        commitmentAt: new Date(),
    },
    {
        description: 'Disponibilidad de recursos del equipo de soporte para la capacitación.',
        createdAt: new Date(),
        inChargeAvatar: employees[0].avatar,
        inChargeName: employees[0].name,
        status: 'Done',
        commitmentAt: new Date(),
    }
]
const workPlan: WorkPlan[] = [
    {
        order: 0,
        activity: 'Plan',
        startDate: new Date('08/1/2024'),
        endDate: new Date('11/4/2024'),
        color: '#2685f0'
    },
    {
        order: 1,
        activity: 'Colaborador (DEV Front)',
        startDate: new Date('08/19/2024'),
        endDate: new Date('10/4/2024'),
        color: '#8cce17'
    },
    {
        order: 2,
        activity: 'Comercial (DEV Front)',
        startDate: new Date('08/26/2024'),
        endDate: new Date('09/30/2024'),
        color: '#febd2b'
    },
    {
        order: 3,
        activity: 'Lider (DEV Front)',
        startDate: new Date('09/16/2024'),
        endDate: new Date('10/4/2024'),
        color: '#ff8b2c'
    },
    {
        order: 4,
        activity: 'PMO (DEV Front)',
        startDate: new Date('09/23/2024'),
        endDate: new Date('10/11/2024'),
        color: '#6343c9'
    },

    {
        order: 5,
        activity: 'Creación y modificación de servicios (DEV Back)',
        startDate: new Date('08/28/2024'),
        endDate: new Date('09/27/2024'),
        color: '#db359c'
    },
    {
        order: 6,
        activity: 'Roles',
        startDate: new Date('09/25/2024'),
        endDate: new Date('11/22/2024'),
        color: '#6343c9'
    },
    {
        order: 7,
        activity: 'Integración/Modificaciones BD (DEV Back & Front)',
        startDate: new Date('10/07/2024'),
        endDate: new Date('11/08/2024'),
        color: '#2685f0'
    },
    {
        order: 8,
        activity: 'Documentación',
        startDate: new Date('11/01/2024'),
        endDate: new Date('11/22/2024'),
        color: '#03c3a8'
    },
    {
        order: 9,
        activity: 'Project Y',
        startDate: new Date('11/11/2024'),
        endDate: new Date('11/15/2024'),
        color: '#db359c'
    },
    {
        order: 8,
        activity: 'X',
        startDate: new Date('08/1/2024'),
        endDate: new Date('1/31/2025'),
        color: '#e4254b'
    },
    {
        order: 10,
        activity: 'Y',
        startDate: new Date('12/16/2024'),
        endDate: new Date('1/31/2025'),
        color: '#6343c9'
    }
]
    /*
    month: 'Septiembre',
    nextMonth: 'Octubre',
    plan:
        [
            {
                title: 'Capacitación de soporte',
                startWeek: 1,
                endWeek: 2,
                color: '#db359c'
            },
            {
                title: 'Integración de herramientas',
                startWeek: 2,
                endWeek: 3,
                color: '#6343c9'
            },
            {
                title: 'Monitoreo de KPIs de soporte técnico',
                startWeek: 3,
                endWeek: 6,
                color: '#2685f0'
            },
            {
                title: 'Documentación',
                startWeek: 6,
                endWeek: 7,
                color: '#03c3a8'
            }
        ]
        */


const additionalTasks: Task[] = [
    {
        title: 'Sesiones de formación para el equipo de soporte sobre las nuevas herramientas de gestión.',
        status: 'To Do',
    },
    {
        title: 'Revisiones periódicas de los KPI de rendimiento de resolución de incidencias.',
        status: 'WIP',
    },
    {
        title: 'Talleres de colaboración entre los equipos de soporte y desarrollo para identificar mejoras en los procesos.',
        status: 'To Do',
    },
    {
        title: 'Implementación de canal de comunicación dedicado para escalaciones urgentes de soporte y desarrollo.',
        status: 'Done',
    }
]

const risks: Risk[] = [
    {
        title: 'Baja disponibilidad del equipo de desarrollo, lo que puede generar retrasos en la resolución de problemas.',
        status: 'Por atender',
    },
    {
        title: 'Resistencia a cambios en los procesos por parte del equipo de soporte técnico.',
        status: 'En atención',
    },
    {
        title: 'Problemas de compatibilidad entre nuevas herramientas y la infraestructura actual.',
        status: 'Atención',
    }
]
const comments: Comment[] = [
    {
        userAvatar: leaders[5].avatar,
        userName: leaders[5].name,
        createdAt: new Date('11/14/2024'),
        comment: 'Gracias por compartirme tu informe, John. Tengo algunos comentarios. ¿Qué estamos haciendo para que las dependencias no atropellen el avance del proyecto? Espero tu respuesta.',
        responses: [
            {
                userAvatar: leaders[0].avatar,
                userName: leaders[0].name,
                createdAt: new Date(),
                comment: 'Estamos reportando las dependencias por mail con el cliente. Sin embargo, no ha dado respuesta aún. En cuanto quede continuamos con esa parte. Saludos.',
            }
        ]
    }
]
export const projectExample: ProjectInfo = {
    overview: {
        title: 'Soporte Técnico Productivo',
        leaderName: leaders[0].name,
        leaderAvatar: leaders[0].avatar,
        updatedAt: new Date(),
        team: [employees[0].id, employees[1].id, employees[2].id, employees[3].id],
        sprint: 13,
        status: 'Desarrollo',
    },
    progress: {
        projection: 25,
        realProgress: 50,
    },
    about: {
        description: 'Este proyecto tiene como objetivo mejorar y asegurar la eficiencia del soporte técnico en Pluxee, optimizando la resolución de incidencias a través de la colaboración directa.',
        objectives: [
            "Reducir los tiempos de resolución de incidencias en un 20%.",
            "Mejorar la comunicación entre el equipo de Soporte y el equipo de Desarrollo.",
            "Implementar un flujo de trabajo eficiente que permita escalar y resolver problemas.",
        ],
        client: 'Pluxee',
        code: 'E40STBSPC240408N',
        methodology: 'Aguile',
    },
    dependencies: dependencies,
    workPlan: workPlan,
    additionalTasks,
    risks: risks,
    comments: comments
}