import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';

interface Area {
  id: string;
  area: string;
  backup?: string
}
interface Backup {
  id: string;
  area: string;
  employee: string;
  avatar: string;
}
@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent {
  public area: Area[] = [
    { id: '1', area: 'Producto' },
    { id: '2', area: 'Becarios' },
    { id: '3', area: 'Pluxee' },
  ]
  public employee: Backup[] = [
    { id: '1', area: 'Producto', employee: 'Lucero Jiménez', avatar: 'assets/users/female-avatar.svg' },
    { id: '2', area: 'Producto', employee: 'Jesús Acha', avatar: 'assets/users/male-avatar.svg' },
    { id: '3', area: 'Producto', employee: 'Ethel Magaña', avatar: 'assets/users/female-avatar.svg' },
    { id: '4', area: 'Producto', employee: 'David Matamoroz', avatar: 'assets/users/male-avatar.svg' },
    { id: '5', area: 'Producto', employee: 'David García', avatar: 'assets/users/male-avatar.svg' },
    { id: '6', area: 'Producto', employee: 'Oscar Loa', avatar: 'assets/users/male-avatar.svg' },
    { id: '7', area: 'Producto', employee: 'Miguel Serrano', avatar: 'assets/users/male-avatar.svg' },
    { id: '8', area: 'Becarios', employee: 'Andrea Mendoza', avatar: 'assets/users/female-avatar.svg' },
    { id: '9', area: 'Becarios', employee: 'Leonardo Alarcón', avatar: 'assets/users/male-avatar.svg' },
    { id: '10', area: 'Comercial', employee: 'Azucena Miranda', avatar: 'assets/users/female-avatar.svg' },
    { id: '11', area: 'PMO', employee: 'Irinacelly Hernandez', avatar: 'assets/users/female-avatar.svg' },
    { id: '12', area: 'Desarrollo', employee: 'José Luis Campos', avatar: 'assets/users/male-avatar.svg' },
    { id: '13', area: 'Desarrollo', employee: 'Noel Alvarado', avatar: 'assets/users/male-avatar.svg' },
  ];
  public backupForm!: FormGroup;
  public breadcrumb: Breadcrumb[] = [
    CRUMBS.areaLeadMain,
    CRUMBS.areaLeadBackup,
  ];
  public today = new Date();

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private router: Router,) {
    this.backupForm = this.formBuilder.group({
      dateRange: this.formBuilder.group({
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
      }),
      areaBackupArray: this.formBuilder.array([])
    });

    this.initializeAreaBackupControls();
    // console.log(this.areaBackupArray.controls)
  }

  private initializeAreaBackupControls() {
    const areaBackupArray = this.backupForm.get('areaBackupArray') as FormArray;
    this.area.forEach(area => {
      areaBackupArray.push(
        this.formBuilder.group({
          area: [area.area],
          backup: [null, Validators.required]
        })
      );
    });
  }

  get areaBackupArray() {
    return this.backupForm.get('areaBackupArray') as FormArray;
  }

  public get startDate(): string | null {
    return this.backupForm.get('dateRange')?.get('startDate')?.value;
  }

  onSubmit() {
    if (this.backupForm.valid) {
      console.log('Form values:', this.backupForm.getRawValue());
      setTimeout(() => {
        if(Math.random() < 0.5) {
          this.dialog.open(AlertComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              title: '¡Éxito!',
              description: 'Tus backups ha sido asignados según el periodo que especificaste. No olvides notificar al colaborador.',
              close: 'Aceptar',
            } as AlertDialogData,
          });
        } else {
          this.dialog.open(AlertComponent, {
            width: '464px',
            data: {
              icon: 'status_rejected',
              title: '¡Error!',
              description: 'No ha sido posible asignar tus backups. Inténtalo de nuevo.',
              close: 'Aceptar',
            } as AlertDialogData,
          });
        }
      }, 2000);
      // Submit form data 
    }
  }

  public cancelBackup() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_question',
        warn: false,
        title: '¿Estás seguro?',
        description: 'Al cancelar el registro de esta Backup, no podrás guardar los cambios.',
        close: 'Salir sin guardar',
        confirm: 'Seguir editando'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.router.navigateByUrl('/hours-teams');
      }
    });
  }
}
