<!-- <div class="container d-none d-block d-lg-none d-xl-none">
    <div class="container mt-5 align-middle">
        <div class="row ">
            <div class="col-12 text-center">
                Esta sección debe ser realizada en una computadora.
            </div>
        </div>
        <div class="row mt-3 pt-4">
            <div class="d-flex mx-auto mb-5">
                <button class="btn-secondary">Salir</button>
            </div>
        </div>
    </div>
</div> -->
<div class="container pr-lg-0">
    <div class="container mt-2 mb-4 mx-auto" *ngIf="visible">
        <div class="row">
            <a href="#" [routerLink]="['/home/welcome']">
                <img src="assets/img/logos/01.svg" class="logo mr-2" width="124px" height="40px">
            </a>
            <div class="col-lg d-lg-flex justify-content-lg-end">
                <div class="col-lg-4 pr-0">
                    <div class="row d-flex justify-content-end px-lg-4">
                        <span class="mr-2 px-0 text-right my-auto fw-bold txt-blue fs-13">Empresa: </span>
                        <p class="mr-2 px-0 text-right my-auto fw-bold  fs-12">{{nameCompany}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="visible" class="card mt-4">
        <div class="row mx-auto col-12 pb-3 pt-3">
            <div class="col-lg-1 pt-2">
                <img class="clockImage mt-n5 mt-lg-0" src="../../../../assets/img/test/timer.svg">
            </div>
            <div class="col-lg-8">
                <div class="fw-bold txt-blue fs-16">
                    {{infoEmployee?.name}} {{infoEmployee?.surnames}}
                </div>
                <div class="txt-lightgray fs-12 pt-2">
                    {{infoEmployee?.marketstall}}
                </div>
                <div class="row txt-darkgrey justify-content-lg-center">
                    <div class="col-lg-5 fs-12">
                        Empresa: {{nameCompany}}
                    </div>
                    <div class="col-lg-3 fs-12">
                        Centro: {{infoEmployee?.workplace}}
                    </div>
                    <div class="col-lg-4 fs-12">
                        Área: {{infoEmployee?.area}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mx-auto d-block text-right order-first order-lg-last">
                <div class="fw-bold ">
                    No. de empleado <span class="txt-light-blue">{{infoEmployee?.employeeNumber}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 fs-21 txt-grey fw-black">
        <div class="row ">
            <div class="col-12 text-center">
                Guía de Referencia II
            </div>
        </div>
        <div class="row mt-3">
            <div class=" col-12 text-center">
                IDENTIFICACIÓN Y ANÁLISIS DE LOS FACTORES DE RIESGO PSICOSOCIAL
            </div>
        </div>
    </div>
    <div class="col-12 form-inline ml-1 pt-4 fs-20 txt-blue px-0" *ngIf="visible">
        Instrucciones
        <div class="cssToolTip infoTool pl-2" (mouseenter)="displayInfo = 'inline'" (mouseleave)="displayInfo = 'none'">
            <img src="assets/icons/questionary/info_fill.svg" alt="info">
            <div class="content-tool fs-12 text-left" [style.display]="displayInfo">
                <div class="row">
                    <div class="col-12 px-3 border-bottom mb-2">
                        <div class="row">
                            <div class="ml-2">
                                <img src="assets/icons/info.svg" width="25px" alt="info-tooltip">
                            </div>
                            <div class="col pl-3 py-2">
                                <p class="mb-0 pl-3">Información importante e Instrucciones</p>
                            </div>
                            <div class="col-2 py-1 closebtn text-right" (click)="displayInfo = 'none'">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="#B3B3B3" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="#B3B3B3" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-3">
                        <p>Con la finalidad de establecer los elementos para identificar, analizar y prevenir los factores de riesgo psicosocial, así como para promover un entorno organizacional favorable en los centros de trabajo, te invitamos a resolver
                            el siguiente cuestionario; antes de contestarlo es importante recordar lo siguiente:
                        </p>
                        <ul>
                            <li>1. Verificar que las condiciones de las instalaciones y mobiliario en dónde te encuentres sean idóneas</li>
                            <li>2. Entender claramente que la finalidad del cuestionario es identificar, analizar y prevenir los factores de riesgo psicosocial para promover un entorno organizacional favorable en los centros de trabajo.</li>
                            <li>3. Es muy importante que sepas que tus respuestas se manejaran de acuerdo con la PRIVACIDAD Y CONFIABILIDAD DEL MANEJO DE LOS DATOS DE TU EMPRESA, el uso de la información proporcionada y sus resultaros serán EXCLUSIVAMENTE
                                para fines de mejora del ambiente de trabajo.</li>
                            <li>4. En el siguiente cuestionario no existen respuestas correctas o incorrectas.</li>
                            <li>5. Considera EL ÚLTIMO MES para dar tu respuesta a cada pregunta.</li>
                        </ul>
                        <p>
                            Selecciona SI o No cada pregunta.
                        </p>
                        <p>
                            ¡Gracias por tu tiempo!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 px-0 ml-1 pt-3 pb-2 fs-14 txt-grey">
        Contesta las siguientes preguntas seleccionando una de las opciones disponibles. Todos los campos son obligatorios.
    </div>
    <button (click)="saveProgress()" class="btn-secondary fs-12" *ngIf="visible">Guardar Progreso</button>
    <form [formGroup]="test2" (ngSubmit)="checkAnswers()">
<div [ngSwitch]="changeSection" class="fs-14">


        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section1" *ngSwitchCase="0">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Para responder las preguntas siguientes considere las condiciones de su centro de trabajo, así como la
                cantidad y ritmo de trabajo.
            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionOne; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+1}}" id="Siempre{{i+1}}" formControlName="II{{i+1}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','1.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+1}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+1}}" id="Casi siempre{{i+1}}" formControlName="II{{i+1}}" value="Casi siempre"
                                                formControlName="II{{i+1}}"
                                                (change)="answerTrigger('Casi siempre','1.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+1}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+1}}" id="Algunas veces{{i+1}}" formControlName="II{{i+1}}" value="Algunas veces"
                                                formControlName="II{{i+1}}"
                                                (change)="answerTrigger('Algunas veces','1.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+1}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+1}}" id="Casi nunca{{i+1}}"  value="Casi nunca"
                                                formControlName="II{{i+1}}"
                                                (change)="answerTrigger('Casi nunca','1.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+1}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+1}}" id="Nunca{{i+1}}" value="Nunca"
                                                formControlName="II{{i+1}}"
                                                (change)="answerTrigger('Nunca','1.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+1}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionOne.length-1" class="hr-m-2">
            </div>
        </div>


        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section2" *ngSwitchCase="1">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las
                responsabilidades que tiene.
            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionTwo; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+10}}" id="Siempre{{i+10}}" formControlName="II{{i+10}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','2.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+10}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+10}}" id="Casi siempre{{i+10}}" formControlName="II{{i+10}}" value="Casi siempre"
                                                formControlName="II{{i+10}}"
                                                (change)="answerTrigger('Casi siempre','2.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+10}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+10}}" id="Algunas veces{{i+10}}" formControlName="II{{i+10}}" value="Algunas veces"
                                                formControlName="II{{i+10}}"
                                                (change)="answerTrigger('Algunas veces','2.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+10}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+10}}" id="Casi nunca{{i+10}}" formControlName="II{{i+10}}" value="Casi nunca"
                                                formControlName="II{{i+10}}"
                                                (change)="answerTrigger('Casi nunca','2.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+10}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+10}}" id="Nunca{{i+10}}" formControlName="II{{i+10}}" value="Nunca"
                                                formControlName="II{{i+10}}"
                                                (change)="answerTrigger('Nunca','2.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+10}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionTwo.length-1" class="hr-m-2">
            </div>
        </div>


        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section3" *ngSwitchCase="2">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Las preguntas siguientes están relacionadas con el tiempo destinado a su trabajo y sus responsabilidades familiares.
            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionThree; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+14}}" id="Siempre{{i+14}}" formControlName="II{{i+14}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','3.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+14}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+14}}" id="Casi siempre{{i+14}}" formControlName="II{{i+14}}" value="Casi siempre"
                                                formControlName="II{{i+14}}"
                                                (change)="answerTrigger('Casi siempre','3.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+14}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+14}}" id="Algunas veces{{i+14}}" formControlName="II{{i+14}}" value="Algunas veces"
                                                formControlName="II{{i+14}}"
                                                (change)="answerTrigger('Algunas veces','3.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+14}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+14}}" id="Casi nunca{{i+14}}" formControlName="II{{i+14}}" value="Casi nunca"
                                                formControlName="II{{i+14}}"
                                                (change)="answerTrigger('Casi nunca','3.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+14}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+14}}" id="Nunca{{i+14}}" formControlName="II{{i+14}}" value="Nunca"
                                                formControlName="II{{i+14}}"
                                                (change)="answerTrigger('Nunca','3.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+14}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionThree.length-1" class="hr-m-2">
            </div>
        </div>



        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section4" *ngSwitchCase="3">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.
            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionFour; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+18}}" id="Siempre{{i+18}}" formControlName="II{{i+18}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','4.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+18}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+18}}" id="Casi siempre{{i+18}}" formControlName="II{{i+18}}" value="Casi siempre"
                                                formControlName="II{{i+18}}"
                                                (change)="answerTrigger('Casi siempre','4.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+18}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+18}}" id="Algunas veces{{i+18}}" formControlName="II{{i+18}}" value="Algunas veces"
                                                formControlName="II{{i+18}}"
                                                (change)="answerTrigger('Algunas veces','4.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+18}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+18}}" id="Casi nunca{{i+18}}" formControlName="II{{i+18}}" value="Casi nunca"
                                                formControlName="II{{i+18}}"
                                                (change)="answerTrigger('Casi nunca','4.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+18}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+18}}" id="Nunca{{i+18}}" formControlName="II{{i+18}}" value="Nunca"
                                                formControlName="II{{i+18}}"
                                                (change)="answerTrigger('Nunca','4.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+18}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionFour.length-1" class="hr-m-2">
            </div>
        </div>



        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section5" *ngSwitchCase="4">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Las preguntas siguientes están relacionadas con la capacitación e información que recibe sobre su trabajo.
            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionFive; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+23}}" id="Siempre{{i+23}}" formControlName="II{{i+23}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','5.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+23}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+23}}" id="Casi siempre{{i+23}}" formControlName="II{{i+23}}" value="Casi siempre"
                                                formControlName="II{{i+23}}"
                                                (change)="answerTrigger('Casi siempre','5.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+23}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+23}}" id="Algunas veces{{i+23}}" formControlName="II{{i+23}}" value="Algunas veces"
                                                formControlName="II{{i+23}}"
                                                (change)="answerTrigger('Algunas veces','5.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+23}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+23}}" id="Casi nunca{{i+23}}" formControlName="II{{i+23}}" value="Casi nunca"
                                                formControlName="II{{i+23}}"
                                                (change)="answerTrigger('Casi nunca','5.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+23}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+23}}" id="Nunca{{i+23}}" formControlName="II{{i+23}}" value="Nunca"
                                                formControlName="II{{i+23}}"
                                                (change)="answerTrigger('Nunca','5.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+23}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionFive.length-1" class="hr-m-2">
            </div>
        </div>



        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" id="section6" *ngSwitchCase="5">
            <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                Las preguntas siguientes se refieren a las relaciones con sus compañeros de trabajo y su jefe.


            </div>
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                    Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                    Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div *ngFor="let question of questionsSectionSix; index as i">
                <div class="container">
                    <div class="row ">
                        <div class="row col-lg" [innerHTML]="question">
                        </div>
                        <div class="row col-lg-8 d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II{{i+28}}" id="Siempre{{i+28}}" formControlName="II{{i+28}}" value="Siempre"
                                            (change)="answerTrigger('Siempre','6.'+(i+1))" required>
                                        <span
                                            class="checkmark-radio"></span>

                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre{{i+28}}">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+28}}" id="Casi siempre{{i+28}}" formControlName="II{{i+28}}" value="Casi siempre"
                                                formControlName="II{{i+28}}"
                                                (change)="answerTrigger('Casi siempre','6.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi siempre{{i+28}}">Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+28}}" id="Algunas veces{{i+28}}" formControlName="II{{i+28}}" value="Algunas veces"
                                                formControlName="II{{i+28}}"
                                                (change)="answerTrigger('Algunas veces','6.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Algunas veces{{i+28}}">Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+28}}" id="Casi nunca{{i+28}}" formControlName="II{{i+28}}" value="Casi nunca"
                                                formControlName="II{{i+28}}"
                                                (change)="answerTrigger('Casi nunca','6.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Casi nunca{{i+28}}">Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II{{i+28}}" id="Nunca{{i+28}}" formControlName="II{{i+28}}" value="Nunca"
                                                formControlName="II{{i+28}}"
                                                (change)="answerTrigger('Nunca','6.'+(i+1))" required>
                                            <span
                                                class="checkmark-radio"></span>

                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label for="Nunca{{i+28}}">Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i<questionsSectionSix.length-1" class="hr-m-2">
            </div>
        </div>



<div *ngSwitchCase="6">
        <div class="txt-grey mt-5">
            Las preguntas siguientes están relacionadas con la atencion a clientes y usuarios
        </div>


        <div class="card mt-2 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-cyan text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px"> Sección/Pregunta
                </div>
                <div class="col-6 text-right text-cyan d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px"> Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <form [formGroup]="serviceToCustomersOrUsersForm">
                <div class="container ">
                    <div class="row ">
                        <div class="row col-lg">
                            <div class="col">En mi trabajo debo brindar servicio a clientes o usuarios:</div>
                        </div>
                        <div class="row col-lg-4 d-flex flex-row-reverse ">
                            <div class="col-auto row mx-auto mx-lg-2">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                    <input type="radio" name="option" value="No"  (click)="sendAnswerDisable('serviciocliente','no')" formControlName="option" (change)="servicioaClientes(0)"
                                    >
                                    <span class="checkmark-radio"></span>
                                </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>No</label>
                                </div>
                            </div>
                            <div class="col-auto row mx-auto mx-lg-2">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                    <input type="radio" name="option" value="Si" formControlName="option" (click)="sendAnswerDisable('serviciocliente','si')" (change)="servicioaClientes(true)" >
                                    <span class="checkmark-radio"></span>
                                </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Sí</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>



        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}" [style.color]="blockedText1">
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px"> Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px"> Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div class="container ">
                <div class="row ">
                    <div class="row col-lg pr-0">
                        <div class="col-auto pr-0">41)</div>
                        <div class="col" [ngClass]="{'pr-0': withSidebar}">Atiendo clientes o usuarios muy enojados</div>
                    </div>
                    <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                        <div class="col-lg-auto row">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="II41" value="Siempre" id="Siempre41"  formControlName="II41"
                                        (change)="answerTrigger('Siempre','7.1')" required>
                                    <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked"></span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span
                                            class="checkmark-radio"></span>

                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label for="Siempre41">Siempre</label>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II41" value="Casi siempre" formControlName="II41"
                                            (change)="answerTrigger('Casi siempre','7.1')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi siempre</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II41" value="Algunas veces" formControlName="II41"
                                            (change)="answerTrigger('Algunas veces','7.1')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Algunas veces</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II41" value="Casi nunca" formControlName="II41"
                                            (change)="answerTrigger('Casi nunca','7.1')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi nunca</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II41" value="Nunca" formControlName="II41"
                                            (change)="answerTrigger('Nunca','7.1')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Nunca</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr-m-2">
            <div class="container ">
                <div class="row ">
                    <div class="row col-lg pr-0">
                        <div class="col-auto pr-0">42)</div>
                        <div class="col" [ngClass]="{'pr-0': withSidebar}">Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas
                        </div>
                    </div>
                    <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                        <div class="col-lg-auto row">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="II42" value="Siempre" id="Siempre42"  formControlName="II42"
                                        (change)="answerTrigger('Siempre','7.2')" required>
                                    <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked"></span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span
                                            class="checkmark-radio"></span>

                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label for="Siempre42">Siempre</label>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II42" value="Casi siempre" formControlName="II42"
                                            (change)="answerTrigger('Casi siempre','7.2')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi siempre</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II42" value="Algunas veces" formControlName="II42"
                                            (change)="answerTrigger('Algunas veces','7.2')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Algunas veces</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II42" value="Casi nunca" formControlName="II42"
                                            (change)="answerTrigger('Casi nunca','7.2')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi nunca</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II42" value="Nunca" formControlName="II42"
                                            (change)="answerTrigger('Nunca','7.2')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Nunca</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr-m-2">
            <div class="container ">
                <div class="row ">
                    <div class="row col-lg pr-0">
                        <div class="col-auto pr-0">43)</div>
                        <div class="col" [ngClass]="{'pr-0': withSidebar}">Para hacer mi trabajo debo demostrar sentimientos distintos a los míos</div>
                    </div>
                    <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                        <div class="col-lg-auto row">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="II43" value="Siempre" id="Siempre43"  formControlName="II43"
                                        (change)="answerTrigger('Siempre','7.3')" required>
                                    <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked"></span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span
                                            class="checkmark-radio"></span>

                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label for="Siempre43">Siempre</label>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II43" value="Casi siempre" formControlName="II43"
                                            (change)="answerTrigger('Casi siempre','7.3')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi siempre</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II43" value="Algunas veces" formControlName="II43"
                                            (change)="answerTrigger('Algunas veces','7.3')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Algunas veces</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II43" value="Casi nunca" formControlName="II43"
                                            (change)="answerTrigger('Casi nunca','7.3')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Casi nunca</label>
                                </div>
                            </div>
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II43" value="Nunca" formControlName="II43"
                                            (change)="answerTrigger('Nunca','7.3')" required>
                                        <span *ngIf="!optionalSeccion1; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Nunca</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
<div *ngSwitchCase="7">
        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan">
                <div class="col-lg-6 text-center text-cyan text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px"> Sección/Pregunta
                </div>
                <div class="col-6 text-right text-cyan d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px"> Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <form [formGroup]="bossForm">
                <div class="container ">
                    <div class="row ">
                        <div class="row col-lg">
                            <div class="col">Soy jefe de otros trabajadores:</div>
                        </div>
                        <div class="row col-lg-4 d-flex flex-row-reverse ">
                            <div class="col-auto row mx-auto mx-lg-2">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                    <input type="radio" name="option" value="No" formControlName="option" (click)="sendAnswerDisable('jefe','no')" (change)="soyJefe(0)">
                                    <span class="checkmark-radio"></span>
                                </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>No</label>
                                </div>
                            </div>
                            <div class="col-auto row mx-auto mx-lg-2">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                    <input type="radio" name="option" value="Si" formControlName="option" (click)="sendAnswerDisable('jefe','si')" (change)="soyJefe(true)">
                                    <span class="checkmark-radio"></span>
                                </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label>Sí</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>



        <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
            <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1 text-cyan text-cyan">
                <div class="col-lg-6 text-center text-lg-left">
                    <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px"> Sección/Pregunta
                </div>
                <div class="col-6 text-center d-none d-lg-block">
                    <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px"> Respuesta
                </div>
            </div>
            <hr class="hr-5 mt-0 mb-2">
            <div [style.color]="blockedText2">
                <div class="container ">
                    <div class="row ">
                        <div class="row col-lg pr-0">
                            <div class="col-auto pr-0">44)</div>
                            <div class="col" [ngClass]="{'pr-0': withSidebar}">Comunican tarde los asuntos de trabajo</div>
                        </div>
                        <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II44" value="Siempre" id="Siempre44"  formControlName="II44"
                                            (change)="answerTrigger('Siempre','8.1')" required>
                                        <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span>
                                        <ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre44">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II44" value="Casi siempre" formControlName="II44"
                                                (change)="answerTrigger('Casi siempre','8.1')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II44" value="Algunas veces" formControlName="II44"
                                                (change)="answerTrigger('Algunas veces','8.1')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II44" value="Casi nunca" formControlName="II44"
                                                (change)="answerTrigger('Casi nunca','8.1')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II44" value="Nunca" formControlName="II44"
                                                (change)="answerTrigger('Nunca','8.1')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="hr-m-2">
                <div class="container ">
                    <div class="row ">
                        <div class="row col-lg pr-0">
                            <div class="col-auto pr-0">45)</div>
                            <div class="col" [ngClass]="{'pr-0': withSidebar}">Dificultan el logro de los resultados del trabajo</div>
                        </div>
                        <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II45" value="Siempre" id="Siempre45"  formControlName="II45"
                                            (change)="answerTrigger('Siempre','8.2')" required>
                                        <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span><ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre45">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II45" value="Casi siempre" formControlName="II45"
                                                (change)="answerTrigger('Casi siempre','8.2')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II45" value="Algunas veces" formControlName="II45"
                                                (change)="answerTrigger('Algunas veces','8.2')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II45" value="Casi nunca" formControlName="II45"
                                                (change)="answerTrigger('Casi nunca','8.2')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II45" value="Nunca" formControlName="II45"
                                                (change)="answerTrigger('Nunca','8.2')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="hr-m-2">
                <div class="container ">
                    <div class="row ">
                        <div class="row col-lg pr-0">
                            <div class="col-auto pr-0">46)</div>
                            <div class="col" [ngClass]="{'pr-0': withSidebar}">Ignoran las sugerencias para mejorar su trabajo</div>
                        </div>
                        <div class="row d-flex flex-row-reverse align-items-center" [ngClass]="{'col-lg-7': !withSidebar, 'col-lg-9': withSidebar}">
                            <div class="col-lg-auto row">
                                <div class="col-auto pl-1">
                                    <label class="position-relative">
                                        <input type="radio" name="II46" value="Siempre" id="Siempre46"  formControlName="II46"
                                            (change)="answerTrigger('Siempre','8.3')" required>
                                        <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                            class="checkmark-radio-blocked"></span><ng-template #elseBlockNoBloacked>
                                            <span
                                                class="checkmark-radio"></span>

                                        </ng-template>
                                    </label>
                                </div>
                                <div class="col-auto pl-1 pt-1">
                                    <label for="Siempre46">Siempre</label>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II46" value="Casi siempre" formControlName="II46"
                                                (change)="answerTrigger('Casi siempre','8.3')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi siempre</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II46" value="Algunas veces" formControlName="II46"
                                                (change)="answerTrigger('Algunas veces','8.3')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Algunas veces</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II46" value="Casi nunca" formControlName="II46"
                                                (change)="answerTrigger('Casi nunca','8.3')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Casi nunca</label>
                                    </div>
                                </div>
                                <div class="col-lg-auto row">
                                    <div class="col-auto pl-1">
                                        <label class="position-relative">
                                            <input type="radio" name="II46" value="Nunca" formControlName="II46"
                                                (change)="answerTrigger('Nunca','8.3')" required>
                                            <span *ngIf="!optionalSeccion2; else elseBlockNoBloacked"
                                                class="checkmark-radio-blocked"></span>
                                            <ng-template #elseBlockNoBloacked>
                                                <span
                                                    class="checkmark-radio"></span>

                                            </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-auto pl-1 pt-1">
                                        <label>Nunca</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</div>
<div class="col-12 form-inline px-0 mb-4">
    <div class="col-sm px-0">
    <button class="fs-12 mx-auto mx-sm-0 mr-sm-auto d-block btn-secondary" (click)="goBack()" *ngIf="changeSection>0">Sección anterior</button>
    </div>
    <div class="col-sm px-0 mt-3 mt-sm-0">
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===0" [disabled]="test2.controls['II'+1].invalid || test2.controls['II'+2].invalid || test2.controls['II'+3].invalid || test2.controls['II'+4].invalid || test2.controls['II'+5].invalid || test2.controls['II'+6].invalid || test2.controls['II'+7].invalid || test2.controls['II'+8].invalid || test2.controls['II'+9].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===1" [disabled]="test2.controls['II'+10].invalid || test2.controls['II'+11].invalid || test2.controls['II'+12].invalid || test2.controls['II'+13].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===2" [disabled]="test2.controls['II'+14].invalid || test2.controls['II'+15].invalid || test2.controls['II'+16].invalid || test2.controls['II'+17].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===3" [disabled]="test2.controls['II'+18].invalid || test2.controls['II'+19].invalid || test2.controls['II'+20].invalid || test2.controls['II'+21].invalid || test2.controls['II'+22].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===4" [disabled]="test2.controls['II'+23].invalid || test2.controls['II'+24].invalid || test2.controls['II'+25].invalid || test2.controls['II'+26].invalid || test2.controls['II'+27].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===5" [disabled]="test2.controls['II'+28].invalid || test2.controls['II'+29].invalid || test2.controls['II'+30].invalid || test2.controls['II'+31].invalid || test2.controls['II'+32].invalid || test2.controls['II'+33].invalid || test2.controls['II'+34].invalid || test2.controls['II'+35].invalid || test2.controls['II'+36].invalid || test2.controls['II'+37].invalid || test2.controls['II'+38].invalid || test2.controls['II'+39].invalid || test2.controls['II'+40].invalid">Siguiente sección</button>
    <button class="fs-12 btn-primary mx-auto mx-sm-0 ml-sm-auto d-block" (click)="nextSection()" *ngIf="changeSection===6" [disabled]="serviceToCustomersOrUsersForm.controls['option'].invalid || serviceToCustomersOrUsersForm.controls['option'].value ==='n/s'">Siguiente sección</button>
    <button [disabled]="test2.invalid || serviceToCustomersOrUsersForm.controls['option'].value ==='n/s' || bossForm.controls['option'].value ==='n/s'" (click)="nextQuest()" class="fs-12 btn-secondary mx-auto mx-sm-0 ml-sm-auto d-block" *ngIf="visible && changeSection===7">Continuar</button>
</div>
</div>
    </div>
    </form>
</div>
