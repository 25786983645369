import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { activities, Activity } from '../record/record.conts';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { proposals } from '@pmo/projects/projects.const';
import { catalogActivities } from './activities.const';
import { Comment } from '@pmo/projects/project/project.const';
import { leaders } from '@pmo/teams/team/team.const';
import { TimeService } from 'src/app/services/time/time.service';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { LogService } from 'src/app/services/log/log.service';
import { isFuture } from 'date-fns';

interface Activities {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-register-hours',
  templateUrl: './register-hours.component.html',
  styleUrls: ['./register-hours.component.scss']
})

export class RegisterHoursComponent {
  public registryForm!: FormGroup;
  public activityDate = new Date();
  public timeAccumulated = {
    hours: 0,
    minutes: 0
  }
  idUser = '';
  public catActivities = []
  public types = [] // ['Proyecto 1', 'Proyecto 2', 'Proyecto 3', 'Proyecto 3'];
  public readonly breadcrumb: Breadcrumb[] = [
    CRUMBS.colaboratorRegisterActivities
  ];
  public activities: Activity[] = activities.slice()
  public displayedColumns: string[] = ['date', 'activity', 'type', 'duration', 'description', 'status', 'options'];
  public activitiesDataSource!: MatTableDataSource<Activity>;
  public isEdit: boolean = false;
  public readonly commentsExample: Comment[] = [
    {
        userAvatar: leaders[5].avatar,
        userName: leaders[5].name,
        createdAt: new Date('11/10/2024'),
        comment: 'Gracias por compartirme tu informe, John. Tengo algunos comentarios. ¿Qué estamos haciendo para que las dependencias no atropellen el avance del proyecto? Espero tu respuesta.',
        responses: [
            {
                userAvatar: leaders[0].avatar,
                userName: leaders[0].name,
                createdAt: new Date(),
                comment: 'Estamos reportando las dependencias por mail con el cliente. Sin embargo, no ha dado respuesta aún. En cuanto quede continuamos con esa parte. Saludos.',
            }
        ]
    }
  ]
  // Mobile
  public currentCard = 0;
  public card: Activity | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private timeService: TimeService,
    private oportunityService: OportunityService,
    private logService: LogService
  ) {}

  ngOnInit(): void {
    this.evaluateTime();

    this.idUser = sessionStorage.getItem('idUser')
    this.registryForm = this.formBuilder.group({
      activity: [
        null,
        Validators.required
      ],
      code: [
        null,
        Validators.compose([Validators.required])
      ],
      mins: [
        0,
        Validators.compose([Validators.required, Validators.min(0), Validators.max(59)])
      ],
      hours: [
        0,
        Validators.compose([Validators.required, Validators.min(0), Validators.max(24)])
      ],
      description: [
        '',
        {
          updateOn: 'blur',
          validators: Validators.compose([Validators.required, Validators.minLength(56), Validators.maxLength(112)])
        }
      ],
      status: [
        ''
      ]
    }, { validators: this.validHoursMinutes() } );

    this.loadTableActivities();

    const activityId = this.route.snapshot.paramMap.get('id');
    if (activityId) this.loadActivityForm(activityId);

    // Cambiar crumb
    let crumb = CRUMBS.colaboratorRegisterActivity;
    if (activityId) {
      crumb = { ...CRUMBS.colaboratorUpdateActivity };
      crumb.route += `/${activityId}`;
    }
    this.breadcrumb.push(crumb);

    this.timeService.getTimes().subscribe(data=>{
      this.catActivities = data.filter(objeto => !objeto.name.includes('INCIDENCIA'));
    })

    this.oportunityService.getOportunitiesByEmployee(this.idUser).subscribe(data=>{
      this.types = data
    })
  }

  private validHoursMinutes() {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const hours = Number(formGroup.get('hours')?.value ?? 0);
      const minutes = Number(formGroup.get('mins')?.value ?? 0);
  
      return hours > 0 || minutes > 0 ? null : { validHoursMinutes: true };
    };
  }

  private evaluateTime() {
    const timeParam = this.route.snapshot.paramMap.get('time') ?? '';
    if (timeParam.length !== 13) return;

    const time = Number(timeParam ?? 0);
    if (time <= 0) return;

    const newDate = new Date(time);
    if (isFuture(newDate)) return;

    this.activityDate = new Date(time);
  }

  private loadActivityForm(activityId: string) {
    const activity = this.activities.find((activity) => activity.id === activityId);
    if (!activity) {
      this.router.navigateByUrl(CRUMBS.colaboratorRegisterActivity.route);
      return;
    };

    this.isEdit = true

    this.registryForm.patchValue({
      activity: activity.activity,
      code: activity.type,
      mins: activity.duration.minutes,
      hours: activity.duration.hours,
      description: activity.description,
      status: activity.status,
    });
    this.registryForm.updateValueAndValidity();

    this.activityDate = new Date(activity.date.getTime());
  }

  private loadTableActivities() {
    this.timeAccumulated = {
      hours: 0,
      minutes: 0
    }
    const activitiesTemplate = this.activities.slice().map((activity) => {
      let hours = activity.duration.hours + this.timeAccumulated.hours;
      let minutes = activity.duration.minutes + this.timeAccumulated.minutes;
      if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
      }
      this.timeAccumulated = { hours, minutes }

      return {
        ...activity,
        activity: catalogActivities.find((cat) => cat.value === activity.activity)?.viewValue ?? 'Desconocido',
        type: proposals.find((prop) => prop.code === activity.type)?.title ?? 'Desconocido',
      };
    });
    this.activitiesDataSource = new MatTableDataSource(activitiesTemplate);

    this.card = activitiesTemplate[this.currentCard]; // Mobile
  }

  public registryHasError(controlName: string) {
    return this.registryForm.controls[controlName]?.invalid && (this.registryForm.controls[controlName]?.dirty || this.registryForm.controls[controlName]?.touched);
  }

  public managementHours(incrementValue: 1 | -1 | 0 = 0) {
    let newValue = Number(this.registryForm.controls['hours'].value ?? 0) + incrementValue;

    if (newValue < 0) return;

    if (newValue >= 24) {
      newValue = 24;
      this.registryForm.controls['mins'].setValue(0);
      this.registryForm.controls['mins'].disable();
    } else if (this.registryForm.controls['mins'].disabled) {
        this.registryForm.controls['mins'].enable();
    }
    this.registryForm.controls['hours'].setValue(newValue);
  }

  public managementMinutes(incrementValue: 1 | -1 | 0 = 0) {
    let newValue = Number(this.registryForm.controls['mins'].value ?? 0) + incrementValue;

    if (newValue < 0) return;

    if (newValue >= 60) {
      newValue = 0;
      this.managementHours(1)
    }
    this.registryForm.controls['mins'].setValue(newValue);
  }

  public sendActivity(formDirective: FormGroupDirective) {
    const formValue = this.registryForm.value;

    let oportunity = this.types.filter(a=>{
      return a.code === this.registryForm.get('code')?.value
    })
    let newActivity = {
      collaborator: this.idUser,
      date: this.activityDate,
      time: formValue.activity,
      oportunity: oportunity[0].name,
      oportunityCode: formValue.code,
      activities: formValue.description,
      hours: Number(formValue.hours) ?? 0,
      minutes: Number(formValue.mins) ?? 0,
      status: 'pending'
    }

    this.logService.createLog(newActivity).subscribe(res=>{
      if(res){
        // this.activities.push(newActivity)
        this.loadTableActivities(); 
        const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '464px',
        data: {
          icon: 'status_approved',
          warn: false,
          title: 'Actividad enviada',
          description: 'Tu reporte de horas ha sido actualizado. ¿Deseas registrar otra actividad?',
          close: 'No agregar',
          confirm: 'Sí, agregar otra'
        } as ConfirmDialogData,
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          if (this.isEdit) {
            this.router.navigateByUrl('/register');
          } else {
            formDirective.resetForm();
            this.registryForm.reset();
          }
        } else {
          this.router.navigateByUrl('/hours-employee');
        }
  
      });
      }
    })

  }

  public cancelActivity() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_question',
        warn: false,
        title: '¿Estás seguro?',
        description: 'Al cancelar el registro de esta actividad, no podrás guardar los cambios.',
        close: 'Salir sin guardar',
        confirm: 'Seguir editando'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.router.navigateByUrl('/hours-employee');
      }
    });
  }

  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.activities[indexCard];
    if (card) { 
      this.currentCard = indexCard;
      this.card = card;
    }
  }
}