import { Component, Input } from '@angular/core';
import { About } from '../project.const';

@Component({
  selector: 'app-about[about]',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  @Input() about!: About;

}
