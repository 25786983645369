<div>
  <div class="modal-header pb-0" style="border-bottom:none">
    <button type="button" class="close"  aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
 
<div class="content-modal d-flex justify-content-center align-items-center flex-column ">
  <div class="icon-main">
    <span class="time-icon-status_question fs-40 icon-main" *ngIf="data.fluxQuestion"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
  <span class="time-icon-status_approved fs-40 icon-main" *ngIf="!data.fluxQuestion"><span class="path1"></span><span class="path2"></span></span>

  </div>
  <p class="title">{{data.fluxQuestion ? '¿Estás seguro?' : '¡Perfecto!'}} </p>
  <div mat-dialog-content class="dialog-content py-0 mb-3">
    <p class="body-text">{{data.message}}</p>
  </div>
  <div mat-dialog-actions>
    <button (click)="cancel()" *ngIf="data.fluxQuestion" class="general-secondary mr-2">Cancelar</button>
    <button  (click)="confirm()"  *ngIf="data.fluxQuestion" class="general-primary">Crear {{data.typeOportunity}} </button>
    <button  (click)="cancel()"  *ngIf="!data.fluxQuestion" class="general-primary">Completar registro </button>
  </div>
</div>
</div>