export interface IComplaintBoxModel {
  idCompany: string;
  comments: ICommentsModel;
}

export interface ICommentsModel {
  id?: string;
  priority?: boolean;
  code?: string;
  expires?: Date;
  date?: Date;
  names?: string;
  surnames?: string;
  secondSurname?: string;
  email?: string;
  workcenter?: string;
  area?: string;
  comment?: string;
  category?: EComplaintCategories;
  status?: 'Nuevo' | 'En seguimiento' | 'Finalizado';
  actions?: string;
  employeeId?: string;
  jobDescription?: string;
  finalizedDate?:Date;
}
/**
 * Identificadores para flujo de creacion de queja sugerencia
 */

export enum EComplaintStages {
  WELCOME,
  FOLIO,
  CATEGORY,
  PERSONAL_DATA,
  SUCCESS,
}

export enum EComplaintCategories {
  POSITIVE_LIDERSHIP = 1,
  WORKPLACE_SUGGESTION,
  WORPLACE_VIOLENCE,
  WORKPLACE_COMPLAINT,
  TRAUMATIC_EVENT,
}
export enum EComplaintCategoriesDesc {
  'Reconocimiento a liderazgo positivo' = 1,
  'Práctica a favor de un ambiente laboral favorable',
  'Violencia laboral',
  'Práctica contra un ambiente laboral favorable',
  'Acontecimiento traumático severo',
}
