import { Injectable } from '@angular/core';
/**Router */
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../../models/users/users.model';
import { HttpService } from '../http-service/http.service';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { ResponseContent } from '../../models';
import { notificationModel } from 'src/app/models/user/user.model';


@Injectable({
  providedIn: 'root'
})
export class UsersService {


  constructor(
    private http: HttpService
  ) { }

  // Observable para obtener todos los usuarios
  allUsers = new BehaviorSubject<UserModel[]>([]);

  //Observable de notificaciones
  notifications = new BehaviorSubject<notificationModel[]>([]);

  // Permisos de usuarios
  permissions = new BehaviorSubject<any>([]); // modelar los permisos
  // permisos del usuario logeado
  userPermissions = new BehaviorSubject<any>([]);

/**
 * funcion para validar disponiblidad de usuario
 *
 * @param {*} email
 * @returns
 * @memberof UsersService
 */
validateUser(email){

  Swal.fire({title: 'Cargando', text: 'Generando orden de compra',
    allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false});
  Swal.showLoading();

  return this.http.post('/users/validateUser', { email }, true).pipe(map((res: ResponseContent) => {
    if (res && res.clave === 'OK') {
      return res;
    }
  }));
  }

  /**
   * Obtiene todos los usuarios
   */
  getAllUsers(idCompany): Observable <ResponseContent>{
    return this.http.post('/users/getUsers', { idCompany }, true).pipe(map((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        this.allUsers.next(response.content);
        return response;
      }
    }));
  }
  /**
   * Obtiene los permisos de todos los usuarios
   */
  getPermissions(users: UserModel[] | any[]): void {
    this.http.post('/users/getPermissions', users, true).subscribe((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        this.permissions.next(response.content);
      }
    });
  }

  /**
   *Obtener info de un Usuario
   *
   * @param {string} id
   * @returns
   * @memberof UsersService
   */
  getUser(_id: string){
    return this.http.post('/users/getUser', {_id}, true).pipe(map((res:any) => {
      return res;
    }));
  }
  /**
   *Función para actualizar datos de usuarios
   *
   * @param {*} id
   * @param {*} object
   * @returns
   * @memberof UsersService
   */
  updateUser( object){
    return this.http.post('/users/updateUser', {object}, true);
  }
  /**
   *Función para eliminar usuario
   *
   * @param {string} id
   * @returns
   * @memberof UsersService
   */
  deleteUser(id:string){
    return this.http.post('/users/deleteUser', {id: id}, true);
  }

  /**
   *Busqueda de imágen  en 64 bits por número de empleado
   *
   * @param {*} id
   * @param {UserModel[]} user
   * @returns {string}
   * @memberof UsersService
   */
   searchImg(id, user: UserModel[]): string {
    let img64 = '';
    for (const item of user){
      if(item._id.toString() === id.toString()){
        img64 = item.profilePicture64;
      }
      return img64;
    }
  }

  /**
   *Método para obtener la imágen del usuario
   *
   * @param {UserModel[]} array
   * @returns {UserModel[]}
   * @memberof UsersService
   */
  getImageUser(array: UserModel[]): UserModel[] {
    if(array.length > 0){
      for(const user  of array){
        if(user.profilePicture){
          const json={
            folder:'profileImage',
            image: `${user.profilePicture}`
          };
          this.http.post('/file/getFile', json, true).subscribe((resp: any) => {
            if(resp  && resp.clave == 'ok'){
              user.profilePicture64 = `data:image/${user.profilePicture.replace('.', '')};base64,${resp.content}`;
            }
          })
        }
      }
    }
    return array
  }

  /**
   *Método que recibe el nombre  del usuario por servicio
   *
   * @param {*} nameImage
   * @returns
   * @memberof UsersService
   */
  getImage(nameImage){
    return this.http.post('/getImage', {id: nameImage}).pipe(map((value:any) =>{
      if( value.clave === 'ok'){
        return value.content
      }else{
        return false;      }
    }))
  }

  /**
   *Obtiene la imágen del usuario n sesión
   *
   * @param {*} image
   * @returns
   * @memberof UsersService
   */
  getImageUserCurrent(image: any){
    if(image){
      const json ={
        folder:'profileImage',
        image:`${image.id}${image.ext}`
      };
      return this.http.post('/file/download', json, true).pipe(map(
        (resp: any) => {
          if (resp && resp.clave === 'OK') {
            return `data:image/${image.ext.replace('.', '')};base64,${resp.content}`;
          }
      }
      ));
    }
  }

  getNotifications(idUser, limit: number){
    return this.http.post('/users/getNotifications', { idUser, limit}, true).pipe(
      map((response: ResponseContent) => {
          if(response.clave === 'OK'){
            this.notifications.next(response.content.notifications);
          }
        }
      )
    );
  }

  getNotificationsByCompany(idUser, limit: number, idCompany){
    return this.http.post('/users/getNotificationsByCompany', { idUser, limit,idCompany}, true).pipe(
      map((response: ResponseContent) => {
          if(response.clave === 'OK'){
            this.notifications.next(response.content.notifications);
          }
        }
      )
    );
  }

  viewNotifications(){
    const idUser = sessionStorage.getItem('idUser');
    return this.http.post('/users/viewNotifications', {idUser}, true).pipe(
      map((response: ResponseContent) => {}
      )
    );
  }

  viewNotification(idNotification:string){
    const idUser = sessionStorage.getItem('idUser');
    return this.http.post('/users/viewNotification', {idUser,idNotification: idNotification}, true).pipe(
      map((response: ResponseContent) => {}
      )
    );
  }

  changePass(oldPass:string, newPass:string, idComp): Observable <Boolean>{
    return this.http.post('/users/changePass',{oldPass,newPass, idComp}, true).pipe(map((response: ResponseContent) => {
        return (response && response.clave === 'OK');
    }));
  }

}
