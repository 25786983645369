/** Angular Core */
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Servicio de modal genérico
 *
 * @export
 * @class ModalService
 */
@Injectable({providedIn: 'root'})
export class ModalService {

  //obserbable para el mensaje
  alertModal = new BehaviorSubject<any>({
    numCase: 0,
    data: {
      title: "",
      message: "",
      module: "",
      action: "",
      includeButtonNo:false
    }
  });
    

  /**
   * 
   */
  constructor() {}

  /**
   * Modal de éxito
   * @param data 
   */
  successful(data:any){
    data = this.validateData(data); 

    this.alertModal.next({
      numCase: 1, 
      data
    });

  }
  
  /**
   * Modal de advertencia
   * @param data 
   */
  warning(data:any){
    data = this.validateData(data);

    this.alertModal.next({
      numCase:2, 
      data  
    });
  }

  /**
   * Modal de error
   * @param data 
   */
  error(data:any){
    data = this.validateData(data);

    this.alertModal.next({
      numCase:3, 
      data
    });
  }

  /**
   * Modal de alerta de error en el sistema
   */ 
  errorInTheSystem(data:any){
    data = this.validateData(data);

    this.alertModal.next({
      numCase:4,
      data
    });
  }

  /**
   * Modal de alerta de error de conexion
   */ 
  connectionError(){
    this.alertModal.next({
      numCase:5
    });
  }

  /**
   * Cierra el modal abierto
   */ 
  close(){
    this.alertModal.next({
      numCase:0
    });
  }

  /**
   * Coloca los campos vacíos si no recibe el parámetro
   * @param data 
   */
  private validateData(data){
    data.title = data.title     == undefined ?"":data.title;
    data.message = data.message == undefined ?"":data.message;
    data.module = data.module   == undefined ?"":data.module;
    data.action = data.action   == undefined ?"":data.action;
    data.includeButtonNo = data.includeButtonNo == undefined ? false: data.includeButtonNo;
    return data;
  }

}
