import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { log } from 'console';
import { AccessService } from 'src/app/services/access/access.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalOportunityComponent } from '../comercial/modal-oportunity/modal-oportunity.component';
import { UsersService } from 'src/app/services/users/users.service';
import { TimeService } from 'src/app/services/time/time.service';
import { TeamService } from 'src/app/services/team/team.service';
import { EmployeesService } from 'src/app/services';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { LogService } from 'src/app/services/log/log.service';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { MatSelect } from '@angular/material/select';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { Router } from '@angular/router';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
declare var bootstrap: any;
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-oportunity',
  templateUrl: './oportunity.component.html',
  styleUrls: ['./oportunity.component.scss']
})


export class OportunityComponent {
  allOportunities: [any];
  @ViewChild('modalChangeStatus') modalChangeStatus!: ElementRef;
  isNew = true;
  locales = listLocales();
  selectedCityCodes: string[];
  existDetail = false;
  modalFlux = '';

  originalCategory: any;
  usersByCompany: any;
  comercialForm!: FormGroup;
  toppings = new FormControl('');
  controlsPayment = ['dateApprove', 'dateOrderBuy', 'currency', 'amount', 'price', 'agreedPayments', 'dateProcessPayment', 'typePayment', 'paymentDocument', 'costCenter', 'billingFrequency', 'dateInitBilling', 'totalHoursQuoted'];
  controlsAsignacion: ['dateInitAsign', 'dateEndAsign', 'technicalLead', 'realHour'];
  controlsProyecto: ['dateInitProyect', 'dateEndProyect', 'typeProyect', 'metodology', 'technology', 'timeGaranty', 'proyectLead'];
  teamsList: any;
  catActivities: any;
  myControl = new FormControl();
  options = [];
  optionsStaff = [];
  filteredOptions: Observable<any>;
  selectedOption: any = null;
  activeOportunityEditing: any;
  modelSelected: any = null;

  @ViewChild('authorizedStaffSelect') authorizedStaffSelect: MatSelect;

  //controls a cambiar estatus de acuerdo a radio facturable
  constructor(private localeService: BsLocaleService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private accessService: AccessService,
    private router: Router,

    private userService: UsersService,
    private timeService: TimeService,
    private teamService: TeamService,
    private oportunityService: OportunityService,
    private cdr: ChangeDetectorRef,
    private employeeService: EmployeesService
  ) {
    this.oportunityService.getOportunities().subscribe((data) => {
      this.allOportunities = data;
      const idOp = this.accessService.opportunityActiveIndex.value;
      if (idOp !== null && data.length !== 0) {
        this.activeOportunityEditing = this.allOportunities.filter(oportunity => oportunity._id === idOp)[0];
        this.comercialForm.patchValue(this.activeOportunityEditing);
        this.originalCategory = this.comercialForm.get('category')?.value;
        this.isNew = false;
      }
    });
    this.teamService.getTeams().subscribe((data) => {
      this.teamsList = data;
    })
    this.timeService.getTimes().subscribe(data => {
      const filteredItems = data.filter(item => !item?.name?.toLowerCase().includes("incidencia"));

      this.catActivities = filteredItems;
    })
    const idCompany = sessionStorage.getItem('idCompanySelected')
    this.employeeService.getAllCompanyEmployees(idCompany).subscribe((data: any) => {
      this.usersByCompany = data;
      if (!this.isNew) {
        const activeUserSelected = this.usersByCompany?.find(usuario => usuario.idUser === this.comercialForm.get('responsable2bcore')?.value);
        setTimeout(() => {
          this.onOptionSelected(
            {
              name: `${activeUserSelected?.names} ${activeUserSelected?.surnames} ${activeUserSelected?.secondSurname}`,
              image: activeUserSelected?.image,
              _id: activeUserSelected?.idUser
            }
          );
        });
      }
      this.usersByCompany.forEach((user: any) => {
        const addUser = {
          name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
          image: user.image,
          _id: user.idUser
        }
        this.options.push(addUser);
        this.optionsStaff.push(addUser);

      });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),  // Comienza con un valor vacío para activar el Autocomplete inmediatamente
        map(value => this._filter(value || ''))
      );
      setTimeout(() => {
        const IdsStaff = this.comercialForm.get('authorizedStaff')?.value;
        const idsSet = new Set(IdsStaff);
        const staffData = this.usersByCompany
          .filter((user: any) => idsSet.has(user.idUser))
          .map((user: any) => ({
            name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
            image: user.image,
            _id: user.idUser
          }));
        this.comercialForm.get('authorizedStaff').setValue(staffData);
        this.cdr.detectChanges();
        this.comercialForm.updateValueAndValidity();

        // Forzar actualización del estado del mat-select
      }, 300)
    });
    this.localeService.use('es');
    this.comercialForm = this.fb.group({
      category: ['Propuesta comercial', Validators.required],
      name: ['', Validators.required],
      code: ['', Validators.required],
      client: ['', Validators.required],
      responsableClient: ['', Validators.required],
      time: ['', Validators.required],
      viabilityEnd: ['', Validators.required],
      lastContact: ['', Validators.required],
      statusOportunity: ['', Validators.required],
      responsable2bcore: ['', Validators.required],
      accessTeam: ['', Validators.required],
      authorizedStaff: ['', Validators.required],
      //datos para oportunidad aceptada
      dateApprove: [''],
      dateOrderBuy: [''],
      currency: [''],
      amount: [''],
      price: [''],
      agreedPayments: [''],
      dateProcessPayment: [''],
      typePayment: [''],
      paymentDocument: [''],
      costCenter: [''],
      billingFrequency: [''],
      dateInitBilling: [''],
      totalHoursQuoted: [''],
      // datos para rechazo
      commentDecline: [''],
      // datos para reactivacion en standby
      reactivationDay: [''],
      // datos para cuando es asignacion
      dateInitAsign: [''],
      dateEndAsign: [''],
      technicalLead: [''],
      realHour: [''],
      //datos para cuando es proyecto
      dateInitProyect: [''],
      dateEndProyect: [''],
      typeProyect: [],
      metodology: [''],
      technology: [''],
      timeGaranty: [''],
      proyectLead: []

    });
  }
  // Alterna la selección de un chip
  toggleSelection(option: any): void {
    option.selected = !option.selected;
  }
  ngOnInit() {



  }

  // Función para mostrar el nombre en el input después de seleccionar la opción
  displayFn(option: any): string {
    return option ? option.name : '';
  }

  // Método para manejar la opción seleccionada
  onOptionSelected(option: any) {
    this.selectedOption = option;
    this.comercialForm.get('responsable2bcore').setValue(option.name);
    this.cdr.detectChanges();
    this.comercialForm.updateValueAndValidity();
  }

  private _filter(name: string): any[] {
    const filterValue = name?.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  remove(option: any): void {
    const currentValues = this.comercialForm.get('accessTeam').value as string[];
    const index = currentValues.indexOf(option);
    if (index >= 0) {
      currentValues.splice(index, 1);
      this.comercialForm.get('accessTeam').setValue(currentValues); // Actualizamos el valor del FormControl
    }
  }
  removeUserChip(option: any): void {
    const currentValues = this.comercialForm.get('authorizedStaff').value as any[];
    const index = currentValues.indexOf(option);
    if (index >= 0) {
      currentValues.splice(index, 1);
      this.comercialForm.get('authorizedStaff').setValue(currentValues); // Actualizamos el valor del FormControl
    }
  }
  onSubmit() {
    if (this.isNew) {
      this.existDetail = true;
      const formData = this.comercialForm.value;
      const staff: any = [];
      formData.authorizedStaff.forEach((data: any) => {
        staff.push(data._id)
      });
      formData.authorizedStaff = staff;

      const objectToSave: any = {
        accessTeam: formData.accessTeam,
        authorizedStaff: formData.authorizedStaff,
        category: formData.category,
        client: formData.client,
        code: formData.code,
        lastContact: formData.lastContact,
        name: formData.name,
        responsable2bcore: this.selectedOption._id,
        responsableClient: formData.responsableClient,
        statusOportunity: formData.statusOportunity,
        time: formData.time,
        viabilityEnd: formData.viabilityEnd
      }
      this.oportunityService.createOportunity(objectToSave).subscribe((data) => {
        this.showSuccesDialog(false);
      });
    } else {
      //this.localOportunities.splice(this.accessService.opportunityActiveIndex.value,1);
      const formData = this.comercialForm.value;
      const staff: any = [];
      formData.authorizedStaff.forEach((data: any) => {
        staff.push(data._id)
      });
      formData.authorizedStaff = staff;
      formData.responsable2bcore = this.selectedOption._id;
      Object.keys(formData).forEach((key) => {
        if (formData[key] === null || formData[key] === undefined || formData[key] === "") {
          delete formData[key];
        }
      });
      let objectToSave: any = {
        id: this.activeOportunityEditing._id,
        body: formData
      }
      this.oportunityService.updateOportunity(objectToSave).subscribe((data) => {
        this.showSuccesDialog(true);
      });

      //localStorage.setItem('comercialForm', JSON.stringify(this.localOportunities));
    }


  }

  validateStatus(event: any) {
    const selectedValue = event.value;
    if (selectedValue === 'Aceptada') {
      this.controlsPayment.forEach((control: any) => {
        this.comercialForm.addControl(control, new FormControl('', Validators.required));
        this.comercialForm.removeControl('commentDecline');

      })
    } else if (selectedValue === 'Cerrada') {
      this.openModal()
      this.modalFlux = 'cerrada';
      this.controlsPayment.forEach((control: any) => {
        this.comercialForm.removeControl(control);
      })
      this.comercialForm.addControl('commentDecline', new FormControl('', Validators.required));
    }
    else if (selectedValue === 'Standby') {
      this.openModal()
      this.modalFlux = 'standBy';
      this.controlsPayment.forEach((control: any) => {
        this.comercialForm.removeControl(control);
        this.comercialForm.removeControl('commentDecline');
      })
      this.comercialForm.addControl('reactivationDay', new FormControl('', Validators.required));
    }
    else {
      this.controlsPayment.forEach((control: any) => {
        this.comercialForm.removeControl(control);
        this.comercialForm.removeControl('commentDecline');
        this.comercialForm.removeControl('reactivationDay');
      })
    }
  }
  openModal() {
    const modalElement = this.modalChangeStatus.nativeElement;
    const modalInstance = new bootstrap.Modal(modalElement); // Crea la instancia del modal
    modalInstance.show(); // Muestra el modal
  }

  closeModal() {
    const modalElement = this.modalChangeStatus.nativeElement;
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }
  onCategoryChange(): void {
    const newCategory = this.comercialForm.get('category')?.value;

    if (newCategory !== this.originalCategory) {
      const dialogRef = this.dialog.open(ModalOportunityComponent, {
        data: {
          message: `Estás por crear un${newCategory !== 'Proyecto' ? 'a' : ''} ${newCategory.toLowerCase()} a partir de la propuesta ${this.comercialForm.get('name').value.toLowerCase()} ¿Deseas continuar?`,
          typeOportunity: newCategory.toLowerCase(),
          fluxQuestion: true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.originalCategory = newCategory;
          const dialogRefSuccess = this.dialog.open(ModalOportunityComponent, {
            data: {
              message: `${this.comercialForm.get('name').value.toLowerCase()} de ${this.comercialForm.get('client').value.toLowerCase()} ahora es un${newCategory !== 'Proyecto' ? 'a' : ''} ${newCategory.toLowerCase()}. Por favor, completa el registro para continuar.`,
              typeOportunity: newCategory.toLowerCase(),
              fluxQuestion: false
            }
          });
        } else {
          this.comercialForm.get('category')?.setValue(this.originalCategory);
        }
      });
    }
  }
  validateCategory(event: any) {
    const selectedValue = event.value;
    if (selectedValue === 'Asignación') {
      this.controlsAsignacion.forEach((control: any) => {
        this.comercialForm.addControl(control, new FormControl('', Validators.required));

      })
    } else if (selectedValue === 'Proyecto') {
      this.controlsProyecto.forEach((control: any) => {
        this.comercialForm.addControl(control, new FormControl('', Validators.required));

      })
    }
  }

  public cancelNewOportunity() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_question',
        warn: false,
        title: '¿Estás seguro?',
        description: 'Al cancelar el registro de esta asignación, no podrás guardar los cambios.',
        close: 'Salir sin guardar',
        confirm: 'Seguir editando'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.router.navigateByUrl('/comercial');
      }
    });
  }

   showSuccesDialog(isUpdate: boolean) {
    const msgAction = isUpdate == true ? 'actualizada' : 'creada'
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_approved',
        warn: false,
        title: '¡Perfecto!',
        description: `La nueva propuesta comercial ha sido ${msgAction} correctamente.`,
        close: 'Salir',
        confirm: 'Ver detalles'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
       // TODO: route ver detalles : result === true 
      const route = result === true ? '/comercial' : '/comercial';
      this.router.navigateByUrl(route);
    });
  }

  private showErrorDialog() {
    this.dialog.open(AlertComponent, {
      width: '464px',
      data: {
        icon: 'status_rejected',
        title: '¡Error!',
        description: 'No ha sido posible crear la propuesta comercial. Inténtalo de nuevo.',
        close: 'Aceptar',
      }
    });
  }
}
