import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { log } from 'console';



@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphanumericDirective {
  @Input('appAlphaNumeric') pipeParams: string = ''; // Recibe los parámetros como un string

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const [caseTransform, type] = this.parseParams(this.pipeParams);

    // Aplicar el filtro según los parámetros
    let filteredValue = value;
    if (type === 'alphanumeric') {

      filteredValue = filteredValue.replace(/[^a-zA-Z0-9]/g, ''); // Solo alfanuméricos
    } else if (type === 'alphabetic') {
      
      filteredValue = filteredValue.replace(/[^a-zA-Z]/g, ''); // Solo letras
    }

    // Aplicar transformación de mayúsculas o minúsculas
    if (caseTransform === 'uppercase') {
      filteredValue = filteredValue.toUpperCase();
    } else if (caseTransform === 'lowercase') {
      filteredValue = filteredValue.toLowerCase();
    }

    // Actualizar el valor del input
    this.el.nativeElement.value = filteredValue;
  }

  // Método para parsear los parámetros
  private parseParams(params: string): ['uppercase' | 'lowercase' | 'none', 'alphabetic' | 'alphanumeric'] {
    const parts = params.split(',').map(p => p.trim());
    const caseTransform = parts[0] === 'uppercase' || parts[0] === 'lowercase' ? parts[0] : 'none';
    const type = parts[1] === 'alphabetic' || parts[1] === 'alphanumeric' ? parts[1] : 'alphanumeric';
    return [caseTransform as 'uppercase' | 'lowercase' | 'none', type as 'alphabetic' | 'alphanumeric'];
  }

}
