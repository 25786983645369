import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
import { LogService } from 'src/app/services/log/log.service';

export interface DialogData {
  employeeId: string
  employeeName: string,
  activityId: string
}

@Component({
  selector: 'app-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss']
})
export class RejectDialogComponent {
  comment = new FormControl('', [Validators.required, Validators.min(5), Validators.max(112)]);

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<RejectDialogComponent>,
    private log: LogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }


  onSubmit() {
    const object = {
      id: this.data.activityId,
      body: {
        status: 'rejected',
        Leaderfeedback: this.comment.value
      }
    }
    let alertData: AlertDialogData;

    this.log.updateLogs(object).subscribe(res=>{
      if(res){
        alertData = {
          icon: 'status_send',
          title: 'Comentarios enviados',
          description: `Has enviado un comentario a ${ this.data.employeeName } sobre esta actividad. En breve, le informaremos.`,
          close: 'Cerrar',
        }
        this.dialogRef.close(); // Close this modal
      }else{
        alertData = {
          icon: 'status_rejected',
          title: '¡Error!',
          description: 'No ha sido posible enviar el comentario. Inténtalo de nuevo.',
          close: 'Aceptar',
        }
      }

      this.dialog.open(AlertComponent, {
        width: '464px',
        data: alertData
      });
    })

  }
}
