<div class="container mt-3 mb-5">
    <div class="container mt-2 mb-4 mx-auto">
        <div class="row">
            <a href="#" [routerLink]="['/home/welcome']">
                <img src="assets/img/logos/01.svg" class="logo mr-2 " width="124px" height="40px">
            </a>
         <div class="col-lg d-lg-flex justify-content-end">
           <div class="col-lg-4 pr-0">
            <div class="row d-flex justify-content-end px-lg-4">
                <span class="mr-2 px-0 text-right my-auto fw-bold txt-blue fs-13">Empresa: </span>
                <p class="mr-2 px-0 text-right my-auto fw-bold  fs-12">{{nameCompany}}</p>
            </div>
           </div>
         </div>
       </div>
    </div>
       
    <div class="row">
        <div class="col-12 card mt-4 ">
            <div class="row mx-auto col-12 pb-3 pt-3">
                <div class="col-lg-1 pt">
                    <img class="clockImage mt-n5 mt-lg-0" src="../../../../assets/img/test/timer.svg">
                </div>
                <div class="col-lg-8">
                    <div class="fw-bold txt-blue fs-16">
                        {{infoEmployee?.name}} {{infoEmployee?.surnames}}
                    </div>
                    <div class="txt-lightgray fs-12 pt-2">
                        {{infoEmployee?.marketstall}}
                    </div>
                    <div class="row txt-darkgrey justify-content-lg-center">
                        <div class="col-lg-5 fs-12">
                            Empresa: {{nameCompany}}
                        </div>
                        <div class="col-lg-3 fs-12">
                            Centro: {{infoEmployee?.workplace}}
                        </div>
                        <div class="col-lg-4 fs-12">
                            Área: {{infoEmployee?.area}}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 mx-auto d-block text-right order-first order-lg-last">
                    <div class="fw-bold ">
                        No. de empleado <span class="txt-light-blue">{{infoEmployee?.employeeNumber}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 fs-14 text-center mb-5 " *ngIf="examExist">
            <p class="text-center p-4 text-cyan fs-35 mb-1 mt-4">¡Excelente!</p>
            <p>Hemos concluido, agradecemos tu participación.</p>
            <p>De tener dudas o requerir información sobre la NOM-035-STPS-2018 y tus resultados, favor de dirigirte a Capital Humano.</p>
        </div>
        <div class="col-12 fs-14 text-center mb-5 p-4 mt-4 " *ngIf="!examExist">
            <p>Hola {{infoEmployee?.name}},</p>
            <p>Ya has realizado tu cuestionario.</p>
            <p class="mb-0 txt-darkgrey fs-11 mt-5">Fecha y hora de aplicación: {{solveDate | date:'dd/MM/yy, h:mm'}}</p>
        </div>
        <div *ngIf="progressBar == 0" class="col-12 text-center mb-5 ">
            <img src="assets/img/landing/ProgressI.svg" alt="progreso">
        </div>
        <div *ngIf="progressBar == 1" class="col-12 text-center mb-5 ">
            <img src="assets/img/landing/ProgressI-II.svg" alt="progreso">
        </div>
        <div *ngIf="progressBar == 2" class="col-12 text-center mb-5 ">
            <img  src="assets/img/landing/ProgressI-III.svg" alt="progreso">
        </div>
        <div class="col-12 text-center mb-2 ">
            <button [routerLink]="['/']" class="btn-third">Salir</button>
        </div>
    </div>

</div>