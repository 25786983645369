import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './modules/landing/landing.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { HomeLandingComponent } from './modules/home/home-landing/home-landing.component';
import { HeaderComponent } from './shared/header/header.component';
import { NotificationsModalComponent } from "./modules/home/notifications/notifications-modal/notifications-modal.component";
import { FooterComponent } from './shared/footer/footer.component';
import { AlertComponent } from './shared/alert/alert.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Globals } from './globals/globals';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatStepperModule } from '@angular/material/stepper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


/** Injections */
import { GlobalIntermediaryServices } from './injections/GlobalIntermediaryServices';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { PipesModule } from './pipes/pipes.module';
import { DatePipe } from '@angular/common';

import { TextMaskModule } from 'angular2-text-mask';

import { GuardsModule } from './guards/guards.module';

import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from "../assets/custom-fonts.js";
import { NgxCaptureModule } from 'ngx-capture';
import { ClickOutsideModule } from 'ng-click-outside';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PostsListComponent } from './modules/communication/posts-list/posts-list.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { WorkingComponent } from './modules/working/working.component';
import { PeriodsComponent } from './modules/working/periods/periods.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SingleSigninComponent } from './modules/login/single-signin/single-signin.component';
import { TokenResolverService } from './resolver/token-resolver.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChooseRoleComponent } from './shared/choose-role/choose-role.component';
import { NoUserDataComponent } from './modules/login/no-user-data/no-user-data.component';
import { MqttModule } from 'ngx-mqtt';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ComercialComponent } from './modules/time-management/comercial/comercial.component';
import { TimeManagementModule } from './modules/time-management/time-management.module';
import { MatButtonModule } from '@angular/material/button';

PdfMakeWrapper.setFonts(pdfFonts, {
  Lato: {
    normal: 'Lato-Regular.woff',
    bold: 'Lato-Bold.woff',
    italics: 'Lato-Regular.woff',
    bolditalics: 'Lato-Regular.woff'
  }
});

PdfMakeWrapper.useFont('Lato');
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotFoundComponent,
    BreadcrumbComponent,
    HeaderComponent,
    NotificationsModalComponent,
    FooterComponent,
    AlertComponent,
    ConfirmDialogComponent,
    SidebarComponent,
    SingleSigninComponent,
    ChooseRoleComponent,
    NoUserDataComponent],
  imports: [
    MatDialogModule,
    PipesModule,
    BrowserModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatStepperModule,
    SlickCarouselModule,
    AppRoutingModule,
    InterceptorsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgxPaginationModule,
    SweetAlert2Module.forRoot({}),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxPageScrollModule,
    GuardsModule,
    TextMaskModule,
    NgxCaptureModule,
    BsDatepickerModule.forRoot(),
    ClickOutsideModule,
    CKEditorModule,
    ImageCropperModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    TimeManagementModule,
    ModalModule.forRoot(),
    MqttModule.forRoot({
      hostname: 'b-11c6cd56-e4b7-4fb7-82ae-44844cc80546-1.mq.us-east-1.amazonaws.com',
      port: 61619,
      path: '',
      username: '2bcoreAdmin',
      password: 'Esternocleidomastoideo_56',
      protocol: 'wss'
    })

  ],
  providers: [GlobalIntermediaryServices, NgSelectModule, Globals, DatePipe, HomeLandingComponent, PostsListComponent, WorkingComponent, PeriodsComponent, TokenResolverService],
  bootstrap: [AppComponent],
  exports: [PipesModule]
})
export class AppModule { }
