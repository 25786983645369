<div class="container w-90">
    <div class="row">
        <div class="col-12 d-md-none">
            <a [routerLink]="'/hours-employee'" class="text-decoration-none">
                <span class="time-icon-back fs-16"></span>
            </a>
        </div>
        <div class="col-12 d-none d-md-block">
            <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="col-12 mt-3 mt-md-4">
            <p class="title mb-0">Asignar Backup</p>
        </div>

        <div class="col-12 col-md-10 mx-auto mt-4 mt-md-5">
            <p class="subtitle">Busca al colaborador que asignarás como tu Backup, recuerda notificarle.</p>

    <form [formGroup]="backupForm" (ngSubmit)="onSubmit()">
        <div formArrayName="areaBackupArray">
            <div *ngFor="let areaBackup of areaBackupArray.controls; let i = index" [formGroupName]="i"
                class="row">
                <div class="col">
                    <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Área</mat-label>
                        <input matInput formControlName="area" type="text" id="area" readonly>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                        <mat-label>Backup</mat-label>
                        <mat-select formControlName="backup" class="deep-select-overlay">
                            <mat-option *ngFor="let backup of employee" [value]="backup.employee" class="deep-mat-option">
                                <span>
                                    <img class="avatar-40 mr-2" [src]="backup.avatar" [alt]="backup.employee">
                                    {{ backup.employee }}
                                </span>
                            </mat-option>
                        </mat-select>
                        <span matSuffix class="time-icon-arrow_expand fs-16 p-3"></span>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <p class="subtitle">Ingresa la fecha de inicio y fecha fin:</p>
        <div formGroupName="dateRange" class="row">
            <div class="col">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="deep-mat-field w-100">
                    <mat-label>Fecha Inicio</mat-label>
                    <input matInput readonly [matDatepicker]="picker1" formControlName="startDate" [min]="today"
                    [max]="backupForm.get('dateRange')?.get('endDate')?.value" (click)="picker1.open()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1">
                        <span class="time-icon-calendar_active fs-24" matDatepickerToggleIcon></span>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="deep-mat-field w-100">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput readonly [matDatepicker]="picker2" formControlName="endDate"
                        [min]="startDate ? startDate : today"
                        (click)="picker2.open()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2">
                        <span class="time-icon-calendar_active fs-24" matDatepickerToggleIcon></span>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row justify-content-center mt-4 mb-5">
            <button type="button" (click)="cancelBackup()" class="btn btn-secondary mr-3">Cancelar</button>
            <button type="submit" class="btn btn-primary ml-3" [disabled]="backupForm.invalid">Asignar</button>
        </div>
    </form>

        </div>
    </div>
</div>
