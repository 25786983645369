<div class="container">
    <div class="row my-0">
        <div class="col-12 px-0">
            <p class="title text-center txt-dark">{{infoPeriod?.proyect_name}}</p>
            <div class="info my-3 py-0" #info id="info">
                <p class="subtitle">Resultados generales</p>
                <div *ngIf="filter" class="px-3 mb-2">
                  <div>
                    <b>Centro: </b> <ng-container *ngFor="let work of filter.centers; index as i">{{work}}<ng-container *ngIf="filter.centers.length > i+1">,&nbsp;</ng-container> </ng-container>
                  </div>
                  <div>
                    <b>Área: </b> <ng-container *ngFor="let area of filter.areas; index as i">{{area}}<ng-container *ngIf="filter.areas.length > i+1">,&nbsp;</ng-container> </ng-container>
                  </div>
                </div>
                <div class="row px-3">
                    <div class="col-lg-6">
                      <p class="sub mb-0">La calificación general del clima laboral es:</p>
                      <div class="d-flex align-items-center justify-content-center">
                        <p class="mb-0 general-number mx-auto" [ngClass]="infoPeriod?.colorGeneral">
                          {{infoPeriod?.promedio_general > 99 ? (infoPeriod?.promedio_general | number: '3.0-0') : (infoPeriod?.promedio_general | number: '2.0-0')}}
                        </p>
                        <img src="{{infoPeriod?.imageGeneral}}" alt="" class="mx-auto" height="180px">
                      </div>
                    </div>
                    <div class="col-lg-6 general" #generalInfo id="generalInfo"><p class="sub">Información general</p>
                        <div class="row ">
                            <div class="col-lg-4 col-6">
                                <p class="fs-10 txt-blue fw-bold  mb-1">Sexo</p>
                                <table class="w-100 mb-3">
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Femenino
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.gender?.F}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Masculino
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.gender?.M}}%
                                    </td>
                                  </tr>
                                </table>
                            </div>
                            <div class="col-lg-4 col-6">
                                <p class="fs-10 txt-blue fw-bold mb-1">Edades</p>
                                <table class="w-100 mb-3">
                                  <tr>
                                    <td class="general-stats fs-9">
                                      18 a 29
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.age['1']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      30 a 39
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.age['2']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      40 a 49
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.age['3']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      50 a 59
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.age['4']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      60 y más
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.age['5']}}%
                                    </td>
                                  </tr>
                                </table>
                            </div>
                            <div class="col-lg-4 col-6">
                                <p class="fs-10 txt-blue fw-bold mb-1">Estado civil</p>
                                <table class="w-100 mb-3">
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Soltero/a
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.marital_status['1']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Casado/a
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.marital_status['2']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Unión libre
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.marital_status['3']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Divorciado/a
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.marital_status['4']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Viudo/a
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.marital_status['5']}}%
                                    </td>
                                  </tr>
                                </table>
                            </div>
                        <!--</div>
                        <div class="row"> -->
                            <div class="col-lg-4 col-6">
                                <p class="fs-10 txt-blue fw-bold mb-1">Años de antigüedad en la empresa</p>
                                <table class="w-100 mb-3">
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Menos de 1
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.antiquity['1']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      De 1 a 3
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.antiquity['2']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      De 4 a 9
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.antiquity['3']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Más de 10
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.antiquity['4']}}%
                                    </td>
                                  </tr>
                                </table>
                            </div>
                            <div class="col-lg-4 col-6">
                                <p class="fs-10 txt-blue fw-bold mb-1">Escolaridad</p>
                                <table class="w-100 mb-3">
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Sin escolaridad
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.scolarship['1']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Primaria
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.scolarship['2']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Secundaria
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.scolarship['3']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Bachillerato o preparatoria
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.scolarship['4']}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="general-stats fs-9">
                                      Carrera técnica
                                    </td>
                                    <td class="fs-9 text-right">
                                      {{infoPeriod?.general_info?.scolarship['5']}}%
                                    </td>
                                  </tr>
                                </table>
                            </div>
                            <div class="col-lg-4 col-6">
                              <p class="fs-10 mb-0">&nbsp;</p>
                              <table class="w-100 mb-3">
                                <tr>
                                  <td class="general-stats fs-9">
                                    Licenciatura
                                  </td>
                                  <td class="fs-9 text-right">
                                    {{infoPeriod?.general_info?.scolarship['6']}}%
                                  </td>
                                </tr>
                                <tr>
                                  <td class="general-stats fs-9">
                                    Maestría
                                  </td>
                                  <td class="fs-9 text-right">
                                    {{infoPeriod?.general_info?.scolarship['7']}}%
                                  </td>
                                </tr>
                                <tr>
                                  <td class="general-stats fs-9">
                                    Doctorado
                                  </td>
                                  <td class="fs-9 text-right">
                                    {{infoPeriod?.general_info?.scolarship['8']}}%
                                  </td>
                                </tr>
                              </table>
                            </div>
                        </div>                        
                    </div>
                    <p class="sub p-15 pb-0">Calificaciones por indicador</p>
                    <div class="col-12 d-flex" #containerChart id="containerChart">
                      <div id="chart" class="" #chart *ngIf="chartOptions?.series">
                        <apx-chart
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [dataLabels]="chartOptions.dataLabels"
                          [plotOptions]="chartOptions.plotOptions"
                          [xaxis]="chartOptions.xaxis"
                          [colors]="chartOptions.colors"
                          [tooltip]="chartOptions.tooltip"
                       ></apx-chart>
                      </div>
                      <div class="labels ">
                        <ng-container *ngFor="let data of promedios">
                          <p class="p-labels one" *ngIf="data <= 20">{{data}}</p> 
                          <p class="p-labels two" *ngIf="data > 20 && data <= 40">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</p> 
                          <p class="p-labels three" *ngIf="data > 40 && data <= 60">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</p> 
                          <p class="p-labels four" *ngIf="data > 60 && data <= 80">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</p> 
                          <p class="p-labels five" *ngIf="data > 80 && data <= 100">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</p> 
                        </ng-container>
                        
                      </div>
                    </div>
                    <div class="t-indicators col-12 d-flex">
                      <div class="table table-borderless">
                        <table>
                          <tr *ngFor="let data of promedios; let inPr=index">
                            <td class="col-9 p-15 indicator">{{infoPeriod?.promedio_por_indicador[inPr].indicador}}</td>
                            <td class="p-labels one" *ngIf="data <= 20"> {{ data }}</td>
                            <td class="p-labels two" *ngIf="data > 20 && data <= 40">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</td>
                            <td class="p-labels three" *ngIf="data > 40 && data <= 60">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</td>
                            <td class="p-labels four" *ngIf="data > 60 && data <= 80">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</td>
                            <td class="p-labels five" *ngIf="data > 80 && data <= 100">{{data > 99 ? (data | number:'3.0-0'): (data | number:'2.0-0')}}</td>
                          </tr>
                        </table>
                      </div>

                    </div>
                    <div class="col-11 navigation align-items-end mx-auto" *ngIf="userRole !== 'employee'">
                        <app-working-navigation> 
                        </app-working-navigation>
                    </div>
                </div>
            </div>
          </div>
    </div>
</div>