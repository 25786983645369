import { Component, OnInit , ElementRef, ViewChild, LOCALE_ID} from '@angular/core';
/* import * as custombuild from "../../../skEditor/ckeditor";
import RestrictedEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/restrictededitingmode'; */
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmployeesService } from '../../../services/employees/employees.service';
import { ModalService } from '../../../services/modal/modal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { EmployeeModel } from 'src/app/models/employees/employee.model';
import { PublicationService } from 'src/app/services/publication-service/publication.service';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { IPublicationModel } from '../../../models/publications/publication.model';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import {Globals} from 'src/app/globals/globals';
import { UsersService } from 'src/app/services/users/users.service';
import { IPermissioonModel } from 'src/app/models/addOn/permission.model';
import { permissionsOff } from 'src/app/constants/permissionBase';
import { ICreateChangelogModel } from 'src/app/models/changelogs/changelogs.model';
import { ChangelogsService } from 'src/app/services/changelogs/changelogs.service';

registerLocaleData(localeEsMx, 'es-Mx');
@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: [
    './posts-list.component.scss',
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Mx' } ]
})
export class PostsListComponent implements OnInit {
  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('modalDeletePublication') modalDeletePublication: ElementRef;

  slideConfigExperience = {
    lazyLoad: 'progressive',
    slidesToShow: 3,
    autoplay: false,
    adaptiveHeight: false,
    focusOnSelect: true,
    arrows: true,
    dots: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: '.',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    sanitize: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: '/courses/uploadImage',
    toolbarPosition: 'top'
  };

  slides = [];
  /** Todos los post sin el mas actual */
  postList;
  /** Lista de los post filtrados */
  postListFiltered;
  /** Post mas nuevo */
  lastPost: any = [];
  /** índice para la paginación */
  pageIndex = 1;
  /** Para ngModel de busqueda */
  search = "";
  /** Arreglos de áreas y centros de trabajo */
  selects = {
    center: [],
    area: [],
  }
  /** Almacena el post al que se le va editar la privacidad */
  postToEditPrivacity : any;
  /** Almacena el post a eliminar */
  postToDelete : any = null;
  namePostToDelete : any = null;

  idCompanie = "";
  idCompanySelected;
  userInfoLogged;
  subscribers: any = [];
  htmlContent:string='';

  imgCompany: string;
  paginationStorage: any = {};

  /** Almacena los permisos */
  userPermissions: IPermissioonModel = Object.assign({} , permissionsOff);;

  /**
   * Creates an instance of PostsListComponent.
   * @param {EmployeesService} employee
   * @param {AuthService} auth
   * @param {Router} route
   * @param {PublicationService} publicationService
   * @param {ModalService} modal
   * @param {Router} router
   * @memberof PostsListComponent
   */
  constructor(
    private employee: EmployeesService,
    private auth: AuthService,
    private route: Router,
    private publicationService: PublicationService,
    private modal: ModalService,
    private router: Router,
    private companiesService: CompaniesService,
    public globals: Globals,
    private user: UsersService,
    private history: ChangelogsService
  ) {
    this.getDataObservables();
  }

  /**
   *
   * @memberof PostsListComponent
   */
  ngOnInit(): void {

    this.subscribers.permissions = this.user.userPermissions.subscribe((res) => (res && !res.length && res.length !== 0) ? (this.userPermissions = res) : '');

    this.swalLoading();
    this.getSessionData();
    this.getAllPosts();
    if(sessionStorage.getItem('idCompanySelected')){
      this.getCentersAndAreas();
    }
    window.scrollTo(0, 0);
  }

  /**
   * Obtenemos la info guardada en los obs
   */
  getDataObservables(){
    this.subscribers.user = this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
    });
    this.subscribers.idCompanySelected = this.auth.idCompanieSelected.subscribe(res => {
      this.idCompanySelected = res;
    });

    if(sessionStorage.getItem('idCompanySelected')){
      this.subscribers = this.companiesService.getCompany(sessionStorage.getItem('idCompanySelected')).subscribe((response) => {
        this.imgCompany = response.logo;
      });
    }
  }

  pageChanged(numPage){
    this.pageIndex = numPage;
    this.paginationStorage = JSON.parse(sessionStorage.getItem('list'));
    const obj = {
      searching: (this.paginationStorage && this.paginationStorage.searching ) ? this.paginationStorage.searching : '',
      page: this.pageIndex,
    };
    sessionStorage.setItem('list', JSON.stringify(obj));
  }

  /**
   * Obtenemos datos de la sesión
   */
  getSessionData(){
    sessionStorage.setItem('previousComponent', 'posts/menu/list');
    this.idCompanie = sessionStorage.getItem('idCompanySelected');
  }

  /**
   * Obtenemos los centros y áreas
   */
  getCentersAndAreas(){
    this.subscribers.getAllCompanyEmployees = this.employee.getWorkplaceAndAreas(this.idCompanie).subscribe((response: any) => {
      if (response) {
        for (const col of Object.keys(response)) {
          this.selects[col] = [];
          for (const iterator of response[col]) {
            this.selects[col].push({  item: iterator, isSelected: false });
          }
        }
      }
    });
  }

  /**
   * Obtenemos todos los post
   */
  getAllPosts(){
    this.publicationService.companyPublications.subscribe(value => {
      if(value){

        this.postList = value.slice();
        this.lastPost = this.postList.shift();
       /*  this.postList.reverse(); */
  
        if(value.length > 0){
          this.paginationStorage = JSON.parse(sessionStorage.getItem('list'));
          if (this.paginationStorage ) {
            this.pageIndex = this.paginationStorage.page;
            if(this.paginationStorage.searching  !== ''){
              this.search = this.paginationStorage.searching;
              this.searchPosts(this.search);
            }
          }
        }
      }

      this.openCorrespondingModal();
      Swal.close();
    });
    this.publicationService.nomPublications.subscribe(value=>{
      if(value.length > 0){
        this.slides = value;
        /* this.slides.reverse(); */
      }

    });
  }

  openCorrespondingModal(){
    if(this.globals.postModal.open){
      this.globals.postModal.open = false;
      this.modal.successful({
        title: this.globals.postModal.title,
        message: this.globals.postModal.sms
      });
    }
  }

  searchPosts(data: string){
    const obj = {
      searching: this.search,
      page: this.pageIndex
    };

    sessionStorage.setItem('list', JSON.stringify(obj));
    this.paginationStorage = JSON.parse(sessionStorage.getItem('list'));

    this.postListFiltered = this.postList.slice();
    this.postListFiltered.splice(0,0,this.lastPost);
    if(this.search.length !== 0){
      if(this.search != this.paginationStorage.searching){
        this.pageIndex = 0;
      }
      this.postListFiltered = this.postListFiltered.filter(post => post.title.toLowerCase().includes(this.search.toLowerCase()));
    }
  }

  /**
   * Elimina el post
   */
  onDeletePost(){
    this.publicationService.delete(this.postToDelete).subscribe(value=>{
      if(value){
        this.publicationService.findAll().subscribe(result => {
          this.getAllPosts();

            /** Objeto que guarda datos para historial */
            const changelog: ICreateChangelogModel = {
              idCompany: this.idCompanySelected,
              change: {
                img: this.userInfoLogged.profilePicture ? this.userInfoLogged.profilePicture : '',
                name: `${this.userInfoLogged.name} ${this.userInfoLogged.lastName ? this.userInfoLogged.lastName : ''} ${this.userInfoLogged.secondSurname ? this.userInfoLogged.secondSurname : ''}`,
                date: new Date(),
                action: `Eliminó la publicación: ${this.namePostToDelete}`
              }
            };
            /** Funcion que envia los datos de la historia */
            this.history.setChangelog(changelog);

          this.modal.successful({
            title: 'Publicación eliminada con éxito',
          });
        });
      }
    });
    this.postToDelete = null;
    this.namePostToDelete = null;
  }

  resetSearch(event){
    event.preventDefault();
    event.stopPropagation();
    this.search = "";
    this.searchPosts("");
    this.getAllPosts();
    //this.paginationStorage = "";
  }

  /**Manda a ver más sobre la publicación */
  seePublication(id:any){
    sessionStorage.setItem('postSelected', id);
    // this.publication.isEditing.next({value: true, id});
    this.route.navigate(['/posts/info']);
   }

    /**Abre el modal para eliminar invitación */
  setIDPublicationToDelete() {
    this.openModal.nativeElement.click();
  }

  goToDetail(id: any){
    sessionStorage.setItem('idPost', id);
    this.router.navigate(['/posts/add']);
  }

  swalLoading(){
    Swal.fire({allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false, width: '100px'  });
    Swal.showLoading();
  }

  /**
   * Obtenemos la privacidad del post para acomodar los select
   *
   * @param {*} post
   * @memberof PostsListComponent
   */
  getPostPrivacy(post: any){
    this.postToEditPrivacity = post;
    for (const temporalField of Object.keys(this.selects)) {
      post[temporalField] = post[temporalField].map(item => item.toUpperCase());// Se pasan a mayúsculas, de esa manera se presentan

      for (const opcSel of this.selects[temporalField]) {
        if(post[temporalField].includes(opcSel.item.toUpperCase())){
          opcSel.isSelected = true;
        }else{
          opcSel.isSelected = false;
        }
      }
    }
  }

  /**
   * Se actualiza el objeto local con la nueva info
   *
   * @param {string} idUpdatedPost
   * @param {IPublicationModel} tempUptPost
   * @memberof PostsListComponent
   */
  updateLocalList(idUpdatedPost: string, tempUptPost: IPublicationModel){
    for (const iterator of this.postList) {
      if(iterator._id === idUpdatedPost){
        iterator.center = tempUptPost.center;
        iterator.area = tempUptPost.area;
      }
    }
  }

  /**
   * Actualizamos el post con su nueva privacidad
   *
   * @memberof PostsListComponent
   */
  savePost(){
    let publication : IPublicationModel = {
      idCompany: this.postToEditPrivacity.idCompany,
      title: this.postToEditPrivacity.title,
      publication: this.postToEditPrivacity.publication,
      img: this.postToEditPrivacity.img,
      video: this.postToEditPrivacity.video ? this.postToEditPrivacity.video : undefined,
      date: this.postToEditPrivacity.date,
      center: [],
      area: [],
      userIds: []
    };

    for (const temporalField of Object.keys(this.selects)) {
      for (const iterator of this.selects[temporalField]) {
        if(iterator.isSelected){
          publication[temporalField].push(iterator.item);
        }
      }
    }

    this.publicationService.update(publication, this.postToEditPrivacity._id).subscribe(response => {
      if(response.clave == "OK"){

          /** Objeto que guarda datos para historial */
          const changelog: ICreateChangelogModel = {
            idCompany: this.idCompanySelected,
            change: {
              img: this.userInfoLogged.profilePicture ? this.userInfoLogged.profilePicture : '',
              name: `${this.userInfoLogged.name} ${this.userInfoLogged.lastName ? this.userInfoLogged.lastName : ''} ${this.userInfoLogged.secondSurname ? this.userInfoLogged.secondSurname : ''}`,
              date: new Date(),
              action: `Editó la publicación: ${publication.title}`
            }
          };
          /** Funcion que envia los datos de la historia */
          this.history.setChangelog(changelog);

        this.modal.successful({
          title: 'Publicación actualizada con éxito',
          message: ' ',
        });
        this.updateLocalList(this.postToEditPrivacity._id, publication);
      } else {
        this.modal.warning({
          title: "",
          message: response.message,
        });
      }
    });
  }

}
