export const environment = {
  production: true,
  baseUrl: 'https://pre-intranet.2bcore.com.mx/api',
  baseSocketUrl: 'https://pre-intranet.2bcore.com.mx',
  urlPath: 'https://2bcore-site.s3.us-east-2.amazonaws.com/intranet',
  encryptionKey: 'kXp2s5u8x/A?D(G+KbPeShVmYq3t6w9y',
  urlLinks: 'https://pre-intranet.2bcore.com.mx',
  loginURL: 'https://intranet2bcore.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=2bcore-intranet&redirect_uri=https://pre-intranet.2bcore.com.mx/home/init-page&response_type=CODE&client_id=2im05nvcoa1iqm6o5p3ju21p6q&scope=aws.cognito.signin.user.admin',
  logoutURL: 'https://intranet2bcore.auth.us-east-1.amazoncognito.com/logout?client_id=2im05nvcoa1iqm6o5p3ju21p6q&logout_uri=https://pre-intranet.2bcore.com.mx/signin',
  redirectURL: 'https://pre-intranet.2bcore.com.mx/home/init-page',
  cognitoTokenURL: 'https://intranet2bcore.auth.us-east-1.amazoncognito.com/oauth2/token',
};
