import { Component, Input } from '@angular/core';
import { Comment } from '../project.const';
import { leaders } from '@pmo/teams/team/team.const';

@Component({
  selector: 'app-comments[comments]',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input() comments!: Comment[];

  user = {
    userAvatar: leaders[5].avatar,
    userName: leaders[5].name,
  }

  emojiAns: boolean = false;

  handleSelectionAnsw(e) {
    console.log("🚀 ~ CommentsComponent ~ handleSelectionAnsw ~ e:", e)
    // this.msgAnswer += e.char;
    this.emojiAns = false;
  }
  handleCharDelete(e) {
    /*
    if (this.msg.length > 0) {
      this.msg = this.msg.substr(0, this.text.length - 1);
    }
      */
  }

}
