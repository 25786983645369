<ng-container *ngIf="(!router.url.includes('/signin') && !router.url.includes('/nodata')); else outSession">
  <div class="page-content" [ngClass]="[
    ( 
      this.router.url.includes('/login') || this.router.url.includes('/guides-summary') || this.router.url.includes('/administration-companies')
    ) || 
    this.router.url.includes('/quiz') && enabledNotFound() ? 'simple-page-content' : '', 
    this.router.url.includes('/login') ? 'background' : '' 
  ]" [style.grid-template-rows]="rows">
    <app-header class="app-header" *ngIf="!this.router.url.includes('/login') &&
               enabledNotFound() &&
               !this.router.url.includes('/quiz') &&
               !this.router.url.includes('/guide-one') &&
               !this.router.url.includes('/guide-two') &&
               !this.router.url.includes('/guide-three') &&
               !this.router.url.includes('/guides-summary')">
    </app-header>
    <div class="router-outlet">
      <app-alert class="app-alert" *ngIf="existAlert"> </app-alert>
    <div
      *ngIf="!this.router.url.includes('/login') &&
        !this.router.url.includes('/NOM-035') &&
        enabledNotFound() &&
        !this.router.url.includes('/quiz') &&
        !this.router.url.includes('/guide-one') &&
        !this.router.url.includes('/guide-two') &&
        !this.router.url.includes('/guide-three') &&
        !this.router.url.includes('/guides-summary') &&
        this.router.url !== '/administration-companies' &&
        !this.router.url.includes('/invitation-reply')"
        class="sidebar-container"
    >  
      <app-sidebar></app-sidebar>
    </div>
      <div class="py-4">
        <router-outlet class="col-12 px-0"></router-outlet>
      </div>
    </div>
    <app-footer class="footer" *ngIf="enabledNotFound()"></app-footer>
  </div>
</ng-container>

<ng-template #outSession>
  <!-- <app-single-signin></app-single-signin> -->
  <router-outlet></router-outlet>

</ng-template> 