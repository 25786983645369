<div class="container-fluid p-0">
  <div class="mt-2 mb-4 bg-body rounded shadow header">
    <div class="row header-content">
      <a href="#" [routerLink]="['/home/welcome']" class="col d-flex d-md-block  justify-content-center align-items-center p-0">
        <img [src]="(companyImg ? (companyImg | imagePath: 'logoCompany') : 'assets/img/logos/01.svg')"  [ngClass]="companyImg ? 'logo' : 'logo-default'">
      </a>
      <div class="col d-lg-flex justify-content-lg-end align-items-center p-0">
        <div class="pr-0">
          <div class="d-flex justify-content-end text-header">
            <span class="mr-2 px-0 text-right my-auto  txt-blue">Empresa: </span>
            <p class=" px-0 text-right my-auto txt-blue">{{companyName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row" >
    <div class="col-12">
      <div class="content">
        <p class="text-center fs-28 txt-blue fw-bold">Cuestionario Clima Laboral</p>
        <ng-container *ngIf="sended">
          <br>
          <p class="text-center fs-24 mb-0">Hemos concluido,</p>
          <p class="text-center txt-quest fs-24 gratitude-text">agradecemos tu participación.</p>
          <div class="d-flex justify-content-center align-items-center">
            <img src="../../../../assets/img/Group_557.svg" alt="" style="max-width: 100vw;">
          </div>

          <div class="row mt-3 pt-4 pb-4">
            <div class="d-flex mx-auto">
              <button class="btn-secondary fs-12 mx-2" (click)="gotoLanding()">
                Salir
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!sended">
          <p class="txt-blue fs-20 fw-bold">Instrucciones</p>
          <p>Contesta las siguientes preguntas seleccionando una de las opciones disponibles. Todos los campos son obligatorios.</p>
        </ng-container>
        <form class="form" [formGroup]="formEmployeeInfo" *ngIf="step == 0">
          <div class="card">
            <div class="card-header align-items-center header-info">
              <p class="txt-blue mb-0 fw-bold">Información general</p>
            </div>
            <div class="card-body">
              <div class=" div-quest">
                <p class="mb-0 txt-quest">Sexo</p>
                <div class="ml-lg-4 flex-container wrap pb-2">
                  <div class="flex-item">
                    <input type="radio" id="gender-m" formControlName="gender" value="1" [checked]="formEmployeeInfo.controls.gender.value == 'M'">
                    <label class="mr-4 ml-2 mb-0" for="gender-m">M</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="gender-f" formControlName="gender" value="2" [checked]="formEmployeeInfo.controls.gender.value == 'F'">
                    <label class="mx-2 mb-0" for="gender-f">F</label>
                  </div>

                </div>
              </div>
              <div class="separator"></div>
              <div class="div-quest">
                <p class="mb-0 txt-quest">Edad</p>
                <div class="ml-lg-4 flex-container wrap pb-2">
                  <div class="flex-item">
                    <input type="radio" id="age-1" formControlName="age" value="1" [checked]="formEmployeeInfo.controls.age.value == '1'">
                    <label class="mr-4 ml-2" for="age-1">18-29</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="age-2" formControlName="age" value="2" [checked]="formEmployeeInfo.controls.age.value == '2'">
                    <label class="mr-4 ml-2" for="age-2">30-39</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="age-3" formControlName="age" value="3" [checked]="formEmployeeInfo.controls.age.value == '3'">
                    <label class="mr-4 ml-2" for="age-3">40-49</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="age-4" formControlName="age" value="4" [checked]="formEmployeeInfo.controls.age.value == '4'">
                    <label class="mr-4 ml-2" for="age-4">50-59</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="age-5" formControlName="age" value="5" [checked]="formEmployeeInfo.controls.age.value == '5'">
                    <label class="mr-4 ml-2" for="age-5">60 o más</label>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class=" div-quest">
                <p class="mb-0 txt-quest">Estado civil</p>
                <div class="ml-lg-4 flex-container wrap pb-2">
                  <div class="flex-item">
                    <input type="radio" id="status-1" formControlName="marital_status" value="1" [checked]="formEmployeeInfo.controls.marital_status.value == '1'">
                    <label class="mr-4 ml-2" for="status-1">Soltero/a</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="status-2" formControlName="marital_status" value="2" [checked]="formEmployeeInfo.controls.marital_status.value == '2'">
                    <label class="mr-4 ml-2" for="status-2">Casado/a</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="status-3" formControlName="marital_status" value="3" [checked]="formEmployeeInfo.controls.marital_status.value == '3'">
                    <label class="mr-4 ml-2" for="status-3">Unión Libre</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="status-4" formControlName="marital_status" value="4" [checked]="formEmployeeInfo.controls.marital_status.value == '4'">
                    <label class="mr-4 ml-2" for="status-4">Divorciado/a</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="status-5" formControlName="marital_status" value="5" [checked]="formEmployeeInfo.controls.marital_status.value == '5'">
                    <label class="mr-4 ml-2" for="status-5">Viudo/a</label>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class=" div-quest">
                <p class="mb-0 txt-quest">Años de antigüedad en la empresa</p>
                <div class="ml-lg-4 flex-container wrap pb-2">
                  <div class="flex-item">
                    <input type="radio" id="antiquity-1" formControlName="antiquity" value="1" [checked]="formEmployeeInfo.controls.antiquity.value == '1'">
                    <label class="mr-4 ml-2" for="antiquity-1">Menos de 1</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="antiquity-2" formControlName="antiquity" value="2" [checked]="formEmployeeInfo.controls.antiquity.value == '2'">
                    <label class="mr-4 ml-2" for="antiquity-2">De 1 a 3</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="antiquity-3" formControlName="antiquity" value="3" [checked]="formEmployeeInfo.controls.antiquity.value == '3'">
                    <label class="mr-4 ml-2" for="antiquity-3">De 4 a 9</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="antiquity-4" formControlName="antiquity" value="4" [checked]="formEmployeeInfo.controls.antiquity.value == '4'">
                    <label class="mr-4 ml-2" for="antiquity-4">Más de 10</label>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class=" div-quest">
                <p class="mb-0 txt-quest">Escolaridad</p>
                <div class="ml-lg-4 flex-container wrap pb-2">
                  <div class="flex-item">
                    <input type="radio" id="scolarship-1" formControlName="scolarship" value="1" [checked]="formEmployeeInfo.controls.scolarship.value == '1'">
                    <label class="mr-4 ml-2" for="scolarship-1">Sin escolaridad</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-2" formControlName="scolarship" value="2" [checked]="formEmployeeInfo.controls.scolarship.value == '2'">
                    <label class="mr-4 ml-2" for="scolarship-2">Primaria</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-3" formControlName="scolarship" value="3" [checked]="formEmployeeInfo.controls.scolarship.value == '3'">
                    <label class="mr-4 ml-2" for="scolarship-3">Secundaria</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-4" formControlName="scolarship" value="4" [checked]="formEmployeeInfo.controls.scolarship.value == '4'">
                    <label class="mr-4 ml-2" for="scolarship-4">Bachillerato o preparatoria</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-5" formControlName="scolarship" value="5" [checked]="formEmployeeInfo.controls.scolarship.value == '5'">
                    <label class="mr-4 ml-2" for="scolarship-5">Carrera técnica</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-6" formControlName="scolarship" value="6" [checked]="formEmployeeInfo.controls.scolarship.value == '6'">
                    <label class="mr-4 ml-2" for="scolarship-6">Licenciatura</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-7" formControlName="scolarship" value="7" [checked]="formEmployeeInfo.controls.scolarship.value == '7'">
                    <label class="mr-4 ml-2" for="scolarship-7">Maestría</label>
                  </div>
                  <div class="flex-item">
                    <input type="radio" id="scolarship-8" formControlName="scolarship" value="8" [checked]="formEmployeeInfo.controls.scolarship.value == '8'">
                    <label class="mr-4 ml-2" for="scolarship-8">Doctorado</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ng-container *ngIf="step > 0" >
          <ng-container *ngFor="let quiz of quizzes; let i = index">
            <div class="card mx-lg-5 m-md-0" *ngIf="i == (step-1)">
              <div class="card-header align-items-center" [ngClass]="quiz.class">
                <p class="mb-0 fw-bold">{{quiz.indicator}}</p>
              </div>
              <div class="card-body p-0">
                <table class="table indicators mb-0"> <!-- Tabla que se despliega modo escritorio -->

                  <tbody>
                  <tr class="fs-14">
                    <td class="th-table-indicators border-top-0 fw-bold"></td>
                    <td class="th-table-indicators border-top-0 fw-bold">Totalmente de acuerdo</td>
                    <td class="th-table-indicators border-top-0 fw-bold">De acuerdo</td>
                    <td class="th-table-indicators border-top-0 fw-bold">Ni de acuerdo ni en desacuerdo</td>
                    <td class="th-table-indicators border-top-0 fw-bold">En desacuerdo</td>
                    <td class="th-table-indicators border-top-0 fw-bold">Totalmente en desacuerdo</td>
                  </tr>
                  <tr *ngFor="let question of quiz.quizz_answer; let j = index">
                    <td class="fs-14">{{question.title}}</td>
                    <td class="th-table-indicators fs-14"><input type="radio" value="1" [checked]="question.answer == '1'" (change)="changeAnswer(i,j,1)"></td>
                    <td class="th-table-indicators fs-14"><input type="radio" value="2" [checked]="question.answer == '2'" (change)="changeAnswer(i,j,2)"></td>
                    <td class="th-table-indicators fs-14"><input type="radio" value="3" [checked]="question.answer == '3'" (change)="changeAnswer(i,j,3)"></td>
                    <td class="th-table-indicators fs-14"><input type="radio" value="4" [checked]="question.answer == '4'" (change)="changeAnswer(i,j,4)"></td>
                    <td class="th-table-indicators fs-14"><input type="radio" value="5" [checked]="question.answer == '5'" (change)="changeAnswer(i,j,5)"></td>
                  </tr>

                  </tbody>
                </table>
                <div *ngFor="let question of quiz.quizz_answer; let j = index" class="indicators-mobile" [ngClass]="j+1 != quiz?.quizz_answer.length ? '' : 'border-bottom-0'"> <!-- Preguntas y opciones en modo mobile -->
                  <p>{{question.title}}</p>
                  <div class="flex-item option-indicator">
                    <input id="{{question.title + '1'}}" type="radio" value="1" [checked]="question.answer == '1'" (change)="changeAnswer(i,j,1)">
                    <label for="{{question.title + '1'}}" class="ml-2">Totalmente de acuerdo</label>
                  </div>
                  <div class="flex-item option-indicator">
                    <input id="{{question.title + '2'}}" type="radio" value="2" [checked]="question.answer == '2'" (change)="changeAnswer(i,j,2)">
                    <label for="{{question.title + '2'}}" class="ml-2">De acuerdo</label>
                  </div>
                  <div class="flex-item option-indicator">
                    <input id="{{question.title + '3'}}" type="radio" value="3" [checked]="question.answer == '3'" (change)="changeAnswer(i,j,3)">
                    <label for="{{question.title + '3'}}" class="ml-2">Ni de acuerdo ni en desacuerdo</label>
                  </div>
                  <div class="flex-item option-indicator">
                    <input id="{{question.title + '4'}}" type="radio" value="4" [checked]="question.answer == '4'" (change)="changeAnswer(i,j,4)">
                    <label for="{{question.title + '4'}}" class="ml-2">En desacuerdo</label>
                  </div>
                  <div class="flex-item option-indicator">
                    <input id="{{question.title + '5'}}" type="radio" value="5" [checked]="question.answer == '5'" (change)="changeAnswer(i,j,5)">
                    <label for="{{question.title + '5'}}" class="ml-2">Totalmente de desacuerdo</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>

        <div class="mt-3 pt-4 pb-4">
          <div class="d-lg-flex d-md-block justify-content-center w-100">
            <div class="w-md-100">
              <button class="btn-secondary fs-12 mx-2" (click)="backStep()" *ngIf="step > 0 && step <= quizzes.length">
                Regresar
              </button>
            </div>
            <div class="w-md-100">
              <button class="btn-secondary fs-12 mx-2" (click)="nextStep()" *ngIf="step < quizzes.length" [disabled]="checkNext()">
                Continuar
              </button>
            </div>
            <div class="w-md-100">
              <button class="btn-secondary fs-12 mx-2" (click)="sendQuiz()" *ngIf="step == quizzes.length" [disabled]="checkNext()">
                Finalizar
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


