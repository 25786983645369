import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from 'src/app/services/employees/employees.service';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ModalService } from 'src/app/services';
import { Globals } from 'src/app/globals/globals';

FormsModule
@Component({
  selector: 'app-guide-one',
  templateUrl: './guide-one.component.html',
  styleUrls: ['./guide-one.component.scss']
})
export class GuideOneComponent implements OnInit {

  //DataSession
  private idEmployee: string;
  private idCompany: string;
  private idProject: string;
  private nameProject: string;

  //Status
  preSubmit: boolean = true;
  error: boolean = false;
  questionsB: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,];
  prevStatus: boolean = false;
  blockSections: boolean = false;

  //Style
  progressbarCountProgress: number = 0;
  pb: number = 0;
  shipP: number = -65;
  endColorLabel: string = "#D4D4D4";
  displayInfo: string = 'none';
  blockedTextTitle: string = "#003f9b";
  blockedText: string = "#212529";
  withSidebar = false;

  //Models
  infoEmployee: any;
  nameCompany: string = "";

  //Misc
  test1: FormGroup;
  params;
  guide = [];
  questionsSecOne = [];
  questionsSecTwo = [];
  questionsSecThree = [];
  questionsSecFour = [];
  nextGuide;

  visible = false;
  visibletwo = false;
  currentRoute = '';

  mostrar = 'container d-none d-lg-block';
  mostrard = 'container d-none d-block d-lg-none d-xl-none';
  answerQueue: any[] = [];
  dispatching = false;

  constructor(public route: ActivatedRoute,
    private employee: EmployeesService,
    private questionnares: QuestionnairesService,
    public router: Router,
    private fb: FormBuilder,
    private modal: ModalService,
    private globals: Globals
    ) { }

  ngOnInit(): void {
    this.idEmployee = this.route.snapshot.queryParamMap.get('idEmployee');
    this.idCompany = this.route.snapshot.queryParamMap.get('idCompany');
    this.idProject = this.route.snapshot.queryParamMap.get('idProject');
    if (this.idEmployee) {
      this.questionnares.getEmployee(this.idEmployee).subscribe(res => {
        if (res) {
          this.globals.inComponentQuiz=false;
          this.infoEmployee = res.content;
          this.idCompany = this.infoEmployee.idCompany
          this.questionnares.getCompanyName(this.infoEmployee.idCompany).subscribe((response) => {
            if (response) {
              this.nameCompany = response.content.companyName
              if (this.idProject) {
                this.questionnares.getProjectName(this.idProject).subscribe(res => {
                  this.nameProject = res.content;
                  Swal.fire({
                    title: 'Guía I',
                    text: 'Obteniendo guía',
                    icon: 'info',
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    allowEscapeKey: false
                  });
                  Swal.showLoading();
                  this.getNextGuide();
                });
              }
            }
          });
        }else{
          this.globals.inComponentQuiz=true;
        }
      });
    }
    this.initForm();

    if (this.router.url == '/NOM-projects/pdf-personal' && window.innerWidth < 992) {
      this.visible = false;
      this.visibletwo = false;
      this.mostrar = 'mx-auto pdf px-2 mt-0';
      this.mostrard = 'container d-none d-lg-block';
    } else if (this.router.url == '/NOM-projects/pdf-personal' && window.innerWidth >= 992) {
      this.visible = false;
      this.visibletwo = false;
      this.mostrar = 'mx-auto pdf px-2 mt-0';
      this.mostrard = 'container d-none d-block d-lg-none d-xl-none';
    } else if (this.router.url.includes('/employees/file/profile/nom-35')) {
      this.visible = false;
      this.visibletwo = true;
      this.mostrar = 'container d-none d-lg-block';
      this.mostrard = 'container d-none d-block d-lg-none d-xl-none'
    } else if (this.router.url == '/employees/file/profile/nom-35/personal') {
      this.visible = false;
      this.visibletwo = false
      this.mostrar = 'container d-none d-lg-block';
      this.mostrard = 'container d-none d-block d-lg-none d-xl-none'
    } else {
      this.visible = true;
      this.visibletwo = true
      this.mostrar = 'container d-none d-lg-block';
      this.mostrard = 'container d-none d-block d-lg-none d-xl-none'
    }
    window.scroll({ top: 0 });
    this.preSubmit = true;
    this.currentRoute = this.router.url;
    this.getAnswer();
    if (this.router.url.includes('/one')) {
      this.test1.disable();
    }
  }

  getNextGuide() {
    this.questionnares.nextQuestionnaire(this.idProject).subscribe(next => {
      this.nextGuide = next.content;
      this.questionnares.getEmployeeQuests(this.idCompany, this.idEmployee).subscribe(response => {
        Swal.close();
        response.content.forEach(element => {
          if (element.nameProject == this.nameProject) {
            if (element.questionnaire.guideOne.finalized == true) {
              if (this.nextGuide == 1) {
                this.router.navigate(['/guides-summary'], {
                  queryParams: {
                    idEmployee: this.idEmployee,
                    idCompany: this.idCompany, idProject: this.idProject, new: false
                  }
                });
              } else if (this.nextGuide == 2) {
                this.router.navigate(['/guide-two'], {
                  queryParams: {
                    idEmployee: this.idEmployee,
                    idCompany: this.idCompany, idProject: this.idProject
                  }
                });
              } else if (this.nextGuide == 3) {
                this.router.navigate(['/guide-three'], {
                  queryParams: {
                    idEmployee: this.idEmployee,
                    idCompany: this.idCompany, idProject: this.idProject
                  }
                });
              }
            }
            let quest = element.questionnaire
            if (quest) {
              this.loadProgress(quest);
            }
            // * The date is validated only if it is not a forwarded link
            if(!element.questionnaire.chanceOutsideLimitDate){
              if(element.expired){
                this.modal.warning({
                  title: "Cuestionario expirado.",
                  message: "Ya no está disponible este cuestionario.",
                });
              }
            }

          }
        });
      })
    });
  }

  initForm() {
    this.test1 = this.fb.group({
      I1: ['', Validators.required],
      I2: ['', Validators.required],
      I3: ['', Validators.required],
      I4: ['', Validators.required],
      I5: ['', Validators.required],
      I6: ['', Validators.required],
      I7: ['', Validators.required],
      I8: ['', Validators.required],
      I9: ['', Validators.required],
      I10: ['', Validators.required],
      I11: ['', Validators.required],
      I12: ['', Validators.required],
      I13: ['', Validators.required],
      I14: ['', Validators.required],
      I15: ['', Validators.required],
      I16: ['', Validators.required],
      I17: ['', Validators.required],
      I18: ['', Validators.required],
      I19: ['', Validators.required],
      I20: ['', Validators.required]
    });
    this.getRouterStyles();
  }

  loadProgress(quest: any) {
    quest.guideOne.section.forEach(section => {
      if (section.idSection == 1) {
        try {
          //seccion 1
          this.test1.controls.I1.setValue(quest.guideOne.section[0].questions[0].value);
          this.test1.controls.I2.setValue(quest.guideOne.section[0].questions[1].value);
          this.test1.controls.I3.setValue(quest.guideOne.section[0].questions[2].value);
          this.test1.controls.I4.setValue(quest.guideOne.section[0].questions[3].value);
          this.test1.controls.I5.setValue(quest.guideOne.section[0].questions[4].value);
          this.test1.controls.I6.setValue(quest.guideOne.section[0].questions[5].value);
        } catch (error) {
        }
      } else if (section.idSection == 2) {
        try {
          //seccion 2
          this.test1.controls.I7.setValue(quest.guideOne.section[1].questions[0].value);
          this.test1.controls.I8.setValue(quest.guideOne.section[1].questions[1].value);

        } catch (error) {
        }
      } else if (section.idSection == 3) {
        try {
          //seccion 3
          this.test1.controls.I9.setValue(quest.guideOne.section[2].questions[0].value);
          this.test1.controls.I10.setValue(quest.guideOne.section[2].questions[1].value);
          this.test1.controls.I11.setValue(quest.guideOne.section[2].questions[2].value);
          this.test1.controls.I12.setValue(quest.guideOne.section[2].questions[3].value);
          this.test1.controls.I13.setValue(quest.guideOne.section[2].questions[4].value);
          this.test1.controls.I14.setValue(quest.guideOne.section[2].questions[5].value);
          this.test1.controls.I15.setValue(quest.guideOne.section[2].questions[6].value);

        } catch (error) {
        }
      } else if (section.idSection == 4) {
        try {
          //seccion 4
          this.test1.controls.I16.setValue(quest.guideOne.section[3].questions[0].value);
          this.test1.controls.I17.setValue(quest.guideOne.section[3].questions[1].value);
          this.test1.controls.I18.setValue(quest.guideOne.section[3].questions[2].value);
          this.test1.controls.I19.setValue(quest.guideOne.section[3].questions[3].value);
          this.test1.controls.I20.setValue(quest.guideOne.section[3].questions[4].value);

        } catch (error) {
        }
      }
    });
    if (this.test1.controls.I1.value == "No"
      && this.test1.controls.I2.value == "No"
      && this.test1.controls.I3.value == "No"
      && this.test1.controls.I4.value == "No"
      && this.test1.controls.I5.value == "No"
      && this.test1.controls.I6.value == "No"
    ) {
      this.prevStatus = true;
      this.blockSections = true;
      this.blockedTextTitle = "#D4D4D4"
      this.blockedText = "#D4D4D4"
    }
  }
  /**
   * Valida ruta para agregar estilos de cuestionario con o sin sidebar
   */
  getRouterStyles(): void {
    this.withSidebar = this.router.url !== '/guide-one';
  }

  checkAnswers() {
    const test: {
      I1: string;
      I2: string;
      I3: string;
      I4: string;
      I5: string;
      I6: string;
      I7: string;
      I8: string;
      I9: string;
      I10: string;
      I11: string;
      I12: string;
      I13: string;
      I14: string;
      I15: string;
      I16: string;
      I17: string;
      I18: string;
      I19: string;
      I20: string;
    } = this.test1.value;
    this.preSubmit = false;
  }

  upgradeProgressBar() {

    this.pb = this.progressbarCountProgress * 5;
    this.shipP = this.shipP + 25;
    if (this.pb == 100) {
      this.endColorLabel = "#99C4FF";
    }

  }

  async answerTrigger(value, question) {
    var splitted = question.split(".");
    this.sendAnswer(splitted[0], splitted[1], value);

    if (question == '1.1'
      || question == '1.2'
      || question == '1.3'
      || question == '1.4'
      || question == '1.5'
      || question == '1.6'
    ) {
      if (this.test1.controls.I1.value == "No"
        && this.test1.controls.I2.value == "No"
        && this.test1.controls.I3.value == "No"
        && this.test1.controls.I4.value == "No"
        && this.test1.controls.I5.value == "No"
        && this.test1.controls.I6.value == "No"
      ) {
        this.prevStatus = true;
        this.blockSections = true;
        this.blockedTextTitle = "#D4D4D4"
        this.blockedText = "#D4D4D4"
        this.test1.controls.I7.setValue('No');
        this.test1.controls.I8.setValue('No');
        this.test1.controls.I9.setValue('No');
        this.test1.controls.I10.setValue('No');
        this.test1.controls.I11.setValue('No');
        this.test1.controls.I12.setValue('No');
        this.test1.controls.I13.setValue('No');
        this.test1.controls.I14.setValue('No');
        this.test1.controls.I15.setValue('No');
        this.test1.controls.I16.setValue('No');
        this.test1.controls.I17.setValue('No');
        this.test1.controls.I18.setValue('No');
        this.test1.controls.I19.setValue('No');
        this.test1.controls.I20.setValue('No');
        this.answerQueue.splice(0, this.answerQueue.length)
        this.sendAnswer(1, 1, 'No');
        this.sendAnswer(1, 2, 'No');
        this.sendAnswer(1, 3, 'No');
        this.sendAnswer(1, 4, 'No');
        this.sendAnswer(1, 5, 'No');
        this.sendAnswer(1, 6, 'No');

      } else if (this.prevStatus) {
        this.prevStatus = false;
        this.blockSections = false;
        this.blockedTextTitle = "#212529"
        this.blockedText = "#212529"
        this.test1.controls.I7.setValue('');
        this.test1.controls.I8.setValue('');
        this.test1.controls.I9.setValue('');
        this.test1.controls.I10.setValue('');
        this.test1.controls.I11.setValue('');
        this.test1.controls.I12.setValue('');
        this.test1.controls.I13.setValue('');
        this.test1.controls.I14.setValue('');
        this.test1.controls.I15.setValue('');
        this.test1.controls.I16.setValue('');
        this.test1.controls.I17.setValue('');
        this.test1.controls.I18.setValue('');
        this.test1.controls.I19.setValue('');
        this.test1.controls.I20.setValue('');
      }
    }


  }

  sendAllNo() {
    this.sendAnswer(2, 1, 'No');
    this.sendAnswer(2, 2, 'No');
    this.sendAnswer(3, 1, 'No');
    this.sendAnswer(3, 2, 'No');
    this.sendAnswer(3, 3, 'No');
    this.sendAnswer(3, 4, 'No');
    this.sendAnswer(3, 5, 'No');
    this.sendAnswer(3, 6, 'No');
    this.sendAnswer(3, 7, 'No');
    this.sendAnswer(4, 1, 'No');
    this.sendAnswer(4, 2, 'No');
    this.sendAnswer(4, 3, 'No');
    this.sendAnswer(4, 4, 'No');
    this.sendAnswer(4, 5, 'No');
  }
  sleep(ms) {
    return new Promise<void>(prom => {
      setTimeout(() => {
        prom();
      }, ms);
    });
  }

  sendAnswer(section: number, question: number, value: string) {
    let answer = {
      "section": Number(section),
      "question": Number(question),
      "value": value
    }
    if (this.answerQueue.length == 0) {
      this.answerQueue.push(answer);
    } else {
      if (this.answerQueue[this.answerQueue.length - 1].section == answer.section && this.answerQueue[this.answerQueue.length - 1].question == answer.question) {
        this.answerQueue.pop()
        this.answerQueue.push(answer)
      } else {
        this.answerQueue.push(answer)
      }
    }
  }

  nextQuest() {
    if(!this.test1.invalid){
      if (this.test1.controls.I1.value == "No"
          && this.test1.controls.I2.value == "No"
          && this.test1.controls.I3.value == "No"
          && this.test1.controls.I4.value == "No"
          && this.test1.controls.I5.value == "No"
          && this.test1.controls.I6.value == "No"

        ) {
        this.sendAllNo();
        }
      let answers = {
        "idCompany": this.idCompany,
        "idProject": this.idProject,
        "idEmployee": this.idEmployee,
        "guide": "guideOne",
        "answers": this.answerQueue
      }
      Swal.fire({
        title: 'Guía I',
        text: 'Terminando envio de respuestas',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.questionnares.sendAnswer(answers).subscribe(async res => {
        if (res.clave != "OK") {
          throw new Error("Error al mandar respuestas");
        } else {
          this.checkSend()
        }
      });
    }else{
      this.modal.warning({
        title: '¡Aun no has respondido todas las preguntas!',
        message: '',
      });
    }
  }

  async saveProgress() {
    if (this.answerQueue.length > 0) {
      if (this.test1.controls.I1.value == "No"
        && this.test1.controls.I2.value == "No"
        && this.test1.controls.I3.value == "No"
        && this.test1.controls.I4.value == "No"
        && this.test1.controls.I5.value == "No"
        && this.test1.controls.I6.value == "No"

      ) {
        this.answerQueue.splice(6, this.answerQueue.length)
      }

      let answers = {
        "idCompany": this.idCompany,
        "idProject": this.idProject,
        "idEmployee": this.idEmployee,
        "guide": "guideOne",
        "answers": this.answerQueue
      }
      Swal.fire({
        title: 'Guía I',
        text: 'Guardando respuestas',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      this.questionnares.sendAnswer(answers).subscribe(async res => {
        if (res.clave != "OK") {
          throw new Error("Error al mandar respuestas");
        } else {
          Swal.close();
        }
      });
    } else {
      Swal.fire({
        title: 'Guía I',
        text: 'Sin progreso que salvar',
        icon: 'info',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false
      });
      Swal.showLoading();
      await this.sleep(1000);
      Swal.close();
    }
  }

  checkSend() {
    this.questionnares.evalGuide({
      idProject: this.idProject, 
      idEmployee: this.idEmployee
    }).subscribe(res => {
      if(res && res.clave==="OK"){
        if(this.nextGuide == 1){
          
          this.questionnares.gratitudeEmail({
            idProject: this.idProject,
            idEmployee: this.idEmployee 
          }).subscribe(email => {});

          Swal.close();
          this.router.navigate(['/guides-summary'], {
            queryParams: {
              idEmployee: this.idEmployee,
              idCompany: this.idCompany, idProject: this.idProject, new: true, img: 1
            }
          });
        } else if (this.nextGuide == 2) {
          Swal.close();
          this.router.navigate(['/guide-two'], {
            queryParams: {
              idEmployee: this.idEmployee,
              idCompany: this.idCompany, idProject: this.idProject
            }
          });
        } else if (this.nextGuide == 3) {
          Swal.close();
          this.router.navigate(['/guide-three'], {
            queryParams: {
              idEmployee: this.idEmployee,
              idCompany: this.idCompany, idProject: this.idProject
            }
          });
        }
      }
    });
  }

  getAnswer() {
    this.employee.projectSelected.subscribe((res) => {
      if (res) {
        let quest = res;
        if (Object.keys(quest).length != 0) {
          if (quest.guideOne.finalized == true) {
            if (
              quest.guideOne.section[0].questions[0].value == 'No' &&
              quest.guideOne.section[0].questions[1].value == 'No' &&
              quest.guideOne.section[0].questions[2].value == 'No' &&
              quest.guideOne.section[0].questions[3].value == 'No' &&
              quest.guideOne.section[0].questions[4].value == 'No' &&
              quest.guideOne.section[0].questions[5].value == 'No'
            ) {
              this.prevStatus = true;
              this.blockSections = true;
              this.blockedTextTitle = "#D4D4D4"
              this.blockedText = "#D4D4D4"
              this.test1.controls.I1.setValue(quest.guideOne.section[0].questions[0].value);
              this.test1.controls.I2.setValue(quest.guideOne.section[0].questions[1].value);
              this.test1.controls.I3.setValue(quest.guideOne.section[0].questions[2].value);
              this.test1.controls.I4.setValue(quest.guideOne.section[0].questions[3].value);
              this.test1.controls.I5.setValue(quest.guideOne.section[0].questions[4].value);
              this.test1.controls.I6.setValue(quest.guideOne.section[0].questions[5].value);

              this.test1.controls.I7.setValue(quest.guideOne.section[1].questions[0].value);
              this.test1.controls.I8.setValue(quest.guideOne.section[1].questions[1].value);
              this.test1.controls.I9.setValue(quest.guideOne.section[2].questions[0].value);
              this.test1.controls.I10.setValue(quest.guideOne.section[2].questions[1].value);
              this.test1.controls.I11.setValue(quest.guideOne.section[2].questions[2].value);
              this.test1.controls.I12.setValue(quest.guideOne.section[2].questions[3].value);
              this.test1.controls.I13.setValue(quest.guideOne.section[2].questions[4].value);
              this.test1.controls.I14.setValue(quest.guideOne.section[2].questions[5].value);
              this.test1.controls.I15.setValue(quest.guideOne.section[2].questions[6].value);
              this.test1.controls.I16.setValue(quest.guideOne.section[3].questions[0].value);
              this.test1.controls.I17.setValue(quest.guideOne.section[3].questions[1].value);
              this.test1.controls.I18.setValue(quest.guideOne.section[3].questions[2].value);
              this.test1.controls.I19.setValue(quest.guideOne.section[3].questions[3].value);
              this.test1.controls.I20.setValue(quest.guideOne.section[3].questions[4].value);
              this.test1.disable()
            } else {
              this.prevStatus = false;
              this.blockSections = false;
              this.blockedTextTitle = "#212529"
              this.blockedText = "#212529"
              this.test1.controls.I1.setValue(quest.guideOne.section[0].questions[0].value);
              this.test1.controls.I2.setValue(quest.guideOne.section[0].questions[1].value);
              this.test1.controls.I3.setValue(quest.guideOne.section[0].questions[2].value);
              this.test1.controls.I4.setValue(quest.guideOne.section[0].questions[3].value);
              this.test1.controls.I5.setValue(quest.guideOne.section[0].questions[4].value);
              this.test1.controls.I6.setValue(quest.guideOne.section[0].questions[5].value);

              this.test1.controls.I7.setValue(quest.guideOne.section[1].questions[0].value);
              this.test1.controls.I8.setValue(quest.guideOne.section[1].questions[1].value);
              this.test1.controls.I9.setValue(quest.guideOne.section[2].questions[0].value);
              this.test1.controls.I10.setValue(quest.guideOne.section[2].questions[1].value);
              this.test1.controls.I11.setValue(quest.guideOne.section[2].questions[2].value);
              this.test1.controls.I12.setValue(quest.guideOne.section[2].questions[3].value);
              this.test1.controls.I13.setValue(quest.guideOne.section[2].questions[4].value);
              this.test1.controls.I14.setValue(quest.guideOne.section[2].questions[5].value);
              this.test1.controls.I15.setValue(quest.guideOne.section[2].questions[6].value);
              this.test1.controls.I16.setValue(quest.guideOne.section[3].questions[0].value);
              this.test1.controls.I17.setValue(quest.guideOne.section[3].questions[1].value);
              this.test1.controls.I18.setValue(quest.guideOne.section[3].questions[2].value);
              this.test1.controls.I19.setValue(quest.guideOne.section[3].questions[3].value);
              this.test1.controls.I20.setValue(quest.guideOne.section[3].questions[4].value);
              this.test1.disable()
            }

          } else {
            this.initForm()
          }
        }
      }
    });
  }
}
