import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/internal/operators';
import { ResponseContent } from '../../models';
import { BehaviorSubject } from 'rxjs';
import { IChangelogModel, ICreateChangelogModel } from '../../models/changelogs/changelogs.model';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChangelogsService {

  constructor(
    private http: HttpService
  ) {}

  changelogs = new BehaviorSubject<IChangelogModel[]>([]);

  setChangelog(changelog: ICreateChangelogModel){
    this.http.post('/changeLog/setChange', {changelog}, true).subscribe((response: ResponseContent) => { });
  }

  getChangelogs(id){
    return this.http.post('/changeLog/getChanges', {id}, true).pipe(
      map((response: ResponseContent) => {
        if(response && response.clave == 'OK'){
          this.changelogs = response.content;
          return response.content
        }
        }
      )
    );
  }

}
