import { IPermissioonModel } from '../models/addOn/permission.model';

export const permissionsOff: IPermissioonModel = {
  generalData: {
    viewAndDownloadCompanyData: false,
    editCompanyData: false,
    viewAndDownloadDataForFoldr: false,
    editDataForFolder: false,
    viewAndDownloadDeploymentData: false,
    editDeploymentData: false,
    editDataForPrograms: false,
    viewAndDownloadDataForPrograms: false,
  },
  configuredUserManagement: {
      view: false,
  },
  addFiles: { 
      single: false, 
      bulkLoad: false, 
  },
  history: {
      view: false,
  },
  Nom035: {
      viewGeneralReportCompany: false,
      viewReportByWorkCenter: false,
      viewReportByArea: false,
      sendQuestionnaires: false,
      viewStatistics: false,
  },
  /* fileConsultation: {
      deleteEmployeeFiles: false,
      allowEmployeesViewReports: false,
  }, */
  employeeFile: {
      viewNom035: false,
      viewPersonalData: false,
      editPersonalData: false,
      resendQuestionnaires: false,
      deleteEmployeeFiles: false
  },
  complaintBox: {
      complaintBoxManagement: false,
  },
  communication:{
    toPost: false,
    editPost: false,
    deletePost: false,
    comment: false,
    removeThirdPartyComments: false
  },
  invitations:{
    createInvitation : false,
    editInvitation: false,
    deleteInvitation: false,
    manageInvited: false,
    manageEvidence: false
  },
  workingEnvironment:{
    viewReports : false,
    filterReports: false,
    sendQuestionnaires: false,
    viewStats: false
  }
};
