import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import Swal from 'sweetalert2';
import { ResponseContent } from '../../models';
import { map } from 'rxjs/operators';
import { Swal2Loader } from '@consts/swal2-loader';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpService
  ) { }


  /**
 * funcion para obtener logs
 *
 * @returns
 * @memberof LogService
 */
  getLogs() {

    Swal.fire(Swal2Loader)

    return this.http.post('/log/get', {}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res.content;
      }
    }));
  }

    /**
 * funcion para obtener logs por colaborador
 *
 * @returns
 * @memberof LogService
 */
    getLogsByCollaborator(id:string) {

      Swal.fire(Swal2Loader)
  
      return this.http.post('/log/getByCollaborator', {id}, true).pipe(map((res: ResponseContent) => {
        if (res && res.clave === 'OK') {
          Swal.close();
          return res.content;
        }
      }));
    }

    getReport(id:string, date:Date) {

      Swal.fire(Swal2Loader)
  
      return this.http.post('/log/getReport', {id, date}, true).pipe(map((res: ResponseContent) => {
        if (res && res.clave === 'OK') {
          Swal.close();
          return res.content;
        }
      }));
    }


  /**
* funcion para crear logs
*
* @param {*} email
* @returns
* @memberof LogService
*/
  createLog(log) {

    Swal.fire(Swal2Loader)

    return this.http.post('/log/create', log, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res;
      }
    }));
  }


  /**
* funcion para actualizar logs
*
* @param {*} email
* @returns
* @memberof LogService
*/
  updateLogs(log) {

    Swal.fire(Swal2Loader)

    return this.http.post('/log/update', log, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res;
      }
    }));
  }


}
