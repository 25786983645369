<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="homeRoute"> <span class="time-icon-home"></span> <span class="home-label ml-3">Inicio</span> </a>
        </li>
        <li class="breadcrumb-item" *ngFor="let crumb of crumbs">
            <span class="time-icon-paginator_next"></span>
            <a [routerLink]="crumb.route"> {{ crumb.title }} </a>
        </li>
    </ol>
</nav>
