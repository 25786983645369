import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompaniesService } from '../../../services/companies/companies.service';
import { ICompanyModel } from '../../../models/companies/companies.model';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorsService } from '../../../services/errors.service';
import { ResponseContent} from '../../../models';
import { ModalService } from '../../../services/modal/modal.service';
import { FilterModel } from 'src/app/models/filter/filter.model';
import { FiltersService } from 'src/app/services/filters/filters.service';
import { ICreateChangelogModel } from 'src/app/models/changelogs/changelogs.model';
import { ChangelogsService } from '../../../services/changelogs/changelogs.service';
import {suscriberDestroyMiddleware} from '../../../middleware/suscribers-destroy.middleware';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import * as environment from 'src/environments/environment';

import Swal from 'sweetalert2/dist/sweetalert2.js';

/**
 * Componente para la administración de empresas
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
@suscriberDestroyMiddleware()

export class CompanyManagementComponent implements OnInit, OnDestroy {
  @ViewChild('dismisseModal') dismisseModal: ElementRef;
  // Validación del formulario para agregar usuaro configurado
  addCompanyForm: FormGroup;
  // Logo de la empresa
  logo: string;
  // Id de la empresa a eliminar
  idCompany: string;
  // Nombre de la empresa a eliminar
  nameCompany: string;
  // Arreglo de empresas ACTIVADAS
  companiesArray: ICompanyModel[] = [];

  //Para saber si es multiempresa
  isMultiempresa=false;

  // Alamacena suscripciones activas
  subscriber: any = [];
  // Almacena información del usuario logueado
  userInfoLogged;
  // Id de la empresa seleccionada
  idCompanySelected;

  // Id del nuevo logo
  idImage;
  // Nuevo logo de la empresa
  newImageCompany;
  nameImage = '';
  exteImg = '';
  nameData: any;
  // Folder del logo
  folderProfile = 'logoCompany';
  filterArray: FilterModel[] = [];
  searchFilter = '';
  info: ICompanyModel[] = [];
  show: boolean;
  search: string = '';
  // Texto para botón de licencia
  buttonText = 'Comprar licencia';
  // Habilita edición/eliminar dependiendo de los privilegios de licencia
  unableAdminCompany = false;
  // Habilita acciones dependiendo de privilegios de licencia
  unableActions = false;
  // Deshabilita/habilita boton de renovación
  disableButton = true;

  /**
   * Creates an instance of CompanyManagementComponent.
   */
  constructor(private fb: FormBuilder, private errorsService: ErrorsService,
              private companyService: CompaniesService,
              private authService: AuthService,
              private filterService: FiltersService,
              private modalService: ModalService,
              private history: ChangelogsService,
              private router: Router,
              private criptoService: CryptoService) {
    this.subscriber.user = this.authService.userInfoLogged.subscribe(res => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
      this.unableEdit();
    });

    this.idCompanySelected = sessionStorage.getItem('idCompanySelected');
  }

  /**
   * Inicialización de componente
   */
  ngOnInit(): void {
    this.initializeCompany();
    this.getCompanies();
  }

  /**
   * Inicializa formulario para agregar empresa
   */
  initializeCompany(): void {
    this.addCompanyForm = this.fb.group({
      companyName: ['', Validators.compose([Validators.required])],
      logo: ['']
    });
  }

  /**
   * Obtiene las empresas del usuario Admin logueado
   */
  getCompanies(): void {
    this.subscriber.companies = this.authService.allCompanies.subscribe((res: ICompanyModel[]) => {
      this.validateCompanies(res);
    });
  }

  /**
   * Valida información de las empresas
   */
  validateCompanies(array: ICompanyModel[]): void {
    if (array && array.length > 0) {
      this.info = array;
      this.companiesArray = array;
      this.show = true;
      if(this.companiesArray.length >= 5 ){
        this.unableActions = false;
      }
    } else {
      this.info = [];
      this.companiesArray = [];
      this.show = true;
    }
  }

  /**
   * Envía petición para agregar una empresa
   */
  addCompany(companyData): void {


    if ( this.addCompanyForm.status === 'VALID') {

      Swal.fire({title: 'Guardando',icon: 'info',allowOutsideClick: false,allowEnterKey: false,allowEscapeKey: false});
      Swal.showLoading();

      let object;
      if (this.logo) {
        object = {
          companyName: companyData.companyName,
          responsable: {
            name: this.userInfoLogged.name
          },
          logo: this.nameImage
        };
      } else {
        object = {
          companyName: companyData.companyName,
          responsable: {
            name: this.userInfoLogged.name
          }
        };
      }

      this.subscriber.company = this.companyService.createCompany(object).subscribe((response: any) => {
        if (response && response.clave === 'OK') {
            this.subscriber.image = this.companyService.addImage(this.newImageCompany).subscribe((res: ResponseContent) => {
              Swal.close();
              if (res && res.clave === 'OK') {
              }
              this.companyService.getAllCompanies(this.userInfoLogged._id);

              this.modalService.successful({
                title: '¡Operación exitosa!',
                message: 'La empresa ha sido agregada.'
              });
            });
          // Objeto que guarda datos para historial
          const changelog: ICreateChangelogModel = {
            idCompany: this.idCompanySelected,
            change: {
              img: this.userInfoLogged.profilePicture ? this.userInfoLogged.profilePicture : '',
              name: `${this.userInfoLogged.name} ${this.userInfoLogged.lastName ? this.userInfoLogged.lastName : ''} ${this.userInfoLogged.secondSurname ? this.userInfoLogged.secondSurname : ''}`,
              date: new Date(),
              action: `Agregó empresa: ` + response.content.companyName
            }
          };
          // Funcion que envia los datos de la historia
          this.history.setChangelog(changelog);
        }
      });

      this.addCompanyForm.reset();
      this.logo = '';
      document.getElementById('cancelAddCompany').click();

    } else {
      this.modalService.warning({
        title: '¡Atención!',
        message: 'Introduzca información válida.'
      });
    }
  }

  /**
   * Subir logo de empresa
   */
  uploadImage(file: any): void {
    if (file.length > 0) {
      this.idImage = Math.random().toString(26).replace('0.', '');
      this.newImageCompany = new FormData();
      const array = file[0].type.split('/');
      this.nameImage = `${this.idImage}.${array[1]}`;
      this.exteImg = `.${array[1]}`;
      this.nameData = `${this.idImage}.${array[1]},${this.folderProfile}`;
      this.newImageCompany.append('file', file[0], this.nameData);
      const mimeType = file[0].type;
      if (mimeType.match(/image\/*/) === null) {
        this.newImageCompany = '';
        alert('Solo se aceptan archivos de tipo imagen');
        return;
      }
      if (this.exteImg !== '.jpeg' && this.exteImg !== '.jpg' && this.exteImg !== '.png') {
        this.newImageCompany = '';
        alert('Solo se aceptan imágenes JPEG, JPG, PNG');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event) => {
        this.logo = reader.result.toString();
      };
    }
  }

  /**
   * Resetear formulario para agregar empresa
   */
  resetForm(): void {
    this.addCompanyForm.reset();
    this.logo = '';
  }

  /**
   * Elimina la empresa seleccionada
   */
  // deleteCompany(): void {
  //   if (!this.unableActions) {
  //     return;
  //   }

  //   if (this.idCompanySelected !== this.idCompany) {

  //     Swal.fire({title: 'Eliminando',icon: 'info',allowOutsideClick: false,allowEnterKey: false,allowEscapeKey: false});
  //     Swal.showLoading();

  //      this.subscriber.deleteCompany = this.companyService.deleteCompany(this.idCompany).subscribe((response: any) => {
  //       Swal.close();
  //       if (response && response.clave === 'OK') {
  //         this.companyService.getAllCompanies(this.userInfoLogged._id);
  //         this.modalService.successful({
  //           title: '¡Operación exitosa!',
  //           message: 'La empresa ha sido eliminada.'
  //         });
  //       }
  //        // Objeto que guarda datos para historial
  //       const changelog: ICreateChangelogModel = {
  //         idCompany: this.idCompanySelected,
  //         change: {
  //           img: this.userInfoLogged.profilePicture ? this.userInfoLogged.profilePicture : '',
  //           name: `${this.userInfoLogged.name} ${this.userInfoLogged.lastName ? this.userInfoLogged.lastName : ''}`,
  //           date: new Date(),
  //           action: `Eliminó la empresa: ${response.content.companyName}`
  //         }
  //       };
  //       // Funcion que envia los datos de la historia
  //       this.history.setChangelog(changelog);
  //     });
  //   } else {
  //     this.modalService.warning({
  //       title: '¡Atención!',
  //       message: 'No es posible eliminar la empresa seleccionada.'
  //     });
  //   }
  // }

  /**
   * Abre modal con confirmación de eliminación de empresa
   */
  // openModalData(idCompany, nameCompany): void {
  //   this.idCompany = idCompany;
  //   this.nameCompany = nameCompany;
  // }

  /**
   * Buscar empresa por nombre
   */
  searchFilterCompany(data: string): void{
    this.search=data;
    this.filterArray=[];
    if(data.length>0){
      const temporal: ICompanyModel[] = this.filterService.searchCompany(this.info,data);
      if (temporal.length > 0) {
        this.companiesArray = temporal;
         this.show = true;
       } else {
        this.companiesArray = [];
         this.show = false;
       }
     } else {
       this.validateCompanies(this.info);
     }
  }

  /**
   * Define permisos para agregar/editar/eliminar empresas
   */
  unableEdit(): void {
    if (this.unableAdminCompany) {
      if ((this.userInfoLogged.role === 'admin' || this.userInfoLogged.role === 'root') && this.companiesArray.length < 5) {
        this.unableActions = true;
      }
    }
  }

  /**
   * Destruir susbscripciones activas
   */
  ngOnDestroy(): void {
    if(this.dismisseModal)
      this.dismisseModal.nativeElement.click();//Se cierra el modal para evitar residuos cuando lo saquen de sesión

      if (this.subscriber.company){
      this.subscriber.company.unsubscribe();
    }
    if (this.subscriber.companies) {
      this.subscriber.companies.unsubscribe();
    }
    if (this.subscriber.user) {
      this.subscriber.user.unsubscribe();
    }
    if (this.subscriber.image) {
      this.subscriber.image.unsubscribe();
    }
    if (this.subscriber.idCompanySelected) {
      this.subscriber.idCompanySelected.unsubscribe();
    }
  }
}
