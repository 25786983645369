import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http.service';
import Swal from 'sweetalert2';
import { ResponseContent } from '../../models';
import { map } from 'rxjs/operators';
import { Swal2Loader } from '@consts/swal2-loader';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private http: HttpService
  ) { }


  /**
 * funcion para obtener equipos
 *
 * @returns
 * @memberof TeamService
 */
  getTeams() {

    Swal.fire(Swal2Loader)

    return this.http.post('/team/get', {}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res.content;
      }
    }));
  }

  getLeaders() {

    return this.http.post('/team/getLeaders', {}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        return res.content;
      }
    }));
  }

  getTeamsByLeader(id: string) {
    return this.http.post('/team/getTeamsByLeader', {id}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        return res.content;
      }
    }));
  }

  getEmployeeInfo(id: string){

    Swal.fire(Swal2Loader)

    return this.http.post('/team/getEmployeeInfo', {id}, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res.content;
      }
    }));
  }


  /**
* funcion para crear Team
*
* @param {*} email
* @returns
* @memberof TeamService
*/
  createTeam(Team) {

    Swal.fire(Swal2Loader)

    return this.http.post('/team/create', Team, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res;
      }
    }));
  }


  /**
* funcion para actualizar Team
*
* @param {*} email
* @returns
* @memberof TeamService
*/
  updateTeam(Team) {

    Swal.fire(Swal2Loader)

    return this.http.post('/team/update', Team, true).pipe(map((res: ResponseContent) => {
      if (res && res.clave === 'OK') {
        Swal.close();
        return res;
      }
    }));
  }

  
  /**
* funcion para eliminar Team
*
* @param {*} email
* @returns
* @memberof TeamService
*/
deleteTeam(id) {
  return this.http.post('/team/delete', {id}, true).pipe(map((res: ResponseContent) => {
    if (res && res.clave === 'OK') {
      Swal.close();
      return res;
    }
  }));
}


}
