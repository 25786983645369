import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/* INTERCEPTORS */
import { RequestInterceptor } from './request/request.interceptor';
import { ResponseInterceptor } from './response/response.interceptor';

/* SERVICES */
import { TokenService } from '../services/token/token.service';

/**
 * Módulo contenedor de los elementos necesarios
 * para interceptores
 * @export
 * @class InterceptorsModule
 */
@NgModule({
	providers: [
	  	TokenService,
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
	]
})
export class InterceptorsModule {}
