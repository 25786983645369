<div class="container">
    <div class="row">
        <div class="col-12">
            <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="col-12">
            <p class="title mt-4">
               {{tmRole === 'pmo' ? 'Proyectos y asignaciones (PMO)' : 'Proyectos'}}
            </p>
        </div>
            <div class="col-xs-12 m-auto" *ngIf="tmRole === 'pmo'">
                <div class="content-options">
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'propuesta'}"
                        (click)="changeActive('propuesta')">
                        Propuestas
                    </div>
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'proyecto'}"
                        (click)="changeActive('proyecto')">
                        Proyectos
                    </div>
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'asignacion'}"
                        (click)="changeActive('asignacion')">
                        Asignaciones
                    </div>
                </div>
            </div>
            <div class="col-12 mt-5" *ngIf="tmRole === 'pmo'">
                <div class="menus p-0">
                    <button mat-button [matMenuTriggerFor]="orderMenu" #orderMenuTrigger="matMenuTrigger">
                        Ordenar
                        <span class="align-middle" [ngClass]="orderMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
                    </button>
                    <mat-menu #orderMenu="matMenu">
                        <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                            Ordenar por
                        </button>
                        <button mat-menu-item (click)="checkOrder('asc')" class="menu-filter-item">
                            <mat-checkbox [checked]="sortDirection === 'asc'" disabled="true">Ascendente</mat-checkbox>
                        </button>
                        <button mat-menu-item (click)="checkOrder('desc')" class="menu-filter-item">
                            <mat-checkbox [checked]="sortDirection === 'desc'" disabled="true">Desendente</mat-checkbox>
                        </button>
                    </mat-menu>
                
                    <button mat-button [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                        Filtrar
                        <span class="align-middle" [ngClass]="filterMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
                    </button>
                    <mat-menu #filterMenu="matMenu">
                        <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                            Filtrar por
                        </button>
                        <button mat-menu-item *ngFor="let name of leadersName" (click)="applyFilter(name)" class="menu-filter-item">
                            <mat-checkbox [checked]="currentFilter === name" disabled="true">{{name}}</mat-checkbox>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="col-12">
                <div class="row mb-5 mt-3 p-3 p-sm-0 cards-container custom-scroll">
                    <div class="col-11 col-sm-6 col-md-4 pl-0 pr-2 p-sm-3" *ngFor="let project of getElementToShow()" id="elm">
                        <a class="project--card p-3 p-md-4 deep-icon-options deep-button" [routerLink]="'project'" (click)="dataToDetail(project)">
                            <div class="status-chip">{{project.statusOportunity}}</div>
                            <img [src]="project.responsable2bcoreDetails[0].image ? (project.responsable2bcoreDetails[0].image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="avatar-40 mt-4"
                                [alt]="'Photo of ' + project.responsable2bcoreDetails.name" />
                            <h3 class="project--title mt-3 text-truncate">{{ project.name}}</h3>
                            <p class="project--code mt-2 text-truncate">{{ project.code }}</p>
                            <p class="project--client mt-4">Cliente: <b>{{ project.client }}</b> </p>
                            <div class="row align-items-center mt-3" [ngClass]="activeFlux === 'proyecto' ? 'pb-3 project--divider' : ''">
                                <div class="col">
                                    <app-avatar-collection [allEmployees]="project.authorizedStaffDetails"
                                        [team]="project.authorizedStaffDetails"></app-avatar-collection>
                                </div>

                                <div class="col-auto" *ngIf="activeFlux === 'proyecto'">
                                    <span class="time-icon-Comment align-middle mr-2 position-relative">
                                        <span class="project--comments-new" *ngIf="project.newMessages"></span>
                                    </span>
                                    <!--TODO: new comments change icon -->
                                    <span class="project--comments-count align-middle">
                                        {{ project.commentsCount }}
                                    </span>
                                </div>
                            </div>
                            <button mat-flat-button color="primary" class="mt-4" *ngIf="activeFlux === 'proyecto'"> Ver reporte </button>
                        </a>
                    </div>
                </div>
                <app-paginator [elementsByPage]="elementsByPage" [elementsCount]="projectsTemplate.length" (pageEmitter)="setPage($event)"></app-paginator>
            </div>

    </div>
</div>