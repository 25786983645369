import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para encriptacion
 */
export class CryptoService {

  /**
   * Creates an instance of CryptoService.
   * @memberof CryptoService
   */
  constructor() { }

  /**
   *
   * Method is use for encrypt the value.
   * @param {*} keys
   * @param {*} value
   * @returns
   * @memberof CryptoService
   */
  encryptData(keys, value): string {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse('C&F)J@NcRfUjXn2r');
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  /**
   *
   * Method is use for decrypt the value.
   * @param {*} keys
   * @param {*} value
   * @returns
   * @memberof CryptoService
   */
  decryptData(keys, value): string{
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse('C&F)J@NcRfUjXn2r');
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptHex(keys: string, value: string): string {
    var b64 = CryptoJS.AES.encrypt(value, keys).toString();
    var e64 = CryptoJS.enc.Utf8.parse(b64);
    var eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }

  decryptHex(keys: any, value: any): string {
    var reb64 = CryptoJS.enc.Hex.parse(value);
   var bytes = reb64.toString(CryptoJS.enc.Utf8);
   var decrypt = CryptoJS.AES.decrypt(bytes, keys);
   var plain = decrypt.toString(CryptoJS.enc.Utf8);
   return plain;
}

}
