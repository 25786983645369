import { Component,OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/models/user/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';

@Component({
  selector: 'app-choose-role',
  templateUrl: './choose-role.component.html',
  styleUrls: ['./choose-role.component.scss']
})
export class ChooseRoleComponent implements OnInit {
  generalRoles = [
    'employee','addOn','admin','root'
  ]
  rolesUser:any;

  currentRole: string = '';

  constructor(
    private bsModalRef: BsModalRef,
    private authSv: AuthService,
    private companieService: CompaniesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentRole = sessionStorage.getItem('role');
    this.authSv.getRoles().subscribe((dataUser: User)=>{
   /*   this.authSv.idCompanieSelected.next(dataUser.content.notifications[0].idCompany);
      sessionStorage.setItem('idCompanySelected', dataUser.content.notifications[0].idCompany);*/
      if(dataUser){

        sessionStorage.setItem('idUser', dataUser._id);
        this.rolesUser = dataUser.roles.filter(elem => this.generalRoles.includes(elem));
        
      }else{
        this.hideModal();
        this.authSv.signOut(false);
      }

    });
  }

  chooseRole(chooseRoleForm: NgForm): void{

    const role = chooseRoleForm.value.role;
    if(this.currentRole == role){ return; }

    if (chooseRoleForm.valid){
      this.authSv.chooseRoles(role).subscribe(res => {
        if(res?.clave == 'OK'){
          this.bsModalRef.hide();
          this.companieService.themeSelected.reload();
          this.router.navigateByUrl('/home/init-page');
        }
      });
    }

  }

  hideModal(): void{

    if(!sessionStorage.getItem('isLogged')){
      this.bsModalRef.hide();
      this.authSv.signOut();
    }
    else {
      if (!sessionStorage.getItem('role')){
        this.authSv.signOutSimple();
      }
    }
    this.bsModalRef.hide();
  }

}
