<div bsModal #shownModal="bs-modal" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-content">
    <div class="modal-body py-5">
      <div class="mt-4 d-flex flex-column justify-content-center w-70 mx-auto">
        <p class="mt-3 mb-4 txt-main fw-semibold fs-14 text-center">¿Qué tipo de usuario deseas utilizar?</p>
        <form (ngSubmit)="chooseRole(chooseRoleForm)" #chooseRoleForm="ngForm" id="chooseRoleForm">
          <fieldset>
            <div class="d-flex justify-content-around">
              <div *ngFor="let role of rolesUser" class="d-flex">
                <input class="radio-input cr-pointer" type="radio" name="role" id="{{role+'Role'}}" value="{{role}}" ngModel
                  required>
                <label class="radio-label cr-pointer" for="{{role+'Role'}}">{{role | showRoles}}</label>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="mt-5 mb-4 d-flex justify-content-center">
        <button class="btn btn-primary mx-2" type="submit" form="chooseRoleForm"
          [disabled]="chooseRoleForm.invalid">Aceptar</button>
        <button class="btn btn-secondary mx-2" aria-label="Close" (click)="hideModal()">Cancelar</button>
      </div>
    </div>
  </div>
</div>