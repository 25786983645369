/** RXJS */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
/** SERVICES */
import { ErrorService } from '../../services/error-service/error.service';
/** INTERFACES */
/**
 * Guard |
 * @export
 * @class PrivateRouteGuard
 * @implements {CanActivate}
 * @implements {OnDestroy}
 */
@Injectable()
export class  PrivateRegisterRouteGuard implements CanActivate, CanActivateChild, OnDestroy {
  /** lista de suscripciones */
  subscribers: any = {};
  /** login */
  isLoggedIn = false;

  /**
   *Creates an instance of PrivateRouteGuard.
   * @param {AuthenticationService} authService
   * @memberof PrivateRouteGuard
   */
  constructor(
    private route: Router,
    private authService: ErrorService) {
    this.subscribers.log = this.authService.isRegister.subscribe(
      (res: boolean) => {
        this.isLoggedIn = res;
      }
    );
  }

  /**
   * funciones necesarias para activacion de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PrivateRouteGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Fn | Revisa credenciales si está definido cliente y se retorna a `inicio`
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PublicRouteGuard
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Función para validación de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof PrivateRouteGuard
   */
  checkRoute(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isLoggedIn) {
      this.route.navigate(['/']);
    }
    return this.isLoggedIn;
  }

  /**
   * Destructor
   * @memberof PrivateRouteGuard
   */
  ngOnDestroy() {}
}
