import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from 'src/app/services';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service';

@Component({
  selector: 'app-guides-summary',
  templateUrl: './guides-summary.component.html',
  styleUrls: ['./guides-summary.component.scss']
})
export class GuidesSummaryComponent implements OnInit {
  examExist = false;
  isMobile = true;

  //DataSession
  private idEmployee: string;
  private idCompany: string;
  private idProject: string;
  private nameProject: string;

  //Models
  infoEmployee: any;
  nameCompany: string = "";

  //Style
  progressBar: number = 3;

  //Data
  solveDate = new Date();

  //Misc
  nextGuide;
  new: string;


  constructor(
    private route: ActivatedRoute,
    private questionnares: QuestionnairesService,
  ) {
    this.idEmployee = this.route.snapshot.queryParamMap.get('idEmployee');
    this.idCompany = this.route.snapshot.queryParamMap.get('idCompany');
    this.idProject = this.route.snapshot.queryParamMap.get('idProject');
    this.new = this.route.snapshot.queryParamMap.get('new');
    this.nextGuide = this.route.snapshot.queryParamMap.get('img');
    if (this.new && this.new != '') {
      if (this.new == 'true') {
        this.examExist = true;
      } else if (this.new == 'fasle'){
        this.examExist = false 
      }
    }
    if (this.nextGuide == 1) {
      this.progressBar = 0;
    } else if (this.nextGuide == 2) {
      this.progressBar = 1;
    } else if (this.nextGuide == 3) {
      this.progressBar = 2;
    }

  }

  ngOnInit(): void {

    this.questionnares.getEmployee(this.idEmployee).subscribe(res => {
      if (res) {
        this.infoEmployee = res.content;
  
        if (this.infoEmployee.idCompany == this.idCompany) {
          this.questionnares.getCompanyName(this.infoEmployee.idCompany).subscribe((response) => {
            if (response) {
              this.nameCompany = response.content.companyName
              
            }
          });
        } else {
          throw new Error("Empleado no perteneciente a esta compañia");
        }
      }
    })
  }

  getDate() {
    this.questionnares.getProjectName(this.idProject).subscribe(res => {
      this.questionnares.getEmployeeQuests(this.idCompany,this.idEmployee).subscribe(quests => {
        quests.content.forEach(element => {
          if (element.nameProject === res.content) {
            this.solveDate = element.questionnaire.solveDate;
          }
        });
      });
    });



  }

}
