import { Injectable } from '@angular/core';
import { ModalService } from '../services/modal/modal.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class GlobalIntermediaryServices {

    constructor(
        private modalS: ModalService,
        private auth: AuthService
    ){}

    desconectado(){
        this.auth.statusConnection.next(false);
        setTimeout((()=>{
            if(!this.auth.statusConnection.getValue()){
            this.modalS.connectionError();
            } 
        }),5000);
    }

    conectado(){
        this.auth.statusConnection.next(true);
        if(this.auth.statusConnection.getValue()){//para afectar el cerrado de la alerta de conexión solamente
            this.modalS.close();
        }
        
    }

}
