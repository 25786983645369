<div class="container w-90 mb-5">
    <div class="row align-items-center mb-5">
        <div class="col-12 mb-4">
            <app-breadcrumb [crumbs]="breadcrumb"> </app-breadcrumb>
        </div>
        <div class="col-12">
            <p class="title mb-3">Registro de horas</p>
        </div>
        <div class="col-12 col-md-7">
            <p class="title-text mb-0">
                Registrar tus actividades nos ayuda a asegurar que los proyectos y actividades avancen sin problemas.
                Gracias por tu colaboración.
            </p>
        </div>
        <div class="col-12 col-md-5 d-flex flex-column flex-md-row justify-content-end mt-3 mt-md-0 deep-button">
            <button mat-flat-button color="primary" class="my-3 my-md-0 mr-0 mr-md-2" routerLink="/register">Nueva actividad</button>
            <button mat-stroked-button color="primary" routerLink="/record">Ver historial</button>
        </div>
    </div>

    <div class="row align-items-center justify-content-between mb-5">
        <div class="col-6 col-md-auto calendar__date">
            <h3 class="month-title mb-0 text-truncate">{{ viewDate | calendarDate:'monthViewTitle' | titlecase }}</h3>
        </div>

        <div class="col-12 col-md text-center calendar__tabs">
            <div class="btn-group">
                <div class="d-flex align-items-center justify-content-center mr-1 btn__calendar"
                    [class.btn__calendar--disabled]="view !== CalendarView.Week" (click)="setView(CalendarView.Week)">
                    Semanal
                </div>
                <div class="d-flex align-items-center justify-content-center ml-1 btn__calendar"
                    [class.btn__calendar--disabled]="view !== CalendarView.Month" (click)="setView(CalendarView.Month)">
                    Mensual
                </div>
            </div>
        </div>

        <div class="col-6 col-md-auto text-right calendar__controls">
            <div class="btn-group align-items-center">
                <div class="d-flex align-items-center justify-content-center control__arrow" mwlCalendarPreviousView
                    [view]="view" [(viewDate)]="viewDate" [excludeDays]="excludeDays">
                    <span class="time-icon-back"></span>
                </div>
                <div class="d-flex align-items-center justify-content-center control__today mx-2" mwlCalendarToday
                    [(viewDate)]="viewDate">
                    Hoy
                </div>
                <div class="d-flex align-items-center justify-content-center control__arrow" mwlCalendarNextView
                    [view]="view" [(viewDate)]="viewDate" [excludeDays]="excludeDays">
                    <span class="time-icon-back" style="rotate: 180deg"></span>
                </div>
            </div>
        </div>
    </div>

    <div [ngSwitch]="view" class="row">
        <div class="col-12 month-calendar" *ngSwitchCase="CalendarView.Month">
            <mwl-calendar-month-view [viewDate]="viewDate" [cellTemplate]="customCellTemplate" [events]="events"
                [excludeDays]="excludeDays" [activeDayIsOpen]="false" (dayClicked)="dayClicked($event.day)"
                [locale]="locale">
            </mwl-calendar-month-view>
            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                <div class="cal-cell-top">
                    <!-- Show the status icon for each day -->
                    <span class="cal-status-icon">
                        <ng-container *ngIf="day.events.length > 0; else monthEmptyStatus">
                            <ng-container *ngFor="let event of day.events">
                                <ng-container [ngSwitch]="event.status">
                                    <ng-container *ngSwitchCase="STATUS.APPROVED">
                                        <span class="time-icon-status_approved align-middle">
                                            <span class="path1"></span><span class="path2"></span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="STATUS.REJECTED">
                                        <span class="time-icon-status_rejected align-middle">
                                            <span class="path1"></span><span class="path2"></span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="STATUS.PENDING">
                                        <span class="time-icon-status_incomplete align-middle"><span class="path1"></span><span
                                                class="path2"></span><span class="path3"></span></span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="STATUS.EMPTY">
                                        <img src="assets/icons/status/status_empty.svg" alt="Empty status" class="status_empty">
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #monthEmptyStatus>
                            <img src="assets/icons/status/status_empty.svg" alt="Empty status" class="status_empty">
                        </ng-template>
                    </span>

                    <!-- Default day number -->
                    <span class="cal-day-number">
                        <span class="day">{{ day.date | calendarDate:'monthViewDayNumber':locale }} </span>
                        <span class="month">{{isSameMonth(day.date)}}</span>
                    </span>
                </div>
            </ng-template>
        </div>

        <div *ngSwitchCase="CalendarView.Week" class="col-12 week-calendar">
            <mwl-calendar-week-view #calendarWeek [hidden]="true" [viewDate]="viewDate" [events]="events"
                [excludeDays]="excludeDays">
            </mwl-calendar-week-view>

            <div class="cards custom-scroll">
                <div class="card" *ngFor="let day of getWeek(calendarWeek.days, calendarWeek.events)">
                    <p class="text-capitalize mb-2">{{ day.date | date:'EEEE' : locale }}</p>
                    <p class="card__day-number mb-3">
                        <span class="circle-day" [class.today]="day.isToday">{{ day.date |
                            calendarDate:'monthViewDayNumber':locale }} </span>
                        <span class="month">{{isSameMonth(day.date)}}</span>
                    </p>

                    <p class="text-left mb-5" [class.is-future]="day.isFuture">
                        <ng-container *ngIf="day.event; else weekEmptyStatus">
                            <ng-container [ngSwitch]="day.event.status">
                                <ng-container *ngSwitchCase="STATUS.APPROVED">
                                    <span class="week-icons time-icon-status_approved">
                                        <span class="path1"></span><span class="path2"></span>
                                    </span>
                                </ng-container>
                                <ng-container *ngSwitchCase="STATUS.REJECTED">
                                    <span class="week-icons time-icon-status_rejected">
                                        <span class="path1"></span><span class="path2"></span>
                                    </span>
                                </ng-container>
                                <ng-container *ngSwitchCase="STATUS.PENDING">
                                    <span class="week-icons time-icon-status_incomplete"><span
                                            class="path1"></span><span class="path2"></span><span
                                            class="path3"></span></span>
                                </ng-container>
                                <ng-container *ngSwitchCase="STATUS.EMPTY">
                                    <img src="assets/icons/status/status_empty.svg" alt="Empty status" width="16"
                                        height="16">
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #weekEmptyStatus>
                            <img src="assets/icons/status/status_empty.svg" alt="Empty status" width="16" height="16">
                        </ng-template>
                        <span class="ml-2">{{ getStatusLabel(day.event?.status) }}</span>
                        <ng-container *ngIf="day.event?.approvedHours">
                            <span>:&nbsp;</span>
                            <b>{{ day.event?.approvedHours }} h</b>
                        </ng-container>
                    </p>
                    <button class="btn fs-18 mt-3 w-100"
                        [ngClass]="day.event?.status === STATUS.APPROVED ? 'btn-secondary' : 'btn-primary'"
                        [disabled]="day.isFuture" (click)="dayClicked({date: day.date, events: (day.event ? [day.event] : [])})">
                        {{ getButtonLabel(day.event?.status) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>