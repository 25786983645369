import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http-service/http.service';
import { ResponseContent } from '../../models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { map, tap } from 'rxjs/internal/operators';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import { ModalService } from '../modal/modal.service';
import { TokenService } from '../token/token.service';
import { IAccountGet } from 'src/app/models/account/account.model';
import { ICompanyModelGet } from 'src/app/models/companies/companies.model';
import { IUserGet} from 'src/app/models/user/user.model';
import {Router} from '@angular/router';
import { UsersService } from '../users/users.service';
import { EmployeesService } from '../employees/employees.service';
import { InvitationService } from '../invitation/invitation.service';
import { environment } from 'src/environments/environment'

import { DataUser, User } from '../../models/user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public onBoarding = new BehaviorSubject<number>(0);
  public onBoardProgress = this.onBoarding.asObservable();
  boardingNumber:number;
  /** Variable random para imágenes */
  randomImg = new BehaviorSubject<number>(1);
  multiSession = new BehaviorSubject<boolean>(false);
  /**observable que almacena la cuenta del usuario logeado */
  account = new BehaviorSubject<IAccountGet>({});
  /**observable que almacena la información del usuario logeado */
  userInfoLogged = new BehaviorSubject<IUserGet>({});
  higherRole = new BehaviorSubject<string>('employee');
  /**observable que indica si es laprimera vez que inicia sessión */
  firstTimeLogged = new BehaviorSubject<boolean>(false);
  /**observable que indica si esta logeado */
  isLogged = new BehaviorSubject<boolean>(false);
  /**observable estado de conexión (sockets) */
  statusConnection = new BehaviorSubject<boolean>(true);

  /** Companies to which the logged in user has access */
  allCompanies = new BehaviorSubject<ICompanyModelGet[]>([]);
  /**observable que indica cual es la empresa seleccionada */
  idCompanieSelected = new BehaviorSubject<string>('');
  // nombre de la empresa seleccionada
  nameCompanySelected = new BehaviorSubject<string>('');
  // logo de la empresa seleccionada
  logoCompanySelected = new BehaviorSubject<string>('');
  // Menu toggled flag
  sideBarToggled = new BehaviorSubject<boolean>(false);

/**
 *
 * @param {HttpService} http
 */
  constructor(
    private http: HttpService,
    private CryptoService: CryptoService,
    private modalS: ModalService,
    private router: Router,
    private token: TokenService,
    private userService: UsersService,
    private modal: ModalService,
    private employeeService: EmployeesService,
    private invitationService: InvitationService
  ) {}

 onBoard(boardingNumber:number){
    if(boardingNumber){
      this.boardingNumber = boardingNumber;
      this.onBoarding.next(this.boardingNumber);
    }
  }


  /**
   * login
   */
  signInSession() {
    this.modalS.close();

    this.randomImg.next(Math.floor(Math.random() * 1000000));

    return this.http.post('/auth/login', {reload: false}, true).pipe(
    map((response: any) => {
          if (response && response.clave === 'OK') {
          this.isLogged.next(true);
            if (response.message !== 'ERR-05') {
              this.userService.getNotificationsByCompany(response.content.user._id, 4,sessionStorage.getItem('idCompanySelected')).subscribe();
              return response.content == null?false:response.content;
            }
          }else {
              return false;
          }
        }
      )
    );
  }

  reloadSession(){
      const email = sessionStorage.getItem('email');
      if(sessionStorage.getItem('editingInvitation') === 'true'){
        this.invitationService.isEditing.next({
          value: true,
          id: sessionStorage.getItem('invitationSelected')
        })
      }
      return this.http.post('/auth/getSession', {}, true).pipe(
        map((response: ResponseContent) => {
          this.isLogged.next(true);
          if(this.router.url.includes('/NOM-projects/')){
            this.router.navigate(['/NOM-projects/projects']);
          }
          if(this.router.url.includes('employees/profile')){
            if(this.router.url.includes('/employees/file/profile/nom-35/personal')){
              this.router.navigate(['/employees/list']);
            }else{
              this.employeeService.isEditing.next({ value: false, id: '' });
            }
          }
          if (response && response.clave === 'OK') {
              if (response.message !== 'ERR-05') {
                this.userService.getNotificationsByCompany(response.content.user._id, 4,sessionStorage.getItem('idCompanySelected')).subscribe();

                sessionStorage.setItem('idUser', response.content.user._id);
                this.userInfoLogged.next(response.content.user);
                this.account.next(response.content.account);
                this.allCompanies.next(response.content.companies);
                this.firstTimeLogged.next(response.content.firstTime);
                  if(response.content.user.role!='employee'){
                    this.userService.userPermissions.next(response.content.permission);
                    sessionStorage.setItem('permission', JSON.stringify(response.content.permission));
                  }
                  const company = response.content.companies.find(element => element._id === sessionStorage.getItem('idCompanySelected'));
                  this.idCompanieSelected.next(sessionStorage.getItem('idCompanySelected'));
                  this.nameCompanySelected.next(company.companyName);
                  this.logoCompanySelected.next(company.logo);

                  Swal.close();
                this.modal.close();
                return true;
              }
            }else {
                return false;
            }
          }
        )
      );
  }

  /**
   * Solicita el reseteo de la contraseña del usuario
   * @param data
   */
  solicitResetPwd(email:string){

    email = this.CryptoService.encryptData(environment.encryptionKey,email);

    return this.http.post('/auth/solicitResetPassword', {email}, false).pipe(
      map((response: ResponseContent) => {
          if (response && response.clave === 'OK') {
            return true;
          }else {
            return false;
          }
        }
      )
    );
  }

  /**
   * Ejecuta el reemplazo de la contraseña del usuario
   * @param data
   */
  resetPwd(code:string,email:string,newPass:string) {

    newPass = this.CryptoService.encryptData(environment.encryptionKey,newPass);

    return this.http.post('/auth/resetPassword', {code,email,newPass}, false).pipe(
      map((response: ResponseContent) => {
          if (response && response.clave === 'OK') {
            return true;
          }else {
            return false;
          }
        }
      )
    );
  }

  resendActivationCode(email:string) {
    return this.http.post('/auth/resendActivationCode', {email}, false).pipe(
      map((response: ResponseContent) => {
          if (response && response.clave === 'OK') {
            return true;
          }else {
            return false;
          }
        }
      )
    );
  }

  getRoles():Observable<User>{

    return this.http.post('/auth/getRoles', {}, true)
      .pipe(
        tap((res: DataUser) => {

          if(res && !!res.content.theme){

            const { theme } = res.content;

            this.logoCompanySelected.next(theme.logo || '');
            document.documentElement.style.setProperty('--mainDemo', theme.color.main);
            document.documentElement.style.setProperty('--darkDemo', theme.color.dark);
            document.documentElement.style.setProperty('--lightDemo',theme.color.light);
            document.documentElement.style.setProperty('--main', theme.color.main);
            document.documentElement.style.setProperty('--dark', theme.color.dark);
            document.documentElement.style.setProperty('--light',theme.color.light);

          }

        }),
        map((res:DataUser) => { return res?.content.user })
      );
  }

  chooseRoles(role: string){

    const params =  {
      "role": role
    }

    return this.http.post('/auth/chooseSessionRole', {params}, true)
    .pipe(
      tap((res: ResponseContent)=>{

        sessionStorage.setItem('idUser', res.content.user._id);
        sessionStorage.setItem('idEmp', res.content.user.idEmployee);
        sessionStorage.setItem('higherRole', res.content.higherLevelRole);
        sessionStorage.setItem('role', params.role);
        sessionStorage.setItem('firstName', res.content.user.name);
        sessionStorage.setItem('email', res.content.user.email);

        // Se llenan los BehaviorSubject
        this.userInfoLogged.next(res.content.user);
        this.higherRole.next(res.content.higherLevelRole);
        //this.account.next(response.account);
        this.allCompanies.next(res.content.companies);
        this.firstTimeLogged.next(res.content.firstTime);

        if (res.content.firstTime){ // Se inicia el onboarding solo cuando inicia por primera vez
          this.onBoarding.next(1);
        }

        sessionStorage.setItem('idCompanySelected', res.content.account.idCompanies[0]);

        this.idCompanieSelected.next(res.content.companies[0]._id);
        this.nameCompanySelected.next(res.content.companies[0].companyName);
        this.logoCompanySelected.next(res.content.companies[0].logo || '');

        this.isLogged.next(true);
        sessionStorage.setItem('theme', JSON.stringify(res.content.companies[0].theme));
        sessionStorage.setItem('isLogged', 'true');

        if(params.role != 'employee'){

          this.userService.userPermissions.next(res.content.permission);
          sessionStorage.setItem('permission', JSON.stringify(res.content.permission));

          // // * Buzón de quejas y sugerencias
          // if(this.router.url === '/comment'){
          //   this.router.navigate(['/box/list'])
          // }
        }else{
          this.userService.userPermissions.next({});
          sessionStorage.removeItem('permission');

          // // * Buzón de quejas y sugerencias
          // if(this.router.url === '/box/list'){
          //   this.router.navigate(['/comment']);
          // }else{
          //   this.router.navigateByUrl('/home/init-page');
          // }
        }

      }),
      map((res)=>{
        return res;
      })
    );


  }



  /**
   * Cerrar sesión de usuario
   */
  signOut(toLogin=true) {
    sessionStorage.clear();
    this.isLogged.next(false);
    this.token.clearToken();
    this.allCompanies.next([]);
    this.logoCompanySelected.next('');
    this.nameCompanySelected.next('');
    this.userInfoLogged.next({});
    if (toLogin){
      window.location.assign(environment.logoutURL);
    }
    else {
      window.location.assign(environment.logoutURL);
      this.router.navigate([toLogin?'/':'/nodata']);
    }

  }
  /**
   * Cerrar sesión de usuario
   */
  signOutSimple(): void {
    sessionStorage.clear();
    this.isLogged.next(false);
    this.token.clearToken();
    this.allCompanies.next([]);
    this.logoCompanySelected.next('');
    this.nameCompanySelected.next('');
    this.userInfoLogged.next({});
    this.router.navigateByUrl('/');
  }
}
