import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { activities, Activity } from './report.const';
import { ActivitiesPerMonth } from './report.const';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { MatDatepicker } from '@angular/material/datepicker';
import { eachWeekendOfMonth, getDaysInMonth, isSameMonth } from 'date-fns';
import { IncidenceComponent } from '../incidence/incidence.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '@components/alert/alert.component';
import { RejectDialogComponent } from '../reject-dialog/reject-dialog.component';
import { LogService } from 'src/app/services/log/log.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public employeeActivities: ActivitiesPerMonth | undefined;
  public monthActivities: any;
  public selected: Date = new Date();
  employeeId;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public totalPages: number = 0;
  public activeIndex: number | null = null;
  private readonly HOURS_PER_DAY = 8;
  public readonly TODAY = new Date();
  public readonly START_DATE = new Date('2022-1-1');
  public readonly breadcrumb: Breadcrumb[] = [ CRUMBS.areaLeadMain ];
  public chartData = {
    approved: {
      hours: 0,
      percentage: 0,
    },
    pending: {
      hours: 0,
      percentage: 0,
    },
    rejected: {
      hours: 0,
      percentage: 0,
    },
    empty: {
      hours: 0,
      percentage: 0,
    },
  }

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private log: LogService) { }

  ngOnInit(): void {
    this.employeeId = this.route.snapshot.paramMap.get('id');
    this.employeeActivities = activities.find(activity => activity.employeeId === this.employeeId);

    this.filterValues();

    const breadRoute = `${CRUMBS.areaLeadReport.route}/${this.employeeId}`
    this.breadcrumb.push({...CRUMBS.areaLeadReport, route: breadRoute})

    // this.getInfo()
  }

  onDateSelected(selectedDate: Date): void {
    if (selectedDate) {
      this.selected = selectedDate;
      this.filterValues();
    }
  }

  getInfo(){
    this.log.getReport(this.employeeId, this.selected).subscribe(res=>{
      console.log(res)
    })
  }

  private filterValues() {
    this.log.getReport(this.employeeId, this.selected).subscribe(res=>{
      this.monthActivities = res.log
      console.log(this.monthActivities)
      this.updateChartData(res.count.totalHoursApproved, res.count.totalHoursPending, res.count.totalHoursRefused ); // Set initial chart data
  
      this.totalPages = Math.ceil(this.monthActivities?.length / this.itemsPerPage);
    })

    // this.monthActivities = this.employeeActivities.activities.filter(a => {
    //   const activityDate = new Date(a.date);
    //   return isSameMonth(activityDate, this.selected);
    // });

  }

  updateChartData(approvedTime: number, pendingTime: number, rejectedTime: number): void {

    const hoursInMonth = this.getTotalHoursInMonth(this.selected);
    const emptyTime = hoursInMonth - (approvedTime + pendingTime + rejectedTime);

    this.chartData = {
      approved: {
        hours: approvedTime,
        percentage: this.calculatePercentege(approvedTime, hoursInMonth)
      },
      pending: {
        hours: pendingTime,
        percentage: this.calculatePercentege(pendingTime, hoursInMonth)
      },
      rejected: {
        hours: rejectedTime,
        percentage: this.calculatePercentege(rejectedTime, hoursInMonth)
      },
      empty: {
        hours: emptyTime,
        percentage: this.calculatePercentege(emptyTime, hoursInMonth)
      },
    }
  }

  private calculateHours(activities: Activity[]): number {
    let totalHours = 0;
    activities.forEach(a => {
      const hours = parseInt(a.hours);
      const minutes = Math.ceil(parseInt(a.minutes) / 60); // Convert to hours & round up
      totalHours += hours + minutes;
    });
    return totalHours;
  };

  private calculatePercentege(value: number, total: number): number {
    const percentage = (value / total) * 100;
    return +percentage.toFixed(2);
  }

  private getTotalHoursInMonth(month: Date) {
    const daysInMonth = getDaysInMonth(month);
    const weekendDaysOfMonth = eachWeekendOfMonth(month)
    const workingDays = daysInMonth - weekendDaysOfMonth.length;
    return workingDays * this.HOURS_PER_DAY;
  }

  get paginatedActivities() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.monthActivities?.slice(startIndex, endIndex);
  }

  toggleCollapse(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  changePage(page: number): void {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  downloadPDF(): void {
    const doc = new jsPDF();

    const month = this.selected.toLocaleString('default', { month: 'long' });
    const year = this.selected.getFullYear();
    const employeeName = this.employeeActivities?.employee || 'Empleado';

    const columns = ['Fecha', 'Actividad', 'Tipo de Actividad', 'Tiempo', 'Descripción', 'Estatus'];
    const rows = this.monthActivities.map(activity => [
      activity.date,
      activity.activity,
      activity.type,
      `${activity.hours} h ${activity.minutes} min`,
      activity.description,
      activity.status
    ]);

    // Title
    doc.setFontSize(14);
    doc.setTextColor(24, 99, 214);
    doc.setFont('helvetica', 'bold');
    doc.text(`Registro de horas ${month} ${year}`, 14, 15);

    // Subtitle
    doc.setFontSize(10);
    doc.setTextColor(1, 23, 60);
    doc.setFont('helvetica');
    doc.text(`${employeeName}`, 14, 20);

    // Table
    autoTable(doc, {
      head: [columns],
      body: rows,
      startY: 35,
      styles: {
        lineColor: [220, 232, 249],
        lineWidth: 0.1
      },
      headStyles: {
        fillColor: [220, 232, 249],
        textColor: [17, 70, 152],
        fontSize: 10,
      },
      bodyStyles: {
        fontSize: 9,
        textColor: [1, 23, 60],
      }
    });

    doc.save(`Reporte_${employeeName}_${month}_${year}.pdf`);
  }

  public approveActivity(activityId: string) {
    const object = {
      id: activityId,
      body: {
        status: 'approved'
      }
    }
    this.log.updateLogs(object).subscribe(res=>{
      if(res){
        this.dialog.open(AlertComponent, {
          width: '464px',
          data: {
            icon: 'status_approved',
            title: 'Actividad aprobada',
            description: `Has aprobado esta actividad. En breve, le informaremos a ${ this.employeeActivities?.employee } sobre tu aprobación.`,
            close: 'Cerrar',
          }
        });
        this.filterValues()
      }
    })
  }

  openRejectDialog(activityId: string) {
    const dialogRef = this.dialog.open(RejectDialogComponent,
     { data: { employeeId: this.employeeActivities?.employeeId, employeeName: this.employeeActivities?.employee, activityId }, width: '464px', disableClose: true });
   
     dialogRef.afterClosed().subscribe((result) => {
      this.filterValues()
     });
  }

  setMonthAndYear(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
    datepicker.close();
    this.selected = normalizedMonthAndYear;
  }

  public openIncidenceDialog() {
    const dialogRef = this.dialog.open(IncidenceComponent, {
      width: '464px',
      data: {
        employeeId: "employeeId",
        employeeName: "Nombre de usuario"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}
