<div class="menus d-none d-md-flex" *ngIf="sort" id="dependenciesMenu">
    <button mat-button [matMenuTriggerFor]="orderMenu" #orderMenuTrigger="matMenuTrigger">
        Ordenar
        <span class="align-middle" [ngClass]="orderMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
    </button>
    <mat-menu #orderMenu="matMenu">
        <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
            Ordenar por
        </button>
        <button mat-menu-item (click)="checkOrder('asc')" class="menu-filter-item">
            <mat-checkbox [checked]="sort.direction === 'asc'" disabled="true">Ascendente</mat-checkbox>
        </button>

        <button mat-menu-item (click)="checkOrder('desc')" class="menu-filter-item">
            <mat-checkbox [checked]="sort.direction === 'desc'" disabled="true">Desendente</mat-checkbox>
        </button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
        Filtrar
        <span class="align-middle" [ngClass]="filterMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
    </button>
    <mat-menu #filterMenu="matMenu">
        <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
            Filtrar por
        </button>
        <button mat-menu-item (click)="applyFilter('To Do')" class="menu-filter-item">
            <mat-checkbox [checked]="currentFilter === 'To Do'" disabled="true">To Do</mat-checkbox>
        </button>
        <button mat-menu-item (click)="applyFilter('WIP')" class="menu-filter-item">
            <mat-checkbox [checked]="currentFilter === 'WIP'" disabled="true">WIP</mat-checkbox>
        </button>
        <button mat-menu-item (click)="applyFilter('Done')" class="menu-filter-item">
            <mat-checkbox [checked]="currentFilter === 'Done'" disabled="true">Done</mat-checkbox>
        </button>
    </mat-menu>
</div>

<div class="border-table d-none d-md-block">

    <table mat-table [dataSource]="dependenciesDataSource" matSort [matSortDirection]="'asc'" [matSortDisabled]="true"
        class="deep-table">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
                Dependencia
            </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>
                Registro
            </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="inCharge">
            <th mat-header-cell *matHeaderCellDef>
                Responsable
            </th>
            <td mat-cell *matCellDef="let element">
                <img [src]="element.inChargeAvatar" class="avatar-40"
                    [matTooltip]="element.inChargeName"
                    matTooltipPosition="above" aria-tooltip="Displays tooltip with name of personal in charge of dependency"
                    [alt]="'Photo of personal in charge of dependency'" />
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                Estatus
            </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="commitmentAt">
            <th mat-header-cell *matHeaderCellDef>
                Compromiso
            </th>
            <td mat-cell *matCellDef="let element"> {{element.commitmentAt | date:'shortDate'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching</td>
        </tr>
    </table>
</div>

<!-- Mobile view -->
<div class="cards d-md-none">
    <div class="card" *ngIf="card">
        <img [src]="card.inChargeAvatar" class="avatar-40"
            [alt]="'Photo of personal in charge of dependency'" />
        <p class="card__label card__label--primary mt-4">Responsable</p>
        <p class="card__label mt-2">{{ card.inChargeName }}</p>

        <p class="card__label card__label--primary mt-4">Dependencia</p>
        <p class="card__label mt-2">{{ card.description }}</p>

        <p class="card__label card__label--primary mt-4">Estatus</p>
        <p class="card__label mt-2">{{ card.status }}</p>

        <p class="card__label card__label--primary mt-4">Compromiso</p>
        <p class="card__label mt-2">{{ card.commitmentAt | date:'shortDate' }}</p>
    </div>
    <div class="controls d-flex justify-content-center mt-3">
        <button mat-icon-button type="button" (click)="changeCard(-1)"
            class="p-0 mr-3"
            [disabled]="currentCard <= 0">
            <span class="fs-32 time-icon-left"></span>
        </button>
        <button mat-icon-button type="button" (click)="changeCard(1)"
            class="p-0 ml-3"
            [disabled]="currentCard >= (dependencies.length - 1)">
            <span class="fs-32 time-icon-right"></span>
        </button>
    </div>
</div>
