import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

/** ENVIROMENTS */
import { environment } from '../../../environments/environment';

/* SERVICES */
import { TokenService } from '../../services/token/token.service';
import { AuthService } from 'src/app/services/auth/auth.service';
/**
 * Interceptor de peticiones `HTTP}
 * Interceptor de todas las llamadas de HttpClient
 * y dar el formato correcto al body y agregar los headers de seguridad
 * @export
 * @class RequestInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	private token: any = '';


	/**
	 *Creates an instance of RequestInterceptor.
	* @param {TokenService} TokenService
	* @memberof RequestInterceptor
	*/
	constructor(
		private tokenService: TokenService,
		private auth: AuthService
	) {
		/***recupera token para peticiones */
		this.token = sessionStorage.getItem('token');
	}

	timeoutHandle = window.setTimeout(()=>{
		this.auth.signOut();
	}, 1200000);

	/**
	 * @param {HttpRequest<any>} req
	 * @param {HttpHandler} next
	 * @returns {Observable<HttpEvent<any>>}
	 * @memberof RequestInterceptor
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		window.clearTimeout(this.timeoutHandle);
		this.timeoutHandle = window.setTimeout(()=>{
			this.auth.signOut();
		}, 1200000);
		if( req.url === environment.cognitoTokenURL){
			req = this.defaultRequest(req);
		}else if (!this.tokenService.servicesInterceptor.includes(req.url)) {
			if (req.method === 'POST') {
				//this.timeoutService.resetTimer();
				/** Se clona request para intercambio para modificaciones */
				req = this.setRequestPOST(req);
			} else if (req.method === 'GET') {
				/** Se clona request para intercambio para modificaciones */
				req = this.setRequestGET(req);
			} else if (req.method === 'DELETE') {
				req = this.setRequestDELETE(req);
			}
			else if (req.method === 'PUT') {
				req = this.setRequestDELETE(req);
			}
		} else {
			req = this.defaultRequest(req);
		}
		return next.handle(req);
	}

	/**
	 * Retorna respuesta con implementación de token
	 * @param {HttpRequest<any>} req
	 * @returns
	 * @memberof RequestInterceptor
	 */
	defaultRequest(req: HttpRequest<any>) {
		return req.clone({
			/**
			 * Se agrega url base definida en `environment` a llamada
			 * @example 'http://localhost:3000/api' + '/getusers'
			 */
			url: req.url
		});
	}

	/**
	 * Retorna respuesta con implementación de token
	 * @param {HttpRequest<any>} req
	 * @returns
	 * @memberof RequestInterceptor
	 */
	setRequestPOST(req: HttpRequest<any>) {
		return req.clone({
			/**
			 * Se agrega url base definida en `environment` a llamada
			 * @example 'http://localhost:3000/api' + '/getusers'
			 */
			url: `${environment.baseUrl}${req.url}`,
			/** Se parsea el cuerpo de la llamada a formato adecuado para api y emitir error de cors*/
			setHeaders: {
				'authorization': `Bearer ${this.tokenService.searchServiceToken(req.url)}`,
				'x-session': `${this.tokenService.searchApiServiceToken()}`
			}
		});
	}

	/**
	 * Asignar valores a objeto request para llamadas GET
	 * @param {any} req
	 * @memberof RequestInterceptor
	 */
	setRequestGET(reqs: HttpRequest<any>) {
		return reqs.clone({
			/**
			 * Se agrega url base definida en `environment` a llamada
			 * @example 'http://localhost:3000/api' + '/getusers'
			 */
			withCredentials: true,
			url: `${environment.baseUrl}${reqs.url}`,
			setHeaders: {
				'Accept-Language': 'es-MX,es;q=0.9,en;q=0.8',
				'authorization': `Bearer ${this.tokenService.searchServiceToken(reqs.url)}`,
				'x-session': `${this.tokenService.searchApiServiceToken()}`
			}
		});
	}

	/**
	 * Asignar valores a objeto request para llamadas GET
	 * @param {any} req
	 * @memberof RequestInterceptor
	 */
	setRequestDELETE(req: HttpRequest<any>) {
		return req.clone({
			/**
			 * Se agrega url base definida en `environment` a llamada
			 * @example 'http://localhost:3000/api' + '/getusers'
			 */
			withCredentials: true,
			url: `${environment.baseUrl}${req.url}`,
			setHeaders: {
				'Accept-Language': 'es-MX,es;q=0.9,en;q=0.8',
				'authorization': `Bearer ${this.tokenService.searchServiceToken(req.url)}`,
				'x-session': `${this.tokenService.searchApiServiceToken()}`
			}
		});
	}


}
