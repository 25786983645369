import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
/** RXJS */
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
/** MODELS */
import { RouteLocation } from '../../models/route-location.model';


@Injectable()
export class PublicRouteGuard implements CanActivate, CanActivateChild {
  /** LISTA DE SUSCRIPCIONES */
  subscribers: any = {};
  /** variable de logeo */
  isLoggedIn = false;

  /**
   * Creates an instance of PublicRouteGuard.
   * @param {AuthenticationService} authService
   * @param {RouteService} routeService
   * @param {ArrayService} arrayService
   * @memberof PublicRouteGuard
   */
  constructor(
    private route: Router,
    private authService: AuthService
  ) {

    this.subscribers.log = this.authService.isLogged.subscribe(
      (res: boolean) => {
        this.isLoggedIn = res;
      }
    );
  }

  /**
   * Fn | Revisa credenciales si está definido cliente y se retorna a `inicio`
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PublicRouteGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Fn | Revisa credenciales si está definido cliente y se retorna a `inicio`
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PublicRouteGuard
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Función para validación de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof PublicRouteGuard
   */
  checkRoute(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /** Resultado de comparar rutas para accesar */
    let accesResult = true;

    if (this.isLoggedIn) {
      this.route.navigate(['/']);
    }
    return !this.isLoggedIn && accesResult;
  }
}
