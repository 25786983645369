import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router) { }
  currentYear = new Date().getUTCFullYear();
  ngOnInit(): void {
  }

  isFooterDark(){
    return this.router.url.includes('/login') || this.router.url.includes('/signin') || this.router.url.includes('/nodata');
  }
  
  openModalAvisoPrivacidad(){
    Swal.fire({
      title: 'Aviso de Privacidad',
      confirmButtonText:'Aceptar',
      width: '100%',
      html: `
        <div class="container text-justify fsm-10">
          <div class="row">
            <!-- ************************************************************************** -->
            <div class="col-12">
              2BCORE, S.A. de C.V., con domicilio comercial en Plaza Melchor Ocampo 36, Piso 4, Col. Cuauhtémoc,
              C.P. 06500, Ciudad de México, es responsable de recabar sus datos personales,
              del uso que se le dé a los mismos y de su protección.
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              DATOS PERSONALES SOMETIDOS A TRATAMIENTO
            </div>
            <div class="col-12">
              Únicamente solicitaremos aquellos datos personales que sean necesarios para la
              cotización, contratación, cobranza y uso del producto o servicio de su elección:
              Nombre completo, dirección, correo electrónico, números telefónicos personales y
              de empresas donde ha laborado, cursos de su interés, información laboral y
              profesional, información para facturación y cobranza tal como RFC y domicilio fiscal,
              considerado como personal, según la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares; <span class="fw-bold">En este sitio no recabamos
              información considerada como sensible de acuerdo a Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares.</span>
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              FINALIDADES DEL TRATAMIENTO
            </div>
            <div class="col-12">
              <ul class="list-number">
                <li>Ofrecerle cotizaciones de los productos y servicios de su interés y regular los
                derechos y <span class="fw-bold">obligaciones</span> que surjan de la relación jurídica que celebremos
                con Usted.</li>
                <li>Cumplimiento normativo, prevención de fraudes y colaboración con
                autoridades según corresponda.</li>
                <li>Cobrar, administrar, mantener, renovar o cancelar los productos y servicios
                contratados, así como generar estadística sobre los mismos.</li>
              </ul>
              Su información personal será utilizada para proveer los servicios y productos que
              ha solicitado, informarle sobre cambios en los mismos y evaluar la calidad del
              servicio que le brindamos. En este sitio no recabamos información considerada
              como sensible de acuerdo a Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares.
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              MEDIOS Y PROCEDIMIENTOS PARA EJERCER DERECHOS ARCO (ACCESO,</br>
              RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN)
            </div>
            <div class="col-12">
              El Titular, por sí o mediante representante legal debidamente acreditado, tiene
              reconocidos y podrá ejercitar los derechos de Acceso, Rectificación, Cancelación y
              Oposición “ARCO”, frente al Responsable. Para ello el Titular o su representante
              legal podrán solicitar un formato de ejercicio de Derechos ARCO a la dirección de
              correo electrónico: <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>; dicho formato se deberá llenar y firmar y
              devolver a la misma dirección de correo electrónico acompañado de la siguiente
              documentación escaneada, a fin de que pueda llevarse a cabo la autenticación del
              titular que quiera ejercer sus Derechos ARCO:
              <ul class="list-number">
                <li>Identificación oficial vigente del Titular (Credencial del Instituto Federal
                  Electoral, Pasaporte emitido por la Secretaría de Relaciones Exteriores,
                  Cartilla del servicio Militar Nacional o Cédula Profesional)
                  .</li>
                <li>En los casos en que el ejercicio de los Derechos ARCO se realice a través
                del representante legal del Titular, además de la acreditación de la identidad
                de ambos, se deberá entregar el poder notarial correspondiente, carta poder
                firmada ante dos testigos o declaración en comparecencia del Titular.</li>
                <li>Cuando se quiera ejercer el derecho de rectificación, se tendrá que entregar
                la documentación que acredite el cambio solicitado de acuerdo a los datos
                personales a rectificar.</li>
              </ul>
            </div>

            <div class="col-12">
              La respuesta a dicho formato, se llevará a cabo por el Responsable después <span class="fw-bold">de 20
              días</span> hábiles contados a partir de la fecha en que fue recibido el mismo. El
              Responsable podrá <span class="fw-bold">ampliar por una sola vez éste plazo</span> cuando el caso lo amerite,
              previa notificación de esto al Titular. Si su solicitud es procedente, podrá hacer
              efectivo su derecho ARCO dentro de <span class="fw-bold">los 15 días</span> siguientes a la fecha de respuesta.
              <span class="fw-bold">Este plazo se podrá ampliar por una sola vez por causa justificada.</span>
            </div>

            <div class="col-12 pt-2">
              La resolución adoptada por el Responsable será comunicada al Titular a través de
              las opciones elegidas por éste, las cuales se encuentran establecidas en el formato
              de ejercicio de Derechos ARCO que solicitará por correo electrónico. Los medios
              para la respuesta a su solicitud así como los medios para obtener la información o
              datos personales solicitados a través del ejercicio del derecho de acceso, es decir,
              copias simples, documentos electrónicos o cualquier otro medio, se le harán saber
              el cómo obtenerlos y se le facilitarán a través de la dirección electrónica y teléfono
              antes mencionados.
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              MECANISMO PARA REVOCAR SU CONSENTIMIENTO AL TRATAMIENTO DE</br>
              DATOS PERSONALES
            </div>
            <div class="col-12">
              En los casos en que el Titular desee revocar la autorización otorgada al
              Responsable respecto de los Datos Personales proporcionados, deberá realizar la
              solicitud correspondiente a la dirección de correo electrónico:</br>
              <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>
            </div>
            <div class="col-12 pt-2">
              La respuesta a dicho formato, se llevará a cabo por el Responsable en un máximo
              de 20 días hábiles contados a partir de la fecha en que fue recibido el mismo con
              toda la información que acredite su identidad o la de su representante legal, y en su
              caso, la información que avale la rectificación de datos. El Responsable podrá
              ampliar por una sola vez éste plazo cuando el caso lo amerite, previa notificación
              de esto al Titular.
            </div>
            <div class="col-12 pt-2">
              La respuesta de su solicitud de ejercicio de Derechos ARCO, así como en su caso
              entrega de los Datos Personales respectivos, será gratuita, debiendo cubrir el Titular
              únicamente los gastos justificados de envío o con el costo de reproducción en
              copias u otros formatos, en los casos que lo amerite.
            </div>
            <div class="col-12 pt-2">
              En aquellos casos en los que se desee ejercer el derecho de Acceso a sus Datos
              Personales, la información solicitada será proporcionada a través del medio que
              Usted haya decidido en la solicitud de ejercicio de derechos ARCO y en el supuesto
              en el que el Responsable no cuente con sus Datos Personales, se lo informaremos
              por los medios a través de los cuales realizó la solicitud.
            </div>
            <div class="col-12 pt-2">
              Para cualquier duda o aclaración respecto del presente proceso favor de enviar un
              correo a la Unidad de Protección de Datos Personales a la dirección
              <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              LIMITACIÓN DE USO Y DIVULGACIÓN DE DATOS PERSONALES
            </div>
            <div class="col-12 pt-2">
              Para limitar el uso de sus datos personales, favor de enviar un correo a
              <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a> o notificación por escrito a la dirección Plaza Melchor Ocampo 36, Piso 4, Col. Cuauhtémoc, C.P. 06500, Ciudad de México, dirigida al Departamento de
              Protección de Datos Personales,, en el que se señale la limitación al uso de sus
              datos deseada.
            </div>
            <div class="col-12 pt-2">
            Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede
            solicitarlo al Comité de derechos ARCO, a través de <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>
            </div>
            <div class="col-12 pt-2">
            Cualquier modificación a este aviso de privacidad podrá consultarla en
            <a href="https://2bcore.com/" target="_blank" >www.2bcore.com</a> Comité de derechos ARCO: Comuníquese a los siguientes
            teléfonos y correo, a efectos de exigir sus derechos ARCO 52-55-6274-3314  <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>
            </div>

            <!-- ************************************************************************** -->
            <div class="col-12 text-center fw-bold my-2">
              NOTIFICACIÓN DE CAMBIOS AL AVISO DE PRIVACIDAD
            </div>
            <div class="col-12">
            El presente Aviso de Privacidad podrá ser modificado en cualquier momento para
            cumplir con actualizaciones legislativas, jurisprudenciales, políticas, internas,
            nuevos requisitos para la prestación de servicios de 2BCORE, o cualquier otra
            causa a entera discreción de 2BCORE y para dar a conocer los cambios o
            actualizaciones antes mencionadas se publicaran a través de su página de internet
            <a href="https://2bcore.com/" target="_blank" >www.2bcore.com</a>, de en la cual se publicara de forma clara e inmediata cualquier
            actualización o cambios efectuados al mismo la página de internet es:
            <a href="mailto:arco@2bcore.com" >arco@2bcore.com</a>. Asimismo, usted puede solicitar un ejemplar impreso en
            nuestras oficinas.
            </div>
          </div><!-- **termino row** -->
        </div><!-- **termino content** -->
      `
    });
  }

  openModalPoliticaSeguridad(){
    Swal.fire({
      title: 'Política de seguridad de la información',
      confirmButtonText:'Aceptar',
      html: `
      <p class="text-justify">En 2BCORE S.A de C.V., proveedor líder en desarrollo de soluciones, estamos comprometidos con la preservación de la confidencialidad, integridad y disponibilidad de la información de la organización y de nuestros clientes. Revisamos periódicamente nuestro SGSI para disminuir de manera continua los niveles de exposición, cumplir con los requisitos contractuales y legales aplicables.</p>
      <p class="text-justify">Para 2BCORE S.A. de C.V. la información es un activo fundamental para la prestación de sus servicios y la toma de decisiones, por lo que existe un compromiso expreso de Alta Dirección para la protección de sus activos de información como parte de una estrategia orientada a la continuidad de la operación, la mejora continua, sus objetivos, la administración de riesgos y la consolidación de una cultura de seguridad de la información.</p>`
    });
  }

}
