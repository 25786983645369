import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarCollectionComponent } from './avatar-collection/avatar-collection.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AlertComponent } from './alert/alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PaginatorComponent } from './paginator/paginator.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { OnlyNumbersMaxLengthDirective } from './directives/only-numbers-max-length.directive';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    AvatarCollectionComponent,
    AlertComponent,
    ConfirmComponent,
    PaginatorComponent,
    BreadcrumbComponent,
    OnlyNumbersMaxLengthDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    PipesModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    AvatarCollectionComponent,
    PaginatorComponent,
    BreadcrumbComponent,
    OnlyNumbersMaxLengthDirective,
  ]
})
export class ComponentsModule { }
