<div class="container w-90">
    <div class="row">
    <div class="col-12 d-md-none">
        <a [routerLink]="'/hours-employee'" class="text-decoration-none">
            <span class="time-icon-back fs-16"></span>
        </a>
    </div>
    <div class="col-12 d-none d-md-block">
        <app-breadcrumb [crumbs]="breadcrumb"> </app-breadcrumb>
    </div>

    <div class="col-12 my-3 my-md-4 d-flex justify-content-between ">
        <p class="title mb-0">
            {{ isEdit ? 'Editar actividad' : 'Nueva actividad' }}
        </p>
        <span class="subtitle d-flex align-items-center">
            <span class="time-icon-calendar_active mr-2"></span>
            <span class="d-none d-md-inline">{{ activityDate.toLocaleString('es-Mx', {day: 'numeric', month: 'long', year: 'numeric'}) }}</span>
            <span class="d-md-none">{{ activityDate | date:'shortDate' }}</span>
        </span>
    </div>

    <form [formGroup]="registryForm" (submit)="sendActivity(formDirective)" class="col-12 col-md-10 mx-auto" #formDirective="ngForm">
        <div class="row">
            <div class="col-12 pb-1" *ngIf="isEdit === true">
                <ng-container [ngSwitch]="registryForm.controls['status'].value">
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'approved'">
                        <span class="icon-24 time-icon-status_approved mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Aprobada
                    </span>
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'pending'">
                        <span class="icon-24 time-icon-status_pending mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Por aprobar
                    </span>
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'rejected'">
                        <span class="icon-24 time-icon-status_rejected mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Rechazada
                    </span>
                </ng-container>
            </div>

            <div class="col-12 col-md-6 mt-4">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                    <mat-label>Selecciona una actividad</mat-label>
                    <mat-select formControlName="activity" class="deep-select-overlay" [required]="false">
                        <mat-option *ngFor="let item of catActivities" [value]="item.name" class="deep-mat-option">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                    <span matSuffix class="time-icon-arrow_expand fs-16 p-3"></span>
                </mat-form-field>

                <div class="form-control-error">
                    <span *ngIf="registryHasError('activity')" class="d-flex align-items-center mt-1">
                        <span class="time-icon-status_error mr-2">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Selecciona una opción
                    </span>
                </div>
            </div>

            <div class="col-12 col-md-6 mt-4">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                    <mat-label [class.label-error]="registryHasError('code')">Selecciona un tipo de actividad</mat-label>
                    <mat-select formControlName="code" class="deep-select-overlay" [required]="false">
                        <mat-option *ngFor="let item of types" [value]="item.code" class="deep-mat-option">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                    <span matSuffix class="time-icon-arrow_expand fs-16 p-3"></span>
                </mat-form-field>

                <div class="form-control-error">
                    <span *ngIf="registryHasError('code')" class="d-flex align-items-center mt-1">
                        <span class="time-icon-status_error mr-2">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Selecciona una opción
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-6 mt-4 mt-md-5">
                <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                    <mat-label>Código</mat-label>
                    <input matInput disabled aria-disabled="true" [value]="registryForm.controls['code'].value">
                </mat-form-field>

                <div class="form-control-error">
                    <span *ngIf="registryHasError('code')" class="d-flex align-items-center mt-1">
                        <span class="time-icon-status_error mr-2">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Sin código de proyecto
                    </span>
                </div>
            </div>

                <div class="col-6 col-xl-3 d-flex input-controls mt-4 mt-md-5">

                    <div class="input">
                        <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                            <mat-label [class.label-error]="registryHasError('hours')">Horas</mat-label>
                            <input matInput formControlName="hours" id="hours" type="number" autocomplete="off" [appOnlyNumbersMaxLength]="2" (blur)="managementHours()">
                            <span matTextSuffix>h</span>
                        </mat-form-field>

                        <div class="form-control-error">
                            <span *ngIf="registryHasError('hours')" class="d-flex align-items-center mt-1">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa horas
                            </span>
                        </div>
                    </div>
                    <div class="controls d-flex flex-column deep-icon-controls">
                        <button mat-icon-button type="button" (click)="managementHours(1)">
                            <span class="icon-24 time-icon-time_plus"></span>
                        </button>
                        <button mat-icon-button type="button" (click)="managementHours(-1)">
                            <span class="icon-24 time-icon-time_minus"></span>
                        </button>
                    </div>


                </div>
            <div class="col-6 col-xl-3 d-flex input-controls mt-4 mt-md-5">


                <div class="input">
                    <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                        <mat-label [class.label-error]="registryHasError('mins')">Minutos</mat-label>
                        <input matInput formControlName="mins" id="mins" type="number" autocomplete="off" [appOnlyNumbersMaxLength]="2" (blur)="managementMinutes()">
                        <span matTextSuffix>min</span>
                    </mat-form-field>
                    <div class="form-control-error">
                        <span *ngIf="registryHasError('mins')" class="d-flex align-items-center mt-1">
                            <span class="time-icon-status_error mr-2">
                                <span class="path1"></span><span class="path2"></span>
                            </span>
                            Ingresa minutos
                        </span>
                    </div>
                </div>
                <div class="controls d-flex flex-column deep-icon-controls">
                    <button mat-icon-button type="button" (click)="managementMinutes(1)" [disabled]="registryForm.controls['hours'].value >= 24">
                        <span class="icon-24 time-icon-time_plus"></span>
                    </button>
                    <button mat-icon-button type="button" (click)="managementMinutes(-1)" [disabled]="registryForm.controls['hours'].value >= 24">
                        <span class="icon-24 time-icon-time_minus"></span>
                    </button>
                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-12 mt-4 mt-md-5">
                <mat-form-field class="w-100 deep-mat-field" appearance="outline" subscriptSizing="dynamic">
                    <mat-label [class.label-error]="registryHasError('description')">Descripción de la
                        actividad</mat-label>
                    <textarea matInput formControlName="description" name="description" id="textarea" rows="3" #descriptionTextarea
                        maxlength="112"></textarea>
                </mat-form-field>
                <div class="form-control-error d-flex">
                    <span *ngIf="registryHasError('description')" class="d-flex align-items-center mt-1">
                        <span class="time-icon-status_error mr-2">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        La descripción debe tener entre 56 y 112 caracteres
                    </span>
                    <span class="ml-auto description-length" [class.with-error]="registryHasError('description')"> {{ descriptionTextarea.value.length }} / 112 </span>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 justify-content-end d-flex flex-column flex-md-row deep-button">
                <button mat-stroked-button color="primary" class="m-2" type="button" (click)="cancelActivity()">Cancelar</button>
                <button mat-flat-button color="primary" class="m-2" type="submit" [disabled]="!registryForm.valid">Enviar actividad</button>
            </div>
        </div>
    </form>

    <div class="col-12 d-flex flex-column flex-md-row justify-content-between my-5" *ngIf="isEdit === false">
        <p class="subtitle mb-0">Horas acumuladas:
            <b *ngIf="timeAccumulated.hours > 0"> {{ timeAccumulated.hours }} h </b>
            <b *ngIf="timeAccumulated.minutes > 0"> {{ timeAccumulated.minutes }} min </b>
        </p>
        <div class="d-flex justify-content-between justify-md-content-end">
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_approved mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Aprobada
            </span>
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_pending mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Por aprobar
            </span>
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_rejected mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Rechazada
            </span>
        </div>
    </div>

        
    <div class="col-12" *ngIf="isEdit === false">
        <div class="border-table d-none d-lg-block">
        <table mat-table [dataSource]="activitiesDataSource" class="deep-table">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    Fecha
                </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef>
                    Actividad
                </th>
                <td mat-cell *matCellDef="let element"> {{element.activity | titlecase  }} </td>
            </ng-container>
    
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                    Tipo de actividad
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.type | titlecase }} </td>
            </ng-container>
    
            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef>
                    Tiempo
                </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.duration.hours > 0"> {{ element.duration.hours }} h </span>
                    <span *ngIf="element.duration.minutes > 0"> {{ element.duration.minutes }} min </span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    Descripción
                </th>
                <td mat-cell *matCellDef="let element"> {{element.description }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    Estatus
                </th>
                <td mat-cell *matCellDef="let element"> 
                    <span class="icon-24 time-icon-status_approved mr-1" *ngIf="element.status == 'approved'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                    <span class="icon-24 time-icon-status_pending mr-1" *ngIf="element.status == 'pending'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                    <span class="icon-24 time-icon-status_rejected mr-1" *ngIf="element.status == 'rejected'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="options" stickyEnd>
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="optionsMenu" aria-label="Toggle menu options of element">
                        <span class="icon-24 time-icon-options"></span>
                    </button>
                    <mat-menu #optionsMenu="matMenu">
                        <ng-container *ngIf="element.status == 'approved';else editTemp">
                            <button mat-menu-item class="deep-menu-item" [routerLink]="['/collaborator/details', element.id]">
                                <span class="time-icon-Report mr-2"></span>
                                <span class="label">Ver detalles</span>
                            </button>
                        </ng-container>
                        <ng-template #editTemp>
                            <button mat-menu-item class="deep-menu-item" [routerLink]="['/update', element.id]">
                                <span class="time-icon-option_edit mr-2"></span>
                                <span class="label">Editar</span>
                            </button>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        </div>

        <!-- Mobile view -->
        <div class="cards d-lg-none">
            <div class="card position-relative" *ngIf="card">
                <p class="card__label card__label--primary mb-2">Estatus</p>
                <ng-container [ngSwitch]="card.status">
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'approved'">
                        <span class="icon-24 time-icon-status_approved mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Aprobada
                    </span>
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'pending'">
                        <span class="icon-24 time-icon-status_pending mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Por aprobar
                    </span>
                    <span class="d-flex align-items-center status-label" *ngSwitchCase="'rejected'">
                        <span class="icon-24 time-icon-status_rejected mr-1">
                            <span class="path1"></span><span class="path2"></span>
                        </span>
                        Rechazada
                    </span>
                </ng-container>
                
                <p class="card__label card__label--primary mt-4">Actividad</p>
                <p class="card__label mt-2">{{ card.activity | titlecase  }}</p>

                <p class="card__label card__label--primary mt-4">Tipo de actividad</p>
                <p class="card__label mt-2">{{ card.type | titlecase }}</p>

                <p class="card__label card__label--primary mt-4">Tiempo</p>
                <p class="card__label mt-2">
                    <span *ngIf="card.duration.hours > 0"> {{ card.duration.hours }} h </span>
                    <span *ngIf="card.duration.minutes > 0"> {{ card.duration.minutes }} min </span>
                </p>

                <p class="card__label card__label--primary mt-4">Descripción</p>
                <p class="card__label mt-2">{{ card.description }}</p>
                
                <p class="card__label card__label--primary mt-4">Fecha</p>
                <p class="card__label mt-2">{{ card.date | date:'shortDate' }}</p>

                <button mat-icon-button [matMenuTriggerFor]="optionsMenu" aria-label="Toggle menu options of element" class="button-menu">
                    <span class="icon-24 time-icon-options"></span>
                </button>
                <mat-menu #optionsMenu="matMenu">
                    <ng-container *ngIf="card.status == 'approved';else editTempMobile">
                        <button mat-menu-item class="deep-menu-item" [routerLink]="['/collaborator/details', card.id]">
                            <span class="time-icon-Report mr-2"></span>
                            <span class="label">Ver detalles</span>
                        </button>
                    </ng-container>
                    <ng-template #editTempMobile>
                        <button mat-menu-item class="deep-menu-item" [routerLink]="['/update', card.id]">
                            <span class="time-icon-option_edit mr-2"></span>
                            <span class="label">Editar</span>
                        </button>
                    </ng-template>
                </mat-menu>

            </div>
            <div class="controls d-flex justify-content-center mt-3">
                <button mat-icon-button type="button" (click)="changeCard(-1)"
                    class="p-0 mr-3"
                    [disabled]="currentCard <= 0">
                    <span class="fs-32 time-icon-left"></span>
                </button>
                <button mat-icon-button type="button" (click)="changeCard(1)"
                    class="p-0 ml-3"
                    [disabled]="currentCard >= (activities.length - 1)">
                    <span class="fs-32 time-icon-right"></span>
                </button>
            </div>
        </div>

    </div>

    <div class="col-12 mt-5" *ngIf="isEdit === true">
        <mat-accordion multi class="deep-accordion mt-5" hideToggle="true">
            <mat-expansion-panel expanded #commentsTemp>
                <mat-expansion-panel-header>
                    <mat-panel-title class="justify-content-between">
                        Comentarios
                        <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="commentsTemp._getExpandedState() === 'expanded'"></span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-comments [comments]="commentsExample"></app-comments>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    </div>
</div>