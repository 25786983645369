import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {ModalService} from 'src/app/services/modal/modal.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para manejo de errores
 */
export class ErrorsService {


  /**
   *
   * Creates an instance of ErrorService
   */
  constructor(
    private modalS: ModalService,
    private route: Router
    ) {}

  /**
   * Servicio para manejo de errores
   * @param error
   */
  handleError(error?: any) {
    Swal.close();
    if(error.error?.status === 500){
      if(error.error.message && error.error.message==='No existe el usuario de la sesión.'){
        this.route.navigate(['/']);
        return;
      }
      if(error.error.message && error.error.message==='jwt expired'){
        this.route.navigate(['/']);
        return;
      }
    }else if(error.error?.status === 0){
      this.modalS.connectionError();

    } else if  (error.error?.status === 401){
      sessionStorage.clear();
      this.route.navigate(['/']);
      this.modalS.error({message :"Ha caducado tu sesión.",});

    }else{
      if(error.error?.message){
        this.modalS.error({title:'Error', message :error.error.message});
      }else{
        this.modalS.error({title:'Página en mantenimiento', message :'Inténtalo de nuevo más tarde.'});
      }
    }
  }

}
