import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProjectInfo, projectExample } from './project.const';
import { employees } from '@pmo/teams/team/team.const'

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('overviewTemp', { read: ElementRef }) overviewElement!: ElementRef;
  @ViewChild('progressTemp', { read: ElementRef }) progressElement!: ElementRef;
  @ViewChild('aboutTemp', { read: ElementRef }) aboutElement!: ElementRef;
  @ViewChild('dependenciesTemp', { read: ElementRef }) dependenciesElement!: ElementRef;
  @ViewChild('workPlanTemp', { read: ElementRef }) workPlanElement!: ElementRef;
  @ViewChild('risksTemp', { read: ElementRef }) risksElement!: ElementRef;
  @ViewChild('additionalTasksTemp', { read: ElementRef }) additionalTasksElement!: ElementRef;
  @ViewChild('loaderTemplate') loaderDialog!: any;
  public project: ProjectInfo = projectExample;
  tmRole: any;
  public employees = employees.slice();
  private readonly pdfMargin = 7; // mm
  
  public breadcrumb: Breadcrumb[] = [
    CRUMBS.pmoProjects,
    CRUMBS.pmoProject,
  ];

  ngOnInit(): void {
    this.tmRole = sessionStorage.getItem('timeManagementRole');
    this.tmRole === 'proyectLeader' ? this.breadcrumb = [
      CRUMBS.proyectLeader,
      CRUMBS.pmoProject
    ] : '';
    this.breadcrumb.push({
      title: this.project.overview.title,
      route: CRUMBS.pmoProject.route
    })
  }

  constructor(private dialog: MatDialog) {}

  public async downloadReport() {
    const metaElenent = document.querySelector('meta[name="viewport"]');
    const originalViewport = metaElenent?.getAttribute("content") ?? 'width=device-width, initial-scale=1';
    if (window.innerWidth < 1200 && metaElenent) {
      metaElenent.setAttribute("content", "width=1300px, user-scalable=no");
    }
  
    const dialogRef = this.dialog.open(this.loaderDialog, {disableClose: true});
    this.accordion.openAll();

    try {
      let pdf = new jsPDF('p', 'mm', 'letter');

      const overviewCanvas = await this.getCanvas(this.overviewElement);
      const progressCanvas = await this.getCanvas(this.progressElement);
      const aboutCanvas = await this.getCanvas(this.aboutElement);
      pdf = this.addPageToPdf(pdf, [overviewCanvas, progressCanvas, aboutCanvas])

      pdf.addPage()
      const dependenciesCanvas = await this.getCanvas(this.dependenciesElement);
      const workPlanCanvas = await this.getCanvas(this.workPlanElement);
      pdf = this.addPageToPdf(pdf, [dependenciesCanvas, workPlanCanvas])

      pdf.addPage()
      const risksCanvas = await this.getCanvas(this.risksElement);
      const additionalTasksCanvas = await this.getCanvas(this.additionalTasksElement);
      pdf = this.addPageToPdf(pdf, [risksCanvas, additionalTasksCanvas])

      this.addExtras(pdf);
      
      pdf.save(`reporte-${this.project.overview.updatedAt.toISOString()}.pdf`);

      dialogRef.close()
    } catch (error) {} finally {
      if (metaElenent) {
        metaElenent.setAttribute("content", originalViewport);
      }
    }
  }

  private async getCanvas(element: ElementRef) {
    return html2canvas(element.nativeElement, {
      scale: 3,
      useCORS: true,
      allowTaint: false,
      windowWidth: 1800,
      ignoreElements: (element) => {
        if (element.id == 'buttonFullscreen') return true;
        if (element.classList.contains('toggle-icon')) return true;
        if (element.id == 'dependenciesMenu') return true;
        return false;
      }
    })
  }

  private addPageToPdf(pdf: jsPDF, canvasToInclude: HTMLCanvasElement[]) {
    const fileWidth = 200;
    let position = 25;

    canvasToInclude.forEach((canvas) => {
      const image = canvas.toDataURL('image/jpeg');
      const imageHeight = (canvas.height * fileWidth) / canvas.width;
      pdf.addImage(image, 'JPEG', this.pdfMargin, position, fileWidth, imageHeight);
      // pdf.html()
      position += imageHeight + 10;
    })

    return pdf;
  }

  private addExtras(doc) {
    doc.setTextColor('#01173c');
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Página ${i} de ${pageCount}`,
        doc.internal.pageSize.width - this.pdfMargin,
        doc.internal.pageSize.height - this.pdfMargin,
        { align: 'right' }
      );

      const img = new Image();
      img.src = 'assets/img/logos/encabezadopdf.JPG';
      doc.addImage(img, 'jpg', this.pdfMargin, this.pdfMargin, 35.9, 11.94);
    }
  }

  //función para redirecciòn a flujo de edicion de detalle de proyecto
  goToEdit() {
    localStorage.setItem('activeProyect',JSON.stringify(this.project))

  }
}
