export class questionsTest2Text {

    public static SECTION_ONE = 
[
    '<div class="col-auto pr-0">1)</div><div class="col"> Mi trabajo me exige hacer mucho esfuerzo físico</div>',
    '<div class="col-auto pr-0">2)</div><div class="col"> Me preocupa sufrir un accidente en mi trabajo</div>',
    '<div class="col-auto pr-0">3)</div><div class="col"> Considero que las actividades que realizo son peligrosas</div>',
    '<div class="col-auto pr-0">4)</div><div class="col"> Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno</div>',
    '<div class="col-auto pr-0">5)</div><div class="col"> Por la cantidad de trabajo que tengo debo trabajar sin parar</div>',
    '<div class="col-auto pr-0">6)</div><div class="col"> Considero que es necesario mantener un ritmo de trabajo acelerado</div>',
    '<div class="col-auto pr-0">7)</div><div class="col"> Mi trabajo exige que esté muy concentrado</div>',
    '<div class="col-auto pr-0">8)</div><div class="col"> Mi trabajo requiere que memorice mucha información</div>',
    '<div class="col-auto pr-0">9)</div><div class="col"> Mi trabajo exige que atienda varios asuntos al mismo tiempo</div>'
]

    public static SECTION_TWO = 
[
    '<div class="col-auto pr-0">10)</div><div class="col"> En mi trabajo soy responsable de cosas de mucho valor</div>',
    '<div class="col-auto pr-0">11)</div><div class="col"> Respondo ante mi jefe por los resultados de toda mi área de trabajo</div>',
    '<div class="col-auto pr-0">12)</div><div class="col"> En mi trabajo me dan órdenes contradictorias</div>',
    '<div class="col-auto pr-0">13)</div><div class="col"> Considero que en mi trabajo me piden hacer cosas innecesarias</div>'
]

public static SECTION_THREE = 
[
    '<div class="col-auto pr-0">14)</div><div class="col"> Trabajo horas extras más de tres veces a la semana</div>',
    '<div class="col-auto pr-0">15)</div><div class="col"> Mi trabajo me exige laborar en días de descanso, festivos o fines de semana</div>',
    '<div class="col-auto pr-0">16)</div><div class="col"> Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o personales</div>',
    '<div class="col-auto pr-0">17)</div><div class="col"> Pienso en las actividades familiares o personales cuando estoy en mi trabajo</div>'
]

public static SECTION_FOUR = 
[
    '<div class="col-auto pr-0">18)</div><div class="col"> Mi trabajo permite que desarrolle nuevas habilidades</div>',
    '<div class="col-auto pr-0">19)</div><div class="col"> En mi trabajo puedo aspirar a un mejor puesto</div>',
    '<div class="col-auto pr-0">20)</div><div class="col"> Durante mi jornada de trabajo puedo tomar pausas cuando las necesito</div>',
    '<div class="col-auto pr-0">21)</div><div class="col"> Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo</div>',
    '<div class="col-auto pr-0">22)</div><div class="col"> Puedo cambiar el orden de las actividades que realizo en mi trabajo</div>'
]

public static SECTION_FIVE = 
[
    '<div class="col-auto pr-0">23)</div><div class="col"> Me informan con claridad cuáles son mis funciones</div>',
    '<div class="col-auto pr-0">24)</div><div class="col"> Me explican claramente los resultados que debo obtener en mi trabajo</div>',
    '<div class="col-auto pr-0">25)</div><div class="col"> Me informan con quién puedo resolver problemas o asuntos de trabajo</div>',
    '<div class="col-auto pr-0">26)</div><div class="col"> Me permiten asistir a capacitaciones relacionadas con mi trabajo</div>',
    '<div class="col-auto pr-0">27)</div><div class="col"> Recibo capacitación útil para hacer mi trabajo</div>'
]

public static SECTION_SIX = 
[
    '<div class="col-auto pr-0">28)</div><div class="col"> Mi jefe tiene en cuenta mis puntos de vista y opiniones</div>',
    '<div class="col-auto pr-0">29)</div><div class="col"> Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo</div>',
    '<div class="col-auto pr-0">30)</div><div class="col"> Puedo confiar en mis compañeros de trabajo</div>',
    '<div class="col-auto pr-0">31)</div><div class="col"> Cuando tenemos que realizar trabajo de equipo los compañeros colaboran</div>',
    '<div class="col-auto pr-0">32)</div><div class="col"> Mis compañeros de trabajo me ayudan cuando tengo dificultades</div>',
    '<div class="col-auto pr-0">33)</div><div class="col"> En mi trabajo puedo expresarme libremente sin interrupciones</div>',
    '<div class="col-auto pr-0">34)</div><div class="col"> Recibo críticas constantes a mi persona y/o trabajo</div>',
    '<div class="col-auto pr-0">35)</div><div class="col"> Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones</div>',
    '<div class="col-auto pr-0">36)</div><div class="col"> Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones</div>',
    '<div class="col-auto pr-0">37)</div><div class="col"> Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador</div>',
    '<div class="col-auto pr-0">38)</div><div class="col"> Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores</div>',
    '<div class="col-auto pr-0">39)</div><div class="col"> Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo</div>',
    '<div class="col-auto pr-0">40)</div><div class="col"> He presenciado actos de violencia en mi centro de trabajo</div>'
]

}