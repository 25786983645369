import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
/* SERVICES */
import { ErrorService } from '../../services/error-service/error.service';
import { TokenService } from '../../services/token/token.service';
import { ErrorsService} from '../../services/errors.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

/* CONSTANT */
import {Response, ResponseContent} from '../../models';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para peticiones HTTP
 */
export class HttpService {
  /**
   * Creates an instance of HttpService
   * @param {HttpClient} httpClient
   * @param {ErrorService} errorService
   * @param {TokenService} tokenService
   */
  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private tokenService: TokenService,
    private errorsService: ErrorsService

  ){}

  
  /**
	 * Realizar llamada `GET`
	 * @param {string} url
	 * @returns {any}
	 */
  get(url: string, options = {}, requireToken = false): Observable<any> {
    if (url) {
      if (requireToken) {
        this.tokenService.saveService(url);
    }
    return this.httpClient.get(url, options);
    }
  }

  /**
 *Realiza petición post
 *
 * @param {string} service
 * @param {*} [obj]
 * @param {boolean} [requireToken=false]
 * @returns {Observable<any>}
 * @memberof HttpService
 */
post(service: string, obj?: any, requireToken = false, interceptor = true): Observable<any> {
    if (service) {
      if (requireToken) {
        this.tokenService.saveService(service);
      }

      if (!interceptor) {
        this.tokenService.saveServiceInterceptor(service);
      }

      return this.httpClient.post<Response>(service, obj).pipe(map((responseOK) => {
        return  this.success(responseOK);}
      ),
      catchError(err => {
        Swal.close();
        this.errorsService.handleError(err);
        return of([]);

    }));

    }
}

postWithProgress(service: string, obj?: any, requireToken = false, interceptor = true): Observable<any> {
    if (service) {
      if (requireToken) {
        this.tokenService.saveService(service);
      }

      if (!interceptor) {
        this.tokenService.saveServiceInterceptor(service);
      }

      return this.httpClient.post<Response>(service, obj,{ reportProgress: true, observe: 'events'}).pipe(
        map((responseOK) => {
            return  responseOK
        }),
        catchError(err => {
          Swal.close();
          this.errorsService.handleError(err);
          return of([]);
        }
      ));
    }
}

/**
 *Validación pra requst de petición de servicio
 *
 * @private
 * @param {Response} responseOK
 * @returns
 * @memberof HttpService
 */
private success(
  responseOK: Response
) {
  /** Si respuesta OK, regresa sólo data */
  if (responseOK.data.clave === 'OK' ) {
    return responseOK.data;
  } else {
    return this.errorService.errorMessage(responseOK.data.message.replace("Error:",""));
  }
}

}
