import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatCardModule } from '@angular/material/card';
import { RootRoutingModule } from './root-routing.module';
import { ComponentsModule } from '../components/components.module';

import { RootComponent } from './root.component';
import { BackupComponent } from './backup/backup.component';
import { DetailsComponent } from './details/details.component';
import { ReportComponent } from './report/report.component';

import localeEs from '@angular/common/locales/es';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IncidenceComponent } from './incidence/incidence.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RejectDialogComponent } from './reject-dialog/reject-dialog.component';
import { PipesModule } from 'src/app/pipes/pipes.module';

registerLocaleData(localeEs, 'es');
@NgModule({
    declarations: [
        RootComponent,
        BackupComponent,
        DetailsComponent,
        ReportComponent,
        EmployeeDetailComponent,
        IncidenceComponent,
        RejectDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        MatExpansionModule,
        NgApexchartsModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        RootRoutingModule,
        RouterModule,
        ComponentsModule,
        PipesModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es' }
    ]
})
export class RootModule { }
