import {  AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import * as domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { firstPageHtml } from './template';
import { quizzes } from '../quiz/questions';
@Component({
  selector: 'app-periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent implements OnInit, AfterViewInit {
  @ViewChild("chart") chart: ElementRef;
  public chartOptions: Partial<any>;
  p = 0;
  labelsData = [91, 85, 18, 78, 98, 56, 93, 35, 10, 67, 49, 75];
  width;
  infoPeriod;
  promedios = [];
  quiz = quizzes.slice(0, quizzes.length);
  filter;

  userRole: string = '';
  constructor(
    private quizService: QuizService,
    private companiesService: CompaniesService,
  ) { }

  ngAfterViewInit(): void {
    let element = document.getElementById('info');
    let width = element.offsetWidth
    this.width = width - 140;
  }

  ngOnInit(): void {
    this.companiesService.getCompany(sessionStorage.getItem('idCompanySelected')).subscribe((response) => {
      if (response.logo){
      sessionStorage.setItem('logo', 'https://2bcore-site.s3.us-east-2.amazonaws.com/intranet/logoCompany/'+response.logo) 
      } else {
      sessionStorage.setItem('logo', 'assets/img/logos/logoPDF.png');
      }
    });
    window.scrollTo(0, 0);
    this.quizService.idPeriodSelect.subscribe((idPeriodSelect:string) =>{
      if(sessionStorage.getItem("idPeriodSelect")){
        if(sessionStorage.getItem('filterQuiz')){
          let item = JSON.parse(sessionStorage.getItem('filterQuiz'));
          this.filter = item;
          this.filter.centers = [];
          this.filter.areas = [];
          this.filter.works.forEach(element => {
            this.filter.centers.push(element.workcenter);
            element?.areas.forEach(area => {
              this.filter.areas.push(area);
            });
          });
          this.quizService.getFilterPeriod(item.id, item.works).subscribe(response => {
            if(response){
              this.infoPeriod = response;
              let labels = [];
              this.promedios = [];
              this.infoPeriod.promedio_por_indicador.forEach(element => {
                this.promedios.push(element.promedio_indicador);
                labels.push(element.indicador);          
              });
              if (this.infoPeriod.promedio_general<= 20) {
                this.infoPeriod.colorGeneral = 'one'
                this.infoPeriod.imageGeneral = "assets/img/stats/one.png"
              } else if(this.infoPeriod.promedio_general<= 40 && this.infoPeriod.promedio_general> 20) {
                this.infoPeriod.colorGeneral = 'two'
                this.infoPeriod.imageGeneral = "assets/img/stats/two.png"
              } else if(this.infoPeriod.promedio_general<= 60 && this.infoPeriod.promedio_general> 40) {
                this.infoPeriod.colorGeneral = 'three'
                this.infoPeriod.imageGeneral = "assets/img/stats/three.png"
              } else if(this.infoPeriod.promedio_general<= 80 && this.infoPeriod.promedio_general> 60) {
                this.infoPeriod.colorGeneral = 'four'
                this.infoPeriod.imageGeneral = "assets/img/stats/four.png"
              } else if(this.infoPeriod.promedio_general<= 100 && this.infoPeriod.promedio_general> 80) {
                this.infoPeriod.colorGeneral = 'five'
                this.infoPeriod.imageGeneral = "assets/img/stats/five.png"
              }
              sessionStorage.setItem('infoPeriod', JSON.stringify(this.infoPeriod))
              this.chartOptions = {
                series: [
                  {
                    name: "Result",
                    data: this.promedios
                  }
                ],
                colors: [
                  function ({ value }) {
                    if (value <= 20) {
                      return "#FEAD18";
                    } else if(value <= 40 && value > 20) {
                      return "#FE746A";
                    }
                    else if(value <= 60 && value > 40) {
                      return "#CE496D";
                    }
                    else if(value <= 80 && value > 60) {
                      return "#966ACC";
                    }
                    else if(value <= 100 && value > 80) {
                      return "#33529C";
                    }
                  }
                ],
                chart: {
                  type: "bar",
                  height: 500,
                  width: this.width,
                  toolbar: {
                    show: false,
                  },
                  events: {
                    animationEnd: function(chartContext, config) {
                      /* domtoimage.toPng(document.getElementById('containerChart'))
                      .then(async (blob) => {
                        sessionStorage.setItem('image-bar-graph', blob);
                      });
                      */
                      domtoimage.toPng(document.getElementById('generalInfo'))
                      .then(async (blob) => {
                        sessionStorage.setItem('image-info', blob);
                      }); 
                    }
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels:{ position: 'top'},
                    xaxis: {position: 'top', style:{
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },},
                  }
                },
                dataLabels: {
                  enabled: false,
                  distributed: true,
                  offsetX: 1100,
                  offsetY: 0,
                  style:{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  colors: ['#33529C', '#966ACC', '#FEAD18'
                  ]
                },
                },
                
                xaxis: {
                  categories: labels,
                  tickPlacement: 'between',
                  max: 100,
                  labels: {
                    show: true,
                    align: 'left',
                    position: "left",
          
                  },
                  axisTicks: {
                    show: false,
                },
                },
          
                yaxis:{
                  show: false,
                  position: 'top',
                  offsetY: 30,
                  
                  labels: {
                    show: true,
                    align: 'left',
                  },
                },
                legend: {
                  position: 'left', 
                },
                tooltip:{
                  enabled: false,
                }
              };

            }
          });
        }else{
          this.quizService.getPeriod(sessionStorage.getItem("idPeriodSelect")).subscribe(response =>{
            if(response){
              this.filter = null;
              this.infoPeriod = response;
              let labels = [];
              this.promedios = [];
              this.infoPeriod.promedio_por_indicador.forEach(element => {
                this.promedios.push(element.promedio_indicador);
                labels.push(element.indicador);          
              });
              if (this.infoPeriod.promedio_general<= 20) {
                this.infoPeriod.colorGeneral = 'one'
                this.infoPeriod.imageGeneral = "assets/img/stats/one.png"
              } else if(this.infoPeriod.promedio_general<= 40 && this.infoPeriod.promedio_general> 20) {
                this.infoPeriod.colorGeneral = 'two'
                this.infoPeriod.imageGeneral = "assets/img/stats/two.png"
              } else if(this.infoPeriod.promedio_general<= 60 && this.infoPeriod.promedio_general> 40) {
                this.infoPeriod.colorGeneral = 'three'
                this.infoPeriod.imageGeneral = "assets/img/stats/three.png"
              } else if(this.infoPeriod.promedio_general<= 80 && this.infoPeriod.promedio_general> 60) {
                this.infoPeriod.colorGeneral = 'four'
                this.infoPeriod.imageGeneral = "assets/img/stats/four.png"
              } else if(this.infoPeriod.promedio_general<= 100 && this.infoPeriod.promedio_general> 80) {
                this.infoPeriod.colorGeneral = 'five'
                this.infoPeriod.imageGeneral = "assets/img/stats/five.png"
              }
              sessionStorage.setItem('infoPeriod', JSON.stringify(this.infoPeriod))
              this.chartOptions = {
                series: [
                  {
                    name: "Result",
                    data: this.promedios
                  }
                ],
                colors: [
                  function ({ value }) {
                    if (value <= 20) {
                      return "#FEAD18";
                    } else if(value <= 40 && value > 20) {
                      return "#FE746A";
                    }
                    else if(value <= 60 && value > 40) {
                      return "#CE496D";
                    }
                    else if(value <= 80 && value > 60) {
                      return "#966ACC";
                    }
                    else if(value <= 100 && value > 80) {
                      return "#33529C";
                    }
                  }
                ],
                chart: {
                  type: "bar",
                  height: 500,
                  width: this.width,
                  toolbar: {
                    show: false,
                  },
                  events: {
                    animationEnd: function(chartContext, config) {
                      /* domtoimage.toPng(document.getElementById('containerChart'))
                      .then(async (blob) => {
                        sessionStorage.setItem('image-bar-graph', blob);
                      });
                      */
                      domtoimage.toPng(document.getElementById('generalInfo'))
                      .then(async (blob) => {
                        sessionStorage.setItem('image-info', blob);
                      }); 
                    }
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels:{ 
                      position: 'top',
                      style:{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        colors: ['#33529C', '#966ACC', '#FEAD18'
                        ]
                      },
                    },
                    xaxis: {position: 'top'},
                  }
                },
                dataLabels: {
                  enabled: false,
                  distributed: true,
                  offsetX: 1100,
                  offsetY: 0,
                  style:{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    colors: ['#33529C', '#966ACC', '#FEAD18'
                    ]
                  },
                },
                
                xaxis: {
                  categories: labels,
                  tickPlacement: 'between',
                  max: 100,
                  labels: {
                    show: true,
                    align: 'left',
                    position: "left",
                  },
                  axisTicks: {
                    show: false,
                  },
                },
          
                yaxis:{
                  show: false,
                  position: 'top',
                  offsetY: 30,
                  fontSize: '18px',
                  labels: {
                    show: true,
                    align: 'left',
                  },
                },
                legend: {
                  position: 'left', 
                },
                tooltip:{
                  enabled: false,
                }
              };

            }
          });
        }
        
    }
    });

    this.userRole = sessionStorage.getItem('role') || '';
  }
  
}
