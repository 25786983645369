<div class="comment" *ngFor="let comment of comments">
    <ng-template [ngTemplateOutlet]="commentTemplate" [ngTemplateOutletContext]="{ $implicit: comment}"></ng-template>

    <div class="comment__responses">
        <div class="comment" *ngFor="let response of comment.responses">
            <ng-template [ngTemplateOutlet]="commentTemplate"
                [ngTemplateOutletContext]="{ $implicit: response}"></ng-template>

        </div>
    </div>
</div>

<ng-template #commentTemplate let-comment>
    <img [src]="comment.userAvatar" class="comment__avatar" [alt]="'Photo of ' + comment.userName" />
    <p class="comment__name">{{ comment.userName }}</p>
    <p class="comment__date">{{ comment.createdAt | customDate }}</p>
    <button mat-flat-button extended class="comment__btn-response">
        <mat-icon>reply</mat-icon>
        Responder
    </button>
    <p class="comment__comment">{{ comment.comment }}</p>
</ng-template>

<div class="new-comment">

</div>
<form class="comment-form deep-button">
    <img [src]="user.userAvatar" class="avatar d-none d-md-block" [alt]="'Photo of ' + user.userName" />

    <mat-form-field appearance="outline">
        <input matInput placeholder="Añadir comentario" rows="1">
        <span class="time-icon-comment_emoji mr-3" matSuffix (click)="emojiAns =! emojiAns"></span>
    </mat-form-field>

    <button mat-flat-button extended color="primary">
        Enviar
    </button>
</form>

<ngx-emoj class="emoji-picker" *ngIf="emojiAns"
    (onemojipick)="handleSelectionAnsw($event)"
    (onchardelete)="handleCharDelete($event)"
    (onClick)="$event.stopPropagation()"
    [width]="'342px'"
    [height]="'286px'"
    [theme]="{
        martShowHeader: true,
        martShowFooter: false,
        martHeaderPadding: {x: '0px', y: '0px'},
        martFooterPadding: {x: '0px', y: '0px'},
        martHeaderBG: '#e3e7e8',
        martFooterBG: '#e3e7e8',
        martBG: '#ffffff',
        martCategoryColor: '#94a0a6',
        martCategoryColorActive:  '#455a64',
        martActiveCategoryIndicatorColor: '#00897b',
        martEmojiFontSize: '167%',
        martCategoryFontSize: '20px',
        martBorderRadius: '10px',
        martEmojiPadding: {x: '40px', y: '40px'}}">
</ngx-emoj>