<div class="row justify-content-center" *ngIf="totalPages > 1">
    <nav aria-label="Page navigation of projects">
        <ul class="pagination align-items-center">
            <li class="page-item">
                <a class="page-link" aria-label="Previous" (click)="selectPage(currentPage - 1)">
                    <span class="time-icon-paginator_prev" aria-hidden="true"></span>
                </a>
            </li>
            <li class="page-item" *ngFor="let page of getPageRange();">
                <a class="page-link" [class.active]="currentPage == page" (click)="selectPage(page)">{{ page +1 }}</a>
            </li>
            <li class="page-item">
                <a class="page-link" aria-label="Next" (click)="selectPage(currentPage + 1)">
                    <span class="time-icon-paginator_next" aria-hidden="true"></span>
                </a>
            </li>
        </ul>
    </nav>
</div>
