import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator[elementsCount][elementsByPage]',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Output() pageEmitter = new EventEmitter<number>();
  @Input() elementsByPage!: number;
  @Input()
  set elementsCount(elementsCount: number) {
    this.calcTotalPages(elementsCount);
    this.selectPage(0);
  }
  private readonly pagesRange = 5;
  public totalPages = 0;
  public currentPage = 0;

  private calcTotalPages(elementsCount: number) {
    this.totalPages = Math.ceil(elementsCount / this.elementsByPage);
  }
  
  public selectPage(page: number) {
    if (page < 0 || page >= this.totalPages) return;
    this.currentPage = page;
    this.pageEmitter.emit(page);
  }

  public getPageRange(): Array<number> {
    const start = Math.floor(this.currentPage / this.pagesRange) * this.pagesRange;

    let end = start + this.pagesRange;
    if (end > this.totalPages) end = this.totalPages; 

    const range: number[] = [];

    for (let index = start; index < new Array(end).length; index++) {
      range.push(index); 
    }
    return range;
  }
}
