import { employees, leaders } from '@pmo/teams/team/team.const';

export interface Project {
    code: string
    title: string
    leaderId: string
    team: string[]
    client: string
    status: string
    commentsCount: number
    category?:string
}

// projects assignments
export const proposals: Project[] = [
    { 
        code: 'M40STBSPC240408N',
        title: '1 Soporte Técnico Productivo V1',
        leaderId: leaders[0].id,
        team: [employees[2].id, employees[1].id],
        client: 'Pluxaa',
        status: 'Enviada',
        commentsCount: 5,
        category:'Proyecto'
    },
    { 
        code: 'L40STBSPC240408O',
        title: '2 Soporte Técnico Productivo V2',
        leaderId: leaders[1].id,
        team: [employees[0].id, employees[1].id, employees[2].id, employees[3].id],
        client: 'Pluxee',
        status: 'Enviada',
        commentsCount: 7,
        category:'Proyecto'
    },
    { 
        code: 'K40STBSPC240408P',
        title: '3 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Proyecto'
    },
    { 
        code: 'J40STBSPC240408Q',
        title: '4 Soporte Técnico Productivo V5',
        leaderId: leaders[3].id,
        team: [employees[1].id, employees[2].id, employees[3].id],
        client: 'Pluxoo',
        status: 'Enviada',
        commentsCount: 0,
        category:'Proyecto'
    },
    { 
        code: 'I40STBSPC240408P',
        title: '5 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Proyecto'
    },
    { 
        code: 'H40STBSPC240408Q',
        title: '6 Soporte Técnico Productivo V5',
        leaderId: leaders[3].id,
        team: [employees[1].id, employees[2].id, employees[3].id],
        client: 'Pluxoo',
        status: 'Enviada',
        commentsCount: 0,
        category:'Proyecto'
    },

    { 
        code: 'G40STBSPC240408P',
        title: '7 Soporte Técnico Productivo V4',
        leaderId: leaders[3].id,
        team: [employees[3].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Asignación'
    },
    { 
        code: 'F40STBSPC240408P',
        title: '8 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Asignación'
    },
    { 
        code: 'E40STBSPC240408P',
        title: '9 Soporte Técnico Productivo V4',
        leaderId: leaders[3].id,
        team: [employees[3].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Asignación'
    },
    { 
        code: 'D40STBSPC240408P',
        title: '10 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Asignación'
    },
    { 
        code: 'C40STBSPC240408P',
        title: '11 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Asignación'
    },
    { 
        code: 'B40STBSPC240408P',
        title: '12 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Propuesta Comercial'
    },
  
    { 
        code: 'A40STBSPC240408P',
        title: '13 Soporte Técnico Productivo V4',
        leaderId: leaders[2].id,
        team: [employees[2].id],
        client: 'Pluxii',
        status: 'Enviada',
        commentsCount: 2,
        category:'Propuesta Comercial'
    },
]
