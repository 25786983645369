import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public isLogged: boolean = false;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.isLogged.subscribe((value: boolean) => {
      this.isLogged = value;
    })
  }

  signOut(){
  }

  ngOnDestroy() {
    this.auth.isLogged.complete();
  }
}
