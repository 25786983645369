import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import {
  EComplaintCategories,
  EComplaintStages,
  ICommentsModel,
} from '../../models/complaint-box/complaint-box.model';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/operators';
import { ResponseContent } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ComplaintBoxService {
  complaintData = new BehaviorSubject<ICommentsModel>(null);
  complaintStage = new BehaviorSubject<EComplaintStages>(0);
  commentDetail = new BehaviorSubject<ICommentsModel>(null);

  constructor(private http: HttpService) {}

  /**
   * Disminuye contador en flujo para "retroceder" pantalla
   */
  stepBack(): void {
    this.complaintStage.next(this.complaintStage.getValue() - 1);
  }

  resetData(): void {
    this.complaintData.next(null);
    this.complaintStage.next(EComplaintStages.WELCOME);
  }

  /**
   * Actualizaciones de datos a lo largo del flujo
   * @param email
   * @param idCompany
   */
  updateEmail(email: string, idCompany: string): Observable<ResponseContent> {
    return this.http
      .post('/complaintsBox/generateCode', { email, idCompany })
      .pipe(
        map((res: ResponseContent) => {
          if (res) {
            this.complaintData.next({ email });
            this.complaintStage.next(EComplaintStages.FOLIO);
          }

          return res;
        })
      );
  }

  validateFolio(
    code: string,
    idCompany: string,
    email: string
  ): Observable<ResponseContent> {
    return this.http
      .post('/complaintsBox/validateCode', { code, idCompany, email })
      .pipe(
        map((res: ResponseContent) => {
          if (res) {
            this.complaintData.next({
              ...this.complaintData.getValue(),
              ...res.content,
            });
            this.complaintStage.next(EComplaintStages.CATEGORY);
          }
          return res;
        })
      );
  }

  updateCategory(category: EComplaintCategories): void {
    this.complaintData.next({
      ...this.complaintData.getValue(),
      // tslint:disable-next-line:object-literal-shorthand
      ...{ category: category },
    });
    this.complaintStage.next(EComplaintStages.PERSONAL_DATA);
  }

  sendComplaintComments( comment: string,idCompany: string, data: any): Observable<ResponseContent> {
    this.complaintData.next({
      ...this.complaintData.getValue(),
      ...{ comment },
    });
    let payload = this.complaintData.getValue();
    payload.email = data.email;
    payload.names = data.names;
    payload.surnames = data.surnames;
    payload.secondSurname = data.secondSurname;
    payload.employeeId  = data.employeeId ;
    return this.http
      .post('/complaintsBox/sendComments', {
        comments: payload,
        idCompany,
      })
      .pipe(
        map((res) => {
          if (res) {
            this.complaintStage.next(EComplaintStages.SUCCESS);
          }
          return res;
        })
      );
  }

  /**
   * Obtiene lista de comentarios de la empresa
   */
  getComments(idCompany: string, category: string): Observable<ResponseContent> {
    return this.http.post('/complaintsBox/getComments', { idCompany, category }, true).pipe(
        map((res) => {
          return res;
        })
      );
  }

  /**
   * Envía seguimiento del comentario
   */
  followUp(
    comment: ICommentsModel,
    idCompany: string
  ): Observable<ResponseContent> {
    return this.http
      .post('/complaintsBox/setStatus', { comments: comment, idCompany }, true)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /**
   * Realiza registro de acciones tomadas
   */
  registerActions(
    comment: ICommentsModel,
    idCompany: string
  ): Observable<ResponseContent> {
    return this.http.post('/complaintsBox/setActions', { comments: comment, idCompany },true).pipe(
        map((res) => {
          return res;
        })
      );
  }
  /**
   * Cambia prioridad
   */
  setPriority(
    comment: ICommentsModel,
    idCompany: string
  ): Observable<ResponseContent> {
    return this.http
      .post('/complaintsBox/setPriority', { comments: comment, idCompany }, true)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
