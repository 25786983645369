import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { quizzes } from './questions';
import * as domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { ModalService } from 'src/app/services';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild("chart") chart: ElementRef;
  formEmployeeInfo: FormGroup;
  step = 1000; 
  quizzes = quizzes;
  sended = false;

  idProject = '';
  idEmployee  = '';

  /***alamcena suscripciones activas */
  subscribers: any = [];
  companyImg = "";
  companyName = "";
  employeeInfo;
  public chartOptions: Partial<any>;
  constructor(
    private formbuilder: FormBuilder,
    public route: ActivatedRoute,
    private quizService: QuizService,
    private modal: ModalService,
    private questionnares: QuestionnairesService
  ) {
    this.chartOptions = {

      series: [
        {
          name: "Puntuacion",
          data: [91,85, 18, 78, 98, 56, 93, 35, 10, 67, 49, 75]
        }
      ],
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }) {
          if (value <= 20) {
            return "#FEAD18";
          } else if(value <= 40 && value > 20) {
            return "#FE746A";
          }
          else if(value <= 60 && value > 40) {
            return "#CE496D";
          }
          else if(value <= 80 && value > 60) {
            return "#966ACC";
          }
          else if(value <= 100 && value > 80) {
            return "#33529C";
          }
        }
      ],
      chart: {
        type: "bar",
        height: 450
      },
      plotOptions: {
        bar: {
          distributed: false,
          horizontal: true,
          dataLabels: {
            hideOverflowingLabels: false,
            position: "bottom"
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"]
        },
      },
      xaxis: {
        categories: [
          "Comunicación",
          "Cooperación",
          "Conflicto",
          "Ambiente",
          "Estructura",
          "Identidad",
          "Liderazgo",
          "Motivación",
          "Remuneración",
          "Innovación", 
          "Formación y desarrollo",
          "Equidad"
        ]
      },
      yaxis: {
        labels: {
          show: true
        }
      },
    };
    this.formEmployeeInfo = formbuilder.group({
      idEmployee: [''],
      gender: ['', Validators.required],
      age:['', Validators.required],
      marital_status:['', Validators.required],
      antiquity: ['', Validators.required],
      scolarship: ['', Validators.required],
      quiz:[],
    });
   }

  ngOnInit(): void {
    this.idProject = this.route.snapshot.queryParamMap.get('idProject');
    this.idEmployee = this.route.snapshot.queryParamMap.get('idEmployee');
    this.subscribers.getEmployee = this.questionnares.getEmployee(this.idEmployee).subscribe((response) => {
      this.employeeInfo = response.content
      this.questionnares.getCompanyName(response.content.idCompany).subscribe((response) => {
        if (response){
          this.companyImg = response.content.logo;
          this.companyName = response.content.companyName;
        }
      });
    });
    this.subscribers.checkStatusQuizz = this.quizService.checkStatusQuizz(this.idProject).subscribe(response=>{
      if(response){
        this.subscribers.checkStatusAnswer = this.quizService.checkStatusAnswer(this.idEmployee, this.idProject).subscribe(res=>{
          if(res){        
            this.step = this.quizzes.length+1;
            this.sended = true;
          }else{
            this.step = 0;
          }
        }), error=>{
        }
      }else{
        this.modal.warning({
          title: '¡La fecha para responder ha expirado!',
          message: '',
        });
        this.step = this.quizzes.length+1;
        this.sended = true;
      }
    })
       
  }

  checkNext(){
    if(this.step == 0){
      return this.formEmployeeInfo.invalid
    }else{
      let flag = this.quizzes[this.step-1].quizz_answer.find(quizz => quizz.answer == 0);
      return flag ? true : false;
    }
  }

  backStep(){
    window.scrollTo(0, 0);
    this.step--;
  }
  nextStep(){
    if(!this.checkNext()){
      window.scrollTo(0, 0);
      this.step++;
    }else{
      this.modal.warning({
        title: '¡Aun no has respondido todas las preguntas!',
        message: '',
      });
    }    
  }
  changeAnswer(indicatorIndex, answerIndex, value){
    this.quizzes[indicatorIndex].quizz_answer[answerIndex].answer = value;
  }
  sendQuiz(){
    if(!this.checkNext()){      
      this.step++;
      this.sended = true;
      let quizzes_aux = this.quizzes.slice(0,this.quizzes.length)
      for (let index = 0; index < quizzes_aux.length; index++) {
        delete quizzes_aux[index]["class"];
        for (let index1 = 0; index1 < quizzes_aux[index]["quizz_answer"].length; index1++) {
          delete quizzes_aux[index]["quizz_answer"][index1]["title"];
          //quizzes_aux[index]["quizz_answer"][index1]["answer"] = Math.floor((Math.random()*5)+1)
        }
      }
      let quizz_send = {
        "idProyect":this.idProject,
        "idEmployee":this.idEmployee,
        "gender": this.formEmployeeInfo.controls.gender.value,
        "age": this.formEmployeeInfo.controls.age.value,
        "marital_status": this.formEmployeeInfo.controls.marital_status.value,  
        "antiquity": this.formEmployeeInfo.controls.antiquity.value,
        "scolarship": this.formEmployeeInfo.controls.scolarship.value,
        "quizzes": quizzes_aux
      }

      this.subscribers.sendAnswer = this.quizService.sendAnswer(quizz_send).subscribe((response: any) => {
        Swal.close();  
        if(response){
            this.modal.successful({
              title: '¡Operación exitosa!',
              message: 'Se ha creado el periodo correctamente',
            });//this.route.navigate(['/work/work']);
          }
      });
    }else{
      this.modal.warning({
        title: '¡Aun no has respondido todas las preguntas!',
        message: '',
      });
    }  
  }

  savePDF(){
    domtoimage.toPng(document.getElementById('chart'))
    .then(function (blob) {
        var pdf = new jsPDF('p', 'px');
        pdf.addImage(blob, 'JPG', 0, 0, 240, 180);
        pdf.save(`example.pdf`);
    });
  }

  gotoLanding(){
    window.open(environment.baseSocketUrl, '_self', '');
  }
}
