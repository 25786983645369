import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RootComponent } from "./root.component";
import { BackupComponent } from "./backup/backup.component";
import { DetailsComponent } from "./details/details.component";
import { ReportComponent } from "./report/report.component";
import { EmployeeDetailComponent } from "./employee-detail/employee-detail.component";

const routes: Routes = [
    { path: '', component: RootComponent },
    { path: 'backup', component: BackupComponent},
    { path: 'details/:activityId/:employeeId', component: DetailsComponent},
    { path: 'report/:id', component: ReportComponent},
    { path: 'employee-detail/:id', component: EmployeeDetailComponent}
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class RootRoutingModule { }