import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http-service/http.service';
import { IInvitationModel, IEvidenceModel, IGuestsModel } from '../../models/invitation/invitation-model';
import {map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(
    private httpService: HttpService,
    private route: Router) { }

  idInvitation = new BehaviorSubject <string>('');

  isEditing = new BehaviorSubject<{value: boolean, id: string}>({value: false, id: ''});

  allGuests = new BehaviorSubject<any>([]);
  fondo = new BehaviorSubject <string>('');
  /**Método para crear una invitación */
  create(obj: IInvitationModel){
    return this.httpService.post('/invitation/create', obj, true).pipe(
      map((response) => {
        if(response && response.clave === 'OK'){
          return response.content;
        };
      })
    );
  };
  /**
   * Elimina una invitación
   */
  delete(id){
    return this.httpService.post('/invitation/delete', id, true).pipe(
      map((response) => {
        if( response && response.clave ===  'OK'){
          return response.content;
        };
      })
    );
  };
  /**
   * Obtiene una invitación
   * @param id
   */
  getInvitation(id,encryp=false){
    return this.httpService.post('/invitation/getInvitation', {id,encryp}, true).pipe(
      map(response => {
        if (response && response.clave ===  'OK'){
          return response.conetnt;
        };
      })
    );
  };
  /**Actualiza la invitación */
  updateInvitation(id, invitation: IInvitationModel){
    return this.httpService.post('/invitation/update', {id, invitation}, true).pipe(
      map(response => {
        if (response && response.clave ===  'OK'){
          return response.clave;
        }
      })
    );
  };
  /**
   * Obtiene todas las invitaciones
   */
  getInvitations(obj){
    return this.httpService.post('/invitation/getInvitations', obj, true ).pipe(
      map(response => {
        if(response && response.clave ===  'OK'){
          return response.content;
        }
      })
    );
  };
  /**Agrega evidencias */
  setEvidence(id: string, evidences: IEvidenceModel){
    return this.httpService.post('/invitation/setEvidences', {id, evidences}, true).pipe(
      map(response => {
        if( response && response.clave ===  'OK'){
          return response.content;
        };
      })
    );
  };
/**Actualiza la evidencia */
  updateEvidence(id, idEvidence, evidences: IEvidenceModel){
    return this.httpService.post('/invitation/updateEvidence', {id, idEvidence, evidences}, true).pipe(
      map(response => {
        if (response && response.clave === 'OK'){
          return response.content;
        };
      })
    )
  };

  getEvidences(id){
    return this.httpService.post('/invitation/getEvidences', id, true).pipe(
      map(response => {
        if(response && response.clave === 'OK'){
          return response.content;
        };
      })
    )
  };

  deleteEvidence(idInvitation, idEvidence){
    return this.httpService.post('/invitation/deleteEvidence', {idInvitation, idEvidence}, true).pipe(
      map(response => {
        if(response && response.clave === 'OK'){
          return response.clave;
        }
      })
    )
  };
/**Agrega Invitados */
  setGuets(id: string, guests:IGuestsModel){
    return this.httpService.post('/invitation/setGuests', {id, guests }, true).pipe(
      map(response => {
        if( response && response.clave ===  'OK'){
          return response;
        };
      })
    );
  };
  /**Responde Invitación */
  answerInvitation(id: string, answer:{answer: boolean, id:string} ){
    return this.httpService.post('/invitation/setAnswer', {id, answer}, true ).pipe(
      map(response => {
        if( response && response.clave ===  'OK'){
          return response.message;
        };
      })
    )
  };

    /**Obtener invitados*/
    getGuests(id: string){
      return this.httpService.post('/invitation/getGuests', {id}, true ).pipe(
        map(response => {
          if( response && response.clave === 'OK'){
            this.allGuests.next(response.content);
            return response.content;
          };
        })
      )
    };
    /**Socket para obtener la respuesta del vinnitado en tiempo */
    socketAnswer(data:any){
      if(data){
        if(this.route.url.includes('/invite/menu/guests')){
          this.allGuests.next(data);
        };
      };
    };

    getImage(array: any[]): any[] {
      let base64 = [];
      if (array.length > 0) {
        for (const img of array) {
          if (img.idName) {
            const json = {
              folder: 'evidences',
              image: `${img.idName}`
            };
            this.httpService.post('/file/getFile', json, true).subscribe((resp: any) => {
              if (resp && resp.clave == 'OK') {
                base64.push(`data:image/${img.idName.replace('.', '')};base64,${resp.content}`)
              }
            })
          }
        }
      }
      return base64;
    };

    getbackground(back: string){
          if (back) {
            const json = {
              folder: 'invitationBackground',
              image: `${back}`
            };
            this.httpService.post('/file/getFile', json, true).subscribe((resp: any) => {
              if (resp && resp.clave == 'OK') {
                this.fondo.next(`data:image/${back.replace('.', '')};base64,${resp.content}`)
              };
            })

          }
        };
}
