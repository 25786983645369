<div>
    <div class="modal-header pb-0" style="border-bottom:none">
      <button type="button" class="close"  aria-label="Close" (click)="onCancel()">
        <span class="fs-30" aria-hidden="true">&times;</span>
    </button>
  </div>


<div class="body-modal">
    <div class="d-flex justify-content-center">
        <span class="time-icon-modal-activity fs-40"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
    </div>
    <h2 mat-dialog-title class="title">{{data ? 'Actualizar' : 'Agregar'}} actividad</h2>
    <div [formGroup]="activityForm">
        <div>
          <mat-form-field appearance="fill" class="w-100 deep-mat-field">
            <mat-label>Actividad*</mat-label>
            <input matInput formControlName="activity" />
          </mat-form-field>
        </div>
      
        <p class=" desc">Elige una fecha de inicio y fin de esta actividad</p>
        <div>
            <mat-form-field class="deep-mat-field w-100" appearance="fill">
                <mat-label>Fecha de inicio*</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="startDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon class="time-icon-calendar_active"
                        matDatepickerToggleIcon></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
      
            <mat-form-field class="deep-mat-field w-100" appearance="fill">
                <mat-label>Fecha de fin*</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="endDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker2">
                    <mat-icon class="time-icon-calendar_active"
                        matDatepickerToggleIcon></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
      
        <p class="desc text-left mb-1">Color:</p>
        <div class="d-flex flex-wrap" style="margin-bottom: 36px;">
          <div class="ext-circle"  *ngFor="let color of colors"   [class.selected]="activityForm.get('color')?.value === color">
            <div class="color-circle" [style.background]="color" 
               (click)="activityForm.get('color')?.setValue(color)">
          </div>
          </div>
        </div>
      </div>
      
      <div style="margin-bottom: 32px;">
        <button class="button-custom-cancel"     (click)="onCancel()">Cancelar</button>
        <button class="button-accept" [class.disabled]="!activityForm.valid" [disabled]="!activityForm.valid" (click)="onAdd()">{{data ? 'Actualizar' : 'Agregar'}}</button>
      </div>
</div>