import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  PdfMakeWrapper, Txt, Img, Columns, Stack, Table, Ol, Ul, Item, Cell} from 'pdfmake-wrapper';
  import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2/dist/sweetalert2.js';
/** Servicios */

import {CompaniesService} from '../../../services/companies/companies.service';
import { AuthService } from '../../../services/auth/auth.service';
import {suscriberDestroyMiddleware} from '../../../middleware/suscribers-destroy.middleware';
/**
 * Componente para la gráfica de avance
 * @export
 * @implements {OnInit}
 */
@Component({
  selector: 'app-home-landing',
  templateUrl: './home-landing.component.html',
  styleUrls: ['./home-landing.component.scss']
})
@suscriberDestroyMiddleware()

export class HomeLandingComponent implements OnInit, OnDestroy{

  // Avance 20% al llenar sección Datos de la Empresa
  companyData = false;
  // Avance 40% al cargar al menos un usuario
  userData = false;
  // Avance 60% al enviar al menos un cuestionario
  sentQuestionary = false;
  // Avance 80% al descargar todos los reportes
  downloadReports = false;
  // Avance 100% al agregar al menos un programa en Datos para los Programas
  programsData = false;
  // Avance general de la empresa
  overallProgress = 0;
  // Logo de la empresa
  logo: string;
  // Nombre de la empresa
  companyName = '';
  // Controla posición de la nave
  rocketPosition: number;
  // Alamacena suscripciones activas
  subscriber: any = [];

  /**
   * Creates an instance of HomeLandingComponent.
   */
  constructor(
    private companiesService: CompaniesService,
    private authService: AuthService,
    private datePipe: DatePipe,
    ) {
    this.subscriber.companyName = this.authService.nameCompanySelected.subscribe(res => {
      this.companyName = res;
    });
    this.subscriber.companyLogo = this.authService.logoCompanySelected.subscribe(res => {
      this.logo  = res;
    });
   }

  /**
   * Inicialización de componente
   */
  ngOnInit(): void {

    this.getStatus();
    this.getProgress();

  }

  /**
   * Obtiene el porcentaje en base a la empresa seleccionada
   */
  getStatus(): void {
      this.companiesService.getProgress(sessionStorage.getItem('idCompanySelected'));
  }

  /**
   * Obtiene el avance general de la empresa
   */
  getProgress(): void {
    this.companiesService.percentageModules.subscribe((response) =>{
      // Inicializar valores
      this.overallProgress = 0;
      this.companyData = false;
      this.userData = false;
      this.sentQuestionary = false;
      this.downloadReports = false;
      this.programsData = false;
      this.rocketPosition = 4.5;

      if(response.generalData){//20
        this.overallProgress = 20;
        this.companyData = true;
        this.rocketPosition = 22.5;

        if(response.employeeLoad){//40
          this.overallProgress = 40;
          this.userData = true;
          this.rocketPosition = 40.2;

          if(response.sendingQuestionnaires){//60
            this.overallProgress = 70;
            this.sentQuestionary = true;
            this.rocketPosition = 57.9;

            if(response.reportQuery){//80
              this.overallProgress = 80;
              this.downloadReports = true;
              this.rocketPosition = 75.6;

              if(response.continuousImprovement){//100
                this.overallProgress = 100;
                this.programsData = true;
                this.rocketPosition = 93.2;
              }
            }
          }
        }
      }
    });

  }

  async  guiauno(){
  
 
    const pdf = new PdfMakeWrapper();
    pdf.styles({
      style1: {
         background:  "#ff5500"
      },
      style2: {
          italics: true
      }
  });
    //estilos generales
      pdf.defaultStyle({
        fontSize: 9,
        color:'#4D4D4D'
    });
  //HOJA 1
  
    pdf.pageMargins([40, 40, 20, 60]);
    pdf.background(new Txt('Expedido: '+ this.datePipe.transform(Date.now(), 'd/MM/yy HH:mm') + ' hrs'+'').alignment('right').margin([40,10]).fontSize(8).end);
    pdf.add(await new Img('../../.././../assets/img/guides/guide-one-part-one.JPG').margin([0, 0, 0, 0]).width(450).height(200).alignment('center').build())
    pdf.add(await new Img('../../.././../assets/img/guides/guide-one-part-two.JPG').width(450).height(200).alignment('center').build())
    pdf.add(await new Img('../../.././../assets/img/guides/guide-one-part-three.JPG').width(450).height(100).alignment('center').build())
    pdf.footer(await new Img('../../../../assets/img/logos/indicepdf.jpg').width('50').margin([40,0]).build());
  
    pdf.create().download("Guia_uno.pdf");
     }
     async guiados(){
        const pdf = new PdfMakeWrapper();
        pdf.styles({
          style1: {
            background:  "#ff5500"
          },
          style2: {
              italics: true
          }
      });
          pdf.defaultStyle({
            fontSize: 9,
            color:'#4D4D4D'
        });
        pdf.pageMargins([40, 40, 20, 60]);
        pdf.background(new Txt('Expedido: '+ this.datePipe.transform(Date.now(), 'd/MM/yy HH:mm') + ' hrs'+'').alignment('right').margin([40,10]).fontSize(8).end);
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-one.JPG').margin([0, 0, 0, 0]).width(465).height(230).alignment('center').build())
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-two.JPG').width(450).height(220).alignment('center').build())
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-three.JPG').pageBreak('after').width(450).height(100).alignment('center').build())
        //hoja 2
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-four.JPG').margin([0, 0, 0, 0]).width(450).height(150).alignment('center').build())
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-five.JPG').width(450).height(150).alignment('center').build())
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-six.JPG').pageBreak('after').width(450).height(290).alignment('center').build())
        //hoja 3
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-seven.JPG').margin([0, 0, 0, 0]).width(465).height(200).alignment('center').build())
        pdf.add(await new Img('../../.././../assets/img/guides/guide-two-part-eight.JPG').width(450).height(115).alignment('center').build())
        pdf.footer(await new Img('../../../../assets/img/logos/indicepdf.jpg').width('50').margin([40,0]).build());
        pdf.create().download("Guia_dos.pdf");
     }
     async guiatres(){
      const pdf = new PdfMakeWrapper();
      pdf.styles({
        style1: {
          background:  "#ff5500"
        },
        style2: {
            italics: true
        }
    });
        pdf.defaultStyle({
          fontSize: 9,
          color:'#4D4D4D'
      });
      pdf.pageMargins([40, 40, 20, 60]);
      pdf.background(new Txt('Expedido: '+ this.datePipe.transform(Date.now(), 'd/MM/yy HH:mm') + ' hrs'+'').alignment('right').margin([40,10]).fontSize(8).end);
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-one.JPG').margin([0, 0, 0, 0]).width(465).height(210).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-two.JPG').width(450).height(200).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-three.JPG').pageBreak('after').width(450).height(100).alignment('center').build())
      //hoja 2
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-four.JPG').margin([0, 0, 0, 0]).width(450).height(200).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-five.JPG').width(450).height(250).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-six.JPG').pageBreak('after').width(450).height(150).alignment('center').build())
      //hoja 3
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-seven.JPG').margin([0, 0, 0, 0]).width(465).height(200).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-eight.JPG').width(450).height(150).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-nine.JPG').pageBreak('after').width(465).height(200).alignment('center').build())
     //hoja 4
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-ten.JPG').margin([0, 0, 0, 0]).width(450).height(250).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-eleven.JPG').width(450).height(150).alignment('center').build())
      pdf.add(await new Img('../../.././../assets/img/guides/guide-three-part-twelve.JPG').width(450).height(125).alignment('center').build())
  
      pdf.footer(await new Img('../../../../assets/img/logos/indicepdf.jpg').width('50').margin([40,0]).build());
      pdf.create().download("Guia_tres.pdf");
  
     }
   async pdf(){
    Swal.fire({ title: 'Generando PDF', icon: 'info', allowOutsideClick: false, allowEnterKey: false, allowEscapeKey: false });
    Swal.showLoading();
    this.guiauno();
    this.guiados();
    this.guiatres();
      Swal.close();
    }

/**
 * Destructor
 * @memberof PrivateRouteGuard
 */
ngOnDestroy() { }

}
