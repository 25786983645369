import { Injectable } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AwsCognitoService } from '../services/aws/aws-cognito.service';
import { HttpService } from '../services/http-service/http.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from '../services/auth/auth.service';
import { ModalService } from '../services';
import { CryptoService } from '../services/crypto/crypto.service';
import {Globals} from 'src/app/globals/globals';
import { ICompanyModelGet } from '../models/companies/companies.model';
import { IAccountGet } from '../models/account/account.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChooseRoleComponent } from '../shared/choose-role/choose-role.component';
import { CompaniesService } from '../services/companies/companies.service';
import {environment} from '../../environments/environment';
import { User } from '../models/user/user.interface';
import { MqService } from 'src/app/services/mqtt/mqtt.service';


@Injectable()
export class TokenResolverService implements Resolve<any> {
  code = null;
  isLoggedIn = false;
  public user = new BehaviorSubject<{}>({});
  generalRoles = [
    'employee','addOn','admin','root'
  ]
    /** Empresas */
    companies : ICompanyModelGet[] = [];
    /** Cuenta */
    account: IAccountGet;
    // id del usuario logeado
    idUser: string;
    subscribers: any = [];
    params;

  modalRef? : BsModalRef;

  constructor(
    private awsCognitoService: AwsCognitoService,
    private router: Router,
    private http: HttpService,
    private authService: AuthService,
    public globals: Globals,
    private modalS: ModalService,
    private cryptoService: CryptoService,
    private modalService: BsModalService,
    private companieService: CompaniesService,
    private mqtt: MqService
    ) {}

  async resolve(){
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    let code  = urlParams.get('code');
    let token = sessionStorage.getItem('token');

    this.isLoggedIn = sessionStorage.getItem('isLogged') == 'true' ? true : false;

    if ( !!code && !token ){

      return this.getTokenDetailsFromCognito(code);

    }else if (!this.isLoggedIn && !!token){

      this.chooseRole();
      return of(null);

    }else if (!this.isLoggedIn && !token){

      this.router.navigate(['/signin'])
      return of(null);
    }else{

      return of(null);
    }
  }

  getTokenDetailsFromCognito(code: string) {

    return this.awsCognitoService.getTokenDetailsFromCognito(code)
      .subscribe(async (response: any) => {
        sessionStorage.setItem('token', response.access_token);
        sessionStorage.setItem('refresh_token', response.refresh_token);
        let time = new Date().getTime();
        time = time + 7000000;
        sessionStorage.setItem('timeToRefresh', time.toString())
        if (response) {
          this.openConnectionMQ();
          this.chooseRole();

        }

        return of(response);
      }),
      catchError ((error) => {
        return error;
      });

  }

  chooseRole(){

    const path = sessionStorage.getItem('path');

    if(!!path){

      switch (path) {
        case '/comment':
          let params = JSON.parse(sessionStorage.getItem("params"));
          this.authService.chooseRoles("employee").subscribe(res => {

            if(res){
              this.companieService.themeSelected.reload();
              this.router.navigateByUrl('comment?idCompany=' + params.idCompany);
            }else{
              this.router.navigateByUrl('/nodata');
            }
          });
          break;
        case '/invite/menu/info':
          if(sessionStorage.getItem("respondingInvitation") == 'true' ? true : false){

            this.authService.chooseRoles('employee').subscribe((res)=> {

              if(res){
                let params = JSON.parse(sessionStorage.getItem("params"));

                sessionStorage.setItem('invitationSelected', this.cryptoService.decryptHex(environment.encryptionKey, params?.invitation));
                sessionStorage.setItem('guest',this.cryptoService.decryptHex(environment.encryptionKey, params?.guest));
                sessionStorage.setItem('code',params?.code);
                this.companieService.themeSelected.reload();
                this.router.navigateByUrl('/invite/menu/info');
              }else{
                this.router.navigateByUrl('/nodata');
              }

            });

          }
          break;

        default:
          this.behaviorDafault();
          break;
      }

    }else{

      this.behaviorDafault();

    }
  }

  behaviorDafault(){

    this.authService.getRoles().subscribe((dataUser: User)=>{

      if(dataUser){

        const roles = dataUser.roles.filter(elem => this.generalRoles.includes(elem));
        const rolesTimeManagement = dataUser.roles.filter(elem => !this.generalRoles.includes(elem))[0];
        console.log(rolesTimeManagement);
        
        // rolesTimeManagement !== null ? sessionStorage.setItem('timeManagementRole',rolesTimeManagement) : sessionStorage.setItem('timeManagementRole','employee');
        sessionStorage.setItem('timeManagementRole',rolesTimeManagement ?? 'employee');
        if(roles.length > 1 ){

          this.modalRef = this.modalService.show(ChooseRoleComponent, {
            animated: true,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-dialog-centered'
          });
          this.router.navigateByUrl('/home/init-page');

        }else{

          this.authService.chooseRoles('employee').subscribe(()=> {
            this.companieService.themeSelected.reload();
            this.router.navigateByUrl('/home/init-page');
          });

        }

      }else{

        this.router.navigateByUrl('/nodata');

      }

    });

  }

  // ! This functions is not used
  /**
   * !Servicio anterior.
   */
  signInSession(){
    // bandera de texto rojo del login (ERROR-login-notFoundAccount)
    this.globals.flagNotFoundAcountLogin=false;

    Swal.fire({
      title: 'Autenticación',
      text: 'Validando datos de acceso',
      icon: 'info',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false
    });
    Swal.showLoading();

    this.subscribers.signInSession = this.authService.signInSession().subscribe((response: any) => {

      if (response){
        this.idUser = response.user._id;

        // Se llenan los BehaviorSubject
        this.authService.userInfoLogged.next(response.user);
        this.authService.account.next(response.account);
        this.authService.allCompanies.next(response.companies);
        this.authService.firstTimeLogged.next(response.firstTime);
        this.account = response.account;
        this.companies = response.companies;
        if (response.firstTime){ // Se inicia el onboarding solo cuando inicia por primera vez
          this.authService.onBoarding.next(1);
        }
        // ! It is validated with the size since when it is an employee it does not contain that information
        // if( this.companies.length > 1 ){
        //   Swal.close();
        //   this.openModalchooseCompany();
        //   if(response.user.role !== 'employee'){
        //     this.user.getPermissionsByUser(this.idUser);
        //   }
        // }else{
        //   this.idCompany = this.companies[0]._id;
        //   this.nameCompany = this.companies[0].companyName;
        //   // traer permisos de user logeado
        //   if(response.user.role !== 'employee'){
        //     this.user.getPermissionsByUser(this.idUser);
        //   }
        //   this.goToHome();
        // }
        this.goToHome();

      }

    });

  }

  goToHome(idCompanieSelected?:string){
    // Validacion para asignar el id,si no elige una correcta se coloca la primera en la lista
    if(idCompanieSelected){
      const company = this.companies.find(element => element._id === idCompanieSelected);
      this.authService.idCompanieSelected.next(idCompanieSelected);
      sessionStorage.setItem('idCompanySelected', idCompanieSelected);
      this.authService.nameCompanySelected.next(company.companyName);
      this.authService.logoCompanySelected.next(company.logo);
    }else{
      let company;
      if(this.authService.userInfoLogged.getValue().role == 'addOn' || this.authService.userInfoLogged.getValue().role == 'admin' || this.authService.userInfoLogged.getValue().role === 'root'){
        company = this.companies[0];
      }else{
        company = this.companies.find(element => element._id === this.account.idCompanies[0]);
      }
      this.authService.idCompanieSelected.next(company._id);
      sessionStorage.setItem('idCompanySelected', company._id);
      this.authService.nameCompanySelected.next(company.companyName);
      this.authService.logoCompanySelected.next(company.logo);
    }

    this.authService.isLogged.next(true);
    sessionStorage.setItem('isLogged', 'true');
    // if(this.path == "/invite/menu/info"){
    //   try{
    //     sessionStorage.setItem("invitationSelected", this.cryptoService.decryptHex(environment.encryptionKey, this.params.invitation));
    //     sessionStorage.setItem("guest", this.cryptoService.decryptHex(environment.encryptionKey, this.params.guest));
    //     Swal.close();
    //     this.router.navigate(['invite/menu/info']);
    //   }catch(e){
    //     this.router.navigate(['invite/menu/info']);
    //   }

    // }else if(this.path == "/comment"){
    //   Swal.close();
    //   this.router.navigateByUrl('comment?idCompany='+this.params.idCompany);
    // }else{
    //   this.router.navigate(['home/init-page']);
    // }

    this.router.navigate(['home/init-page']);

  }

  openConnectionMQ(){

    this.mqtt.startMQ()
  }
}
