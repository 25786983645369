import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Dependency } from '../project.const';

@Component({
  selector: 'app-dependencies[dependencies]',
  templateUrl: './dependencies.component.html',
  styleUrls: ['./dependencies.component.scss']
})
export class DependenciesComponent {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() dependencies: Dependency[] = [];
  public displayedColumns: string[] = ['description', 'createdAt', 'inCharge', 'status', 'commitmentAt'];
  public dependenciesDataSource!: MatTableDataSource<Dependency, MatTableDataSourcePaginator>;
  public currentFilter: 'To Do' | 'WIP' | 'Done' | '' = '';

  // Mobile
  public currentCard = 0;
  public card: Dependency | null = null;

  constructor() { }

  ngOnInit() {
    this.dependenciesDataSource = new MatTableDataSource(this.dependencies);
    this.card = this.dependencies[this.currentCard];
  }

  ngAfterViewInit() {
    this.dependenciesDataSource.sort = this.sort;
  }

  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (this.sort.direction !== order) {
      this.sort.sort(<MatSortable>{ id: 'description', start: order, disableClear: true })
    }
  }

  applyFilter(status: 'To Do' | 'WIP' | 'Done') {
    const nextFilter = this.currentFilter === status ? '' : status;
    this.currentFilter = nextFilter;
    this.dependenciesDataSource.filter = nextFilter;
  }

  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.dependencies[indexCard];
    if (card) { 
      this.currentCard = indexCard;
      this.card = card;
    }
  }
}
