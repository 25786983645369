import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Overview, ProjectInfo, projectExample } from '@pmo/projects/project/project.const';
import { Employee } from '@pmo/teams/team/team.const';
import { log } from 'console';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, map, startWith } from 'rxjs/operators';
import { OportunityComponent } from '../../oportunity/oportunity.component';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { EmployeesService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ModalTaskComponent } from '../../comercial/modal-task/modal-task/modal-task.component';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-proyects',
  templateUrl: './edit-proyects.component.html',
  styleUrls: ['./edit-proyects.component.scss']
})
export class EditProyectsComponent {
  @Input() overview!: Overview;
  @Input() allEmployees: Employee[] = [];
proyectInfo:ProjectInfo = projectExample;
EditProyectForm!: FormGroup;
responsable2bcoreData:any;
dataUsersDependencies:any = [];
workPlanData:any;
dataId:any;
dataSourceInit = [
  {description: 1, inCharge: 'Hydrogen', status: 1.0079, commitmentAt: 'H'},
];
dataSource = new BehaviorSubject<AbstractControl[]>([]); // DataSource para la tabla

usersByCompany:any;
 displayedColumns: string[] = ['description', 'inCharge', 'status', 'commitmentAt','delete'];
 filteredOptions: Observable<any>;
 realInfoPr:any;
 dataToAvatar:any;
 myControl = new FormControl();
 options = [
];
selectedOption: any = null; 
constructor(private oportunityService:OportunityService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private router:Router,
              private dialog: MatDialog,
              private employeeService: EmployeesService
) {

  this.dataId = JSON.parse(localStorage.getItem('activeProyectStorage'))
  
  this.EditProyectForm = this.fb.group({
    sprint:['', Validators.required],
    defaultObjective: [''],
    defaultDependencie: [''],
    defaultResponsable: [''],
    defaultStatus: [''],
    defaultDeliveryDate: [''],
    defAditionalActivity: [''],
    defAditActStatus: [''],
    defRisk: [''],
    defRiskStatus: [''],
    proyectDescription: ['', Validators.required],
    objectiveInputs: this.fb.array( []),
    dependenciesInputs: this.fb.array( []),
    aditionalTasksInputs:this.fb.array( []),
    aditionalRiskInputs:this.fb.array( [])
  })


  const idCompany = sessionStorage.getItem('idCompanySelected');
  this.employeeService.getAllCompanyEmployees(idCompany).pipe(
    concatMap((data: any) => {
      this.usersByCompany = data;
      this.usersByCompany.forEach((user: any) => {
        const addUser = {
          name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
          image: user.image,
          _id: user.idUser
        }
        this.options.push(addUser);
      });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),  // Comienza con un valor vacío para activar el Autocomplete inmediatamente
        map(value => this._filter(value || ''))
      );
      return this.oportunityService.getOportunityById(this.dataId._id);
    })
  ).subscribe((response) => {
    console.log(response);
    this.realInfoPr = response;
    this.realInfoPr.proyectDescription ? this.EditProyectForm.get('proyectDescription').setValue(this.realInfoPr.proyectDescription): '';

    this.realInfoPr.sprint ? this.EditProyectForm.get('sprint').setValue(this.realInfoPr.sprint): '';
    let updtActvities = [];

    response?.activities?.forEach(element => {
    let  data:any = {
      activity: element.activity,
      color: element.color,
      endDate: element.endDate,
      startDate: element.initDate
     }
     updtActvities.push(data);
    });
  
  console.log(updtActvities);
  
    this.realInfoPr.workPlan = !response?.activities ? [] : [...updtActvities];
    console.log(this.realInfoPr.workPlan);
    
    this.cdr.detectChanges();
    if ( this.realInfoPr.objectives) {
      this.realInfoPr?.objectives?.forEach(objective => {
       this.addInput(objective);
       
      });
    }
    if ( this.realInfoPr.objectives) {
      this.realInfoPr?.dependencies?.forEach(dependencie => {
       this.addDependencyInit(dependencie);
      });
    }
    if ( this.realInfoPr.aditionalActivities) {
      this.realInfoPr?.aditionalActivities?.forEach(actAdic => {
       this.addAditionalTaskInit(actAdic);
      });
    }
    if ( this.realInfoPr.risks) {
      this.realInfoPr?.risks?.forEach(risk => {
       this.addAditionalRiskInit(risk);
      });
    }
    this.responsable2bcoreData = this.options?.filter(user => user._id === response?.responsable2bcore)[0];
    
    this.dataToAvatar = this.options?.filter(user => response?.authorizedStaff.includes(user._id));    
  }, error => {
    // Manejo de errores si alguno de los servicios falla
    console.error('Error:', error);
  });
}

ngOnInit() {
 
    this.loadInitialValues();
}

openModal(activity?: any): void {
  const dialogRef = this.dialog.open(ModalTaskComponent, {
    width: '464px',
    data: activity || {}, // Si es edición, manda datos; si no, envía vacío
  });

  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      console.log(result);
      result.order = this.realInfoPr?.workPlan?.length+1;
      this.realInfoPr.workPlan = [...this.realInfoPr?.workPlan,result]
      this.cdr.detectChanges();
      console.log( this.realInfoPr?.workPlan);
      this.validateOtherAct(result);
    }
  });
}
validateOtherAct(result:any) {
  const dialogRef = this.dialog.open(ConfirmComponent, {
    width: '464px',
    data: {
      icon: 'status_approved',
      warn: false,
      title: 'Actividad agregada',
      description: `La actividad ${result.activity} se agregó con éxito. ¿Deseas agregar otra actividad?`,
      close: 'No agregar',
      confirm: 'Sí, agregar otra'
    } as ConfirmDialogData,
  });

  dialogRef.afterClosed().subscribe(result => {
     // TODO: route ver detalles : result === true 
    if (result) {
      this.openModal();
    }
  });
}
onWorkPlanUpdated(updatedWorkPlan:any) {
  console.log(updatedWorkPlan);
  
  this.realInfoPr.workPlan = this.realInfoPr?.workPlan.map((activity, index) =>
    index === updatedWorkPlan.index ? updatedWorkPlan.activitie : activity
  );
  this.cdr.detectChanges();
}
private _filter(name: string): any[] {
  const filterValue = name?.toLowerCase();
  return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
}
get objectiveInputs(): FormArray {
  return this.EditProyectForm.get('objectiveInputs') as FormArray;
}
get dependenciesInputs(): FormArray {
  return this.EditProyectForm.get('dependenciesInputs') as FormArray;
}
get aditionalTasksInputs(): FormArray {
  return this.EditProyectForm.get('aditionalTasksInputs') as FormArray;

}
get aditionalRiskInputs(): FormArray {
  return this.EditProyectForm.get('aditionalRiskInputs') as FormArray;

}
createEmpFormGroup(value:any){
  return this.fb.group({
    objective: [value ? value : '', [Validators.required]],
  })
}
addDependencyInit (data) {
  let imageUser = this.options.find(option => option._id === data.idResponsable)?.image;
  let name = this.options.find(option => option._id === data.idResponsable)?.name;
  data._id = data.idResponsable;
  data.image = imageUser;
  data.name = name;
  const newDependency = this.fb.group({
    dependency: [ data.dependency],
    idResponsable: [data.name],
    status: [data.status],
    deliveryDate: [data.deliveryDate],
  });   
  this.dependenciesInputs.push(newDependency);
  this.dataSource.next(this.dependenciesInputs.controls);  
  this.dataUsersDependencies.push(data);
}
addDependency(): void {
  const newDependency = this.fb.group({
    dependency: [ this.EditProyectForm.get('defaultDependencie').value],
    idResponsable: [this.EditProyectForm.get('defaultResponsable').value],
    status: [this.EditProyectForm.get('defaultStatus').value],
    deliveryDate: [this.EditProyectForm.get('defaultDeliveryDate').value],
  });
  this.dependenciesInputs.push(newDependency);
  this.dataSource.next(this.dependenciesInputs.controls);
  this.dataUsersDependencies.push(this.selectedOption);
  this.cleanDependencieRow(); 
}

addAditionalTaskInit(data): void {
  const newTask = this.fb.group({
    activity: [ data.activity],
    status: [data.status],
  });
  this.aditionalTasksInputs.push(newTask);
}
addAditionalRiskInit(data): void {
  const newRisk = this.fb.group({
    activity: [ data.activity],
    status: [data.status],
  });
  this.aditionalRiskInputs.push(newRisk);
}
addAditionalTask(): void {
  const newTask = this.fb.group({
    activity: [ this.EditProyectForm.get('defAditionalActivity').value],
    status: [this.EditProyectForm.get('defAditActStatus').value],
  });
  this.aditionalTasksInputs.push(newTask);
  this.EditProyectForm.get('defAditionalActivity')?.reset();
  this.EditProyectForm.get('defAditActStatus')?.reset();
}

addAditionalRisk(): void {
  const newRisk = this.fb.group({
    activity: [ this.EditProyectForm.get('defRisk').value],
    status: [this.EditProyectForm.get('defRiskStatus').value],
  });
  this.aditionalRiskInputs.push(newRisk);
  this.EditProyectForm.get('defRisk')?.reset();
  this.EditProyectForm.get('defRiskStatus')?.reset();
}

cleanDependencieRow () {
  this.EditProyectForm.get('defaultDependencie')?.reset();
  this.EditProyectForm.get('defaultResponsable')?.reset();
  this.EditProyectForm.get('defaultStatus')?.reset();
  this.EditProyectForm.get('defaultDeliveryDate')?.reset();
  this.selectedOption = null;
}
removeDependency(index: number): void {
  this.dependenciesInputs.removeAt(index);
  this.dataSource.next(this.dependenciesInputs.controls);
  this.dataUsersDependencies.splice(index,1);
  console.log(this.dataUsersDependencies);
  
}
removeTask(index: number): void {
  this.aditionalTasksInputs.removeAt(index);
}
removeRisk(index: number): void {
  this.aditionalRiskInputs.removeAt(index);
}
loadInitialValues(): void {
  const goals = this.realInfoPr?.objectives;
  if (goals === undefined) {
    // Si no hay valores en el objeto, añade un input vacío
   // this.objectiveInputs.push(this.fb.control('', Validators.required));
  } else {
    // Si hay valores, inicializa el FormArray con ellos
    goals?.forEach(goal => {
      this.objectiveInputs.push(this.fb.control(goal));
    });
  }
}

addInput(value:any): void {
  if (value) {
    let newMem = this.createEmpFormGroup(value);
    this.objectiveInputs.push(newMem);
    this.EditProyectForm.get('defaultObjective')?.reset();
  }
}

removeInput(index: number): void {
  this.objectiveInputs.removeAt(index);
}

submitForm(): void {
  if (this.EditProyectForm.valid) {
    const dataForm:any = this.EditProyectForm.value;
    let objectives = [];
    let activities = [];
    console.log(this.dataUsersDependencies);
    
   
    this.realInfoPr?.workPlan.forEach(element => {
      let dataActivities:any = {
        activity: element.activity,
        initDate: element.startDate,
        endDate: element.endDate,
        color : element.color
      }
      activities.push(dataActivities);
    });
    dataForm.objectiveInputs.forEach(element => {
      objectives.push(element.objective)
    });
    console.log(this.dataUsersDependencies);
    this.dataUsersDependencies.forEach((element:any, index) => {
      dataForm.dependenciesInputs[index].idResponsable = element._id
    });
    let dataToAPi:any = {
      sprint: dataForm.sprint,
      proyectDescription: dataForm.proyectDescription,
      dependencies:dataForm.dependenciesInputs,
      objectives: objectives,
      activities: activities,
      aditionalActivities : dataForm.aditionalTasksInputs,
      risks: dataForm.aditionalRiskInputs
    }
    console.log('Form Submitted:', dataToAPi);
    let objectToSave: any = {
      id: this.realInfoPr?._id,
      body: dataToAPi
    }
    this.oportunityService.updateOportunity(objectToSave).subscribe((data) => {
      if (data) {
        this.showSuccesDialog(true)
      }
    });
  }
 
}

public cancelNewOportunity() {
  const dialogRef = this.dialog.open(ConfirmComponent, {
    width: '464px',
    data: {
      icon: 'status_question',
      warn: false,
      title: '¿Estás seguro?',
      description: 'Al cancelar la actualización del reporte, no podrás guardar los cambios.',
      close: 'Salir sin guardar',
      confirm: 'Seguir editando'
    } as ConfirmDialogData,
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result === false) {
      this.router.navigateByUrl('/projects');
    }
  });
}
showSuccesDialog(isUpdate: boolean) {
  const msgAction = isUpdate == true ? 'actualizada' : 'creada'
  const dialogRef = this.dialog.open(ConfirmComponent, {
    width: '464px',
    data: {
      icon: 'status_approved',
      warn: false,
      title: '¡Perfecto!',
      description: `Los datos se han actualizado`,
      close: 'Salir',
      confirm: 'Ver detalles'
    } as ConfirmDialogData,
  });

  dialogRef.afterClosed().subscribe(result => {
     // TODO: route ver detalles : result === true 
    const route = result === true ? '/projects' : '/projects';
    this.router.navigateByUrl(route);
  });
}
drop(event: CdkDragDrop<string[]>) {
  moveItemInArray(this.EditProyectForm.get('objectiveInputs').value, event.previousIndex, event.currentIndex);
}

dropDependncies(event: CdkDragDrop<string[]>) {
  
  moveItemInArray(this.EditProyectForm.get('dependenciesInputs').value, event.previousIndex, event.currentIndex);
  console.log('Nuevo orden:', this.EditProyectForm.get('dependenciesInputs').value);
  this.realInfoPr = {
    ...this.realInfoPr,
    dependencies: [...this.EditProyectForm.get('dependenciesInputs').value]
  };

}
dropAdicionalActivities(event: CdkDragDrop<string[]>) {
  moveItemInArray(this.EditProyectForm.get('aditionalTasksInputs').value, event.previousIndex, event.currentIndex);

}
dropRisk(event: CdkDragDrop<string[]>) {
  console.log(event);
  
  moveItemInArray(this.EditProyectForm.get('aditionalRiskInputs').value, event.previousIndex, event.currentIndex);
 

}

onOptionSelected(option: any) {
  this.selectedOption = option;
  this.EditProyectForm.get('defaultResponsable').setValue(option.name);
  this.cdr.detectChanges();
  this.EditProyectForm.updateValueAndValidity();
}
}
