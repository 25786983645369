<div class="content">
    <header class="dark-header">
      <div class="container header">
        <div class="h-logo">
          <img src="/assets/img/logos/2BCore_logo.svg" alt="logo-2bcore">
        </div>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <div class="mt-5 d-flex flex-column align-items-center signin-content">
          <h2 class="fs-30">¡UPS!</h2>
          <p class="mt-4 p-content fs-14">Aún no estás dado de alta en el portal <span class="c-1E88E5">Intra.</span> Comunícate con soporte@2bcore.com</p>
          <img src="/assets/img/login _ error intra@3x.svg" alt="portada">
          <button class="mt-5 mb-5 btn-logout" (click)="logOut()">Cerrar sesión</button>
        </div>
      </div>
    </main>
    <app-footer class="footer"></app-footer>
  </div>
