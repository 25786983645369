<div class="nav bg-lighter py-2 fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light col-lg-10 mx-auto py-0">
    <!-- Sección de logo de empresa -->
    <a (click)="goToHome()" class="cr-pointer navbar__logo">
      <img
        *ngIf="logo && !this.router.url.includes('/NOM-035')"
        src="../../../assets/logo/2BC_logotipo_azul.png"
        alt="User"
        class="logo-company mr-2 mr-lg-0 ml-lg-2"
      >
      <div
        *ngIf="this.userInfoLogged.role && !logo && !this.router.url.includes('/NOM-035')
          && this.router.url !== '/administration-companies'"
          class="fs-12 fw-bold default card-documento text-center">
          <input #file type="file" (change)="fileChangeEvent($event)" *ngIf="!logo && !this.router.url.includes('/NOM-035')
          && this.router.url !== '/administration-companies' && this.userInfoLogged.role !== 'employee'"
                 accept=".jpg, .jpeg, .png" class="cr-pointer fs-5" id="file">
          <p class="m-0 my-1">Agrega <br> tu logo <br> aquí</p>
      </div>
     <!-- <a *ngIf="!logo && userInfoLogged.role === 'employee' && !this.router.url.includes('/NOM-035')">
          <img src="assets/icons/logo2.svg" class="logo mr-2 d-none d-lg-block" width="124px" height="64px">
          <img src="assets/img/logos/logomobile.svg" class="logo d-block d-lg-none mr-2" width="40px"
              height="40px">
      </a>-->
      <div *ngIf="this.router.url.includes('/NOM-035')">
          <img src="assets/img/logos/01.svg" class="logo mr-2 d-none d-lg-block" width="124px" height="64px">
          <img src="assets/img/logos/logomobile.svg" class="logo d-block d-lg-none mr-2" width="40px"
              height="40px">
      </div>
    </a>

    <!-- Sección de logo usuario -->
    <div
      class="nav-item navbar__user dropdown configIconToolTip d-none d-md-block"
      *ngIf="!this.router.url.includes('/NOM-035') && !this.router.url.includes('/invitation-reply')"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [ngClass]="this.router.url === '/admin-company-licence' ? 'ml-auto' : '' "
    >
      <div class="cssToolTip userImg-Tool">
        <img
          *ngIf="userInfoLogged.profilePicture; else user_default"
          [src]="userInfoLogged.profilePicture | imagePath: this.folders.user"
          alt="User"
          class="profile-picture dropdown-toggle"
        >
        <ng-template #user_default>
          <span class="time-icon-user_default align-middle"></span>
        </ng-template>
        <span class="time-icon-dropdown_user fs-24 align-middle ml-2">
          <span class="path1"></span><span class="path2"></span>
        </span>
        <div class="content-tool fs-12 text-center">
          Perfil
        </div>
      </div>
      <div class="dropdown-menu  fs-12 text-left txt-blue p-0" aria-labelledby="dropdownMenuButton">
        <a *ngIf="higherRole !== 'employee'" class="dropdown-item txt-blue" href="#" (click)="changeRole()">Cambiar perfil</a>
        <a *ngIf="viewItem('verExpediente', 'imagenPerfil')" class="dropdown-item txt-blue" href="#" (click)="goToProfile()">Ver expediente</a>
        <a *ngIf="viewItem('cerrarSesion', 'imagenPerfil')"  class="dropdown-item txt-blue" href="#" (click)="signOut()">Cerrar sesión</a>
      </div>
    </div>
    
    <!-- Menú iconos -->
    <div
      *ngIf="this.router.url !== '/admin-company-licence' && !this.router.url.includes('/invitation-reply')"
      class="navbar__icons justify-content-lg-end ml-auto d-lg-flex pl-lg-0" id="navbarSupportedContent"
    >
      <!-- Links Home admin -->
      <div class="d-flex">
        <!-- ? Seleccionar empresa-->
        <div class="nav-item zoom dropdown iconToolTip"
          *ngIf="viewItem('seleccionarEmpresa') && this.userInfoLogged.role && this.userInfoLogged?.role !== 'employee'"
        >

          <a class="nav-link fw-bold nav-intern px-3 fs-14 cssToolTip" data-toggle="dropdown"
            id="dropdownMenuButton"
            data-target=".navbar-collapse.show"
            href="#"
            routerLinkActive="active"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="assets/icons/header/enterprise_switch.svg" class="icon-header" alt="">
            <div class="content-tool fs-12 text-center toolTipTxt">
              Seleccionar empresa
            </div>
          </a>
          <div class="dropdown-menu fs-12 text-left txt-blue p-0" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item txt-blue cr-pointer" *ngFor="let item of companies"
              (click)="changeCompany(item._id)">{{item.companyName  | slice:0:20 }}{{item.companyName.length > 19 ? '...' : ''}}
            </a>
          </div>
        </div>

        <!--? Perfil de la empresa -->
        <div class="nav-item iconToolTip"
          *ngIf="viewItem('perfilEmpresa') && this.userInfoLogged.role && this.userInfoLogged?.role !== 'employee'"
        >

          <a
            class="nav-link fw-bold nav-intern px-3 fs-14 cssToolTip"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            href="#"
            [routerLink]="['/home/company']"
            routerLinkActive="active"
            [ngClass]="true ? ''  : 'blocked'"
          >
          <!--!Se quita del ngClass
            this.userPermissions.generalData.viewAndDownloadCompanyData || this.userPermissions.generalData.editCompanyData
          -->
            <img src="assets/icons/header/perfil.svg" class="icon-header" alt="">
            <div class="content-tool fs-12 text-center toolTipTxt">
              Perfil de la empresa
            </div>
          </a>
        </div>

        <!--? Notificaciones -->
        <div class="nav-item smallIconToolTip dropdown"
          *ngIf="viewItem('notificaciones')"
        >
        <!--! Se eliminó del ngIf
          !this.router.url.includes('/NOM-035')
        -->
          <a class="nav-link nav-intern px-3 fs-14 fw-bold cssToolTip cr-pointer"
            data-toggle="dropdown"
            id="dropdownMenuButton"
            data-target=".navbar-collapse.show"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="time-icon-notification_empty fs-24"></span>
            <span class="new-notification-icon" *ngIf="newNotification"></span>
            <div class="content-tool fs-12 text-center">
              Notificaciones
            </div>
          </a>
          <div class="dropdown-menu notification-box fs-12 text-left txt-blue p-0" aria-labelledby="dropdownMenuButton">
            <p *ngIf="!(this.notifications.toShow.length > 0)" class="text-center my-1">No existen notificaciones</p>
              <a class="dropdown-item border-bottom d-flex cr-pointer" [ngClass]="!item.view? 'not-view' : ''" *ngFor="let item of this.notifications.toShow" (click)="pressNotification(item); item.view = true; checkNewNotification()" id="{{item._id}}">
                  <div class="image-wrapper mr-2">
                      <img src="assets/logo/2BC_logotipo_azul.png" width="35px" height="35px" alt="">
                  </div>

                  <div class="content-notifi">
                      <div class="d-flex">
                          <p class="mb-0 mr-3 txt-grey fw-bold">{{item.name}}</p>
                          <div class="time">
                              <img src="assets/icons/notifications/clock.svg" class="mr-2" alt="">
                              <span class="fs-10 txt-grey font-italic">{{item.date | timeAgo }}</span>
                          </div>
                      </div>
                      <div class="mt-2 fs-12 txt-lightgray2">
                          <p class="m-0">{{item.notification}}  <span class="txt-light-blue">{{item.msg2}}</span></p>
                      </div>
                  </div>
                  <div class="new-notification-icon notification-icon-dropdown" *ngIf="!item.view"></div>
              </a>
              <a *ngIf="this.notifications.toShow.length > 0" class="dropdown-item fs-12 fw-bold txt-blue text-center" [routerLink]="['/home/notifications']">
                  Ver más
              </a>

          </div>
          <span *ngIf="newNotificationsFlag" class="new-notifications-icon"></span>
        </div>

        <!--? Configuración -->
        <div class="nav-item configIconToolTip dropdown"
             *ngIf="viewItem('configuracion') && this.userInfoLogged.role && this.userInfoLogged?.role !== 'employee'"
        >
          <!--!Se eliminó del if
              !this.router.url.includes('/NOM-035') &&
              !this.router.url.includes('/root') &&
              userInfo.role !== 'employee' &&
              this.router.url !== '/admin-company-licence' &&
              !this.router.url.includes('/comment') &&
              !this.router.url.includes('/invitation-reply')
          -->
          <!-- [ngClass]="this.userPermissions.configuredUserManagement.view === false  ? 'blocked' : ''" -->

          <a class="nav-link nav-intern px-3 fs-14 fw-bold cssToolTip" data-toggle="dropdown"
             [ngClass]="true ? '' : 'blocked'"
             id="dropdownMenuButton"
             data-target=".navbar-collapse.show"
             href="#"
             routerLinkActive="active"
             aria-haspopup="true"
             aria-expanded="false"
          >
            <!--!Se eliminó del ngClass
              this.userPermissions.configuredUserManagement.view
            -->
            <img src="assets/icons/header/configuracion.svg" class="icon-header" alt="">
            <div class="content-tool fs-12 text-center">
              Configuración
            </div>
          </a>
          <div class="dropdown-menu fs-12 text-left txt-blue p-0" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item txt-blue"
               *ngIf="viewItem('administracionEmpresas', 'configuracion')"
               [routerLink]="['/home/admin-company']"
            >
              <!--! Se eliminó de ngIf:  this.userInfo.role === 'admin'-->
              Administración <br> de empresas
            </a>

            <a class="dropdown-item txt-blue"
               *ngIf="viewItem('historial', 'configuracion')"
               [routerLink]="['/employees/history']"
            >
              <!--! Se eliminó de ngIf: this.userPermissions.history.view -->
              Historial
            </a>
          </div>
        </div>

      </div>

    </div>

    <span class="icon time-icon-menu_main d-md-none navbar__menu" (click)="toggleMenu()"></span>
  </nav>

</div>

<span #openModalCropHeader id="openModalCropHeader" class="hide" data-toggle="modal" data-target="#modalCropCoverHeader"
data-backdrop="static" data-keyboard="false"></span>
<div
  class="modal fade px-0"
  id="modalCropCoverHeader"
  tabindex="-1"
  aria-labelledby="modalCropCoverHeader"
  aria-hidden="true"
>
  <div class="mx-auto modal-dialog modal-dialog-centered px-2">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0 pt-1 pr-2">
        <button type="button" class="close">
            <span aria-hidden="true" data-dismiss="modal">&times;</span>
        </button>
      </div>
      <div class="modal-body border-0 pt-2">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          [resizeToWidth]="200"
          format="png"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
        <p class="fs-14 fw-bold txt-darkgrey text-center mt-3">Vista previa</p>
        <img [src]="croppedImagePreview" class="mx-auto d-block" />
        <div class="form-inline">
          <button #dismisseModal class="btn btn-secondary fs-14 mx-auto d-block mt-3" data-dismiss="modal" (click)="closeModal()">Cancelar</button>
          <button
            class="btn btn-secondary fs-14 mx-auto d-block mt-3"
            data-dismiss="modal"
            (click)="changeImage();"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
