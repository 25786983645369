import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePathPipe } from './image/image-path.pipe';
import { SafePipe } from './safehtml/safe.pipe';
import { SplitPipe } from './split/split.pipe';
import { TimeAgoPipe } from './timeAgo/time-ago.pipe';
import { ShowRolesPipe } from './showRoles/show-roles.pipe';
import { AlphanumericDirective } from './alpha-numeric/alpha-numeric.directive';



@NgModule({
  declarations: [
    ImagePathPipe,
    SafePipe,
    SplitPipe,
    TimeAgoPipe,
    AlphanumericDirective,
    ShowRolesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImagePathPipe,
    SafePipe,
    SplitPipe,
    AlphanumericDirective,
    TimeAgoPipe,
    ShowRolesPipe
  ]
})
export class PipesModule { }
