export const PATTERNS = {
    specialCharacters: /[[!@#$%^&*()_+=\-[\]{};':"|,.<>/?]/,
    numbers: /^[0-9]+$/,
    mayus: /[A-Z]/,
    minus: /[a-z]/,
  passwords: /^(?=.*\d)(?=.*[a-z])(?=.*[+#$%&¡*-.?!])(?=.*[A-Z]).{8,16}$/,
   email: /(^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})$/,
   businessEmail: /(^[a-z0-9._-]+@(2bcore){1}\.(com){1})$/,
   gender: /^(Masculino|Femenino)$/,
   onlyAllLetters: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+(\s{0,1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s ])*$/,
  nameAndSurname: /^[A-Za-zñÑäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.'’\s]+(\s{0,1}[A-Za-zñÑäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.'’\s])*$/,
  alphanumericEmpNum: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9]*$/,
  alphanumericAndPoint: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9.\s]+(\s{0,1}[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9.\s ])*$/,
  rfcMoralSAT: /^([A-ZÑ]|\&)?[A-ZÑ]{3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/,
  phone: /^[0-9]*$/,
  dateFormat: /^([0-9]{2}[/]{1}){2}([0-9]{4})$/,
  phoneCsv:/^[0-9]{10}$/,
  phoneProfile:/^[+][(][0-9]{2}[)]\s([0-9]{4}\s[0-9]{4})$/,
  phoneContactUs:/^[0-9]{2}\s([0-9]{4}\s[0-9]{4})$/,
  lastDegreeStudies:/^(Primaria|Secundaria|Bachillerato|Técnico|TSU|Licenciatura|Maestría|Doctorado)$/,
  civilStatus:/^(Soltero|Casado|Unión Libre|Divorciado|Viudo)$/,
  validRolesForCsv:/^(employee|admin|addOn)$/

};
