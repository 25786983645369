import { Injectable } from '@angular/core';
import { ResponseContent } from 'src/app/models';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/internal/operators';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QuizService {

  /**observable que se ultiliza como disparador para detectar cambio de periodo*/
  idPeriodSelect = new BehaviorSubject<string>(undefined);
  /**
   * Creates an instance of AuthService.
   * @param {HttpService} http
   * @param {errorsService} errorsService
   */
  constructor(
    private http: HttpService
  ) { }


  /**
    * Enviar solicitud para agregar un nueva encuesta
  */
  createQuiz(idCompany,name,idEmployeeList: string[]){
    return this.http.post('/quiz/create', {idCompany,name,idEmployeeList}, true).pipe(
      map((res: ResponseContent) => {
        return res;
      })
    );
  }

  /** */
  sendQuiz(id: string, day: Date, hour: string, works?){
    return this.http.post('/quiz/send', {id, day, hour, works}, true).pipe(
      map((res: ResponseContent) => {
        return res;
      })
    );
  }

  /** */
  sendAllQuiz(id: string, works?){
    return this.http.post('/quiz/sendAll', {id, works}, true).pipe(
      map((res: ResponseContent) => {
        return res;
      })
    );
  }

  /**
   * Envío de encuentas de clima laboral
   */
  sendToUser(_idProject: string, idStaff:string){

    const params =  { 
      idProject: _idProject, 
      idStaff: idStaff   
    } 

    return this.http.post('/quiz/sendToUser', {params}, true).pipe(
      map((res:ResponseContent) => {

        return (res?.clave == 'OK')? true : false;

      })
    );

  }

  /** */
  deletePeriod(id: string) {
    return this.http.post('/quiz/deletePeriod', {id}, true).pipe(
      map((res: ResponseContent) => {
        if (res && res.clave === 'OK'){
          return true;
        }else {
          return false;
        }
      })
    );
  }

  /** */
  updatePeriod(id, name){
    return this.http.post('/quiz/updatePeriod', {id,name}, true).pipe(
      map((res: ResponseContent) => {
        if (res && res.clave === 'OK'){
          return res;
        }
      })
    );
  }

  /** */
  getPeriods(idCompany: string){
    return this.http.post('/quiz/getPeriods/', {idCompany}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  /** Peticion para obtener los detalles y calculos de un periodo*/
  getPeriod(id: string ){
    return this.http.post('/quiz/getPeriodStatic', {id}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  /** Peticion para obtener los detalles y calculos de un periodo*/
  getFilterPeriod(id: string, works){
    return this.http.post('/quiz/getPeriodStaticWorks', {id, works}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  /** Peticion para guardar la respuesta de una encuesta*/
  sendAnswer(quiz: any ){
    return this.http.post('/quiz/answer', {quiz}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  /** Peticion verificar que la encuesta no haya sido respondida*/
  checkStatusAnswer(idEmployee: any, idProyect: any ){
    return this.http.post('/quiz/statusResponse', {idEmployee, idProyect}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return true;
        }
      })
    );
  }

  checkStatusQuizz( idProyect: any ){
    return this.http.post('/quiz/status', {idProyect}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  getStatistics( id: any ){
    return this.http.post('/quiz/getEmployeesStatic', {id}, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK'){
          return response.content == null ? false : response.content;
        }else{
          return false;
        }
      })
    );
  }

  updateDate(id: string, day: Date, hour: string, works?){
    return this.http.post('/quiz/updatePeriodDate', {id, day, hour, works}, true).pipe(
      map((res: ResponseContent) => {
        return res;
      })
    );
  }
}
