import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import {ModalService} from 'src/app/services/modal/modal.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Globals} from 'src/app/globals/globals';


/**
 *Servicio para validar mensajes de error
 *
 * @export
 * @class ErrorService
 */
@Injectable({
  providedIn: 'root'
})
/**
 * Aervicio para manejo de errores
 */
export class ErrorService {


    /***variable global para registro de usuario*/
    isRegister = new BehaviorSubject<boolean>(false);
  /**
   * creates an instance of errorService
   */
  constructor(
    private modalS: ModalService,
    private globals:Globals,
    private router: Router
    ) { }

  /**
 *LLamada para mostrar mensajes de error en modal
 *
 * @param {string} error
 * @memberof ErrorMessageService
 */
  errorMessage(error: string) {
    this.errors('Error', error, 'Aceptar');
  }


  successMessage(message) {
   this.errors(
     'Error',
     message,
     'Aceptar',
     '',
     true
     );
  }
   /**
  *Instancia de componente compartido de mensajes de errores
  *
  * @param {string} header
  * @param {string} body
  * @param {string} [accept]
  * @param {string} [cancel]
  * @returns {*}
  * @memberof ErrorMessageService
  */
 errors(
  header: string,
  body: string,
  accept?: string,
  cancel?: string,
  isSuccess = false
): any {
  Swal.close();
  if(body.includes('ERROR-solicitResetPassword-notFoundAccount')){
    this.modalS.warning({
      title: "Alerta",
      message :"No existe una cuenta asociada a este correo.",
      includeButtonNo:false
    });

  }else if(body.includes('ERROR-box-mailNotFound')){ // bandera de texto rojo de folio invalido 
     this.globals.flagBoxMailNotFound=true;
  
    }else if(body.includes('ERROR-box-invalidFolio')){ // bandera de texto rojo de folio invalido 
     this.globals.flagBoxInvalidFolio=true;

  }else if(body.includes('ERROR-login-notFoundAccount')){// bandera de texto rojo del login 
     this.globals.flagNotFoundAcountLogin=true;

  }else if(body.includes('ERROR-login-unverifiedAccount')){    
    const emails = body.split(': ')[1];// se extra los email del error
    const emailDes = emails.split('/:')[0];// email desencriptado (para reenvio de codigo de verificación)
    const emailEnc = emails.split('/:')[1];// email encriptado hexa (para la url)
    
    this.router.navigate(['/login/verification'], { queryParams: { email : emailEnc } });
    
  }else{
    this.modalS.error({
      title: "Error",
      message :body
    });
  }

}

}
