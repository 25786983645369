<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="back" style="width: fit-content;cursor: pointer;" [routerLink]="'/comercial'">
                <span class="time-icon-back fs-20 mr-2"></span> Regresar
            </div>
            <div class="title" *ngIf="comercialForm.get('category').value === 'Propuesta comercial'">
                Crear oportunidad
            </div>
            <div class="subtitle col-12" *ngIf="comercialForm.get('category').value !== 'Propuesta comercial'">
                {{comercialForm.get('category').value}} : <strong>{{comercialForm.get('name').value}}</strong>
            </div>
        </div>
        <div class="col-10 m-auto">
            <form [formGroup]="comercialForm">
                <div class="row px-0">
                    <div class="subtitle col-12" >
                        Acerca de {{comercialForm.get('category').value !== 'Propuesta comercial' ? '' : ''}}   oportunidad
                    </div>
                   
                    <div class="col-6 content-input form-group" *ngIf="!isNew">
                        <mat-form-field class="example-full-width w-100"  
                            [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''">
                            <mat-label [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''">Selecciona una categoría *</mat-label>
                            <mat-select formControlName="category" (selectionChange)="onCategoryChange()">
                                <mat-option value="Propuesta comercial">
                                    Propuesta comercial
                                </mat-option>
                                <mat-option value="Asignación">
                                    Asignación
                                </mat-option>
                                <mat-option value="Proyecto">
                                    Proyecto
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"
                            [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''"></span>
                    </div>
                    <div class=" content-input form-group" [ngClass]="!isNew ? 'col-6' : 'col-12'">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Nombre de la oportunidad *</mat-label>
                            <input matInput value="" formControlName="name" appAlphaNumeric="alphanumeric">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Código *</mat-label>
                            <input matInput value="" formControlName="code" appAlphaNumeric="uppercase, alphanumeric">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Cliente *</mat-label>
                            <input matInput value="" formControlName="client" appAlphaNumeric="alphanumeric">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Responsable (Cliente) *</mat-label>
                            <input matInput value="" formControlName="responsableClient" appAlphaNumeric="none,alphabetic">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="none">
                            <mat-label>Responsable 2BCORE *</mat-label>
                            <input type="text" matInput [matAutocomplete]="auto"  [formControl]="myControl" formControlName="responsable2bcore"
                                [ngStyle]="{'background-image': selectedOption ? 'url(' + 'assets/img/person.svg' + ')' : '', 'background-size': '30px 30px', 'background-position': '5px center', 'background-repeat': 'no-repeat', 'padding-left': selectedOption ? '40px' : '10px'}"
                                [displayWith]="displayFn">

                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                (optionSelected)="onOptionSelected($event.option.value)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'" alt="{{option.name}}" width="30" height="30"
                                        style="margin-right: 10px;">
                                    {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>


                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tiempo *</mat-label>
                            <mat-select formControlName="time">
                                <mat-option *ngFor="let activity of catActivities" [value]="activity.name">
                                    {{activity.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Viabilidad de cierre *</mat-label>
                            <mat-select formControlName="viabilityEnd">
                                <mat-option value="1">
                                    1 (Alta)
                                </mat-option>
                                <mat-option value="2">
                                    2 (Media)
                                </mat-option>
                                <mat-option value="3">
                                    3 (Baja)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Último contacto *</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="lastContact">
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input">
                        <mat-form-field appearance="fill" class="w-100"  [ngClass]="comercialForm.get('statusOportunity').value  === 'Aceptada' ? 'disabled' : ''">
                            <mat-label>Estatus de la oportunidad *</mat-label>
                            <mat-select formControlName="statusOportunity" (selectionChange)="validateStatus($event)">
                                <mat-option value="Discovery">
                                    Discovery
                                </mat-option>
                                <mat-option value="Enviada" *ngIf="!isNew">
                                    Enviada
                                </mat-option>
                                <mat-option value="Aceptada" *ngIf="!isNew">
                                    Aceptada
                                </mat-option>
                                <mat-option value="Desarrollo" *ngIf="!isNew">
                                    Desarrollo
                                </mat-option>
                                <mat-option value="Standby" *ngIf="!isNew">
                                    Standby
                                </mat-option>
                                <mat-option value="Cerrada" *ngIf="!isNew">
                                    Cerrada
                                </mat-option>
                            </mat-select>
                            <span class="time-icon-info fs-16" *ngIf="comercialForm.get('statusOportunity').value  === 'Aceptada'"  matTooltip="El proyecto fue aceptado por el cliente, por lo que no puedes modificar el estatus."  matTooltipPosition="right"><span class="path1"></span><span class="path2"></span></span>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16" [ngClass]="comercialForm.get('statusOportunity').value  === 'Aceptada' ? 'disabled' : ''"></span>
                    </div>

                    <div class="col-12 content-input"
                        *ngIf="comercialForm.get('statusOportunity').value  === 'Cerrada'">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Describe por qué el cliente no aprobó la propuesta *
                            </mat-label>
                            <input matInput value="" formControlName="commentDecline">
                        </mat-form-field>
                    </div>
                    <div class="col-12 content-input" *ngIf="comercialForm.get('statusOportunity').value  === 'Standby'">
                        <p style=" color: #01173c;font-size: 18px;"><span class="time-icon-info fs-16 mr-2" matTooltipPosition="right"><span class="path1"></span><span class="path2"></span></span>Para reactivar esta propuesta, es necesario
                            establecer la fecha de reactivación:</p>
                    </div>
                    <div class="col-6 content-input" *ngIf="comercialForm.get('statusOportunity').value  === 'Standby'">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de reactivación *</mat-label>
                            <input matInput [matDatepicker]="picker8" formControlName="reactivationDay">
                            <mat-datepicker-toggle matIconSuffix [for]="picker8">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker8></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('category').value  === 'Asignación'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información sobre de la asignación
                    </div>
                    <div class="col-12">
                        <p class="fs-18" style="color: #01173c;">Al crear una nueva asignación, es necesario completar la siguiente información.</p>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de inicio *</mat-label>
                            <input matInput [matDatepicker]="pickerAssign1" formControlName="dateInitAsign">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerAssign1">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerAssign1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de fin *</mat-label>
                            <input matInput [matDatepicker]="pickerAssign2" formControlName="dateEndAsign">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerAssign2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerAssign2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Líder técnico *</mat-label>
                            <input matInput value="" formControlName="technicalLead">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Horas reales *</mat-label>
                            <input matInput value="" formControlName="realHour">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('category').value  === 'Proyecto'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información sobre el proyecto
                    </div>
                    <div class="col-12">
                        <p class="fs-18" style="color: #01173c;">Al crear un nuevo proyecto, es necesario completar la siguiente información.</p>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de inicio *</mat-label>
                            <input matInput [matDatepicker]="pickerProyect1" formControlName="dateInitProyect">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerProyect1">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerProyect1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de fin *</mat-label>
                            <input matInput [matDatepicker]="pickerProyect2" formControlName="dateEndProyect">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerProyect2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerProyect2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tipo de proyecto *</mat-label>
                            <mat-select formControlName="typeProyect">
                                <mat-option value="Ejecución Única">
                                    Ejecución Única
                                </mat-option>
                                <mat-option value="Tiempo – Materiales">
                                    Tiempo – Materiales
                                </mat-option>
                                <mat-option value="Soporte y Mantenimiento">
                                    Soporte y Mantenimiento
                                </mat-option>
                                <mat-option value="Objetivo y Precio fijo">
                                    Objetivo y Precio fijo
                                </mat-option>
                                <mat-option value="Capacitación">
                                    Capacitación
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Metodología *</mat-label>
                            <mat-select formControlName="metodology">
                                <mat-option value="Scrum">
                                    Scrum
                                </mat-option>
                                <mat-option value="Waterfall">
                                    Waterfall
                                </mat-option>
                                <mat-option value="Hibrida">
                                    Hibrida
                                </mat-option>
                            
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Tecnología *</mat-label>
                            <input matInput value="" formControlName="technology">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Tiempo de garantía (Días / Horas)</mat-label>
                            <input matInput value="" formControlName="timeGaranty">
                        </mat-form-field>
                    </div>
                    <div class="col-12 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Líder de proyecto *</mat-label>
                            <input matInput value="" formControlName="proyectLead">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('statusOportunity').value  === 'Aceptada'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información de pago
                    </div>
                    <div class="col-12">
                        <p class="fs-18 mb-4" style="color: #01173c;">Al actualizar el estatus de la oportunidad a
                            'Aceptada’, es necesario completar
                            la siguiente información.</p>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de aprobación *</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dateApprove">
                            <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de orden de compra *</mat-label>
                            <input matInput [matDatepicker]="picker3" formControlName="dateOrderBuy">
                            <mat-datepicker-toggle matIconSuffix [for]="picker3">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tipo de moneda *</mat-label>
                            <mat-select formControlName="currency">
                                <mat-option value="Peso mexicano">
                                    Peso mexicano (MXN)
                                </mat-option>
                                <mat-option value="  Dólar (USD)">
                                    Dólar estadounidense (USD)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Monto cotizado *</mat-label>
                            <input matInput value="" formControlName="amount">
                        </mat-form-field>
                    </div>
                    <div class="col-6 row px-0 m-0">
                        <div class="col-5 content-input form-group">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Cotización</mat-label>
                                <mat-select formControlName="price">
                                    <mat-option value="Días">
                                        Días
                                    </mat-option>
                                    <mat-option value="Horas">
                                        Horas
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="time-icon-arrow_expand fs-16"></span>
                        </div>
                        <div class="col-7 content-input form-group">
                            <mat-form-field class="example-full-width w-100">
                                <mat-label>{{comercialForm.get('price').value === 'Horas' ? 'Total horas cotizadas *' :
                                    'Total días cotizados *'}}</mat-label>
                                <input matInput value="" formControlName="totalHoursQuoted">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tipo de pago *</mat-label>
                            <mat-select formControlName="agreedPayments">
                                <mat-option value="Mensual">
                                    Mensual
                                </mat-option>
                                <mat-option value="Quincenal">
                                    Quincenal
                                </mat-option>
                                <mat-option value="Trimestral">
                                    Trimestral
                                </mat-option>
                                <mat-option value="Trimestral">
                                    Otra (Número de pagos acordados)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de procesamiento de pago *</mat-label>
                            <input matInput [matDatepicker]="picker4" formControlName="dateProcessPayment">
                            <mat-datepicker-toggle matIconSuffix [for]="picker4">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tipo de pago *</mat-label>
                            <mat-select formControlName="typePayment">
                                <mat-option value="Diferido 50/50">
                                    Diferido 50/50
                                </mat-option>
                                <mat-option value="Mensual">
                                    Mensual
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Documento de pago *</mat-label>
                            <mat-select formControlName="paymentDocument">
                                <mat-option value="Contrato">
                                    Contrato
                                </mat-option>
                                <mat-option value="Orden de compra">
                                    Orden de compra
                                </mat-option>
                                <mat-option value="Orden de compra/Contrato">
                                    Orden de compra/Contrato
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Centro de costos *</mat-label>
                            <input matInput value="" formControlName="costCenter">
                        </mat-form-field>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Frecuencia de facturación *</mat-label>
                            <mat-select formControlName="billingFrequency">
                                <mat-option value="30 días">
                                    30 días
                                </mat-option>
                                <mat-option value="Mensual">
                                    60 Días
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field class="example-full-width w-100" appearance="fill">
                            <mat-label>Fecha de inicio de facturación *</mat-label>
                            <input matInput [matDatepicker]="picker5" formControlName="dateInitBilling">
                            <mat-datepicker-toggle matIconSuffix [for]="picker5">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker5></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-0">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Miembros
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label> Área(s) / Equipo(s) con acceso al código *</mat-label>
                            <mat-select formControlName="accessTeam" multiple class="d-none">
                                <mat-option *ngFor="let team of teamsList" [value]="team.name">
                                    <span>
                                        {{team.name}}
                                    </span>
                                </mat-option>
                            </mat-select>

                            <div class="selected-chips">
                                <mat-chip *ngFor="let option of comercialForm.get('accessTeam').value"
                                    [removable]="true" (removed)="remove(option)">
                                    {{ option }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label> Staff autorizado *</mat-label>
                            <mat-select formControlName="authorizedStaff" multiple class="d-none">
                                <mat-option *ngFor="let option of optionsStaff" [value]="option">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'" width="25" height="25"
                                        style="margin-right: 10px;">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>

                            <div class="selected-chips">
                                <mat-chip *ngFor="let option of comercialForm.get('authorizedStaff').value"
                                    [removable]="true" (removed)="removeUserChip(option)">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'"  width="25" height="25"
                                        style="margin-right: 10px;"> {{ option.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                    </div>
                    <div class="col-12 d-flex justify-content-end mb-5">
                        <button class="button-custom-cancel mr-2"
                            (click)="cancelNewOportunity()">Cancelar</button>
                        <button class="button-accept" data-toggle="modal"
                            [ngClass]="!comercialForm.valid ? 'disabled' : ''" [disabled]="!comercialForm.valid"
                            (click)="onSubmit()">{{!isNew ? 'Guardar' :
                            'Crear oportunidad'}}</button>
                           
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Button trigger modal -->

<!-- Modal -- >
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom:none">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0 text-center" style="border-bottom:none">
                <span class="time-icon-status_question fs-36" *ngIf="!modalType"><span class="path1"></span><span
                        class="path2"></span><span class="path3"></span></span>
                <span class="time-icon-status_approved fs-36" *ngIf="modalType"><span class="path1"></span><span
                        class="path2"></span></span>
                <div class="modal-title" *ngIf="!modalType">
                    ¿Estás seguro?
                </div>
                <div class="modal-title" *ngIf="modalType">
                    ¡Perfecto!
                </div>
                <div class="modal-text-body" *ngIf="!modalType ">
                    Al cancelar el registro de esta asignación, no podrás guardar los cambios.
                </div>
                <div class="modal-text-body" *ngIf="modalType">
                    La nueva propuesta comercial ha sido creada correctamente.
                </div>
            </div>
            <div class="modal-footer justify-content-center" style="border-top:none">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" *ngIf="!modalType"
                    [routerLink]="'/comercial'">Salir sin guardar</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" *ngIf="modalType">Cerrar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" *ngIf="!modalType">Seguir editando
                </button>

                <button type="button" class="btn btn-primary" data-dismiss="modal" [routerLink]="'/comercial'"
                    *ngIf="modalType">Ver detalles </button>
            </div>
        </div>
    </div>
</div>
-->

<div class="modal fade" id="modalChangeStatus" #modalChangeStatus tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom:none">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0 m-auto text-center" style="border-bottom:none">
                <span class="time-icon-status_question fs-36"><span class="path1"></span><span
                        class="path2"></span><span class="path3"></span></span>

                <div class="modal-title">
                    ¿Estás seguro?
                </div>
                <div class="modal-text-body" *ngIf="modalFlux === 'cerrada'">
                    Estás por cambiar el estatus de la propuesta comercial {{comercialForm.get('name').value}} de
                    {{comercialForm.get('client').value}} a ‘Cerrada’. ¿Deseas continuar?</div>
            </div>
            <div class="modal-text-body" *ngIf="modalFlux === 'standBy'">
                <p>Cambiar el estatus a ‘Standby’ impedirá modificarlo nuevamente hasta su reactivación.
                </p>
                <p> ¿Deseas continuar?</p>
            </div>
            <div class="modal-footer justify-content-center" style="border-top:none">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    [routerLink]="'/comercial'">Cancelar</button>

                <button type="button" class="btn btn-primary" data-dismiss="modal">Cambiar
                    estatus</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modalChangeCategory" #modalChangeCategory tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom:none">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center" style="border-bottom:none">
                <span class="time-icon-status_question fs-36"><span class="path1"></span><span
                        class="path2"></span><span class="path3"></span></span>

                <div class="modal-title">
                    ¿Estás seguro?
                </div>
                <div class="modal-text-body" *ngIf="modalFlux === 'cerrada'">

                    Estás por crear una asignación a partir de la propuesta {{comercialForm.get('name').value}} <br> ¿Deseas continuar?
                </div>
            </div>
            <div class="modal-footer justify-content-center" style="border-top:none">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    [routerLink]="'/comercial'">Cancelar</button>

                <button type="button" class="btn btn-primary" data-dismiss="modal">Crear asignación</button>
            </div>
        </div>
    </div>
</div>