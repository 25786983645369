<div class="content">
  <header class="dark-header">
    <div class="container header">
      <div class="h-logo">
        <img src="/assets/logo/2BC_logotipo_blanco.svg" alt="logo-2bcore">
      </div>
    </div>
  </header>
  <main class="main mt-5 pt-5">
    <div class="container">
      <div class="d-flex flex-column align-items-center signin-content">
        <h2 class="fs-30">¡Bienvenido!</h2>
        <p class="mt-4 p-content fs-14">El portal interno de <span class="c-1E88E5">2BCORE</span> es exclusivo de colaboradores, si ya formas parte de nuesto equipo inicia sesión.</p>
        <img src="/assets/img/login.gif" alt="portada">
        <button class="mt-5 mb-5 btn-signin" (click)="navigateToLogin()">Iniciar sesión</button>
      </div>
    </div>
  </main>
  <app-footer class="footer"></app-footer>
</div>
