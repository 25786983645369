import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { IncidenceComponent } from '../incidence/incidence.component';
import { MatDialog } from '@angular/material/dialog';
import { TeamService } from 'src/app/services/team/team.service';


@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})

export class EmployeeDetailComponent {
  public employeeId: string = '';
  public breadcrumb: Breadcrumb[] = [CRUMBS.areaLeadMain];
  employeeInfo;
  projects;
  otherprojects;
  incidences;
  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private teamService: TeamService
  ) { }

  ngOnInit(): void {
    this.employeeId = this.route.snapshot.paramMap.get('id') ?? '';

    const breadRoute = `${CRUMBS.areaLeadEmmployeeDetails.route}/${this.employeeId}`
    this.breadcrumb.push({ ...CRUMBS.areaLeadEmmployeeDetails, route: breadRoute })

    this.teamService.getEmployeeInfo(this.employeeId).subscribe(res=>{
      console.log(res)
      this.employeeInfo = res.employee;

      this.projects = res.oportunities.filter(item => item.category === "Proyecto");
      this.otherprojects = res.oportunities.filter(item => item.category !== "Proyecto");

      this.incidences = res.incidences;
    })
  }

  public openIncidenceDialog() {
    const dialogRef = this.dialog.open(IncidenceComponent, {
      width: '464px',
      data: {
        employeeId: this.employeeId,
        employeeName: "Nombre de usuario"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}
