<div class="container mx-auto">
  <div class="row"> 
    <!-- MODAL AVISO -->
    <div class="modal fade px-0" id="modalAviso" tabindex="-1" aria-labelledby="modalAviso" aria-hidden="true">
      <div class="mx-auto modal-dialog modal-dialog-centered">
        <div class="modal-content col-10 mx-auto">
          <div class="modal-header pb-0 border-0">
            <p class="fs-14 fw-bold txt-dark mb-0">Aviso de privacidad</p>
            <button type="button" class="close">
              <span aria-hidden="true" data-dismiss="modal">&times;</span>
            </button>
          </div>
          <div class="modal-body pt-0">
            <p class="fs-12 text-justify txt-darkgrey">
              Bajo los principios de honestidad, transparencia, cuidado de datos personales y respeto, la información proporcionada en este formulario será manejada con total protección y confidencialidad y sus comentarios serán exclusivamente para fines de mejora del ambiente del trabajo y como cumplimiento de contar con un mecanismo de comunicación que contempla la NOM-035.
            </p>
          
          
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 col-lg-8 mx-auto">
      <div class="col-12 justify-content-center form-inline mt-3">
        <span class="mr-2 px-0 text-lg-right my-auto txt-dark fs-28">Buzón 2BCORE</span>
      </div>
      <img src="../../../../assets/icons/step.svg" class="mt-4 mb-3 mx-auto d-block" *ngIf="complaintStage<2">
      <img src="../../../../assets/icons/step_1.svg" class="mt-4 mb-3 mx-auto d-block" *ngIf="complaintStage===2">
      <img src="../../../../assets/icons/step_2.svg" class="mt-4 mb-3 mx-auto d-block" *ngIf="complaintStage===3">
      <img src="../../../../assets/icons/step_3.svg" class="mt-4 mb-3 mx-auto d-block" *ngIf="complaintStage===4">
      
      <div class="card mt-lg-5 pb-3 mx-auto pt-0" [ngSwitch]="complaintStage">
        <!-- BIENVENIDA -->
        <ng-container *ngSwitchCase="0">
          <div class="col-12  fs-21 px-0 mx-0 p-2 card-title-blue"><p class="col-11 mx-auto mb-0 pl-0">Bienvenido</p></div>
          <p class="col-11 mx-auto fs-16 mt-3 text-justify">
            En cumplimiento con la NOM-035-STPS-2018, nuestro buzón es un medio
            de comunicación seguro y confidencial para recibir comentarios por
            prácticas opuestas al entorno organizacional favorable, denuncias de
            actos de violencia laboral, informar sobre efectos generados por
            acontecimientos traumáticos severos. Así como expresar propuestas a
            favor de un ambiente laboral favorable y reconocer a las personas
            con liderazgo positivo.
            <br /><br />
            La información que aquí registres será manejada cuidadosamente por
            el área designada para dar seguimiento a cada uno de los comentarios
            de los trabajadores. <br /><br />
            Agradecemos tu confianza y te reiteramos que la información será
            manejada con absoluta discreción. <br /><br />
            <!-- <span *ngIf="!isEmployee">Para asegurar el
            correcto uso de nuestro buzón, es necesario validar tu información.</span> 
            <br /><br />
            <span *ngIf="!isEmployee">Por favor, ingresa tu correo electrónico:</span>  -->
          </p>
          <!-- <input 
            *ngIf="!isEmployee"
            id="email"
            placeholder="ej. nombre.correo@2bcore.com"
            type="text"
            class="form-control mx-auto col-10 col-md-7 col-lg-6"
            formControlName="corporateEmail"
            [ngClass]="this.globals.flagBoxMailNotFound ? 'input-error' : '' "
          />
          <div *ngIf="this.globals.flagBoxMailNotFound" class="mx-auto col-10 col-md-7 col-lg-6 pl-0 fs-11 txt-red pt-1">
            El correo electrónico ingresado no se encuentra registrado, revísalo e inténtalo de nuevo.
          </div> -->
          <div class="col-11 mx-auto mt-4 pr-0">
            <div class="col-12 col-md-10 float-md-right mt-2">
              <div class="row float-md-right">
                <div class="col-12 col-md mx-auto mt-3 mt-md-0">
                  <button
                    class="btn btn-primary mx-auto d-block fs-12"
                    (click)="sendEmail()"
                    [disabled]="!welcomeFg?.valid && !isEmployee"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- FOLIO -->
        <ng-container *ngSwitchCase="1" [formGroup]="folioFg">
          <div class="col-12  fs-21 px-0 mx-0 p-2 card-title-blue"><p class="col-11 mx-auto mb-0 pl-0">Bienvenido</p></div>
          <p class="col-11 mx-auto fs-16 mt-3 text-justify">
            Hemos enviado un número de folio a tu correo electrónico, ingrésalo
            en la siguiente casilla para continuar.
          </p>
          <input
            id="emailFolio"
            placeholder="Ingresa tu folio"
            type="text"
            class="form-control mx-auto col-10 col-md-7 col-lg-6"
            formControlName="complaintFolio"
            [ngClass]="this.globals.flagBoxInvalidFolio ? 'input-error' : '' "
          />
          <div *ngIf="this.globals.flagBoxInvalidFolio" class="mx-auto col-10 col-md-7 col-lg-6 pl-0 fs-11 txt-red pt-1">
            El folio ingresado no es válido. Revísalo e inténtalo nuevamente.
        </div>
          <div class="col-11 mx-auto mt-4 pr-0">
            <div class="col-12 col-md-10 float-md-right mt-2">
              <div class="row float-md-right">
                <div class="col-12 col-md mx-auto mt-3 mt-md-0">
                  <button
                    class="btn btn-secondary mx-auto d-block fs-12"
                    type="button"
                    (click)="validateFolio()"
                    [disabled]="!folioFg.valid"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- CATEGORIA -->
        <ng-container *ngSwitchCase="2" [formGroup]="categoryFg">
          <div class="col-12  fs-21 px-0 mx-0 p-2 card-title-blue"><p class="col-11 mx-auto mb-0 pl-0">Categoría</p></div>
          <p class="col-11 mx-auto fs-12 mt-4">
            Selecciona el tipo de comentario que deseas presentar:
          </p>
          <div class="mx-auto">
            <div class="white-box px-3 py-2 my-2">
              <div class="form-inline">
                <input
                  type="radio"
                  name="selectedCategory"
                  id="categoryRadio0"
                  [value]="categories.POSITIVE_LIDERSHIP"
                  formControlName="selectedCategory"
                />
                <span class="checkmark-radio cr-pointer"></span>
                <label class="txt-dark fs-12 ml-4 cr-pointer" for="categoryRadio0">
                  Reconocimiento a liderazgo positivo
                </label>
              </div>
              <label class="ml-4 fs-12 txt-main" *ngIf="categoryFg.controls.selectedCategory.value===1"
                >¿Quieres reconocer a alguien en la empresa que tenga prácticas
                positivas?</label
              >
            </div>
            <div class="white-box px-3 py-2 my-2">
              <div class="form-inline">
                <input
                  type="radio"
                  name="selectedCategory"
                  id="categoryRadio1"
                  [value]="categories.WORKPLACE_SUGGESTION"
                  formControlName="selectedCategory"
                />
                <span class="checkmark-radio cr-pointer mr-3"></span>
                <label class="txt-dark fs-12 ml-4 cr-pointer" for="categoryRadio1">
                  Práctica a favor de un ambiente laboral favorable
                </label>
              </div>
              <label class="ml-4 fs-12 txt-main" *ngIf="categoryFg.controls.selectedCategory.value===2"
                >¿Quieres dar una propuesta para mejorar el clima laboral de la
                empresa?</label
              >
            </div>
            <div class="white-box px-3 py-2 my-2">
              <div class="form-inline">
                <input
                  type="radio"
                  name="selectedCategory"
                  id="categoryRadio2"
                  [value]="categories.WORPLACE_VIOLENCE"
                  formControlName="selectedCategory"
                />
                <span class="checkmark-radio cr-pointer"></span>
                <label class="txt-dark fs-12 ml-4 cr-pointer" for="categoryRadio2">
                  Violencia laboral
                </label>
              </div>
              <label class="ml-4 fs-12 txt-main" *ngIf="categoryFg.controls.selectedCategory.value===3"
                >¿Has sufrido algún acto de acoso psicológico como
                hostigamiento, insultos, humillaciones, comparaciones, que haya
                dañado tu estabilidad psicológica?</label
              >
            </div>
            <div class="white-box px-3 py-2 my-2">
              <div class="form-inline">
                <input
                  type="radio"
                  name="selectedCategory"
                  id="categoryRadio3"
                  [value]="categories.WORKPLACE_COMPLAINT"
                  formControlName="selectedCategory"
                />
                <span class="checkmark-radio cr-pointer"></span>
                <label class="txt-dark fs-12 ml-4 cr-pointer" for="categoryRadio3">
                  Práctica contra un ambiente laboral favorable
                </label>
              </div>
              <label class="ml-4 fs-12 txt-main" *ngIf="categoryFg.controls.selectedCategory.value===4"
                >¿Quieres denunciar alguna práctica que hayas presenciado o de
                la que hayas sido víctima que va en contra de un buen clima
                laboral?</label
              >
            </div>
            <div class="white-box px-3 py-2 my-2">
              <div class="form-inline">
                <input
                  type="radio"
                  name="selectedCategory"
                  id="categoryRadio4"
                  [value]="categories.TRAUMATIC_EVENT"
                  formControlName="selectedCategory"
                />
                <span class="checkmark-radio cr-pointer"></span>
                <label class="txt-dark fs-12 ml-4 cr-pointer" for="categoryRadio4">
                  Acontecimiento traumático severo
                </label>
              </div>
              <label class="ml-4 fs-12 txt-main" *ngIf="categoryFg.controls.selectedCategory.value===5"
                >¿Has experimentado algún acontecimiento durante o con motivo
                del trabajo que te haya generado estrés postraumático?</label
              >
            </div>
          </div>

          <div class="col-11 mx-auto mt-4 pr-0">
            <div class="col-12 col-md-10 float-md-right mt-2">
              <div class="row float-md-right">
                <div class="col-12 col-md mx-auto">
                  <button
                    class="btn btn-secondary mx-auto d-block fs-12"
                    (click)="goBackEmployee()"
                  >
                    Anterior
                  </button>
                </div>
                <div class="col-12 col-md mx-auto mt-3 mt-md-0">
                  <button
                    class="btn btn-secondary mx-auto d-block fs-12"
                    type="button"
                    (click)="updateCategory()"
                    [disabled]="!categoryFg.valid"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- DATOS PERSONALES -->
        <ng-container *ngSwitchCase="3" [formGroup]="personalDataFg">
          <div class="col-12  fs-21 px-0 mx-0 p-2 card-title-blue"><p class="col-11 mx-auto mb-0 pl-0">Comunicado</p></div>
          <div class="row card-body">
            <div class="col-11 mx-auto">
              <p class="fs-16 fw-bold txt-dark" *ngIf="!isEmployee">
                {{ complaintData.names }} {{complaintData.surnames}} {{complaintData?.secondSurname ? complaintData?.secondSurname : ''}}
              </p>
              <p class="fs-12" *ngIf="!isEmployee">Centro: {{ complaintData.workcenter }}</p>
              <p class="fs-12" *ngIf="!isEmployee">Área: {{ complaintData.area }}</p>
              <hr *ngIf="!isEmployee"/>
              <label class="form-label fs-12 txt-dark">Comentario*</label>
              <textarea
                id="comment"
                placeholder="Escribe aquí"
                class="form-control w-100"
                formControlName="commentaryText"
              ></textarea>
              <div class="alert-invalid"
              *ngIf="personalDataFg.controls['commentaryText'].invalid && (personalDataFg.controls['commentaryText'].touched || personalDataFg.controls['commentaryText'].dirty)">
              <span *ngIf="personalDataFg.controls['commentaryText'].errors.required"
                class="text-red small">
                Campo obligatorio
              </span>
              <span *ngIf="personalDataFg.controls['commentaryText'].errors.maxlength" class="text-red small">
                Ingrese máximo 1000 caracteres
              </span>
            </div>
            </div>
            <div class="col-11 ml-5 mt-3">
              <label class="form-inline">
                <input type="checkbox" formControlName="privacyCheckbox" />
                <span class="checkmark-square ml-0 cr-pointer"></span>
                <p class="txt-grey fs-12 txt-rpw cr-pointer mb-0 ml-2">
                  Aceptar <a data-toggle="modal" data-target="#modalAviso">Aviso de Privacidad</a>
                </p>
              </label>
            </div>
          </div>
          <div class="col-11 mx-auto pr-0">
            <div class="col-12 col-md-10 float-md-right mt-2">
              <div class="row float-md-right">
                <div class="col-12 col-md mx-auto">
                  <button
                    class="btn btn-secondary mx-auto d-block fs-12"
                    (click)="complaintService.stepBack()"
                  >
                    Anterior
                  </button>
                </div>
                <div class="col-12 col-md mx-auto mt-3 mt-md-0">
                  <button
                    class="btn btn-secondary mx-auto d-block fs-12"
                    type="button"
                    (click)="sendComments()"
                    [disabled]="!personalDataFg.valid"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- AGRADECIMIENTO -->
        <ng-container *ngSwitchCase="4">
          <div class="col-11 mx-auto fs-35 txt-darkng aerve text-center">
            ¡Excelente!
          </div>
          <p class="col-11 mx-auto text-center fs-14 mt-4">
            Muchas gracias por tu participación, con tus comentarios podremos
            mejorar el ambiente laboral y nuestro entorno de trabajo.
          </p>
          <img
            src="assets/img/Encuesta.svg"
            width="330px"
            height="260px"
            alt="User"
            class="my-3 mx-auto d-block"
          />
          <button class="btn btn-secondary mx-auto h-auto mt-3" (click)="newComment()">Enviar otro<br>comentario</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
