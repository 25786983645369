import { Pipe, PipeTransform } from '@angular/core';
import * as enviroment from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {

  random;

  constructor(private authService: AuthService) {

    this.authService.randomImg.subscribe((value: number) => {
      this.random = value;
   });
  }

  transform(value: string, identifier: string) : string {
    return `${enviroment.environment.urlPath}/${identifier}/${value}?${this.random}`;
  }

}