import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { WorkPlan } from '../project.const';
import { isAfter, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addWeeks, eachMonthOfInterval, format, differenceInDays,parseISO } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { ModalTaskComponent } from 'src/app/modules/time-management/comercial/modal-task/modal-task/modal-task.component';
import { activities } from 'src/app/modules/time-management/record/record.conts';
interface TaskBuild extends WorkPlan {
  style?: string
}
interface WeekBuild {
  start: Date
  end: Date
}


interface MonthBuild {
  month: string;
  percentage: number;
}

@Component({
  selector: 'app-work-plan[workPlan]',
  templateUrl: './work-plan.component.html',
  styleUrls: ['./work-plan.component.scss']
})
export class WorkPlanComponent implements OnInit {
  @Input() workPlan!: WorkPlan[];
  @Input() edition!:boolean;
  @Output() workPlanChange = new EventEmitter<any[]>();

  public auxTasks: TaskBuild[] = [];
  public totalWeeks: WeekBuild[] = [];
  public totalMonths: MonthBuild[] = [];

  constructor(private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this
    if (changes['workPlan'] && changes['workPlan'].currentValue) {
      
      this.workPlan = [...this.workPlan];
      this._build();
    }
  }

  ngOnInit(): void {
    this._build();    
  }

  openModal(plan?: any, index?:any): void {
    const dialogRef = this.dialog.open(ModalTaskComponent, {
      width: '464px',
      data: plan || {}, // Si es edición, manda datos; si no, envía vacío
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
       this.updateWorkPlan(index,result)
      }
    });
  }
  updateWorkPlan(index: number, updatedItem: any) {
    let data:any= {
      index: index,
      activitie: updatedItem
    }
    this.workPlanChange.emit(data); // Emite el arreglo modificado al padre
  }
  private _build() {
    
    if (!this.workPlan || this.workPlan.length === 0) return;
    this.workPlan.forEach((element) => {
      element.startDate = new Date(element.startDate);
      element.endDate = new Date(element.endDate);
    })
    let calendarStart: Date | undefined = undefined;
    let calendarEnd: Date | undefined = undefined;
    
    this.workPlan.forEach((task) => {
      if (!calendarStart || task.startDate.getTime() < calendarStart.getTime()) {
        calendarStart = startOfMonth(task?.startDate);
      }
      if (!calendarEnd || task?.endDate?.getTime() > calendarEnd.getTime()) {
        calendarEnd = endOfMonth(task?.endDate);
      }
    });
    if (!calendarStart || !calendarEnd) return;

    this.totalWeeks = this.calcWeeks(calendarStart, calendarEnd);

    this.auxTasks = this.workPlan.map((task) => {
      const style = `${this.getStylePosition(task)} --color: ${task.color};`;
      return { ...task, style };
    })

    const monthsWithPorcent = this.calculatePercentageDaysPerMonth(this.totalWeeks);
    this.totalMonths = monthsWithPorcent.map((month, i, months) => {
      if (new Date(month.month).getTime() < (calendarStart?.getTime() || 0)) {
        // Elimina meses menores a la fecha de inicio del calendario
        months[i + 1].percentage += month.percentage;
        return null;
      }
      if (new Date(month.month).getTime() > (calendarEnd?.getTime() || 0)) {
        // Elimina meses mayores a la fecha de fin del calendario
        months[i - 1].percentage += month.percentage;
        return null;
      }
      return month;
    }).filter((month): month is MonthBuild => !!month);
  }

  private getStylePosition(task: WorkPlan): string {
    const start = this.findWeekPostion(task.startDate);
    const end = this.findWeekPostion(task.endDate);

    return `--start: ${start + 0};
       --end: ${end + 1};`;
  }

  private findWeekPostion(date: Date): number {
    const index = this.totalWeeks.findIndex((week) => week.start.getTime() <= date.getTime() && date.getTime() <= week.end.getTime())
    return index + 1;
  }

  private calcWeeks(startDate: Date, endDate: Date) {
    const weeks: WeekBuild[] = []
    // Asegurarse de que la fecha de inicio sea antes que la fecha de fin
    if (isAfter(startDate, endDate)) {
      throw new Error("La fecha de inicio debe ser anterior a la fecha de fin.");
    }

    // Ajustar la fecha de inicio al primer día de la semana
    let startWeek = startOfWeek(startDate, { weekStartsOn: 1 }); // 0 para domingo

    // Iterar hasta que la fecha de inicio de la semana sea mayor que la fecha de fin
    while (startWeek.getTime() <= endDate.getTime()) {
      const endWeek = endOfWeek(startWeek, { weekStartsOn: 1 });

      weeks.push({
        start: startWeek,
        end: isAfter(endWeek, endDate) ? endDate : endWeek,
      });

      startWeek = addWeeks(startWeek, 1);
    }

    return weeks;
  }

  private calculatePercentageDaysPerMonth(weeks: WeekBuild[]): MonthBuild[] {
    // Crear un objeto para almacenar los días involucrados por mes
    const daysPerMonth: { [key: string]: number } = {};
    let totalDays = 0;

    // Recorrer cada semana y contar los días involucrados
    weeks.forEach(week => {
      const startDate = new Date(week.start);
      const endDate = new Date(week.end);

      // Obtener todos los meses involucrados en la semana
      const monthsPerWeek = eachMonthOfInterval({ start: startDate, end: endDate });

      monthsPerWeek.forEach(month => {
        const key = month.toDateString();
        const startDate = startOfMonth(month);
        const endDate = endOfMonth(month);

        // Calcular los días involucrados en el mes
        const days = Math.max(0, Math.min(endDate.getTime(), endDate.getTime()) - Math.max(startDate.getTime(), startDate.getTime())) / (1000 * 60 * 60 * 24) + 1;

        // Sumar los días involucrados
        if (!daysPerMonth[key]) {
          daysPerMonth[key] = 0;
        }
        daysPerMonth[key] += days;

        // Sumar al total de días involucrados
        totalDays += days;
      });
    });

    // Calcular el porcentaje de días involucrados por mes
    const result: MonthBuild[] = [];
    for (const month in daysPerMonth) {
      const percentage = (daysPerMonth[month] / totalDays) * 100;
      result.push({ month, percentage: +percentage.toFixed(2) });
    }

    return result;
  }

  public toggleFullscreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
      });
    } else {
      const element = this.elementRef.nativeElement.querySelector('#workPlan');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    }
  }

}