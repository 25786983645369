<button mat-icon-button mat-dialog-close aria-label="Close dialog" class="btn-close">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    <span [class]="'icon time-icon-' + data.icon">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3" *ngIf="data.icon == 'status_send'"></span>
    </span>
    <h1 class="title">{{data.title}}</h1>
</div>
<div mat-dialog-content>
    {{ data.description }}
</div>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>{{ data.close }}</button>
</div>