import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-oportunity',
  templateUrl: './modal-oportunity.component.html',
  styleUrls: ['./modal-oportunity.component.scss']
})
export class ModalOportunityComponent {

  
  constructor(public dialogRef: MatDialogRef<ModalOportunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  cancel(): void {
    this.dialogRef.close(false); // Indica que se canceló
  }

  confirm(): void {
    this.dialogRef.close(true); // Indica que se confirmó
  }
}
