/** RXJS */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
/** SERVICES */
import { AuthService } from '../../services/auth/auth.service';
/** INTERFACES */
/**
 * Guard |
 * @export
 * @class PrivateRouteGuard
 * @implements {CanActivate}
 * @implements {OnDestroy}
 */
@Injectable()
export class  PrivateRouteGuard implements CanActivate, CanActivateChild, OnDestroy {
  /** lista de suscripciones */
  subscribers: any = {};
  /** login */
  isLoggedIn = false;
  /**
   * Creates an instance of PrivateRouteGuard.
   * @param {AuthenticationService} authService
   * @memberof PrivateRouteGuard
   */
  constructor(
    private route: Router,
    private authService: AuthService,
    private auth: AuthService,
  ) {
    this.isLoggedIn = sessionStorage.getItem('isLogged') == 'true' ? true : false;
  }

  /**
   * funciones necesarias para activacion de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PrivateRouteGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Fn | Revisa credenciales si está definido cliente y se retorna a `inicio`
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof PublicRouteGuard
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoute(next, state);
  }

  /**
   * Función para validación de ruta
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof PrivateRouteGuard
   */
  checkRoute(next: any, state: RouterStateSnapshot) {
    this.isLoggedIn = sessionStorage.getItem('isLogged') == 'true' ? true : false;
    let urlToGo = "";
    let flag = true;
    let params = next.queryParams;
    next._urlSegment.segments.forEach(element => {
      urlToGo += "/" +element.path;
    });
    if (!this.isLoggedIn) {
      sessionStorage.setItem("path", urlToGo);
      sessionStorage.setItem("params", JSON.stringify(params))

      if(urlToGo.includes("/invite/menu/info")){
        sessionStorage.setItem("respondingInvitation", "true")
      }

      if(urlToGo.includes("/invite") || urlToGo.includes("/comment")){
        this.route.navigate(['/signin']);
      }else{
        this.route.navigate(['/']);
      }
    }
    return this.isLoggedIn && flag;
  }

  /**
   * Destructor
   * @memberof PrivateRouteGuard
   */
  ngOnDestroy() {
  }
}
