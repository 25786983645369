import { catalogActivities } from "../register-hours/activities.const"
import { proposals } from "@pmo/projects/projects.const"

export interface Activity {
    id: string
    date: Date
    activity: string
    type: string
    duration: {
        hours: number
        minutes: number
    }
    description: string
    status: string
}

export const activities: Activity[] = [
    {
        id: '1',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[1].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo MVP para presentación con cliente.',
        status: 'approved',
    },
    {
        id: '2',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[2].code,
        duration: {
            hours: 2,
            minutes: 0,
        },
        description: 'Definición de sistema de diseño y elementos para el prototipo.',
        status: 'pending',
    },
    {
        id: '3',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[3].code,
        duration: {
            hours: 0,
            minutes: 30,
        },
        description: 'Daily con equipo',
        status: 'approved',
    },
    /*
    {
        id: '4',
        date: new Date('07/20/24'),
        activity: catalogActivities[0].value,
        type: proposals[4].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'pending',
    },
    */
    {
        id: '5',
        date: new Date('07/19/24'),
        activity: catalogActivities[0].value,
        type: proposals[5].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'rejected',
    },
    /*
    {
        id: '6',
        date: new Date('07/18/24'),
        activity: catalogActivities[0].value,
        type: proposals[6].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'approved',
    },
    {
        id: '7',
        date: new Date('07/17/24'),
        activity: catalogActivities[0].value,
        type: proposals[7].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'rejected',
    },
    */
]