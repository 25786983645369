import { Directive, HostListener, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumbersMaxLength]'
})
export class OnlyNumbersMaxLengthDirective {
  @Input('appOnlyNumbersMaxLength') maxLength!: number;

  constructor(private el: ElementRef, @Optional() @Self() private ngControl: NgControl) { }

  /**
   * Limit character size for numeric type inputs
   */
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, '');

    if (value.length > this.maxLength) {
      value = value.slice(0, this.maxLength);
    }

    input.value = value;
    if (this.ngControl) {
      this.ngControl.control?.setValue(value);
    }

    event.stopPropagation();
  }

}
