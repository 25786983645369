import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: Date | string): string {
    const date = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const shortHours: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    if (date.toDateString() === today.toDateString()) {
      return `Hoy a las ${date.toLocaleString('es-Mx', shortHours)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Ayer a las ${date.toLocaleString('es-Mx', shortHours)}`;
    } else {
      const longDate: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      };
      return date.toLocaleString('es-Mx', longDate);
    }
  }
}
