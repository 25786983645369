import { Injectable } from '@angular/core';
import { ResponseContent } from '../../models';
import { HttpService } from '../http-service/http.service';
import { map } from 'rxjs/internal/operators';
import { BehaviorSubject } from 'rxjs';
import { QuestionaryModel, IQuestionnairesModel } from '../../models/questionnaires/questionary.model';
@Injectable({
  providedIn: 'root'
})


/**
 * Servicio para el envio de respuestas decuestionarios
 * @export
 */
export class QuestionnairesService {

  /**
   * Creates an instance of AuthService.
   * @param {HttpService} http
   * @param {errorsService} errorsService
   */
  constructor(
    private http: HttpService
  ) {
  }

  /**
   *Observable para obtener todos los usuarios
   *
   * @memberof UsersService
   */
  quests = new BehaviorSubject<IQuestionnairesModel[]>([]);


  isEmployee = new BehaviorSubject<boolean>(true);



  /**
   * Carga masiva
   */
  sendAnswer(answer) {
    return this.http.post('/questionnaires/answer', answer, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  sendAnswerDisable(answer) {
    return this.http.post('/questionnaires/answerDisable', answer, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  nextQuestionnaire(idProject) {
    return this.http.post('/statistics/getNext', {idProject}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  getQuestionnairies(idCompany,nameProject){
    return this.http.post('/questionnaires/getQuestionnaires/', {idCompany,nameProject}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  getProjectName(idProject){
    return this.http.post('/questionnaires/getName/', {idProject}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  getCompanyName(idCompany){
    return this.http.post('/questionnaires/getNameCompany/', {idCompany}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  getEmployeeQuests(idCompany,idEmployee){
    return this.http.post('/questionnaires/getEmployeeQuests', {idCompany,idEmployee}, true).pipe(
      map((response: ResponseContent) => {
        this.quests.next(response.content)
        return response;
        }
      )
    );
  }

  evalGuide(
    data: {
      idProject: string,
      idEmployee: string
    }
  ){
    return this.http.post('/questionnaires/evalGuides/', data, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  getEmployee(idEmployee){
    return this.http.post('/questionnaires/getEmployeeData/', {idEmployee}, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

  gratitudeEmail(
    data: {
      idProject: string
      idEmployee: string
    }
  ){
    return this.http.post('/questionnaires/gratitudeEmail', data, true).pipe(
      map((response: ResponseContent) => {
        return response;
        }
      )
    );
  }

}
