import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showRoles'
})
export class ShowRolesPipe implements PipeTransform {

  transform(role: string, ): unknown {

    switch (role) {
      case 'employee':
        return 'Colaborador';
      case 'addOn':
        return 'Configurado';
      case 'admin':
        return 'Administrador';
      case 'root':
        return 'Root';
    }

  }

}
