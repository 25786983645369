import { Injectable } from '@angular/core';
import { EmployeeModel } from 'src/app/models/employees/employee.model';
import { IAdminModel } from '../../models/admin/admin.model';
import { IQuestionnairesModel } from '../../models/questionnaires/questionary.model';
import { FilterModel } from '../../models/filter/filter.model';
import { ICompanyModel } from 'src/app/models/companies/companies.model';
import { IGuestsModel, IInvitationModel } from 'src/app/models/invitation/invitation-model';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor() {}

  search(array: EmployeeModel[], data = ''): EmployeeModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.names.toLowerCase().includes(data.toLowerCase()) ||
          item.businessEmail.toLowerCase().includes(data.toLowerCase()) ||
          item.surnames.toLowerCase().includes(data.toLowerCase()) || 
          (item.secondSurname ? item.secondSurname: '').toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  searchEmployee(array: EmployeeModel[], data = ''): EmployeeModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.names.toLowerCase().includes(data.toLowerCase()) ||
          item.workplace.toLowerCase().includes(data.toLowerCase()) ||
          item.surnames.toLowerCase().includes(data.toLowerCase()) ||
          (item.secondSurname ? item.secondSurname : '').toLowerCase().includes(data.toLowerCase()) ||
          item.area.toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  searchInvitation(array: IInvitationModel[], data = ''): IInvitationModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.tittle.toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  searchGuest(array: IGuestsModel[], data = ''): IGuestsModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.name.toLowerCase().includes(data.toLowerCase()) ||
          item.center.toLowerCase().includes(data.toLowerCase()) ||
          item.area.toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }


  searchConsultant(array: IAdminModel[], data = ''): IAdminModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.name.toLowerCase().includes(data.toLowerCase()) ||
          item.email.toLowerCase().includes(data.toLowerCase())) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  searchProject(array: IQuestionnairesModel[], data = ''): IQuestionnairesModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.nameProject.toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  searchCompany(array: ICompanyModel[], data = ''): ICompanyModel[] {
    const arrayTmp = [];
    if (data.length > 0) {
      for (const item of array) {
        if (
          item.companyName.toLowerCase().includes(data.toLowerCase())
        ) {
          arrayTmp.push(item);
        }
      }
      return arrayTmp;
    } else {
      return array;
    }
  }

  orderByAZ(array, key: string) {

    if(isNaN(array[0][key])){
      
      return array.sort((a, b) => a[key].localeCompare(b[key]));

    }else{

      return this.orderByAZNumbers(array, key);

    }

  }

  orderByAZNumbers(array, key: string) {
    array.sort(function (a, b) {
       return a[key] - b[key];
     });
     return array;
   }

   orderByZANumbers(array, key: string) {
    array.sort(function (a, b) {
       return b[key] - a[key];
     });
     return array;
   }

  orderByZA(array, key: string) {

    if(isNaN(array[0][key])){
      
      return array.sort((a, b) => b[key].localeCompare(a[key]));

    }else{

      return this.orderByZANumbers(array, key);

    }
  }

  orderByAZBool(array, key:string){
    array.sort(function(a,b){return a[key]-b[key]});
    return array;
  }

  orderByZABoolReverse(array, key:string){
    array.sort(function(a,b){return b[key]-a[key]});
    return array;
  }


  filterArray(array: EmployeeModel[], filterArray: FilterModel[]): EmployeeModel[] {
    let arrayFiltered = [];
    for (const item of filterArray){
        arrayFiltered.push(array.filter( e => {
            return e[item.key] === item.value;
          }));
    }

    let aux = [];

    for(let item of arrayFiltered){
        for(let i of item){
            aux.push(i);
        }
    }
    if (arrayFiltered.length > 0) {
        arrayFiltered = this.deleteDuplicated(arrayFiltered);
    }
    return aux;
}


deleteDuplicated(array: EmployeeModel[]): EmployeeModel[] {
    const deleteDuplicated = array.filter((value, currentIndex, arr) => arr.indexOf(value) === currentIndex);
    return deleteDuplicated;
}

translateDate(date){
  
  const  fecha = new Date( date);
    let month;
  const day = fecha.getDay();
  switch(fecha.getMonth()){
    case 0:
        month = 'Enero';
        break;
    case 1:
        month = 'Febrero';
        break;
    case 2:
        month = 'Marzo';
        break;
    case 3:
        month = 'Abril';
        break; 
    case 4:
        month = 'Mayo';
        break;
    case 5:
        month = 'Junio';
        break;
    case 6:
        month = 'Julio';
        break;
    case 7:
        month = 'Agosto';
        break;
    case 8:
        month = 'Septiembre';
        break;
    case 9:
        month = 'Octubre';
        break;
    case 10:
        month = 'Noviembre';
        break;
    case 11:
        month = 'Diciembre';
        break; 
    default:
        month = '';
        break;                                                                                                                      
}
let dateResult
  
  return dateResult = `${fecha.getDate() > 9 ? fecha.getDate(): '0' + fecha.getDate()} ${month}`;

}

}
