import { Component } from '@angular/core';
import { log } from 'console';
import { EmployeesService } from 'src/app/services';
import { AccessService } from 'src/app/services/access/access.service';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['./comercial.component.scss']
})
export class ComercialComponent {
  usersByCompany: any;

  localOportunities: [any];
  sortDirection: 'asc' | 'desc' | '' = '';
  projectsTemplate: any = [];
  auxProjectsTemplate: any = [];
  currentFilter: string = '';
  responsableName: any;
  updatedOportunities: [any];
  //bandera para flujo activo por defecto
  activeFlux = 'Propuesta';
  constructor(
    private accessService: AccessService,
    private oportunitiesService: OportunityService,
    private employeeService: EmployeesService
  ) {
    const idCompany = sessionStorage.getItem('idCompanySelected')
    this.employeeService.getAllCompanyEmployees(idCompany).subscribe((data: any) => {
      this.usersByCompany = data;
      this.usersByCompany.forEach((user: any) => {
        const addUser = {
          name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
          image: user.image,
          _id: user.idUser
        }
      });
    });
    this.oportunitiesService.getOportunities().subscribe(data => {
      this.localOportunities = data;
      console.log(data);
      
      this.auxProjectsTemplate = this.localOportunities;

      this.projectsTemplate = this.auxProjectsTemplate;
      if (this.projectsTemplate !== null) {
        this.auxProjectsTemplate?.forEach(oportunidad => {
          // Buscar en el arreglo de `usuarios` el usuario que coincida con el `responsable2bcore`
          const usuarioEncontrado = this.usersByCompany?.find(usuario => usuario.idUser === oportunidad.responsable2bcore);
          // Si se encuentra, se agrega la imagen al objeto oportunidad
          if (usuarioEncontrado) {
            oportunidad.imageResponsalbe = usuarioEncontrado.image;
            oportunidad.responsable2bcore = `${usuarioEncontrado?.names} ${usuarioEncontrado?.surnames} ${usuarioEncontrado?.secondSurname}`
          }
          if (!this.responsableName?.includes(oportunidad.names)) {
            this.responsableName?.push(oportunidad.names);
          }
        });

      }
      this.changeActive( this.activeFlux );
    })



  }


  changeActive(flux: any) {
    this.activeFlux = flux;

    switch (flux) {
      case 'Propuesta':
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Propuesta comercial');
        break;
      case 'Proyecto':
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Proyecto');
        break;
      default:
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Asignación');


    }
  }

  updateActiveEdit(index) {
    this.accessService.opportunityActiveIndex.next(index);
  }
  deleteOportunity(idOp: any) {
    const objData = {
      id: idOp
    }
    this.oportunitiesService.deleteOportunity(objData).subscribe((data) => {
      console.log(data);
      location.reload();
    });
  }
  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (order === this.sortDirection) order = '';
    this.sortDirection = order;

    if (order === '') {
      this.projectsTemplate = this.auxProjectsTemplate.slice();
      return;
    }
    if (order === 'asc') {
      this.projectsTemplate.sort((projectA, projectB) => projectA.name.localeCompare(projectB.name));
      return;
    }
    if (order === 'desc') {
      this.projectsTemplate.sort((projectA, projectB) => projectB.name.localeCompare(projectA.name));
      return;
    }
  }
}
