import {Component, OnDestroy, OnInit} from '@angular/core';

/** Importación del CoursesService */
import {ModalService} from '../../services/modal/modal.service';
import {EmployeesService} from '../../services/employees/employees.service';
import {Router} from '@angular/router';
import {Globals} from '../../globals/globals';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
   /**
    * Variable que nos indica si esta abierto el modal
    */
   openModal = false;

   /**
    * Variable que almacena la alerta ( para validaciones de acción del botón )
    */
   dataAlertModal = {
      numCase: 0,
      module: "",
      action: ""
   };

   backgroudAlert:string = '';
   txtTt:string = '';
   txtSub:string = '';
   iconAlert:string = '';
   colAlertIcon:string = '';
   colTxt:string = '';
   txtTitleStyle:string = '';
   txtSubStyle:string = '';
   btnCol: string = '';
   btnStyle: string = '';
   warningFlag: boolean = false;

   constructor(
      private modal: ModalService,
      private employeesS: EmployeesService,
      private router: Router,
      private globals: Globals,
      ) {
   }

   ngOnInit(): void {
      this.openModal = false;
      this.modal.alertModal.subscribe(response => {

         if (response.numCase !== 0){

            // Variable que almacena la alerta ( para validaciones de acción del botón )
            this.dataAlertModal.numCase = response.numCase;
            if (response.numCase !== 4 && response.numCase !== 5 ){
               this.dataAlertModal.module = response.data.module;
               this.dataAlertModal.action = response.data.action;

               // texto de la alerta
               this.txtTt = response.data.title;
               this.txtSub = response.data.message;
            }

            this.openModal = true;

            switch (response.numCase) {
               case 1: { // successful
                  this.backgroudAlert = 'background-alert-green';
                  this.iconAlert = 'okcircle_white.svg';
                  this.colAlertIcon = 'col-lg-2  col-md-2 col-1';
                  this.txtTitleStyle = 'txt-principal-white';
                  this.txtSubStyle = 'txt-principal-white';
                  this.btnCol = 'col-xs-2 col-sm-2 d-flex justify-content-center align-items-center';
                  this.btnStyle = 'btn-alpha';
                  this.colTxt = 'col-lg-8 col-md-8 col-10 col-sm-9 text-center pb-2';
                  break;
               }
               case 2: { // notTotallySuccessful
                  this.backgroudAlert = 'background-alert-yellow';
                  this.iconAlert = 'warming_grey.svg';
                  this.colAlertIcon = 'col-lg-2  col-md-2 col-1';
                  this.txtTitleStyle = 'txt-principal-grey';
                  this.txtSubStyle = 'txt-principal-grey';
                  this.btnCol = 'col-xs-12 col-sm-2 d-flex justify-content-center align-items-center';
                  this.warningFlag = response.data.includeButtonNo;
                  this.btnStyle = 'btn-grey';
                  this.colTxt = 'col-lg-8 col-md-8 col-10 col-sm-9 text-center pb-2';
                  if (this.warningFlag) {
                     this.colTxt = 'col-10 col-sm-5 text-center pb-2';
                     this.btnCol = 'col-12 col-sm-5 d-flex justify-content-center align-items-center';
                  }
                  break;
               }
               case 3: { // incompatibleFile
                  this.backgroudAlert = 'background-alert-carnetion';
                  this.iconAlert = 'errorcircule_white.svg';
                  this.colAlertIcon = 'col-lg-2  col-md-2 col-1';
                  this.colTxt = 'col-lg-8 col-md-8 col-10 col-sm-9 text-center pb-2';
                  this.txtTitleStyle = 'txt-principal-white tworow mb-1';
                  this.txtSubStyle = 'txt-principal-white tworow mb-1';
                  this.btnCol = 'col-xs-12 col-sm-2 d-flex justify-content-center align-items-center';
                  this.btnStyle = 'btn-alpha';
                  break;
               }
               case 4: { // errorInTheSystem
                  this.backgroudAlert = 'background-alert-carnetion';
                  this.txtTt = response.data.title;
                  this.txtSub = 'Inténtalo de nuevo más tarde.';
                  this.iconAlert = 'errorcircule_white.svg';
                  this.colAlertIcon = 'col-2';
                  this.colTxt = 'col-10 col-sm-8 text-center pb-2';
                  this.txtTitleStyle = 'txt-principal-white tworow';
                  this.txtSubStyle = 'txt-principal-white tworow';
                  this.btnCol = 'col-xs-2 col-sm-2 d-flex justify-content-center align-items-center';
                  this.btnStyle = 'btn-alpha';
                  break;
               }
               case 5: { // connectionError
                  this.backgroudAlert = 'background-alert-yellow';
                  this.txtTt = 'Error de conexión';
                  this.txtSub = 'Revisa tu conexión a internet.';
                  this.iconAlert = 'warming_grey.svg';
                  this.colAlertIcon = 'col-lg-2  col-md-2 col-1';
                  this.colTxt = 'col-10 col-sm-10 text-center py-2 px-lg-3 pl-0 pr-5';
                  this.txtTitleStyle = 'txt-grey';
                  this.txtSubStyle = 'txt-grey';
                  this.btnCol = 'col-xs-2 col-sm-2 d-flex justify-content-center align-items-center';
                  this.btnStyle = 'btn-grey';
                  break;
               }
               default: {

                  break;
               }
            }
            this.router.events.subscribe(value => {
             this.clearAll();
            });
         }else if (response.numCase === 0){ // Cerrar el modal
            this.openModal = false;
         }
      });
   }

   buttonAccept(){
      switch (this.dataAlertModal.action) {
        case 'add-period':
          break;

        default:
          break;
      }
      switch(this.dataAlertModal.numCase) {
         case 1: {
            if(this.dataAlertModal.module == "update-permissions-employee" && this.dataAlertModal.action == "back to list"){
               this.router.navigate(['/employees/list']);
            }
            break;
         }
         case 2: {
            if(this.dataAlertModal.module == "bulk-load-employees" && this.dataAlertModal.action == "download-csv"){
               //descargar el archvio
               this.employeesS.exportToCsv();
            }
            break;
         }
         case 3: {

            break;
         }
         case 4: {

            break;
         }
         case 5: {

            break;
         }
         default: {
            break;
         }
      }
      this.clearAll();
   }

   clearAll(){
      this.openModal = false;
      this.modal.alertModal.next({
         numCase:0
      });
      if(this.globals.inComponentQuiz){
         window.open(environment.baseSocketUrl, '_self', '');
      }
   }
  ngOnDestroy(){

  }
}

