import { Component, OnInit, LOCALE_ID, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsService } from 'src/app/services/errors.service';
import { InvitationService } from 'src/app/services/invitation/invitation.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service';
import {suscriberDestroyMiddleware} from '../../../middleware/suscribers-destroy.middleware';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CryptoService } from '../../../services/crypto/crypto.service';
import {environment} from '../../../../environments/environment';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ViewChild } from '@angular/core';
registerLocaleData(localeEsAr, 'es-Ar');
@Component({
  selector: 'app-guest-reply',
  templateUrl: './guest-reply.component.html',
  styleUrls: ['./guest-reply.component.scss'],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Ar' } ]
})
@suscriberDestroyMiddleware()
export class GuestReplyComponent implements OnInit {
  @ViewChild('openModal') openModal: ElementRef;

  infoEmployee: any;
  nameCompany:string="";
  idEmployee;
  idCompany;
  idInvitation;
  invitationInfo:any;
  background: any;
  urls=[];
  subscribers: any = [];
  idGuest;
  idGuestDecrypt;
  accepted:boolean;
  guestAssistance:boolean;
  htmlContent:string="";
  /* bandera que indica si ya paso la fecha limite de la invitación */
  invitationDeadlineExpired:boolean;
  thereRInvitation = false;
  
  constructor(
    private questionnares: QuestionnairesService,
    public route: ActivatedRoute, 
    private invitationService: InvitationService, 
    private modal: ModalService, 
    private errorService: ErrorsService,
    private cryptoService: CryptoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.invitationDeadlineExpired = true;
    this.idInvitation = this.route.snapshot.queryParamMap.get('invitation');
    this.idGuest = this.route.snapshot.queryParamMap.get('guest');
    this.idGuestDecrypt = this.route.snapshot.queryParamMap.get('code');
    this.getInfoInvitation();
  }
  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: '.',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    sanitize: false,
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadUrl: '/courses/uploadImage',
  toolbarPosition: 'top'
};
  getInfoInvitation(){
    const  id = {
      id:  this.idInvitation
     }
      this.subscribers.get = this.invitationService.getInvitation(id,true).subscribe(response => {
        if(response){
          this.invitationInfo =  response;
          this.htmlContent=response.description;
          const idToCompare = this.cryptoService.decryptHex(environment.encryptionKey, this.idGuest)
          this.background = response.background;
          this.invitationInfo.guests.forEach(element => {
            if(element._id === idToCompare){
              this.guestAssistance= element.assistance;
              this.subscribers.getEmployee = this.questionnares.getEmployee(element.idEmployee).subscribe((response) => {
                if (response) {
                  this.infoEmployee = response.content;
                }
              });
            }
          });
          this.subscribers.getCompany = this.questionnares.getCompanyName(this.invitationInfo.idCompany).subscribe((response) => {
            if (response) {
              this.nameCompany = response.content.companyName
            }
          });
          
          this.checkExpirationDate(response.initialDate);
          this.checkExpirationDate(response.limitDate,'limitAnswer');

        }else{
          this.router.navigate(['/NOM-035']);
        }
     });
  }

  checkExpirationDate(dataTemp:any, opcVal:string='initialDate'){
    
    const actualDateTemp = new Date();

    let dateTemp = new Date(dataTemp.date);
    dateTemp.setHours(parseInt(dataTemp.hour));
    
    if(actualDateTemp.getTime() >= dateTemp.getTime() ){
      if(opcVal === 'initialDate'){
        this.modal.error({message :"Esta invitación ya ha expirado"});
      }
    }else{
      if(opcVal === 'limitAnswer'){
        this.invitationDeadlineExpired = false;
      }
    }
  }

  replyInvitation(reply:boolean){
    this.accepted=reply;
    const answer= {
      answer:reply,
      id: this.idGuest
    }
    this.subscribers.reply = this.invitationService.answerInvitation(this.idInvitation, answer).subscribe((response: any) => {
      if (response) {
        this.openModal.nativeElement.click();        
        Swal.close();
        this.thereRInvitation = true;        
        this.modal.successful({
          title: "¡Operación exitosa!",
          message: "Has respondido la invitación",
          confirmButtonText: 'Aceptar'
        });
      }else{
        this.router.navigate(['/NOM-035']);
      }
    },
    (error) => {
        this.thereRInvitation = false
        this.errorService.handleError(error);
      });
  }
  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribers.forEach(subscription => subscription.unsubscribe());
  }
  
  reload(){
      window.location.reload();
  }
}
