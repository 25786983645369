export const Swal2Loader = {
    html: '<span class="eje"></span> <span class="time-icon-logo_2bcore fs-56"></span>',
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEscapeKey: false,
    customClass: {
        popup: 'custom-swal2-popup',
        content: 'p-0',
        htmlContainer: 'p-0'
    }
}