<div *ngIf="openModal" [ngClass]="backgroudAlert" class="max-width">
    <div class="container">

        <div class="row alert d-flex text-lg-center text-left pt-3" role="alert">
            <div [ngClass]="colAlertIcon" class="col-lg-2 col-1 justify-content-lg-end pr-0 ">
                <img class="icon" src="../../../assets/icons/{{iconAlert}}" height="45px" width="45px" alt="icon">
            </div>
            <div [ngClass]="colTxt">
                <p [ngClass]="txtTitleStyle" class="fw-bold fs-16 mb-2">{{txtTt}}</p>
                <p [ngClass]="txtSubStyle" class="fs-14 mb-0" >{{txtSub}}</p>
            </div>
            <div [ngClass]="btnCol">
                <button [ngClass]="btnStyle" class="btn" (click)="buttonAccept()">Aceptar</button> 
                <button class="btn btn-grey ml-md-1" (click)="clearAll()" *ngIf="warningFlag">No</button>
            </div>
        </div>

    </div>
</div>