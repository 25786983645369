<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <p class="text-center txt-dark fs-30">
        Clima laboral
      </p>
      <button class="btn btn-secondary fs-12 m-3 float-right txt-light" routerLink="/work/add"
        *ngIf="userInfoLogged.role === 'admin' || userInfoLogged.role ==='root'">
        <span class="icon-add"></span> Agregar Período
      </button>
    </div>
  </div>
  <div class="row">
    <!-- [ngClass]="this.userPermissions.workingEnvironment?.viewReports || this.userPermissions.workingEnvironment?.filterReports || this.userPermissions.workingEnvironment?.sendQuestionnaires || this.userPermissions.workingEnvironment?.viewStats ? '' : 'blocked'" -->
    <div class="side-list-container col-lg-3 border-1 ml-0 mr-1">
      <h1 class="fs-16 fw-bold txt-main" id="blue">Períodos:</h1>
      <select class="form-control mb-2 mr-2" [(ngModel)]="actualPeriod" (change)="getSpecificReport()">
        <option value="null" disabled selected>Seleccionar un periodo</option>
        <option [value]="period._id" *ngFor="let period of periods">{{period.name}}</option>
      </select>
      <div *ngIf="actualPeriod != 'null'">
        <h1 class="fs-16 fw-bold">Tipo de reporte:</h1>
        <!-- <div class="mb-2">
                <div class="flex-item option-indicator">
                  <input id="general" type="radio" value="1" [checked]="reportType == '1'" (change)="reportType = '1'">
                  <label for="general" class="ml-2">General</label>
                </div>
                <div class="flex-item option-indicator" *ngIf="this.userPermissions.workingEnvironment.filterReports || this.userPermissions.workingEnvironment?.sendQuestionnaires || this.userPermissions.workingEnvironment?.viewStats">
                  <input id="specific" type="radio" value="2" [checked]="reportType == '2'" (change)="getSpecificReport();reportType = '2'">
                  <label for="specific" class="ml-2">Específico</label>
                </div>
              </div> -->
        <div class="mb-2">
          <div class="flex-item">
            <input id="general" type="radio" value="1" [checked]="reportType == '1'" (change)="reportType = '1'">
            <span class="checkmark-radio"></span>
            <label for="general" class="col ml-2 fs-16 cr-pointer txt-darkgrey">General</label>
          </div>
          <div class="flex-item" *ngIf="userInfoLogged.role !== 'employee'">
            <input id="specific" type="radio" value="2" [checked]="reportType == '2'"
              (change)="getSpecificReport();reportType = '2'">
            <span class="checkmark-radio"></span>
            <label for="specific" class="col ml-2 fs-16 cr-pointer txt-darkgrey">Específico</label>
          </div>
        </div>
      </div>

      <div *ngIf="reportType == '2'">
        <h1 class="fs-16 fw-bold">Centro</h1>
        <div class="flex-item option-indicator" *ngFor="let workCenter of selectedPeriod?.workCenter">
          <label class="row position-relative d-flex align-items-center">
            <input id="workcenter - {{workCenter._id}}" type="checkbox" [value]="workCenter._id"
              [checked]="checkStatusWorkCenter(workCenter)" (click)="updateSelectedWorkCenter(workCenter)">
            <span class="checkmark-square"></span>
            <label for="workcenter - {{workCenter._id}}" class="label-check">{{workCenter.name}}</label>
          </label>

        </div>
        <div *ngIf="selectedWorkCenters.length>0">
          <h1 class="fs-16 fw-bold">Área</h1>
          <ng-container *ngFor="let area of areas">
            <div class="flex-item option-indicator" *ngIf="area.unique">
              <label class="row position-relative d-flex align-items-center">
                <input id="{{area.workCenter}}- {{area.area}}" type="checkbox" [value]="area"
                  [checked]="checkStatusArea(area.area)" (click)="updateSelectedArea(area.area, area.workCenter)">
                <span class="checkmark-square"></span>
                <label for="{{area.workCenter}}- {{area.area}}" class="label-check">{{area.area}}</label>
              </label>
            </div>
          </ng-container>

        </div>

      </div>
      <button *ngIf="actualPeriod != 'null'" class="btn btn-secondary fs-12 my-3 float-left"
        (click)="changeActivePeriod()"> Consultar </button>

      <div class="list-group" id="list-tab" role="tablist" *ngIf="false">
        <a *ngFor="let period of periods" class="side-list list-group-item list-group-item-action"
          [ngClass]="activePeriod==period._id ? '' : '' " id="" data-toggle="list" href="" role="tab" aria-controls=" "
          (click)="changeActivePeriod(period._id)">{{period.name}}</a>
        <!-- <a class="side-list list-group-item list-group-item-action" id="" data-toggle="list" href="" role="tab" aria-controls=" " >Periodo 2</a>
                <a class="side-list list-group-item list-group-item-action" id="" data-toggle="list" href="" role="tab" aria-controls=" ">Periodo 3</a>
                <a class="side-list list-group-item list-group-item-action" id="" data-toggle="list" href="" role="tab" aria-controls=" ">Periodo 4</a> -->
      </div>
    </div>
    <!--<div class="select-period col-12 mb-3">
        <h1 class="fs-16 text-primary fw-bold">Periodos:</h1>
          <select class="custom-select my-1 mr-sm-2" (change)="changeActivePeriod($event.target.value)"
          [(ngModel)]="activePeriod"
          >
            <option [value]="undefined" selected>Selecciona un periodo</option>
            <option *ngFor="let period of periods" [value]="period._id"
            >{{ period.name }}</option>
          </select>
        </div>-->
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="!this.router.url.includes('/work/work') &&
     !this.router.url.includes('/work/send') &&
     !this.router.url.includes('/work/stats') &&
     !this.router.url.includes('/work/add')"
    class="col-lg-9 float-right d-flex flex-wrap justify-content-center p-0 w-100">
    <div class="d-lg-flex justify-content-lg-between width-100 p-0">
      <div class="d-lg-flex p-0">
        <button *ngIf="this.userPermissions.workingEnvironment?.viewStats;"
          class="btn btn-secondary fs-12 my-3 mr-lg-2 ml-lg-3" routerLink="/work/stats"> Estadísticas</button>
      </div>
      <div class="d-lg-flex">
        <div class="justify-content-center mr-lg-2"
          *ngIf="userInfoLogged.role === 'admin' || userInfoLogged.role === 'root'">
          <button class="btn btn-secondary fs-12 my-3 ml-lg-auto mr-lg-0" (click)="downloadPDF()">
            <span class="icon-download fs-20"></span>
            Descargar reporte
          </button>
        </div>
        <div class="justify-content-center mr-lg-2">
          <button *ngIf="this.userPermissions.workingEnvironment?.sendQuestionnaires"
            class="btn btn-secondary fs-12 my-3 mr-lg-0" (click)="sendQuiz()">
            <span class="icon-enviar3x fs-18"></span>
            Enviar encuestas
          </button>
        </div>
        <div class="justify-content-center">
          <button class="btn btn-secondary fs-12 my-3"
            *ngIf="this.userPermissions.workingEnvironment.sendQuestionnaires" data-toggle="modal"
            data-target="#changeDate">
            <img src="assets/icons/edit-date.svg" class="img mr-1 ml-0" width="24px" height="24px" alt="">
            Editar fecha límite
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="generatePDF">
  <div class="col-12 d-flex chart" #containerChartReport id="containerChartReport">
    <div id="chart" class="" #chart *ngIf="chartOptions?.series">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis" [colors]="chartOptions.colors"
        [tooltip]="chartOptions.tooltip"></apx-chart>
    </div>
    <div class="labels ">
      <ng-container *ngFor="let data of promedios">
        <p class="p-labels one" *ngIf="data <= 20">{{data}}</p>
        <p class="p-labels two" *ngIf="data > 20 && data <= 40">{{data > 99 ? (data | number:'3.0-0'): (data |
          number:'2.0-0')}}</p>
        <p class="p-labels three" *ngIf="data > 40 && data <= 60">{{data > 99 ? (data | number:'3.0-0'): (data |
          number:'2.0-0')}}</p>
        <p class="p-labels four" *ngIf="data > 60 && data <= 80">{{data > 99 ? (data | number:'3.0-0'): (data |
          number:'2.0-0')}}</p>
        <p class="p-labels five" *ngIf="data > 80 && data <= 100">{{data > 99 ? (data | number:'3.0-0'): (data |
          number:'2.0-0')}}</p>
      </ng-container>
    </div>
  </div>
</div>

<!--modal-->
<span #sendQuizModal class="hide" data-toggle="modal" data-target="#sendQuizModal"></span>
<div class="modal fade px-0" id="sendQuizModal" tabindex="-1" aria-labelledby="sendQuizModal" aria-hidden="true">
  <div class="mx-auto modal-dialog modal-dialog-centered">

    <div class="modal-content">
      <div class="w-100 d-flex justify-content-end pt-2 pr-2" data-dismiss="modal"><img
          src="../../../assets/icons/quiz/cross.svg" alt="" height="12"></div>
      <div class="modal-header border-0">
        <label class="modal-title fs-14 txt-blue pb-4 fw-bold">
          Esta acción enviará un correo a los colaboradores que no han contestado la encuesta
        </label>
      </div>
      <div class="modal-footer border-0 pt-0 justify-content-center">
        <button type="button" class="btn-secondary fs-14 mr-3" data-dismiss="modal"
          (click)="sureModal()">Aceptar</button>
      </div>
    </div>
  </div>
  <!-- para evitar residuos cuando lo saquen de sesión -->
  <span #dismisseActiveModal class="hide" data-dismiss="modal"></span>
</div>

<div class="modal fade" id="changeDate" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="changeDateLabel" aria-hidden="true">
  <div class="modal-dialog mt-200">
    <div class="modal-content">
      <div class="modal-body px-4">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row mt-3">
          <div class="col">
            <h3 class="text-center color-003F9B fs-16 fw-bold">Editar fecha límite:</h3>
            <p class="text-center color-003F9B fs-14">Al editar la fecha podrás enviar nuevamente el cuestionario.</p>
          </div>
        </div>
        <form (ngSubmit)="updateDate(changeDateForm)" id="changeDateForm" #changeDateForm="ngForm">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="form-group">
                <label class="fs-12 color-003F9B mb-0" for="dateInput">Fecha limite:</label>
                <input class="form-control" [class.invalid]="dateInputInvalid" (change)="cleanErrors('date')"
                  type="date" id="dateInput" name="date" [(ngModel)]="changeDate.date"
                  [min]="today | date: 'yyyy-MM-dd'" required>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="form-group">
                <label class="fs-12 color-003F9B mb-0" for="hourInput">Hora limite</label>
                <select class="form-control" [class.invalid]="hourInputInvalid" (change)="cleanErrors('hours')"
                  id="hourInput" name="hour" [(ngModel)]="changeDate.hour" required>
                  <option value="{{hour}}" *ngFor="let hour of hours; index as i" [attr.selected]="i==0 ? true: null">
                    {{hour}}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-center my-4">
          <button type="button" class="mx-2 fs-12 btn btn-secondary btn-modal" data-dismiss="modal"
            (click)="changeDateForm.reset({date: '',hour: '00:00'})">Cancelar</button>
          <button type="submit" form="changeDateForm" class="mx-2 fs-12 btn btn-secondary btn-modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading-container" [ngClass]="loading">
  <div
    style="width: 150px; height: 150px; background-color: white; display: flex; justify-content: center; align-items: center; border-radius: 25px;">
    <div>
      <div class="spinner mx-auto"></div>
      Generando PDF...
    </div>

  </div>
</div>