export const MENU_ITEMS = {
    comunicacion: {
        key: 'comunicacion',
        icon: 'time-icon-menu_comunication',
        route: '/posts/menu/list',
        label: 'Comunicación',
    },
    invite: {
        key: 'invitaciones',
        icon: 'time-icon-menu_invitations',
        route: '/invite/events',
        label: 'Invitaciones',
    },
    registerHours: {
        key: 'registerHours',
        icon: 'time-icon-menu_time',
        route: '/hours-employee',
        label: 'Registro de horas',
    },
    teams: {
        icon: 'time-icon-menu_team',
        route: '/teams',
        label: 'Equipos',
    },
    projects: {
        icon: '', // fill at runtime
        route: '/projects',
        label: '', // fill at runtime
    },
    commercial: {
        icon: 'time-icon-Chart',
        route: '/comercial',
        label: 'Oportunidades',
    },

    // Submenu
    saludLaboral: {
        key: 'saludLaboral',
        icon: 'time-icon-menu_health',
        label: 'Salud laboral',
        isOpen: false,
        submenu: [
            {
                key: 'climaLaboral',
                icon: 'time-icon-menu_work_environment',
                route: '/work/work',
                label: 'Clima laboral',
            },
            {
                key: 'nom35',
                icon: 'time-icon-Report',
                route: '', // fill at runtime
                label: 'NOM-035',
            },
            {
                key: 'buzon',
                icon: 'time-icon-menu_mailbox',
                route: '', // fill at runtime
                label: 'Buzón 2BCORE',
                queryParams: {},
            }
        ]
    },

    gestionTalento: {
        key: 'gestionTalento',
        icon: 'icon-gestion-de-talento3x', // CHECK icon For not employee (admin)
        label: 'Gestión de talento',
        isOpen: false,
        submenu: [
            {
                key: 'gestionTalento',
                icon: 'icon-gestion-de-talento3x',
                route: '/employees/list',
                label: 'Expediente',
            },
        ]
    },

    register: {
        key: 'register',
        icon: 'time-icon-menu_time',
        label: '', // fill at runtime
        isOpen: false,
        submenu: [
            {
                key: 'my-register',
                icon: 'time-icon-menu_individual',
                route: '/hours-employee',
                label: 'Mi registro',
            },
            {
                key: 'team-register',
                icon: 'time-icon-menu_team',
                route: '/hours-teams',
                label: 'Equipos',
            },
        ]
    },

}
