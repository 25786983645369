import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComplaintBoxService } from '../../../services/complaint-box/complaint-box.service';
import { ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import {
  EComplaintCategories,
  EComplaintStages,
  ICommentsModel,
} from '../../../models/complaint-box/complaint-box.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PATTERNS } from '../../../constants/patterns/patterns.const';
import { environment } from '../../../../environments/environment';
import { QuestionnairesService } from 'src/app/services/questionnaires/questionnaires.service';

import {Globals} from 'src/app/globals/globals';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss'],
})
export class RegistryComponent implements OnInit, OnDestroy {
  /* Etapa en el registro de la queja-comentario */
  complaintStage: EComplaintStages;
  stages = EComplaintStages;
  /* Datos registrados sobre flujo de la queja */
  complaintData: ICommentsModel;
  /* Susbcripciones para su destruccion */
  stageSubs: Subscription;
  dataSubs: Subscription;
  /* Para su uso en binding HTML */
  categories = EComplaintCategories;
  /* FormGroups para validar campos */
  welcomeFg: FormGroup;
  folioFg: FormGroup;
  categoryFg: FormGroup;
  personalDataFg: FormGroup;
  /* Datos obtenido de la URL*/
  idCompany: string;
  email: string;
  url='';
  nameCompany: any = '';
  //bandera para swtichear si es empelaod o no.
  isEmployee: boolean = false;
  userInfoLogged;
  constructor(
    private route: ActivatedRoute,
    private complaintService: ComplaintBoxService,
    private fb: FormBuilder,
    private questionnares: QuestionnairesService,
    public globals: Globals,
    private auth: AuthService
  ) {
    this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.userInfoLogged.role = sessionStorage.getItem('role');
      if(this.userInfoLogged._id){
        if(this.userInfoLogged.role == 'employee'){
          this.isEmployee = true;
        }
        this.initForms();
      }
      this.idCompany = sessionStorage.getItem('idCompanySelected');
      this.email = this.userInfoLogged.email;
    });
  }

  ngOnInit(): void {
    this.idCompany = this.route.snapshot.queryParams.idCompany;
    this.email = this.route.snapshot.queryParams.email;

    this.initSubs();
    // ! Antes se recoge desde los parámetros la información.
      // this.idCompany = this.route.snapshot.queryParams.idCompany;
      // this.email = this.route.snapshot.queryParams.email;

    if (this.email) {
      this.complaintService.complaintStage.next(this.stages.FOLIO);
    }
    this.url = `${environment.baseSocketUrl}/#/comment?idCompany=${this.idCompany}`;
    this.getCompanyName();
    this.globals.flagBoxInvalidFolio=false;
    this.globals.flagBoxMailNotFound=false;
  }
  ngOnDestroy(): void {
    this.stageSubs.unsubscribe();
    this.complaintService.resetData();
  }

  /**
   * Inicializa subscripciones a datos de servicio
   */
  initSubs(): void {
    this.stageSubs = this.complaintService.complaintStage.subscribe(
      (value: EComplaintStages) => {
        this.complaintStage = value;
      }
    );
    this.dataSubs = this.complaintService.complaintData.subscribe(
      (value: ICommentsModel) => {
        this.complaintData = value;
      }
    );
  }
  /**
   * Inicializa formularios
   */
  initForms(): void {
    this.welcomeFg = this.fb.group({
      corporateEmail: [
        this.userInfoLogged.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(PATTERNS.email),
        ]),
      ],
    });
    this.folioFg = this.fb.group({
      complaintFolio: ['', Validators.compose([Validators.required])],
    });
    this.categoryFg = this.fb.group({
      selectedCategory: ['', Validators.compose([Validators.required])],
    });
    this.personalDataFg = this.fb.group({
      commentaryText: ['', Validators.compose([Validators.required, Validators.maxLength(1000)])],
      privacyCheckbox: [false, Validators.compose([Validators.requiredTrue])],
    });
  }

  /**
   * Actualizaciones hacia el servicio
   */
  sendEmail(): void {
    this.globals.flagBoxMailNotFound =false;
    if(this.isEmployee){
      this.complaintService.complaintStage.next(this.stages.CATEGORY);
    }else{
      const mailSubs = this.complaintService
      .updateEmail(this.welcomeFg.value.corporateEmail, this.idCompany)
      .subscribe(() => {
        mailSubs.unsubscribe();
      });

    }
  }
  validateFolio(): void {
    this.globals.flagBoxInvalidFolio =false;
    const codeSubs = this.complaintService
      .validateFolio(
        this.folioFg.value.complaintFolio,
        this.idCompany,
        this.email || this.welcomeFg.value.corporateEmail
      )
      .subscribe(() => {
        codeSubs.unsubscribe();
      });
  }
  updateCategory(): void {
    this.complaintService.updateCategory(
      this.categoryFg.value.selectedCategory
      );
  }

  sendComments(): void {
    let data = {
      email: this.userInfoLogged.email,
      names: this.userInfoLogged.name,
      surnames: this.userInfoLogged.lastName,
      secondSurname: this.userInfoLogged.secondSurname,
      employeeId: this.userInfoLogged.idEmployee
    }
    
    const commentsSubs = this.complaintService
      .sendComplaintComments(
        this.personalDataFg.value.commentaryText,
        this.idCompany,
        data
      )
      .subscribe(() => {
        commentsSubs.unsubscribe();
      });
  }
  newComment(){
    this.complaintStage=0;
    window.location.reload();

  }
  goBackEmployee(){
    if(this.isEmployee){
      this.complaintService.complaintStage.next(this.stages.WELCOME);
    }
  }
  /**
   * Obtiene el nombre de la empresa
   */
  getCompanyName(){
    this.questionnares.getCompanyName(this.idCompany).subscribe(response => {
      if (response){
        this.nameCompany = response.content.companyName;
      }
    });
  }
}
