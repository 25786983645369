 <div class="fs-12 mb-2 desktop">
    <div class="row mr-2">
        <ul class="d-none fs-12 mb-2">
            <li *ngFor="let routes of routes | paginate: { itemsPerPage: 1, currentPage: p }">
                {{ routes }}
                {{ p }}
            </li>
        </ul>
            <div class="paginator text-center col-12 d-lg-block">
                <pagination-controls previousLabel="Atrás |" nextLabel="| Siguiente" (pageChange)= "pageChanged($event)"
                                     class="pagination-Control working-navigation-paginator fs 12">
                                     
                </pagination-controls>
            </div>
            
    </div>
</div>

<div class="fs-12 mb-2 mobile">
    <div class="row">
        <ul class="d-none fs-12 mb-2">
            <li *ngFor="let routes of routes | paginate: { itemsPerPage: 1, currentPage: p }">
                {{ routes }}
                {{ p }}
            </li>
        </ul>
            <div class="paginator text-center col-12 d-lg-block">
                <pagination-controls previousLabel="Atrás |" nextLabel="| Siguiente" (pageChange)= "pageChanged($event)"
                                     class="pagination-Control working-navigation-paginator fs 12" maxSize="0">
                                     
                </pagination-controls>
            </div>
            
    </div>
</div>