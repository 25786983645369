<!-- <div class="container d-block d-lg-none">
    <div class="container mt-5 align-middle">
        <div class="row pl-4 pr-4">
            <div class="col-12 text-center">
                Esta sección debe ser realizada en una computadora.
            </div>
        </div>
        <div class="row mt-3 pt-4">
            <div class="d-flex mx-auto mb-5">
                <button class="btn-secondary">Salir</button>
            </div>
        </div>
    </div>
</div> -->
<!-- assets/img/logos/01.svg -->

<div class="container">
    <div class="container mt-2 mb-4 mx-auto" *ngIf="visible">
        <div class="row">
            <a href="#" [routerLink]="['/home/welcome']">
                <img src="assets/img/logos/01.svg" class="logo mr-2" width="124px" height="40px">
            </a>
            <div class="col-lg d-lg-flex justify-content-lg-end">
                <div class="col-lg-4 pr-0">
                    <div class="row d-flex justify-content-end px-lg-4">
                        <span class="mr-2 px-0 text-right my-auto fw-bold txt-blue fs-13">Empresa: </span>
                        <p class="mr-2 px-0 text-right my-auto fw-bold  fs-12">{{nameCompany}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="visible" class="card mt-4">
        <div class="row mx-auto col-12 pb-3 pt-3">
            <div class="col-lg-1 pt">
                <img class="clockImage mt-n5 mt-lg-0" src="../../../../assets/img/test/timer.svg">
            </div>
            <div class="col-lg-8">
                <div class="fw-bold txt-blue fs-16">
                    {{infoEmployee?.name}} {{infoEmployee?.surnames}}
                </div>
                <div class="txt-lightgray fs-12 pt-2">
                    {{infoEmployee?.marketstall}}
                </div>
                <div class="row txt-darkgrey justify-content-lg-center">
                    <div class="col-lg-5 fs-12">
                        Empresa: {{nameCompany}}
                    </div>
                    <div class="col-lg-3 fs-12">
                        Centro: {{infoEmployee?.workplace}}
                    </div>
                    <div class="col-lg-4 fs-12">
                        Área: {{infoEmployee?.area}}
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mx-auto d-block text-right order-first order-lg-last">
                <div class="fw-bold ">
                    No. de empleado <span class="txt-light-blue">{{infoEmployee?.employeeNumber}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5 fs-21 txt-grey fw-black">
        <div class="row pl-4 pr-4">
            <div class="col-12 text-center">
                Guía de Referencia I
            </div>
        </div>
        <div class="row mt-3">
            <div class="text-center">
                CUESTIONARIO PARA IDENTIFICAR A LOS TRABAJADORES QUE FUERON SUJETOS A ACONTECIMIENTOS TRAUMÁTICOS
                SEVEROS
            </div>
        </div>
    </div>

    <div class="col-12 form-inline ml-1 pt-4 fs-20 txt-blue px-0" *ngIf="visible">
        Instrucciones
        <div class="cssToolTip infoTool pl-2" (mouseenter)="displayInfo = 'inline'" (mouseleave)="displayInfo = 'none'">
            <img src="assets/icons/questionary/info_fill.svg" alt="info">
            <div class="content-tool fs-12 text-left" [style.display]="displayInfo">
                <div class="row">
                    <div class="col-12 px-3 border-bottom mb-2">
                        <div class="row">
                            <div class="ml-2">
                                <img src="assets/icons/info.svg" width="25px" alt="info-tooltip">
                            </div>
                            <div class="col pl-3 py-2">
                                <p class="mb-0 pl-3">Información importante e Instrucciones</p>
                            </div>
                            <div class="col-2 py-1 closebtn text-right" (click)="displayInfo = 'none'">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="#B3B3B3"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="#B3B3B3"
                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 px-3">
                        <p>Con la finalidad de establecer los elementos para identificar, analizar y prevenir los
                            factores de riesgo psicosocial, así como para promover un entorno organizacional favorable
                            en los centros de trabajo, te invitamos a resolver
                            el siguiente cuestionario; antes de contestarlo es importante recordar lo siguiente:
                        </p>
                        <ul>
                            <li>1. Verificar que las condiciones de las instalaciones y mobiliario en dónde te
                                encuentres sean idóneas</li>
                            <li>2. Entender claramente que la finalidad del cuestionario es identificar, analizar y
                                prevenir los factores de riesgo psicosocial para promover un entorno organizacional
                                favorable en los centros de trabajo.</li>
                            <li>3. Es muy importante que sepas que tus respuestas se manejaran de acuerdo con la
                                PRIVACIDAD Y CONFIABILIDAD DEL MANEJO DE LOS DATOS DE TU EMPRESA, el uso de la
                                información proporcionada y sus resultaros serán EXCLUSIVAMENTE
                                para fines de mejora del ambiente de trabajo.</li>
                            <li>4. En el siguiente cuestionario no existen respuestas correctas o incorrectas.</li>
                            <li>5. Considera EL ÚLTIMO MES para dar tu respuesta a cada pregunta.</li>
                        </ul>
                        <p>
                            Selecciona SI o No cada pregunta.
                        </p>
                        <p>
                            ¡Gracias por tu tiempo!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ml-1 pt-3 pb-2 fs-14 txt-grey">
        Contesta las siguientes preguntas seleccionando una de las opciones disponibles. Todos los campos son
        obligatorios.
    </div>
    <button (click)="saveProgress()" class="btn-secondary fs-12" *ngIf="visible">Guardar Progreso</button>
    <form [formGroup]="test1" (ngSubmit)="checkAnswers()">
        <div class="fs-14">
            <div class="card mt-5 mb-4" [ngClass]="{'with-sidebar': withSidebar}">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue">
                    I) Acontecimiento traumático severo
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1">
                    <div class="col-lg-6 text-center text-cyan">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-right text-cyan d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">
                <div class="row pl-4 pr-4 mb-2">
                    <div class="row col-lg text-left">
                        <div class="col-lg">Ha presentado o sufrido alguna vez, durante o con motivo del trabajo un
                            acontecimiento como los siguientes:</div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg text-left">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Accidente que tenga como consecuencia la muerte, la pérdida de un miembro o una
                            lesión grave
                        </div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I1" value="No" formControlName="I1"
                                        (change)="answerTrigger('No','1.1')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I1'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I1" value="Si" formControlName="I1"
                                        (change)="answerTrigger('Si','1.1')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I1'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Asaltos</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I2" value="No" formControlName="I2"
                                        (change)="answerTrigger('No','1.2')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I2'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I2" value="Si" formControlName="I2"
                                        (change)="answerTrigger('Si','1.2')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I2'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Actos violentos que derivaron en lesiones graves</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I3" value="No" formControlName="I3"
                                        (change)="answerTrigger('No','1.3')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I3'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I3" value="Si" formControlName="I3"
                                        (change)="answerTrigger('Si','1.3')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I3'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Secuestro</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I4" value="No" formControlName="I4"
                                        (change)="answerTrigger('No','1.4')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I4'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I4" value="Si" formControlName="I4"
                                        (change)="answerTrigger('Si','1.4')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I4'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Amenazas</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I5" value="No" formControlName="I5"
                                        (change)="answerTrigger('No','1.5')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I5'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I5" value="Si" formControlName="I5"
                                        (change)="answerTrigger('Si','1.5')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I5'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="hr-m-2">

                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col-auto pr-0">•</div>
                        <div class="col">Cualquier otro que ponga en riesgo su vida o salud, y/o la de otras personas
                        </div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I6" value="No" formControlName="I6"
                                        (change)="answerTrigger('No','1.6')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I6'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I6" value="Si" formControlName="I6"
                                        (change)="answerTrigger('Si','1.6')" required>
                                    <span *ngIf="preSubmit || !test1.controls['I6'].invalid; else elseBlock"
                                        class="checkmark-radio"></span>
                                    <ng-template #elseBlock>
                                        <span class="checkmark-radio-required"></span>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-5 mb-4" [style.color]="blockedText" [ngClass]="{'with-sidebar': withSidebar}">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold txt-blue"
                    [style.color]="blockedTextTitle">
                    II) Recuerdos persistentes sobre el acontecimiento (durante el último mes):
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1">
                    <div class="col-lg-6 text-center text-cyan">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-right text-cyan d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido recuerdos recurrentes sobre el acontecimiento que le provocan
                            malestares?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I7" value="No" formControlName="I7"
                                        (change)="answerTrigger('No','2.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I7'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I7" value="Si" formControlName="I7"
                                        (change)="answerTrigger('Si','2.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I7'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido sueños de carácter recurrente sobre el acontecimiento, que le
                            producen malestar?
                        </div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I8" value="No" formControlName="I8"
                                        (change)="answerTrigger('No','2.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I8'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I8" value="Si" formControlName="I8"
                                        (change)="answerTrigger('Si','2.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I8'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="card mt-5 mb-4" [style.color]="blockedText" [ngClass]="{'with-sidebar': withSidebar}">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold"
                    [style.color]="blockedTextTitle">
                    III) Esfuerzo por evitar circunstancias parecidas o asociadas al acontecimiento (durante el último
                    mes):
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1">
                    <div class="col-lg-6 text-center text-cyan">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-right text-cyan d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Se ha esforzado por evitar todo tipo de sentimientos, conversaciones o
                            situaciones que le puedan recordar el acontecimiento?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I9" value="No" formControlName="I9"
                                        (change)="answerTrigger('No','3.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I9'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I9" value="Si" formControlName="I9"
                                        (change)="answerTrigger('Si','3.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I9'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Se ha esforzado por evitar todo tipo de actividades, lugares o personas que
                            motivan recuerdos del acontecimiento?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I10" value="No" formControlName="I10"
                                        (change)="answerTrigger('No','3.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I10'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I10" value="Si" formControlName="I10"
                                        (change)="answerTrigger('Si','3.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I10'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido dificultad para recordar alguna parte importante del evento?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I11" value="No" formControlName="I11"
                                        (change)="answerTrigger('No','3.3')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I11'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I11" value="Si" formControlName="I11"
                                        (change)="answerTrigger('Si','3.3')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I11'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha disminuido su interés en sus actividades cotidianas?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I12" value="No" formControlName="I12"
                                        (change)="answerTrigger('No','3.4')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I12'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I12" value="Si" formControlName="I12"
                                        (change)="answerTrigger('Si','3.4')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I12'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Se ha sentido usted alejado o distante de los demás?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I13" value="No" formControlName="I13"
                                        (change)="answerTrigger('No','3.5')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I13'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I13" value="Si" formControlName="I13"
                                        (change)="answerTrigger('Si','3.5')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I13'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha notado que tiene dificultad para expresar sus sentimientos?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I14" value="No" formControlName="I14"
                                        (change)="answerTrigger('No','3.6')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I14'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I14" value="Si" formControlName="I14"
                                        (change)="answerTrigger('Si','3.6')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I14'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido la impresión de que su vida se va a acortar, que va a morir antes
                            que otras personas o que tiene un futuro limitado?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I15" value="No" formControlName="I15"
                                        (change)="answerTrigger('No','3.7')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I15'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I15" value="Si" formControlName="I15"
                                        (change)="answerTrigger('Si','3.7')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I15'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-5 mb-4" [style.color]="blockedText" [ngClass]="{'with-sidebar': withSidebar}">
                <div class="row border-top-radius-10 mx-auto col-12 pb-3 pt-3 bg-color-grey fs-14 fw-bold"
                    [style.color]="blockedTextTitle">
                    IV) Afectación (durante el último mes)
                </div>
                <div class="row mx-auto col-12 fs-14 fw-bold pb-1 pt-1">
                    <div class="col-lg-6 text-center text-cyan">
                        <img src="../../../../assets/img/test/Question.svg" alt="image" height="15px" width="15px">
                        Sección/Pregunta
                    </div>
                    <div class="col-6 text-right text-cyan d-none d-lg-block">
                        <img src="../../../../assets/img/test/piegraph.svg" alt="image" height="15px" width="15px">
                        Respuesta
                    </div>
                </div>
                <hr class="hr-5 mt-0 mb-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido usted dificultades para dormir?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I16" value="No" formControlName="I16"
                                        (change)="answerTrigger('No','4.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I16'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I16" value="Si" formControlName="I16"
                                        (change)="answerTrigger('Si','4.1')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I16'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha estado particularmente irritable o le han dado arranques de coraje?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I17" value="No" formControlName="I17"
                                        (change)="answerTrigger('No','4.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I17'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I17" value="Si" formControlName="I17"
                                        (change)="answerTrigger('Si','4.2')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I17'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha tenido dificultad para concentrarse?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I18" value="No" formControlName="I18"
                                        (change)="answerTrigger('No','4.3')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I18'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I18" value="Si" formControlName="I18"
                                        (change)="answerTrigger('Si','4.3')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I18'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Ha estado nervioso o constantemente en alerta?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I19" value="No" formControlName="I19"
                                        (change)="answerTrigger('No','4.4')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I19'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I19" value="Si" formControlName="I19"
                                        (change)="answerTrigger('Si','4.4')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I19'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>


                <hr class="hr-m-2">


                <div class="row pl-4 pr-4">
                    <div class="row col-lg">
                        <div class="col">¿Se ha sobresaltado fácilmente por cualquier cosa?</div>
                    </div>
                    <div class="row d-flex flex-row-reverse col-lg-4 align-items-center">
                        <div class="col-auto row mx-auto mx-lg-0">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I20" value="No" formControlName="I20"
                                        (change)="answerTrigger('No','4.5')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I20'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>No</label>
                            </div>
                        </div>
                        <div class="col-auto row mx-auto mx-lg-0" [ngClass]="{'pr-0': withSidebar}">
                            <div class="col-auto pl-1">
                                <label class="position-relative">
                                    <input type="radio" name="I20" value="Si" formControlName="I20"
                                        (change)="answerTrigger('Si','4.5')" required>
                                    <span *ngIf="blockSections; else elseBlockNoBloacked"
                                        class="checkmark-radio-blocked">
                                    </span>
                                    <ng-template #elseBlockNoBloacked>
                                        <span *ngIf="preSubmit || !test1.controls['I20'].invalid; else elseBlock"
                                            class="checkmark-radio"></span>
                                        <ng-template #elseBlock>
                                            <span class="checkmark-radio-required"></span>
                                        </ng-template>
                                    </ng-template>
                                </label>
                            </div>
                            <div class="col-auto pl-1 pt-1">
                                <label>Sí</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row mt-3 pt-4 pb-4">
                <div class="d-flex mx-auto">
                    <button [disabled]="test1.invalid" (click)="nextQuest()" class="btn-secondary fs-12"
                        *ngIf="visible">Continuar</button>
                </div>
            </div>
        </div>
    </form>
</div>
