import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AwsCognitoService {

  constructor(private http: HttpClient) { }

  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details: any = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid+profile',
      redirect_uri: environment.redirectURL,
      client_id: '2im05nvcoa1iqm6o5p3ju21p6q'
    };
    const formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');
    return this.http.post(environment.cognitoTokenURL,
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
  }


  public getRefreshTokenFromCognito(): Observable<any> {
    const token = sessionStorage.getItem('refresh_token')
    const details: any = {
      grant_type: 'refresh_token',
      refresh_token: token,
      scope: 'openid+profile',
      redirect_uri: environment.redirectURL,
      client_id: '2im05nvcoa1iqm6o5p3ju21p6q'
    };
    const formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');
    return this.http.post(environment.cognitoTokenURL,
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
  }

}