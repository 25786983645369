export interface CatalogActivity {
    value: string;
    viewValue: string;
}
export const catalogActivities: CatalogActivity[] = [
    /*
    {value: 'asueto', viewValue: 'INCIDENCIA: ASUETO'},
    {value: 'baja', viewValue: 'INCIDENCIA: BAJA'},
    {value: 'falta', viewValue: 'INCIDENCIA: FALTA'},
    {value: 'INCAPACIDAD', viewValue: 'INCIDENCIA: INCAPACIDAD'},
    {value: 'pcgds', viewValue: 'INCIDENCIA: PERMISO CON GOCE DE SUELDO'},
    {value: 'psgdc', viewValue: 'INCIDENCIA: PERMISO SIN GOCE DE SUELDO'},
    {value: 'vacaciones', viewValue: 'INCIDENCIA: VACACIONES'},
    */
    {value: 'proyecto', viewValue: 'Ordinario: Proyecto'},
    {value: 'alianzas', viewValue: 'Ordinario: Actividades alianzas tecnológicas'},
    {value: 'certi-cursos', viewValue: 'Ordinario: Cursos - Certificaciones'},
    {value: 'eventos', viewValue: 'Ordinario: Eventos'},
    {value: 'actividades-2bcore', viewValue: 'Ordinario: Otras actividades 2BCORE'},
    {value: 'shadow', viewValue: 'Ordinario: Shadow'},
    {value: 'poc', viewValue: 'Ordinario: Apoyo a propuesta comercial'},
    {value: 'asignacion', viewValue: 'Ordinario: Asignación con cliente'},
    {value: 'sin-actividad', viewValue: 'Sin actividad asignada'},
];