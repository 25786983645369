import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Employee } from '@pmo/teams/team/team.const';

@Component({
  selector: 'app-avatar-collection',
  templateUrl: './avatar-collection.component.html',
  styleUrls: ['./avatar-collection.component.scss']
})
export class AvatarCollectionComponent implements OnInit {
  @Input() allEmployees: Employee[] = [];
  @Input() team: string[] = [];
  private readonly MAX_TEAM_TO_SHOW = 3;
  public teamMap = [];
  public otherTeam: number = 0;

  ngOnInit(): void {
    this.buildCollection(this.allEmployees, this.team);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allEmployees'] && changes['allEmployees'].currentValue) {
      
      this.buildCollection(this.allEmployees, this.team);
    }
  }

  private buildCollection(allEmployees: Employee[], team: string[]) {
    this.teamMap = allEmployees
        ?.slice(0, this.MAX_TEAM_TO_SHOW);

    this.otherTeam = team?.length > this.MAX_TEAM_TO_SHOW ? team?.length - this.MAX_TEAM_TO_SHOW : 0;
  }

}
