import { Injectable } from '@angular/core';
import { ResponseContent } from '../../models';
import { map } from 'rxjs/internal/operators';

//Servicios
import { HttpService } from '../http-service/http.service';

// MODELOS
import { EmployeeModel } from 'src/app/models/employees/employee.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { IQuestionnairesModel, IQuestionnairesEmployee } from '../../models/questionnaires/questionary.model';

// Servicios
import { CryptoService } from 'src/app/services/crypto/crypto.service';

// ENVIROMENTS
import * as environment from 'src/environments/environment';

import { forkJoin } from 'rxjs';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


/**
 * Servicio para autenticación de usuarios y datos personales
 * @export
 */
export class EmployeesService {

  /**Para report-navegation */
  allProfileRoutesToShow = new BehaviorSubject<string[]>([]);

  /** Lista que almacena la lista errónea de usuario, que no cumplen con el formato */
  wrongUserList = [];

  allEmployees = new BehaviorSubject<EmployeeModel[]>([]);

  project = new BehaviorSubject<{
    questionnaire: [{ idEmployee: string }],
    _id: '',
    name: string,
    sended: boolean
  }>({ questionnaire: [{ idEmployee: '' }], _id: '', name: '', sended: false });
  AreaSelected = new BehaviorSubject<string>('');
  Center = new BehaviorSubject<string>('');
  projectSelected = new BehaviorSubject<IQuestionnairesEmployee>({});
  projectName = new BehaviorSubject<IQuestionnairesModel>({});

  isEditing = new BehaviorSubject<{ value: boolean, id: string }>({ value: false, id: '' });

  notSelected = new BehaviorSubject<boolean>(true);

  idEmployeeInfo: string;

  dataEmployeEditing = new BehaviorSubject<any>(null);

  /* Porcentaje total de la carga masiva */
  bulkUploadProgress = new BehaviorSubject<number>(0);
  /* El porcentaje de cada una de las peticiones de carga masiva */
  private bulkUploadProgressBrokenDown = {};
  /* Tamaño total aproximado de datos por enviar */
  private bulkLoadSize = 0;
  /** Arreglo que almacena los nombres de las columnas del csv a cargar
   * (utilizado para agregar de que columnas existe un error y generar el csv de retroalimentación) */
  csvColumnTitles = [];
  /** Lista que almacena los usuarios descartados, por limites de la licencia Promocional */
  listOfDiscardedUsers = [];


  /**
   * Creates an instance of AuthService.
   * @param {HttpService} http
   * @param {errorsService} errorsService
   */
  constructor(
    private http: HttpService,
    private cryptoService: CryptoService
  ) {
  }

  /**
   * Obtenemos el numero de peticiones y se inicia la barra de progreso
   * @param employeeListSizePa
   */
  private getTotalNumberRequests(employeeListSizePa) {
    this.bulkUploadProgressBrokenDown = {};
    let totalNumberRequests = 0;

    totalNumberRequests = (employeeListSizePa > 120) ? 5 : totalNumberRequests;
    totalNumberRequests = (employeeListSizePa <= 120) ? 4 : totalNumberRequests;
    totalNumberRequests = (employeeListSizePa <= 60) ? 3 : totalNumberRequests;
    totalNumberRequests = (employeeListSizePa <= 30) ? 2 : totalNumberRequests;
    totalNumberRequests = (employeeListSizePa <= 15) ? 1 : totalNumberRequests;

    // objeto que alacena  el porcentaje de cada petición
    for (let index = 0; index < totalNumberRequests; index++) {
      this.bulkUploadProgressBrokenDown[`${index}`] = 0;
    }

    return totalNumberRequests;
  }

  /**
   * Carga masiva
   */
  bulkLoadBuilder(employeesList, idCompany) {

    this.listOfDiscardedUsers = [];

    this.bulkUploadProgress.next(0);// reiniciar el porcentaje de la carga masiva
    let allEndPoints = [];// Almacena los enpoints a suscribirse

    //número de peticiones
    let totalNumberRequests = this.getTotalNumberRequests(employeesList.length);

    const cantEle = Math.ceil(employeesList.length / totalNumberRequests);// se redondea para arriba para abarcar todos los empleados

    for (let index = 1; index <= totalNumberRequests; index++) {
      const employeesListTemp = employeesList.splice(0, cantEle);// Cada lotes a insertar
      if (employeesListTemp.length > 0) {
        const eleEndPoint = this.bulkLoad(employeesListTemp, idCompany, index, totalNumberRequests);// end-point
        allEndPoints.push(eleEndPoint);
      }
    }

    return forkJoin(allEndPoints);
  }

  bulkLoad(employeesList, idCompany, requestsNumber, totalNumberRequests) {
    return this.http.postWithProgress('/staff/bulkLoad', { employeesList, idCompany }, true).pipe(map((response) => {

      if (response.body && response.body.status === 400) {
        throw new Error(response.body.data.message);
      }

      //En la primera petición obtenemos un tamaño aproximado de todas la carga masiva;
      if (requestsNumber === 1) {
        if (response.type === HttpEventType.UploadProgress) {
          this.bulkLoadSize = response.total * totalNumberRequests;
        }
      }

      // porcentaje
      if (response.type === HttpEventType.UploadProgress) {
        this.bulkUploadProgressBrokenDown[`${requestsNumber}`] = Math.round(response.loaded / this.bulkLoadSize * (100 - totalNumberRequests));
        let toalTemp = 0;
        for (const iterator of Object.values(this.bulkUploadProgressBrokenDown)) {
          toalTemp = toalTemp + Number(iterator);
        }
        this.bulkUploadProgress.next(toalTemp);
      }

      //respuesta
      if (response.type === HttpEventType.Response) {
        if (response.status == 200 && response.statusText === 'OK') {
          this.bulkUploadProgress.next(this.bulkUploadProgress.getValue() + 1);

          //obtenemos la lista mala si ahi
          if (response.body.data.listOfDiscardedUsers.length > 0) {
            this.decryptUserAndAddToWrongList(response.body.data.listOfDiscardedUsers);
          }
          return response;

        } else {
          throw new Error('error');
        }
      }

    }));
  }

  decryptUserAndAddToWrongList(listOfDiscardedUsers) {
    for (const iteratorUser of listOfDiscardedUsers) {
      for (const iteratorKey of Object.keys(iteratorUser)) {
        if (iteratorKey !== "birthDate" && iteratorKey !== "dateAdmission") {
          iteratorUser[iteratorKey] = this.cryptoService.decryptData(environment.environment.encryptionKey, iteratorUser[iteratorKey]);
        }
      }
      this.listOfDiscardedUsers.push(Object.values(iteratorUser));
    }
  }

  /**
   * Genera csv con los empleados que no cumplieron el formato para agregado en la carga masiva
   */
  exportToCsv() {
    const separator = ',';
    const filename = "Errores_carga_masiva.csv";

    let csvColumnTitlesTemp = Object.assign([], this.csvColumnTitles);
    csvColumnTitlesTemp.push("RETROALIMENTACIÓN");
    const columns = csvColumnTitlesTemp;

    let csvRetro: string = columns.join(separator) + '\n';

    for (let indeRow = 0; indeRow < this.wrongUserList.length; indeRow++) {//Recorrido de las filas

      for (let indexC = 0; indexC < (csvColumnTitlesTemp.length - 1); indexC++) {
        csvRetro += this.wrongUserList[indeRow].data[indexC] + ",";
      }

      for (let i = 0; i < this.wrongUserList[indeRow].wrongColumnList.length; i++) {//Recorrido de las columnas y sus errores
        csvRetro += this.wrongUserList[indeRow].wrongColumnList[i].columns + "(";

        for (let ixd = 0; ixd < this.wrongUserList[indeRow].wrongColumnList[i].detail.length; ixd++) {
          csvRetro += this.wrongUserList[indeRow].wrongColumnList[i].detail[ixd];
        }
        csvRetro += ")"
      }
      csvRetro += "\n"
    }
    csvRetro += "\n"

    const blob = new Blob(["\ufeff", csvRetro]);
    // if (navigator.msSaveBlob) { // IE 10+
    //   navigator.msSaveBlob(blob, filename);
    // } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.wrongUserList = [];
      // }
    }
  }

  /**
   * Obtiene los datos de un empleado
   * @param id
   */
  getEmployee(id: string) {
    return this.http.post('/staff/get', { id }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          this.dataEmployeEditing.next(response.content == null ? false : response.content);
          return response.content == null ? false : response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Obtiene todos los empleados de una empresa
   * @param id
   */
  getAllCompanyEmployees(id: string) {
    return this.http.post('/staff/getAll', { id }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Obtiene todos los áreas y centros de trabajo de la empresa
   * @param id
   */
  getWorkplaceAndAreas(idCompany: string) {
    return this.http.post('/company/getWorkplaceAndAreas', { idCompany }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Guarda un empleado.
   * @param employee
   */
  createEmployee(employee: EmployeeModel) {

    /** Encriptado */
    employee.image = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.image);
    employee.idCompany = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.idCompany);
    employee.employeeNumber = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.employeeNumber);
    employee.names = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.names);
    employee.surnames = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.surnames);
    employee.secondSurname = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.secondSurname);
    employee.marketStall = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.marketStall);
    employee.workplace = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.workplace);
    employee.area = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.area);
    employee.gender = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.gender);
    employee.roles = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.roles);
    // employee.birthDate
    employee.civilStatus = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.civilStatus);
    employee.lastDegreeStudies = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.lastDegreeStudies);
    // employee.dateAdmission
    employee.officePhone = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.officePhone);
    employee.personalMobilePhone = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.personalMobilePhone);
    employee.businessEmail = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.businessEmail);
    /* employee.personalMail = this.cryptoService.encryptData(environment.environment.encryptionKey, employee.personalMail); */

    return this.http.post('/staff/create', { employee }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response.content == null ? false : response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Actualiza un empleado.
   * @param _employee
   */
  updateEmployee(_employee: any) {
    /** Encriptado */
    const employee = {
      _id: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee._id),
      area: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.area),
      birthDate: _employee.birthDate,
      businessEmail: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.businessEmail),
      civilStatus: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.civilStatus),
      dateAdmission: _employee.dateAdmission,
      employeeNumber: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.employeeNumber),
      gender: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.gender),
      idCompany: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.idCompany),
      image: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.image ? _employee.image : ''),
      lastDegreeStudies: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.lastDegreeStudies),
      marketStall: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.marketStall),
      names: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.names),
      officePhone: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.officePhone),
      personalMobilePhone: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.personalMobilePhone),
      roles: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.roles),
      secondSurname: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.secondSurname ? _employee.secondSurname : ''),
      surnames: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.surnames),
      workplace: this.cryptoService.encryptData(environment.environment.encryptionKey, _employee.workplace),
    }

    return this.http.post('/staff/update', { employee }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response.content === null ? false : response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Actualiza el estatus del empleado.
   * @parma _idEmployee
   */
  updateEmployeeStatus(_idEmployee: string, enabled: boolean) {

    const params = {
      _id: _idEmployee,
      enabled: enabled
    }

    return this.http.post('/staff/enabled', { params }, true).pipe(

      map((response: ResponseContent) => {
        return response?.clave === 'OK' ? true : false;
      })

    );

  }

  /**
   * Servicio para eliminar el/los usuarios que se agregen al array
   * @param ids
   */
  removeEmployee(ids: Array<string>) {

    const params = { staffIds: ids };

    return this.http.post('/staff/remove', { params }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   *Busqueda de imágen  en 64 bits por número de empleado
   *
   * @param {*} id
   * @param {UserModel[]} user
   * @returns {string}
   * @memberof UsersService
   */
  searchImg(id, user: EmployeeModel[]): string {
    let img64 = '';
    for (const item of user) {
      if (item._id.toString() === id.toString()) {
        img64 = item.img64;
      }
      return img64
    }
  }

  /**
 *Método para obtener la imágen del usuario
 *
 * @param {UserModel[]} array
 * @returns {UserModel[]}
 * @memberof UsersService
 */
  getImageUser(array: EmployeeModel[]): EmployeeModel[] {
    if (array.length > 0) {
      for (const user of array) {
        if (user.image) {
          const json = {
            folder: 'profileImage',
            image: `${user.image}`
          };
          this.http.post('/file/getFile', json, true).subscribe((resp: any) => {
            if (resp && resp.clave == 'ok') {
              user.img64 = `data:image/${user.image.replace('.', '')};base64,${resp.content}`;
            }
          })
        }
      }
    }
    return array
  }

  /**
 *Método que recibe el nombre  del usuario por servicio
 *
 * @param {*} nameImage
 * @returns
 * @memberof UsersService
 */
  getImage(nameImage) {
    return this.http.post('/getImage', { id: nameImage }).pipe(map((value: any) => {
      if (value.clave === 'ok') {
        return value.content
      } else {
        return false;
      }
    }))
  }
  /**
   *Obtiene la imágen del usuario n sesión
   *
   * @param {*} image
   * @returns
   * @memberof UsersService
   */
  getImageUserCurrent(image: any) {
    if (image) {
      const json = {
        folder: 'profileImage',
        image: `${image.id}${image.ext}`
      };
      return this.http.post('/file/download', json, true).pipe(map(
        (resp: any) => {
          if (resp && resp.clave === 'OK') {
            return `data:image/${image.ext.replace('.', '')};base64,${resp.content}`;
          }
        }
      ));
    }
  }

  /* Envía cuestionario individual
  */
  sendQuestionnaire(employeeData) {
    return this.http.post('/staff/sendQuestionnaire', employeeData, true).pipe(map((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        return response.content;
      } else {
        return false;
      }
    }));
  }

  /**
   * Envío masivo de cuestionarios atodos los empleados de la empresa seleccionada
   */
  sendQuestionnaires(employeesData): Observable<any> {
    return this.http.post('/staff/sendQuestionnaires', employeesData, true).pipe(map((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        return true;
      } else {
        return false;
      }
    }));
  }

  /**
   * Obtiene proyectos de empleado
   */
  getProjects(idCompany, idEmployee) {
    return this.http.post('/staff/getProjects', { idCompany, idEmployee }, true).pipe(
      map((response: ResponseContent) => {
        if (response && response.clave === 'OK') {
          return response.content;
        } else {
          return false;
        }
      }
      )
    );
  }

  /**
   * Envío masivo de cuestionarios atodos los empleados de la empresa seleccionada
   */
  updateQuestionnaireDate(employeesData): Observable<any> {
    return this.http.post('/staff/updateQuestionnaireDate', employeesData, true).pipe(map((response: ResponseContent) => {
      if (response && response.clave === 'OK') {
        return true;
      } else {
        return false;
      }
    }));
  }
}
