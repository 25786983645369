<div class="container w-90">
    <div class="row">
    <div class="col-12 d-md-none">
        <a [routerLink]="'/hours-employee'" class="text-decoration-none">
            <span class="time-icon-back fs-16"></span>
        </a>
    </div>
    <div class="col-12 d-none d-md-block">
        <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
    </div>
    <div class="col-12 mt-3 mt-md-4">
        <p class="title mb-0">Historial</p>
    </div>
    <div class="col-12 d-flex flex-column-reverse flex-md-row justify-content-between mt-4 mt-md-5">
        <div class="d-flex justify-content-between align-items-center tags">
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_approved mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Aprobada
            </span>
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_pending mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Por aprobar
            </span>
            <span class="d-flex align-items-center mr-3 status-label flex-column flex-md-row">
                <span class="icon-24 time-icon-status_rejected mr-2 mt-4 mb-3 my-md-0">
                    <span class="path1"></span><span class="path2"></span>
                </span>
                Rechazada
            </span>
        </div>
        
        <div class="menus pb-0" *ngIf="sort">
            <button mat-button [matMenuTriggerFor]="orderMenu" #orderMenuTrigger="matMenuTrigger">
                Ordenar
                <span class="align-middle" [ngClass]="orderMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
            </button>
            <mat-menu #orderMenu="matMenu">
                <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                    Ordenar por
                </button>
                <button mat-menu-item (click)="checkOrder('asc')" class="menu-filter-item">
                    <mat-checkbox [checked]="sort.active !== '' && sort.direction === 'asc'" disabled="true">Ascendente</mat-checkbox>
                </button>        
                <button mat-menu-item (click)="checkOrder('desc')" class="menu-filter-item">
                    <mat-checkbox [checked]="sort.active !== '' && sort.direction === 'desc'" disabled="true">Desendente</mat-checkbox>
                </button>
            </mat-menu>
        
            <button mat-button [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
                Filtrar
                <span class="align-middle" [ngClass]="filterMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
            </button>
            <mat-menu #filterMenu="matMenu">
                <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                    Filtrar por
                </button>
                <button mat-menu-item (click)="applyFilter('approved')" class="menu-filter-item">
                    <mat-checkbox [checked]="currentFilter === 'approved'" disabled="true">Aprobada</mat-checkbox>
                </button>
                <button mat-menu-item (click)="applyFilter('pending')" class="menu-filter-item">
                    <mat-checkbox [checked]="currentFilter === 'pending'" disabled="true">Por aprobar</mat-checkbox>
                </button>
                <button mat-menu-item (click)="applyFilter('rejected')" class="menu-filter-item">
                    <mat-checkbox [checked]="currentFilter === 'rejected'" disabled="true">Rechazada</mat-checkbox>
                </button>
            </mat-menu>
        </div>
    </div>
    
    <div class="col-12 mt-32">
        <div class="border-table d-none d-lg-block">
        <table mat-table [dataSource]="activitiesDataSource" matSort [matSortDirection]="'asc'" [matSortDisabled]="true"
            class="deep-table">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    Fecha
                </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef>
                    Actividad
                </th>
                <td mat-cell *matCellDef="let element"> {{element.activity | titlecase  }} </td>
            </ng-container>
    
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by activity type">
                    Tipo de actividad
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.type | titlecase  }} </td>
            </ng-container>
    
            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef>
                    Tiempo
                </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.duration.hours > 0"> {{ element.duration.hours }} h </span>
                    <span *ngIf="element.duration.minutes > 0"> {{ element.duration.minutes }} min </span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    Descripción
                </th>
                <td mat-cell *matCellDef="let element"> {{element.description }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    Estatus
                </th>
                <td mat-cell *matCellDef="let element"> 
                    <span class="icon-24 time-icon-status_approved mr-1" *ngIf="element.status == 'approved'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                    <span class="icon-24 time-icon-status_pending mr-1" *ngIf="element.status == 'pending'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                    <span class="icon-24 time-icon-status_rejected mr-1" *ngIf="element.status == 'rejected'">
                        <span class="path1"></span><span class="path2"></span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="options" stickyEnd>
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="optionsMenu" aria-label="Toggle menu options of element">
                        <span class="icon-24 time-icon-options"></span>
                    </button>
                    <mat-menu #optionsMenu="matMenu">
                        <ng-container *ngIf="element.status == 'approved';else editTemp">
                            <button mat-menu-item class="deep-menu-item" [routerLink]="['/collaborator/details', element.id]">
                                <span class="time-icon-Report mr-2"></span>
                                <span class="label">Ver detalles</span>
                            </button>
                        </ng-container>
                        <ng-template #editTemp>
                            <button mat-menu-item class="deep-menu-item" [routerLink]="['/update', element.id]">
                                <span class="time-icon-option_edit mr-2"></span>
                                <span class="label">Editar</span>
                            </button>
                        </ng-template>
                    </mat-menu>
                
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching</td>
            </tr>
        </table>
        </div>
        <mat-paginator #paginator
        [pageSize]="5"
        [disabled]="false"
        [showFirstLastButtons]="false"
        [hidePageSize]="true"
        [pageIndex]="0"
        aria-label="Select page" [hidden]="true"></mat-paginator>

        <div class="accordion-height d-lg-none">

            <mat-accordion multi class="deep-accordion-history deep-button" hideToggle="true">
                <mat-expansion-panel *ngFor="let element of activitiesDataSource | async" #elementRef>
                    <mat-expansion-panel-header>
                        <mat-panel-title> 
                            <p class="mb-0 col">
                                <span class="time-icon-status_approved fs-20 mr-3 align-middle" *ngIf="element.status == 'approved'">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                <span class="time-icon-status_pending fs-20 mr-3 align-middle" *ngIf="element.status == 'pending'">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                <span class="time-icon-status_rejected fs-20 mr-3 align-middle" *ngIf="element.status == 'rejected'">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                <span class="align-middle">
                                    {{ element.date | date:'shortDate' }}
                                </span>
                            </p>
                             <p class="mb-0 mr-3">
                                <span *ngIf="element.duration.hours > 0"> {{ element.duration.hours }} h </span>
                                <span *ngIf="element.duration.minutes > 0"> {{ element.duration.minutes }} min </span>
                            </p>
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="elementRef._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="label label--primary">Actividad</p>
                    <p class="label mt-2">{{ element.activity | titlecase }}</p>

                    <p class="label label--primary mt-4">Tipo de actividad</p>
                    <p class="label mt-2">{{ element.type | titlecase }}</p>

                    <p class="label label--primary mt-4">Descripción</p>
                    <p class="label mt-2">{{ element.description }}</p>

                    <ng-container *ngIf="element.status == 'approved';else btnEditTemp">
                        <button mat-flat-button color="primary" class="w-100 mt-32" [routerLink]="['/collaborator/details', element.id]">Detalles de actividad</button>
                    </ng-container>
                    <ng-template #btnEditTemp>
                        <button mat-flat-button color="primary" class="w-100 mt-32" [routerLink]="['/update', element.id]">Editar actividad</button>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center mt-4">
        <nav aria-label="Page navigation example">
            <ul class="pagination align-items-center">
                <li class="page-item">
                    <a class="page-link" aria-label="Previous" (click)="paginator.previousPage()">
                        <span class="time-icon-paginator_prev" aria-hidden="true"></span>
                    </a>
                </li>
                <li class="page-item" *ngFor="let page of [].constructor(paginator.getNumberOfPages()); index as i">
                    <a class="page-link" [class.active]="paginator.pageIndex == i" (click)="selectPage(i)">{{ i + 1 }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" aria-label="Next" (click)="paginator.nextPage()">
                        <span class="time-icon-paginator_next" aria-hidden="true"></span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>

    </div>
</div>