/**rxjs */
import { Subscriber } from 'rxjs';

/**
 * Decorador para destruccion en `ngOnDestroy()` de cada componente
 * @export
 * @returns any
 */
export function suscriberDestroyMiddleware(): any {
    return function(target: any) {
        /** El decorador crea la instancia ngOnDestroyDecorator */
        target.prototype.ngOnDestroy = ngOnDestroyDecorator(target.prototype.ngOnDestroy);

        /**
		 * Desuscrpción de observables
		 * @param {any} f
		 * @returns
		 */
        function ngOnDestroyDecorator(f) {
            return function() {
                /** Guarda el resultado de `ngOnDestroy` a la variable `superData` */
                const superData = f ? f.apply(this, arguments) : null;

                /** Desuscripción a toads las propiedades del objeto ´subscribers´ */
                for (const subscriberKey in this.subscribers) {
                    if (this.subscribers[subscriberKey] instanceof Subscriber) {
                        /** Desuscripción de cada item de subscribers del componente donde se llama `DestroySubscribersDecorator` */
                        const unsuscribe = this.subscribers[subscriberKey];
                        unsuscribe.unsubscribe();
                    }
                }

                /** retorna el valor de superData */
                return superData;
            };
        }

        /** retorna la clase del decorador */
        return target;
    };
}
