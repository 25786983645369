export const access = {
  root: {
    logoEmpresa: {view: true},
    seleccionarEmpresa: {view: true},
    perfilEmpresa: {view: true},
    notificaciones: {view: true},
    configuracion: {
      view: true,
      submenu: {
        administracionEmpresas: true,
        administracionUsuarios: true,
        historial: true,
      },
    },
    imagenPerfil: {
      view: true,
      submenu: {
        cambiarPerfil: true,
        verExpediente: true,
        cerrarSesion: true,
      },
    },
    comunicacion: {view: true},
    saludLaboral: {
      view: true,
      submenu: {
        climaLaboral: true,
        nom035: true,
        buzon: true,
      },
    },
    gestionTalento: {
      view: true,
      submenu: {
        expediente: true,
      },
    },
    invitaciones: {view: true},
    register: {
      view: true,
      submenu: {
        myRegister: true,
        teamRegister: true,
      },
    }
  },
  admin: {
    logoEmpresa: {view: true},
    seleccionarEmpresa: {view: false},
    perfilEmpresa: {view: true},
    notificaciones: {view: true},
    configuracion: {
      view: true,
      submenu: {
        administracionEmpresas: true,
        administracionUsuarios: true,
        historial: true,
      },
    },
    imagenPerfil: {
      view: true,
      submenu: {
        cambiarPerfil: true,
        verExpediente: true,
        cerrarSesion: true,
      },
    },
    comunicacion: {view: true},
    saludLaboral: {
      view: true,
      submenu: {
        climaLaboral: true,
        nom035: true,
        buzon: true,
      },
    },
    gestionTalento: {
      view: true,
      submenu: {
        expediente: true,
      },
    },
    invitaciones: {view: true}
  },
  addOn: {
    logoEmpresa: {view: true},
    seleccionarEmpresa: {view: false},
    perfilEmpresa: {view: true},
    notificaciones: {view: true},
    configuracion: {
      view: true,
      submenu: {
        administracionEmpresas: true,
        administracionUsuarios: true,
        historial: true,
      },
    },
    imagenPerfil: {
      view: true,
      submenu: {
        cambiarPerfil: true,
        verExpediente: true,
        cerrarSesion: true,
      },
    },
    comunicacion: {view: true},
    saludLaboral: {
      view: true,
      submenu: {
        climaLaboral: true,
        nom035: true,
        buzon: true,
      },
    },
    gestionTalento: {
      view: true,
      submenu: {
        expediente: true,
      },
    },
    invitaciones: {view: true}
  },
  employee: {
    logoEmpresa: {view: true},
    seleccionarEmpresa: {view: false},
    perfilEmpresa: {view: false},
    notificaciones: {view: true},
    configuracion: {
      view: false,
      submenu: {
        administracionEmpresas: false,
        administracionUsuarios: false,
        historial: false,
      },
    },
    imagenPerfil: {
      view: true,
      submenu: {
        cambiarPerfil: false,
        verExpediente: true,
        cerrarSesion: true,
      },
    },
    comunicacion: {view: true},
    saludLaboral: {
      view: true,
      submenu: {
        climaLaboral: false,
        nom035: false,
        buzon: true,
      },
    },
    gestionTalento: {
      view: false,
      submenu: {
        expediente: false,
      },
    },
    invitaciones: {view: true}
  }
};
