import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TeamService } from 'src/app/services/team/team.service';
import { IncidenceComponent } from './incidence/incidence.component';

interface Teams {
  id: string;
  employeeId: string;
  team: string;
  employee: string;
  jobTitle: string;
  status: string;
  avatar: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  public readonly breadcrumb: Breadcrumb[] = [
    CRUMBS.areaLeadMain,
  ];
  public teams: Teams[] = [
    { id:'1', employeeId: '001', team: 'Producto', employee: 'Lucero Jiménez', jobTitle: 'Consultor UX', status: 'pendiente', avatar: 'assets/users/female-avatar.svg' },
    { id:'2', employeeId: '002', team: 'Producto', employee: 'Jesús Acha', jobTitle: 'Consultor UX', status: 'pendiente', avatar: 'assets/users/male-avatar.svg' },
    { id:'3', employeeId: '003', team: 'Producto', employee: 'Ethel Magaña', jobTitle: 'Consultor UX', status: 'pendiente', avatar: 'assets/users/female-avatar.svg' },
    { id:'4', employeeId: '004', team: 'Producto', employee: 'David Matamoroz', jobTitle: 'Testing', status: 'rechazada', avatar: 'assets/users/male-avatar.svg' },
    { id:'5', employeeId: '005', team: 'Producto', employee: 'David García', jobTitle: 'Testing', status: 'aprobada', avatar: 'assets/users/male-avatar.svg' },
    { id:'6', employeeId: '006', team: 'Producto', employee: 'Oscar Loa', jobTitle: 'Consultor UX', status: 'aprobada', avatar: 'assets/users/male-avatar.svg' },
    { id:'7', employeeId: '007', team: 'Producto', employee: 'Miguel Serrano', jobTitle: 'Testing', status: 'aprobada', avatar: 'assets/users/male-avatar.svg' },
    { id:'8', employeeId: '008', team: 'Becarios', employee: 'Andrea Mendoza', jobTitle: 'Trainee', status: 'pendiente', avatar: 'assets/users/female-avatar.svg' },
    { id:'9', employeeId: '009', team: 'Becarios', employee: 'Leonardo Alarcón', jobTitle: 'Trainee', status: 'aprobada', avatar: 'assets/users/male-avatar.svg' },
  ];
  public uniqueTeams: string[] = [];
  userInfoLogged;
  constructor(
    private team: TeamService,
    private auth: AuthService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // this.uniqueTeams = this.getUniqueTeams();
    // console.log(this.uniqueTeams);

    this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.team.getTeamsByLeader(this.userInfoLogged.idEmployee).subscribe(res=>{
        if(res){
          this.uniqueTeams = res;
        }
      })
    })

  }

  public getUniqueTeams(): string[] {
    const teamNames = this.teams.map(team => team.team);
    return Array.from(new Set(teamNames));
  }

  public getEmployeesForTeam(team: string): Teams[] {
    return this.teams.filter(employee => employee.team === team);
  }

  public openIncidenceDialog(employeeId: string, employeeName: string) {
    const dialogRef = this.dialog.open(IncidenceComponent, {
      width: '464px',
      data: {
        employeeId: employeeId,
        employeeName: employeeName,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}
