import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ModalService } from '.././../services/modal/modal.service';
/* ROUTES */
import { Router } from '@angular/router';

/* SERVICES */
import { TokenService } from '../../services/token/token.service';
import {AuthService} from '../../services/auth/auth.service';

/**
 * Interceptor de llamadas `HTTP`
 * @export
 * @class ResponseInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	/** Almacena la URL consultada que generó el error 404*/
	failedURL: string;
	/** Bandera utilizada para detener ejecución de peticiones al servidor tras un error 404 o 500 */
	hasHttpError: boolean;

	/**
	 * Creates an instance of ResponseInterceptor.
	 * @param {Router} router
	 * @memberof ResponseInterceptor
	 */
	constructor(
		private router: Router,
		private tokenService: TokenService,
		private errorService: ModalService,
    private authSv: AuthService,
	) {
	}

	/**
	 * Validación de error 500 y redireccionar a home
	 * @param {any} event
	 * @memberof ResponseInterceptor
	 */
	errorResponse(event) {
		if (event && event.status) {
			if (event.status === 500 || event.status === 504) {
				this.authSv.signOut()
			}
		}
	}


	/**
	 * Interceptor de respuestas de llamas con HttpClient
	 * @param {HttpRequest<any>} req
	 * @param {HttpHandler} next
	 * @returns {Observable<HttpEvent<any>>}
	 * @memberof ResponseInterceptor
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(map((event: HttpEvent<any>) => {
			this.errorResponse(event);
			const ev: any = event;

			if (!ev.body && ev.type !== 0) {
				/** Mensaje de error al tener una respuesta nula */
				ev.body = {
					error: {
						clave: '',
						message: `Sin contenido en respuesta de servicio ${ev.url}`
					},
					data: ''
				};
			} else if (ev.body) {
				if (ev.body.securityContext) {

					if(ev.body.securityContext.token){

						this.tokenService.setToken(ev.body.securityContext.token);

					}else{

						this.tokenService.set_xSession(ev.body.securityContext.xSession);
					}

				}
			}
			return ev;
		}));
	}
}
 /* /!**
   * Interceptor de respuestas de llamas con HttpClient
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof ResponseInterceptor
   *!/
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map ((event: HttpEvent<any>) => {
      this.initialServicesResponseValidation(event);
      this.errorResponse(event);
      const ev: any = event;

      if (!ev.body && ev.type !== 0) {
        /!** Mensaje de error al tener una respuesta nula *!/
        ev.body = {
          error: {
            clave: '',
            message: `Sin contenido en respuesta de servicio ${ev.url}`
          },
          data: ''
        };
      } else if (req.url.includes("/auth/login") && ev.body && ev.body.data && ev.body.data.content && ev.body.data.content.token) {
        this.tokenService.setToken(ev.body.idata.content.token);
      }
      return ev;
    }));
  }*/
