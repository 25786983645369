import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-no-user-data',
  templateUrl: './no-user-data.component.html',
  styleUrls: ['./no-user-data.component.scss']
})
export class NoUserDataComponent{

  constructor(private authSv: AuthService) { }

  logOut(){
    this.authSv.signOut();
  }

}
