export class questionsTest3Text {

    public static SECTION_ONE = 
[
    '<div class="col-auto pr-0">1)</div><div class="col"> El espacio donde trabajo me permite realizar mis actividades de manera segura e higiénica</div>',
    '<div class="col-auto pr-0">2)</div><div class="col"> Mi trabajo me exige hacer mucho esfuerzo físico</div>',
    '<div class="col-auto pr-0">3)</div><div class="col"> Me preocupa sufrir un accidente en mi trabajo</div>',
    '<div class="col-auto pr-0">4)</div><div class="col"> Considero que en mi trabajo se aplican las normas de seguridad y salud en el trabajo</div>',
    '<div class="col-auto pr-0">5)</div><div class="col"> Considero que las actividades que realizo son peligrosas</div>'
]

    public static SECTION_TWO = 
[
    '<div class="col-auto pr-0">6)</div><div class="col"> Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno</div>',
    '<div class="col-auto pr-0">7)</div><div class="col"> Por la cantidad de trabajo que tengo debo trabajar sin parar</div>',
    '<div class="col-auto pr-0">8)</div><div class="col"> Considero que es necesario mantener un ritmo de trabajo acelerado</div>'
    
]

public static SECTION_THREE = 
[   
    '<div class="col-auto pr-0">9)</div><div class="col"> Mi trabajo exige que esté muy concentrado</div>',
    '<div class="col-auto pr-0">10)</div><div class="col"> Mi trabajo requiere que memorice mucha información</div>',
    '<div class="col-auto pr-0">11)</div><div class="col"> En mi trabajo tengo que tomar decisiones difíciles muy rápido</div>',
    '<div class="col-auto pr-0">12)</div><div class="col"> Mi trabajo exige que atienda varios asuntos al mismo tiempo</div>'
]

public static SECTION_FOUR = 
[
    '<div class="col-auto pr-0">13)</div><div class="col"> En mi trabajo soy responsable de cosas de mucho valor</div>',
    '<div class="col-auto pr-0">14)</div><div class="col"> Respondo ante mi jefe por los resultados de toda mi área de trabajo</div>',
    '<div class="col-auto pr-0">15)</div><div class="col"> En el trabajo me dan órdenes contradictorias</div>',
    '<div class="col-auto pr-0">16)</div><div class="col"> Considero que en mi trabajo me piden hacer cosas innecesarias</div>'
]

public static SECTION_FIVE = 
[
    '<div class="col-auto pr-0">17)</div><div class="col"> Trabajo horas extras más de tres veces a la semana</div>',
    '<div class="col-auto pr-0">18)</div><div class="col"> Mi trabajo me exige laborar en días de descanso, festivos o fines de semana</div>',
    '<div class="col-auto pr-0">19)</div><div class="col"> Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o personales</div>',
    '<div class="col-auto pr-0">20)</div><div class="col"> Debo atender asuntos de trabajo cuando estoy en casa</div>',
    '<div class="col-auto pr-0">21)</div><div class="col"> Pienso en las actividades familiares o personales cuando estoy en mi trabajo</div>',
    '<div class="col-auto pr-0">22)</div><div class="col"> Pienso que mis responsabilidades familiares afectan mi trabajo</div>'
]

public static SECTION_SIX = 
[
    
    '<div class="col-auto pr-0">23)</div><div class="col"> Mi trabajo permite que desarrolle nuevas habilidades</div>',
    '<div class="col-auto pr-0">24)</div><div class="col"> En mi trabajo puedo aspirar a un mejor puesto</div>',
    '<div class="col-auto pr-0">25)</div><div class="col"> Durante mi jornada de trabajo puedo tomar pausas cuando las necesito</div>',
    '<div class="col-auto pr-0">26)</div><div class="col"> Puedo decidir cuánto trabajo realizo durante la jornada laboral</div>',
    '<div class="col-auto pr-0">27)</div><div class="col"> Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo</div>',
    '<div class="col-auto pr-0">28)</div><div class="col"> Puedo cambiar el orden de las actividades que realizo en mi trabajo</div>'
]

public static SECTION_SEVEN = 
[
    '<div class="col-auto pr-0">29)</div><div class="col"> Los cambios que se presentan en mi trabajo dificultan mi labor</div>',
    '<div class="col-auto pr-0">30)</div><div class="col"> Cuando se presentan cambios en mi trabajo se tienen en cuenta mis ideas o aportaciones</div>'
]

public static SECTION_EIGHT = 
[
    '<div class="col-auto pr-0">31)</div><div class="col"> Me informan con claridad cuáles son mis funciones</div>',
    '<div class="col-auto pr-0">32)</div><div class="col"> Me explican claramente los resultados que debo obtener en mi trabajo</div>',
    '<div class="col-auto pr-0">33)</div><div class="col"> Me explican claramente los objetivos de mi trabajo</div>',
    '<div class="col-auto pr-0">34)</div><div class="col"> Me informan con quién puedo resolver problemas o asuntos de trabajo</div>',
    '<div class="col-auto pr-0">35)</div><div class="col"> Me permiten asistir a capacitaciones relacionadas con mi trabajo</div>',
    '<div class="col-auto pr-0">36)</div><div class="col"> Recibo capacitación útil para hacer mi trabajo</div>'
]

public static SECTION_NINE = 
[
    '<div class="col-auto pr-0">37)</div><div class="col"> Mi jefe ayuda a organizar mejor el trabajo</div>',
    '<div class="col-auto pr-0">38)</div><div class="col"> Mi jefe tiene en cuenta mis puntos de vista y opiniones</div>',
    '<div class="col-auto pr-0">39)</div><div class="col"> Mi jefe me comunica a tiempo la información relacionada con el trabajo</div>',
    '<div class="col-auto pr-0">40)</div><div class="col"> La orientación que me da mi jefe me ayuda a realizar mejor mi trabajo</div>',
    '<div class="col-auto pr-0">41)</div><div class="col"> Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo</div>',
]

public static SECTION_TEN = 
[
    '<div class="col-auto pr-0">42)</div><div class="col"> Puedo confiar en mis compañeros de trabajo</div>',
    '<div class="col-auto pr-0">43)</div><div class="col"> Entre compañeros solucionamos los problemas de trabajo de forma respetuosa</div>',
    '<div class="col-auto pr-0">44)</div><div class="col"> En mi trabajo me hacen sentir parte del grupo</div>',
    '<div class="col-auto pr-0">45)</div><div class="col"> Cuando tenemos que realizar trabajo de equipo los compañeros colaboran</div>',
    '<div class="col-auto pr-0">46)</div><div class="col"> Mis compañeros de trabajo me ayudan cuando tengo dificultades</div>',
]

public static SECTION_ELEVEN = 
[
    '<div class="col-auto pr-0">47)</div><div class="col"> Me informan sobre lo que hago bien en mi trabajo</div>',
    '<div class="col-auto pr-0">48)</div><div class="col"> La forma como evalúan mi trabajo en mi centro de trabajo me ayuda a mejorar mi desempeño</div>',
    '<div class="col-auto pr-0">49)</div><div class="col"> En mi centro de trabajo me pagan a tiempo mi salario</div>',
    '<div class="col-auto pr-0">50)</div><div class="col"> El pago que recibo es el que merezco por el trabajo que realizo</div>',
    '<div class="col-auto pr-0">51)</div><div class="col"> Si obtengo los resultados esperados en mi trabajo me recompensan o reconocen</div>',
    '<div class="col-auto pr-0">52)</div><div class="col"> Las personas que hacen bien el trabajo pueden crecer laboralmente</div>',
    '<div class="col-auto pr-0">53)</div><div class="col"> Considero que mi trabajo es estable</div>',
    '<div class="col-auto pr-0">54)</div><div class="col"> En mi trabajo existe continua rotación de personal</div>',
    '<div class="col-auto pr-0">55)</div><div class="col"> Siento orgullo de laborar en este centro de trabajo</div>',
    '<div class="col-auto pr-0">56)</div><div class="col"> Me siento comprometido con mi trabajo</div>',
]

public static SECTION_TWELVE = 
[
    '<div class="col-auto pr-0">57)</div><div class="col"> En mi trabajo puedo expresarme libremente sin interrupciones</div>',
    '<div class="col-auto pr-0">58)</div><div class="col"> Recibo críticas constantes a mi persona y/o trabajo</div>',
    '<div class="col-auto pr-0">59)</div><div class="col"> Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones</div>',
    '<div class="col-auto pr-0">60)</div><div class="col"> Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones</div>',
    '<div class="col-auto pr-0">61)</div><div class="col"> Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador</div>',
    '<div class="col-auto pr-0">62)</div><div class="col"> Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores</div>',
    '<div class="col-auto pr-0">63)</div><div class="col"> Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo</div>',
    '<div class="col-auto pr-0">64)</div><div class="col"> He presenciado actos de violencia en mi centro de trabajo</div>'
]

public static SECTION_FOURTEEN = 
[
    '<div class="col-auto pr-0">65)</div><div class="col"> Atiendo clientes o usuarios muy enojados</div>',
    '<div class="col-auto pr-0">66)</div><div class="col"> Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas</div>',
    '<div class="col-auto pr-0">67)</div><div class="col"> Para hacer mi trabajo debo demostrar sentimientos distintos a los míos</div>',
    '<div class="col-auto pr-0">68)</div><div class="col"> Mi trabajo me exige atender situaciones de violencia</div>',
]

public static SECTION_FIFTEEN = 
[
    '<div class="col-auto pr-0">69)</div><div class="col"> Comunican tarde los asuntos de trabajo</div>',
    '<div class="col-auto pr-0">70)</div><div class="col"> Dificultan el logro de los resultados del trabajo</div>',
    '<div class="col-auto pr-0">71)</div><div class="col"> Cooperan poco cuando se necesita</div>',
    '<div class="col-auto pr-0">72)</div><div class="col"> Ignoran las sugerencias para mejorar su trabajo</div>',
]

}